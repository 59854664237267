// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lb-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
}

.lb-separator {
  border-bottom: 1px solid black;
}

.lb-section {
  padding: 1rem;
}

.lb-section .item {
  display: flex;
  gap: 1rem;
  align-items: center;
  min-height: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Labelling/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".lb-header {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  height: 60px;\r\n}\r\n\r\n.lb-separator {\r\n  border-bottom: 1px solid black;\r\n}\r\n\r\n.lb-section {\r\n  padding: 1rem;\r\n}\r\n\r\n.lb-section .item {\r\n  display: flex;\r\n  gap: 1rem;\r\n  align-items: center;\r\n  min-height: 30px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
