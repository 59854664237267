import { generateRandomNumber, generateRandomString } from "./random";

export const donorNumber = (length) => {
  // Get the current date
  const date = new Date();
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
  const day = date.getDate().toString().padStart(2, "0");
  const second = date.getSeconds().toString();

  // Generate a random number
  const randomNumber = Math.floor(1000 + Math.random() * 9000).toString(); // Random 4 digit number

  // Combine the date and random number to create the invoice number
  const codeNumber = `${year}${month}${day}${second}-${randomNumber}`;

  return codeNumber;
};
