import React from "react";
import { connect } from "react-redux";
import { Tabs } from "../../Tabs";
import { ROLE_BLOOD_TEST_CONFIRMATION } from "../../../constants/ROLES";
import Serology from "./Serology";
import BloodGrouping from "./BloodGrouping";
import { getStorage } from "../../../utils/storage";
import { defineRole } from "../../../utils/handleAccessRoles";
import language from "../../../language";

class BloodTestConfirmation extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };

  render() {
    const canAccessCreateBloodTest_grouping = defineRole({
      roles: this.state.user.accessRole,
      menu: ROLE_BLOOD_TEST_CONFIRMATION,
      operation: "ConfirmBloodGrouping",
    });

    const canAccessCreateBloodTest_serology = defineRole({
      roles: this.state.user.accessRole,
      menu: ROLE_BLOOD_TEST_CONFIRMATION,
      operation: "confirmSerology",
    });

    let tabsOptions = [];

    if (canAccessCreateBloodTest_grouping) {
      tabsOptions.push({
        title: language[this.props.defaultLanguage].blood_grouping,
        data: <BloodGrouping {...this.props.routeParams} {...this.props} />,
      });
    }

    if (canAccessCreateBloodTest_serology) {
      tabsOptions.push({
        title: language[this.props.defaultLanguage].serology,
        data: <Serology {...this.props.routeParams} {...this.props} />,
      });
    }
    return (
      <>
        <div className="alert alert-info">
          <h3>2FA of Blood test</h3>
          <p>
            You should correct the blood test in case of mismatching with
            previous information. This will result in the rejection of the
            donation.
          </p>
        </div>

        <Tabs options={tabsOptions} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(BloodTestConfirmation);
