import { Link } from "react-router-dom";
import "./styles.css";
import { Loading } from "../Loader";

const Button = (props) => {
  if (props.type === "link") {
    return (
      <Link
        to={props.to}
        className={`btn ${props.className}`}
        style={props.styles}
        disabled={props.isSubmitting || props.disabled}
        title={props.title}
      >
        {!props.isSubmitting && props.icon && (
          <span className="material-symbols-outlined icon">{props.icon}</span>
        )}
        {props.isSubmitting ? <Loading /> : props.text}
      </Link>
    );
  }
  if (props.type === "hyperlink") {
    return (
      <a
        href={props.to}
        to={props.to}
        className={`btn ${props.className}`}
        style={props.styles}
        disabled={props.isSubmitting || props.disabled}
        title={props.title}
      >
        {props.icon && (
          <span className="material-symbols-outlined icon">{props.icon}</span>
        )}
        {props.isSubmitting ? <Loading /> : props.text}
      </a>
    );
  }
  return (
    <button
      className={`btn btn-primary ${props.className}`}
      style={props.styles}
      disabled={props.isSubmitting || props.disabled}
      onClick={!props.isSubmitting ? props.onPress : null}
      id={props.id ? props.id : "dropdownMenuButton1"}
      data-bs-toggle={props.type === "dropdown" ? "dropdown" : ""}
      aria-expanded="false"
      data-bs-auto-close={props.autoCloseType}
      title={props.title}
    >
      {!props.isSubmitting && props.icon && (
        <span className={`${process.env.REACT_APP_ICONS_TYPE} icon`}>
          {props.icon}
        </span>
      )}
      {props.isSubmitting ? <Loading /> : props.text}
    </button>
  );
};

export default Button;
