import { Checkbox } from ".";

const CheckboxQuestionnaire = (props) => {
  return (
    <div className="checkbox-group">
      {props.questionText && (
        <label>
          {props.questionText}{" "}
          {props.required && <span className="text-error">*</span>}
        </label>
      )}

      <div className="checkbox-group-item">
        {props?.answers?.map((el) => {
          return (
            <div>
              <Checkbox
                label={el.label || el.value}
                onCheck={(e) => el.onPress(el.field, el.value)}
                checked={
                  props.checked === el.value ||
                  (Object.keys(el).includes("key") && props.checked === el.key)
                }
              />
            </div>
          );
        })}
      </div>
      {props.error && <span className="text-error">{props.error}</span>}
    </div>
  );
};

export default CheckboxQuestionnaire;
