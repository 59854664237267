// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-form-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding-top: 1rem;
}

.landing-section-img img {
  aspect-ratio: 3/3;
  object-fit: contain;
  width: 100%;
}

/* home-dashboard-layout */
.home-dashboard-layout-container {
  display: flex;
  height: 100vh;
}

.dashboard-layout {
  padding-bottom: 80px;
  flex: 1 1;
  overflow-x: hidden;
  width: 100%;
}

@media screen and (max-width: 500px) {
  .auth-form-layout {
    align-items: start;
  }
}

@media screen and (max-width: 768px) {
  .auth-form-layout {
    align-items: start;
  }

  .landing-section-img img {
    aspect-ratio: 4/2;
    object-fit: contain;
    width: 100%;
    margin: 2rem 0;
  }
}

@media screen and (max-width: 980px) {
  .auth-form-layout {
    align-items: start;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/Layout/layout.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,WAAW;AACb;;AAEA,0BAA0B;AAC1B;EACE,aAAa;EACb,aAAa;AACf;;AAEA;EACE,oBAAoB;EACpB,SAAO;EACP,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;IACnB,WAAW;IACX,cAAc;EAChB;AACF;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".auth-form-layout {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  height: 100vh;\r\n  padding-top: 1rem;\r\n}\r\n\r\n.landing-section-img img {\r\n  aspect-ratio: 3/3;\r\n  object-fit: contain;\r\n  width: 100%;\r\n}\r\n\r\n/* home-dashboard-layout */\r\n.home-dashboard-layout-container {\r\n  display: flex;\r\n  height: 100vh;\r\n}\r\n\r\n.dashboard-layout {\r\n  padding-bottom: 80px;\r\n  flex: 1;\r\n  overflow-x: hidden;\r\n  width: 100%;\r\n}\r\n\r\n@media screen and (max-width: 500px) {\r\n  .auth-form-layout {\r\n    align-items: start;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 768px) {\r\n  .auth-form-layout {\r\n    align-items: start;\r\n  }\r\n\r\n  .landing-section-img img {\r\n    aspect-ratio: 4/2;\r\n    object-fit: contain;\r\n    width: 100%;\r\n    margin: 2rem 0;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 980px) {\r\n  .auth-form-layout {\r\n    align-items: start;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
