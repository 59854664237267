import React from "react";

import { ResetPassword } from "../../components/ResetPassword";
import { withRouter } from "../../utils/withRouter";

class ResetPasswordScreen extends React.Component {
  render() {
    const { routeParams } = this.props;
    return (
      <>
        <ResetPassword token={routeParams.token} type={routeParams.type} />
      </>
    );
  }
}

export default withRouter(ResetPasswordScreen);
