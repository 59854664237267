import React from "react";
import { connect } from "react-redux";
import bloodCenterQuery from "../../../utils/queries/bloodCenterQuery";
import bloodCenterSiteQuery from "../../../utils/queries/bloodCenterSiteQuery";
import { Input, Select } from "../../Input";
import { defineRoleCenterSite } from "../../../utils/handleAccessRoles";
import language from "../../../language";
import { Button } from "../../Button";
import icons from "../../../constants/icons";
import bloodTestQuery from "../../../utils/queries/bloodTest/bloodTestQuery";
import toastMessage from "../../../utils/toastMessage";
import moment from "moment";
import { PrintComponent } from "../../Print";
import Barcode from "react-barcode";
import "./styles.css";

const worksheetsType = [
  {
    label: "ABO RH worksheet",
    value: "ABO",
  },
  {
    label: "Serology Screening worksheet",
    value: "Serology",
  },
];

class Worksheets extends React.Component {
  state = {
    error: {},
    limit: 50,
    csvData: [],
    page: 1,
    type: worksheetsType[0],
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().endOf().format("YYYY-MM-DD HH:mm"),
    data: [],
  };

  componentDidMount() {
    this.getCenters();
  }
  getCenters = async (q) => {
    try {
      this.setState({
        isFetchingCenter: true,
      });

      const data = await bloodCenterQuery(this.props.defaultLanguage, {
        type: "dropdown",
        q,
        page: 1,
        limit: 50,
      });

      let center = null;
      const { centerRole } = await defineRoleCenterSite();
      if (!this.props._id && centerRole) {
        data.forEach((el) => {
          if (centerRole.includes(el._id)) {
            center = el;
          }
        });

        this.setState({ center }, () =>
          this.getCenterSites({ center: center?._id || undefined })
        );
      }

      this.setState({
        centers: data,
        isFetchingCenter: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingCenter: false });

      return [];
    }
  };

  getCenterSites = async (search = {}) => {
    try {
      this.setState({
        isFetchingCenterSite: true,
      });

      const data = await bloodCenterSiteQuery(this.props.defaultLanguage, {
        type: "dropdown",
        page: 1,
        limit: 50,
        ...search,
      });

      const { centerSiteRole } = await defineRoleCenterSite();
      let centerSite = null;

      if (!this.props._id && centerSiteRole) {
        data.forEach((el) => {
          if (centerSiteRole.includes(el._id)) {
            centerSite = el;
          }
        });

        this.setState({ centerSite });
      }

      this.setState({
        centerSites: data,
        isFetchingCenterSite: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingCenterSite: false });

      return [];
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e.target ? e.target.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });

    if (field === "center") {
      this.getCenterSites({ center: inputValue.value });
    }
  };

  donorTableHeaders() {
    let headers = [
      {
        title: "Donation No",
        key: "donationNumber",
      },
      {
        title: "Donor",
        key: "donor",
      },
      {
        title: "ABO RHDR",
        key: "bloodGroup",
      },
      {
        title: "Date",
        key: "date",
      },
      {
        title: "",
        key: "col3",
      },
      {
        title: "",
        key: "col4",
      },
    ];

    return headers;
  }

  virologyScreeningTableHeaders() {
    let headers = [
      {
        title: "Donation No",
        key: "donationNumber",
      },
      {
        title: "HIV",
        key: "hiv",
      },
      {
        title: "HBBAG",
        key: "hbbag",
      },
      {
        title: "HCV",
        key: "hcv",
      },
      {
        title: "Syphilis",
        key: "syphilis",
      },
    ];

    return headers;
  }

  handleReset() {
    this.setState({
      center: null,
      centerSite: null,
      limit: 50,
    });
  }

  onSubmit = async (type) => {
    try {
      this.setState({
        isSubmitting: true,
      });

      const {
        page,
        limit,
        center,
        centerSite,
        bloodGroup,
        type,
        startDate,
        endDate,
      } = this.state;
      const { defaultLanguage } = this.props;

      const { data } = await bloodTestQuery(defaultLanguage, {
        page,
        limit,
        center: center?.value || undefined,
        centerSite: centerSite?.value || undefined,
        bloodGroup: bloodGroup?.value || undefined,
        startCollectionDate: moment(startDate).startOf().format("YYYY-MM-DD"),
        endCollectionDate: moment(endDate).endOf().format("YYYY-MM-DD"),
        sortField: "donationData.donationNumber",
        sortOrder: 1,
      });

      let resData = [];

      data.forEach((el) => {
        resData.push({
          donationNumber: el.donation.donationNumber,
          donorNumber: el?.donor?.donorNumber,
          name: el?.donor?.firstName + " " + el?.donor?.lastName,
          bloodGroup: el.bloodGroup,
          date: moment(el.createdAt).format("YYYY-MM-DD"),
          donationInfo: `${el.donation.donationNumber}/ W / ${moment(
            el.createdAt
          ).format("YYYY-MM-DD")}
          ${el?.centerSite?.name || el?.center?.name}`,
          center: el?.center?.name,
          centerSite: el?.centerSite?.name,
        });
      });

      this.setState({
        isSubmitting: false,
      });

      this.setState({
        data: resData,
      });
    } catch (error) {
      this.setState({ isSubmitting: false });
      toastMessage("error", error);
      console.log(error);
    }
  };

  renderPaginatedItems = (data) => {
    const itemsPerPage = 13;
    const pages = Math.ceil(data.length / itemsPerPage);
    let paginatedItems = [];

    for (let i = 0; i < pages; i++) {
      const start = i * itemsPerPage;
      const end = start + itemsPerPage;
      const pageData = data.slice(start, end);

      paginatedItems.push(
        <div key={i} className="page">
          {this.state.type.value === "ABO" &&
            this.renderBloodGroupingTable(pageData)}
          {this.state.type.value === "Serology" &&
            this.renderSerologyTable(pageData)}
        </div>
      );
    }
    return paginatedItems;
  };

  renderBloodGroupingTable = (data) => {
    return (
      <table class="table table-bordered">
        <thead class="table-light">
          <tr>
            <th rowspan="2" class="align-middle">
              Donation No.
            </th>
            <th rowspan="2" class="align-middle text-center">
              ABO
              <br />
              RHD R
            </th>
            <th colspan="4" class="text-center">
              Forward
            </th>
            <th rowspan="2" class="align-middle text-center">
              BG
            </th>
            <th colspan="3" class="text-center">
              Reverse
            </th>
            <th rowspan="2" class="align-middle text-center">
              Kell
            </th>
            <th rowspan="2" class="align-middle text-center">
              AS
            </th>
            <th rowspan="2" class="align-middle text-center">
              C
            </th>
            <th rowspan="2" class="align-middle text-center">
              1st
              <br />
              Entry
            </th>
            <th rowspan="2" class="align-middle text-center">
              2nd
              <br />
              Entry
            </th>
            <th rowspan="2" class="align-middle text-center">
              Observation
            </th>
          </tr>
          <tr>
            <th class="text-center">-α</th>
            <th class="text-center">-β</th>
            <th class="text-center">-αβ</th>
            <th class="text-center">-D</th>
            <th class="text-center">A</th>
            <th class="text-center">B</th>
            <th class="text-center">O</th>
          </tr>
        </thead>
        <tbody>
          {data.map((el, i) => {
            return (
              <tr key={i}>
                <td>
                  <div>
                    <Barcode
                      width={2}
                      height={13}
                      displayValue={false}
                      value={el.donationNumber}
                    />
                  </div>
                  <div>
                    <span style={{ fontSize: 10 }}>{el.donationInfo}</span>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  renderSerologyTable = (data) => {
    return (
      <table class="table table-bordered">
        <thead class="table-light">
          <tr>
            <th rowspan="2" class="align-middle">
              Donation No.
            </th>
            <th class="align-middle text-center">HIV</th>
            <th class="text-center">HBVA</th>
            <th class="align-middle text-center">HCV</th>
            <th class="text-center">SYPHILLIS</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((el, i) => {
            return (
              <tr key={i}>
                <td>
                  <div>
                    <Barcode
                      width={2}
                      height={13}
                      displayValue={false}
                      value={el.donationNumber}
                    />
                  </div>
                  <div>
                    <span>{el.donationInfo}</span>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  renderPagination = () => {
    return (
      <div className="pagination">
        {/* <span>
          Page {page} of {pageCount}
        </span> */}
      </div>
    );
  };

  render() {
    return (
      <div className="worksheet">
        <div className="row">
          <div className="col-md-4">
            <Select
              options={worksheetsType}
              placeholder={language[this.props.defaultLanguage].select}
              label={language[this.props.defaultLanguage].worksheet_type}
              required
              value={this.state.type}
              onChange={(e) => this.onChangeText("type", e)}
              error={this.state.error.type}
            />
          </div>
          <div className="col-md-4">
            <Select
              loadOptions={(e) => this.getCenters(e)}
              leftIcon={icons.center}
              placeholder={language[this.props.defaultLanguage].select}
              label={language[this.props.defaultLanguage].center}
              required
              value={this.state.center}
              onChange={(e) => this.onChangeText("center", e)}
              error={this.state.error.center}
              isLoading={this.state.isFetchingCenter}
            />
          </div>
          <div className="col-md-4">
            <Select
              loadOptions={(e) => this.getCenterSites({ q: e })}
              leftIcon={icons.site}
              placeholder={language[this.props.defaultLanguage].select}
              label={language[this.props.defaultLanguage].center_site}
              value={this.state.centerSite}
              onChange={(e) => this.onChangeText("centerSite", e)}
              error={this.state.error.centerSite}
              isLoading={this.state.isFetchingCenterSite}
            />
          </div>
          {/* <div className="col-md-12">
          <Select
            options={aboGroups}
            leftIcon={icons.bloodGroup}
            placeholder={language[this.props.defaultLanguage].select}
            label={language[this.props.defaultLanguage].blood_group}
            value={this.state.bloodGroup}
            onChange={(e) => this.onChangeText("bloodGroup", e)}
            error={this.state.error.bloodGroup}
          />
        </div> */}
          <div className="col-md-4">
            <Input
              leftIcon={icons.calendar}
              label={language[this.props.defaultLanguage].start_date}
              value={this.state.startDate}
              onChange={(e) => this.onChangeText("startDate", e)}
              error={this.state.error.startDate}
              type="date"
            />
          </div>
          <div className="col-md-4">
            <Input
              leftIcon={icons.calendar}
              label={language[this.props.defaultLanguage].end_date}
              value={this.state.endDate}
              onChange={(e) => this.onChangeText("endDate", e)}
              error={this.state.error.endDate}
              type="date"
            />
          </div>
          <div className="col-md-4">
            <Input
              placeholder={language[this.props.defaultLanguage].enter_limit}
              label={language[this.props.defaultLanguage].limit}
              value={this.state.limit}
              onChange={(e) => this.onChangeText("limit", e)}
              error={this.state.error.limit}
              type="number"
            />
          </div>
          <div className="modal-footer">
            <Button
              text={language[this.props.defaultLanguage].reset}
              onPress={this.handleReset.bind(this)}
              className="btn-default"
            />
            <Button
              text={language[this.props.defaultLanguage].submit}
              onPress={this.onSubmit.bind(this, this.state.type?.value)}
              isSubmitting={this.state.isSubmitting}
            />
          </div>
        </div>
        {this.state.data.length > 0 && (
          <>
            <hr />
            <PrintComponent
              buttonText={language[this.props.defaultLanguage].print_worksheet}
              footerContent={
                this.state.center &&
                this.state?.center?.label +
                  " - " +
                  " " +
                  this.state?.centerSite?.label
              }
            >
              <div
                class="card-body printable-content"
                style={{ maxHeight: "60vh", overflowY: "auto" }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <div>
                      <span className="text-bold">RBC/BTD Rwanda-Kigali</span>
                    </div>
                    <div>
                      <span className="text-primary">Donor Worksheet</span>
                    </div>
                  </div>
                  <center>
                    <span className="text-primary">
                      {this.state.type.label}
                    </span>
                  </center>
                  <div style={{ flex: 1 }} />
                </div>
                <br />
                {this.renderPaginatedItems(this.state.data)}
                <div className="footer-worksheet">
                  <div>
                    <span>CNTS Rwanda-Kigali</span>
                  </div>
                  <div>
                    <span>{moment().format("lll")}</span>
                  </div>
                </div>
              </div>
            </PrintComponent>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Worksheets);
