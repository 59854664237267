import "./styles.css";
import whocagive from "../../../assets/whocagive.png";
import { Title } from "../Title";
import { connect } from "react-redux";
import language from "../../../language";

const WhoDonate = (props) => {
  const data = [
    {
      description:
        " Most people can give blood. You can donate if you are fit, healthy, weight over 50kg, and are aged between 18 and 60 years old.",
    },
    {
      description:
        " Regular donors can donate every 56 days. Platelet donors can give every 14 days, up to 24 times in a rolling year.",
    },
    {
      description:
        " Travel, medication, and health condition can affect your eligibility. Check with our guidelines or contact us for any queries.",
    },
    {
      description:
        " Pregnant women must wait six months post-childbirth. Individuals who have undergone surgery need to defer donation accordingly.",
    },
  ];
  return (
    <div className="row">
      <div className="col-md-4">
        <div className="container">
          <div className="landing-section-img">
            <img src={whocagive} />
          </div>
        </div>
      </div>
      <div className="col-md-8">
        <div className="container">
          <Title title={language[props.defaultLanguage].who_can_give_blood} />
          <div>
            {data.map((el, i) => {
              return (
                <div className="who-can-give-item" key={i}>
                  <div className="who-can-give-item-icon">
                    <span>{i + 1}</span>
                  </div>
                  <div className="who-can-give-item-description">
                    <span>{el.description}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(WhoDonate);
