export default [
  {
    label: "Blood Grouping",
    value: "bloodGrouping",
  },
  {
    label: "Serology",
    value: "serology",
  },
];
