import React from "react";
import { connect } from "react-redux";
import BloodUsage from "../../../components/BloodUsage/BloodUsage";
import { DashboardHeader } from "../../../components/Header";
import language from "../../../language";

class DonorsScreen extends React.Component {
  render() {
    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <DashboardHeader
              title={language[this.props.defaultLanguage].blood_usage}
            />
            <BloodUsage />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(DonorsScreen);
