import "./styles.css";
import { Button } from "../../Button";

const FeatureCard = (props) => {
  return (
    <div className={`col-md-4 ${props.className}`}>
      <div
        className={`card card-item-feature ${props.primary ? "primary" : ""}`}
      >
        <div className="card-body">
          <center>
            <div className="card-item-feature-icon-container">
              <img src={props.vector} />
            </div>
          </center>
          <center className="card-item-feature-description">
            <h3 className="text-primary text-bold">{props.title}</h3>
            <p>{props.description}</p>
          </center>
          <center className="card-item-feature-footer">
            <Button
              text={props.buttonText}
              className={props.btnStyles}
              onPress={props.onPress}
            />
          </center>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard;
