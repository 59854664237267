import React from "react";
import { connect } from "react-redux";
import { Tabs } from "../../components/Tabs";
import { JobPositions } from "../../components/JobPositions";
import { Departments } from "../../components/Departments";
import { AccessRoles } from "../../components/AccessRole";
import language from "../../language";

class DepartmentsScreen extends React.Component {
  render() {
    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <Tabs
              options={[
                {
                  title: language[this.props.defaultLanguage].departments,
                  data: <Departments />,
                },
                {
                  title: language[this.props.defaultLanguage].job_positions,
                  data: <JobPositions />,
                },
                {
                  title: language[this.props.defaultLanguage].access_roles,
                  data: <AccessRoles />,
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(DepartmentsScreen);
