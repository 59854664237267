const { REACT_APP_NAME } = process.env;

export const setStorage = async (data, storageName = REACT_APP_NAME) =>
  await sessionStorage.setItem(storageName, JSON.stringify(data));

export const getStorage = async (storageName = REACT_APP_NAME) => {
  const user = await JSON.parse(sessionStorage.getItem(storageName));

  return user ? user : {};
};

export const clearStorage = async (key) => {
  if (key) {
    await sessionStorage.removeItem(key);
    return {};
  }
  const user = await sessionStorage.clear();

  return user ? user : {};
};
