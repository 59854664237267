import React from "react";
import { connect } from "react-redux";
import { BloodGroups } from "../../../components/Donors";
import { getStorage } from "../../../utils/storage";
import { withRouter } from "../../../utils/withRouter";
import { Empty } from "../../../components/Empty";
import language from "../../../language";

class DonorsBloodGroupScreen extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };

  render() {
    if (!this.props.routeParams.bloodGroup)
      return <Empty title={language[this.props.defaultLanguage].no_data} />;
    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <BloodGroups bloodGroup={this.props.routeParams.bloodGroup} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(DonorsBloodGroupScreen));
