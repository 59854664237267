import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import bloodDonationQuery from "../../../utils/queries/bloodDonationQuery";
import { getStorage } from "../../../utils/storage";
import bloodGroups from "../../../constants/bloodGroups";
import moment from "moment";
import bloodCenterQuery from "../../../utils/queries/bloodCenterQuery";
import bloodCenterSiteQuery from "../../../utils/queries/bloodCenterSiteQuery";
import { fetchEquipments } from "../../../utils/queries/equipmentQuery";
import { Loading } from "../../Loader";
import { Empty } from "../../Empty";
import availableOptions from "../../../constants/availableOptions";
import { donorNumber } from "../../../utils/uniqueID";
import { fetchTransportationTemperatures } from "../../../utils/queries/transportationTemperatures";
import { findTemperatureRange } from "../../../utils/findTemperatureInRange";
import { fetchBagStatus } from "../../../utils/queries/bagStatus";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

const bagCategories = [
  {
    label: "WHOLE BLOOD",
    value: "WHOLE BLOOD",
  },
  {
    label: "APHERESIS PLATELET",
    value: "APHERESIS PLATELET",
  },
  {
    label: "APHERESIS PLASMA",
    value: "APHERESIS PLASMA",
  },
  {
    label: "RED BLOOD CELLS (RBC)",
    value: "RED BLOOD CELLS (RBC)",
  },
  {
    label: "FRESH FROZEN PLASMA (FFP)",
    value: "FRESH FROZEN PLASMA (FFP)",
  },
  {
    label: "BIO-PLASMA",
    value: "BIO-PLASMA",
  },
  {
    label: "PLATELETS",
    value: "PLATELETS",
  },
];

class NewInventory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      error: {},
      isSubmitting: false,
      volume: "",
      transportationTemperature: "",
      equipments: [],
      donation: [],
      isLoading: true,
      labels: [],
      available: availableOptions[0],
      centers: [],
      centerSites: [],
      status: {
        label: "Incoming",
        value: "incoming",
      },
      category: bagCategories[0],
      donationId: props.donationId,
      code: "",
      temperatures: [],
      inventoryStatuses: [],
      bagStatuses: [],
    };
  }

  componentDidMount = async () => {
    console.log("inventory props", this.props);
    let { error } = this.state;

    this.setState({
      code: donorNumber(4),
    });

    this.getBagStatus(true);
    this.getDonation(true);
    this.getTemperatures(true);
    this.getEquipment(true);
    this.getCenters(true);

    if (this.props._id) {
      await this.setState({
        donationId: this.props.donationId ? this.props.donationId : undefined,
        equipment: this.props.equipment
          ? {
              label: this.props.equipment.name,
              value: this.props.equipment._id,
            }
          : null,
        weight: this.props.weight,
        bloodGroup: {
          label: this.props.bloodGroup,
          value: this.props.bloodGroup,
        },
        category: {
          label: this.props.category,
          value: this.props.category,
        },
        center: this.props.center._id
          ? {
              label: this.props.center.name,
              value: this.props.center._id,
            }
          : null,
        centerSite: this.props.centerSite._id
          ? {
              label: this.props.centerSite.name,
              value: this.props.centerSite._id,
            }
          : null,
        status: this.props.status
          ? {
              label: this.props.status,
              value: this.props.status,
            }
          : null,
        label: {
          label: this.props.label,
          value: this.props.label,
        },
        expirationDate: moment(this.props.expirationDate).format("YYYY-MM-DD"),
        available: {
          label: this.props.available + "",
          value: this.props.available + "",
        },
        transportationTemperature: this.props.transportationTemperature,
        code: this.props.code,
      });
    }

    if (!this.state.donationId) {
      error.errorMessage =
        language[this.props.defaultLanguage].donation_required;
    }
  };

  getTemperatures = async () => {
    try {
      let { status } = this.state;
      this.setState({
        isFetchingTemperatures: true,
      });

      const { data } = await fetchTransportationTemperatures(
        this.props.defaultLanguage,
        { page: 1, limit: 100 }
      );

      const temperatureRange = findTemperatureRange(
        this.state.transportationTemperature,
        data
      );

      let inventoryStatuses = temperatureRange.map((el) => {
        return {
          label: el.decision,
          value: el.decision,
        };
      });

      if (inventoryStatuses && inventoryStatuses.length === 1) {
        status = inventoryStatuses[0];
      }

      this.setState({
        temperatures: data,
        isFetchingTemperatures: false,
        inventoryStatuses,
        status,
      });
    } catch (error) {
      toastMessage("error", error);

      this.setState({
        isFetchingTemperatures: false,
      });
    }
  };

  getCenters = async (isFetchingCenter) => {
    try {
      this.setState({
        isFetchingCenter,
      });

      const data = await bloodCenterQuery(this.props.defaultLanguage, {
        type: "dropdown",
      });

      this.setState({
        centers: data,
        isFetchingCenter: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingCenter: false });
    }
  };

  getSites = async (isFetchingSite, center) => {
    if (!center) return;
    try {
      this.setState({
        isFetchingSite,
      });

      const data = await bloodCenterSiteQuery(this.props.defaultLanguage, {
        center,
        type: "dropdown",
      });

      this.setState({
        centerSites: data,
        isFetchingSite: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingSite: false });
    }
  };

  getDonation = async (isLoading) => {
    if (!this.props.donationId) this.setState({ isLoading: false });
    try {
      this.setState({
        isLoading,
      });

      const data = await bloodDonationQuery(this.props.defaultLanguage, {
        type: "dropdown",
        id: this.props.donationId,
      });

      let donation = {},
        { bloodGroup, center, centerSite } = this.state;

      if (data.length > 0) {
        donation = data[0];
      }

      if (!this.props._id) {
        bloodGroup = {
          label: data[0].bloodInfo.bloodGroup,
          value: data[0].bloodInfo.bloodGroup,
        };

        center = {
          label: data[0].center.name,
          value: data[0].center._id,
        };

        centerSite = {
          label: data[0].centerSite.name,
          value: data[0].centerSite._id,
        };
      }

      this.setState({
        donation,
        isLoading: false,
        bloodGroup,
        centerSite,
        center,
        volume: data[0]?.bloodInfo?.volume || this.state.volume,
      });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  getEquipment = async (isFetchingEquipment) => {
    try {
      this.setState({
        isFetchingEquipment,
      });

      const data = await fetchEquipments(this.props.defaultLanguage, {
        type: "dropdown",
      });

      this.setState({
        equipments: data,
        isFetchingEquipment: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingEquipment: false });
    }
  };

  getBagStatus = async (isFetchingBagStatus, value) => {
    try {
      this.setState({
        isFetchingBagStatus,
      });

      const data = await fetchBagStatus(this.props.defaultLanguage);

      this.setState({
        bagStatuses: data,
        isFetchingBagStatus: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingBagStatus: false });
      console.log("didier", error);
    }
  };

  onChangeText = async (field, e) => {
    let { error, label } = this.state;
    let inputValue = e.target ? e.target.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });

    if (field === "center") {
      this.setState({ site: null });
      this.getSites(true, inputValue.value);
    }

    if (this.state.weight === "") {
      this.setState({ label: null });

      return;
    }

    if (field === "weight") {
      const comparedNumber = Number(inputValue);

      const wholeBloodWeight = this.checkBagStatus(comparedNumber);
      this.setState({
        label: wholeBloodWeight,
        status:
          {
            label: wholeBloodWeight?.status,
            value: wholeBloodWeight?.status,
          } || this.state.status,
      });
    }

    if (field === "transportationTemperature" && inputValue !== "") {
      this.getTemperatures();
    }
  };

  checkBagStatus(comparedNumber) {
    for (let el of this.state.bagStatuses) {
      if (el.min <= comparedNumber && el.max >= comparedNumber) {
        return el;
      }
    }

    return null;
  }

  validateForm() {
    let {
      expirationDate,
      equipment,
      code,
      error,
      label,
      centerSite,
      center,
      transportationTemperature,
      status,
    } = this.state;

    if (!expirationDate) {
      error.expirationDate =
        language[this.props.defaultLanguage].expiration_date_required;
    }

    if (transportationTemperature === "") {
      error.transportationTemperature =
        language[
          this.props.defaultLanguage
        ].transportation_temperature_required;
    }

    if (!equipment) {
      error.equipment = language[this.props.defaultLanguage].equipment_required;
    }

    if (!center) {
      error.center = language[this.props.defaultLanguage].center_required;
    }

    if (!centerSite) {
      error.centerSite = language[this.props.defaultLanguage].site_required;
    }

    if (!label) {
      error.label = language[this.props.defaultLanguage].label_required;
    }

    if (code === "") {
      error.label = language[this.props.defaultLanguage].code_required;
    }

    if (!status) {
      error.status = language[this.props.defaultLanguage].status_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let { _id } = this.props;
      let {
          equipment,
          volume,
          expirationDate,
          donation,
          bloodGroup,
          label,
          center,
          centerSite,
          status,
          category,
          transportationTemperature,
          code,
          weight,
        } = this.state,
        url = `${API_URL}/bloodInventory`,
        method = "POST",
        user = await getStorage();

      let requestedBody = {
        donation: donation._id,
        bloodGroup: bloodGroup.value,
        expirationDate,
        volume,
        addedBy: user.id,
        equipment: equipment.value,
        label: label.value,
        center: center.value,
        centerSite: centerSite.value,
        status: label.value === "rejected" ? "rejected" : status.value,
        category: category.value,
        transportationTemperature,
        code,
        weight,
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestedBody.id = _id;

        delete requestedBody.addedBy;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };
      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT" ? "update_data_success" : "add_data_success"
            ]
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          this.setState({ isSubmitting: false });
          toastMessage("error", error);
        });
    }
  };

  render() {
    return (
      <>
        <div>
          {this.props.errorMessage && (
            <div className="alert alert-danger">{this.props.errorMessage}</div>
          )}
          {this.state.isLoading ? (
            <center>
              <Loading className="fullscreen" />
            </center>
          ) : Object.keys(this.state.donation).length > 0 ? (
            <>
              <div className="card-body">
                <div className="row">
                  <>
                    <div className="col-md-12">
                      <Input
                        label={
                          language[this.props.defaultLanguage].donation_number
                        }
                        required
                        value={this.state.donation.donationNumber}
                        error={this.state.error.donation}
                      />
                    </div>
                    <div className="col-md-6">
                      <Select
                        loadOptions={this.getCenters}
                        placeholder={
                          language[this.props.defaultLanguage].select
                        }
                        label={language[this.props.defaultLanguage].center}
                        required
                        value={this.state.center}
                        onChange={(e) => this.onChangeText("center", e)}
                        error={this.state.error.center}
                        isLoading={this.state.isFetchingCenter}
                        // disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <Select
                        loadOptions={() =>
                          this.getSites(
                            true,
                            this.state.available?.center?.value
                          )
                        }
                        placeholder={
                          language[this.props.defaultLanguage].select
                        }
                        label={language[this.props.defaultLanguage].site}
                        required
                        value={this.state.centerSite}
                        onChange={(e) => this.onChangeText("centerSite", e)}
                        error={this.state.error.centerSite}
                        isLoading={this.state.isFetchingSite}
                        // disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        label={
                          language[this.props.defaultLanguage]
                            .transportation_temperature
                        }
                        placeholder={
                          language[this.props.defaultLanguage]
                            .transportation_temperature_placeholder
                        }
                        required
                        value={this.state.transportationTemperature}
                        error={this.state.error.transportationTemperature}
                        onChange={(e) =>
                          this.onChangeText("transportationTemperature", e)
                        }
                        type="number"
                        isLoading={this.state.isFetchingTemperatures}
                        min={-1000}
                      />
                    </div>

                    <div className="col-md-6">
                      <Input
                        label={language[this.props.defaultLanguage].weight}
                        placeholder={
                          language[this.props.defaultLanguage]
                            .weight_placeholder
                        }
                        required
                        value={this.state.weight}
                        error={this.state.error.weight}
                        onChange={(e) => this.onChangeText("weight", e)}
                        type="number"
                      />
                    </div>

                    <div className="col-md-6">
                      <Select
                        options={bloodGroups}
                        placeholder={
                          language[this.props.defaultLanguage].select
                        }
                        label={language[this.props.defaultLanguage].blood_group}
                        value={this.state.bloodGroup}
                        onChange={(e) => this.onChangeText("bloodGroup", e)}
                        error={this.state.error.bloodGroup}
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <Select
                        loadOptions={(e) => this.getBagStatus(true, e)}
                        placeholder={
                          language[this.props.defaultLanguage].select
                        }
                        label={
                          language[this.props.defaultLanguage].weight_status
                        }
                        value={this.state.label}
                        onChange={(e) => this.onChangeText("label", e)}
                        error={this.state.error.label}
                        // disabled
                        isLoading={this.state.isFetchingBagStatus}
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        label={
                          language[this.props.defaultLanguage].expiration_date
                        }
                        required
                        value={this.state.expirationDate}
                        error={this.state.error.expirationDate}
                        onChange={(e) => this.onChangeText("expirationDate", e)}
                        type="date"
                        min={moment().format("YYYY-MM-DD")}
                      />
                    </div>
                    <div className="col-md-6">
                      <Select
                        options={this.state.equipments}
                        placeholder={
                          language[this.props.defaultLanguage].select
                        }
                        label={
                          language[this.props.defaultLanguage].used_equipment_id
                        }
                        required
                        value={this.state.equipment}
                        onChange={(e) => this.onChangeText("equipment", e)}
                        error={this.state.error.equipment}
                        isLoading={this.state.isFetchingEquipment}
                      />
                    </div>
                    <div className="col-md-6">
                      <Select
                        options={this.state.inventoryStatuses}
                        label={language[this.props.defaultLanguage].status}
                        disabled
                        value={this.state.status}
                        error={this.state.error.status}
                        isLoading={this.state.isFetchingTemperatures}
                        onChange={(e) => this.onChangeText("status", e)}
                      />
                    </div>
                    <div className="col-md-6">
                      <Select
                        options={bagCategories}
                        placeholder={
                          language[this.props.defaultLanguage].select
                        }
                        label={language[this.props.defaultLanguage].category}
                        required
                        value={this.state.category}
                        onChange={(e) => this.onChangeText("category", e)}
                        error={this.state.error.category}
                      />
                    </div>
                  </>
                </div>
              </div>
              <div className="modal-footer">
                <Button
                  text={language[this.props.defaultLanguage].cancel}
                  onPress={this.props.handleCloseModal}
                  className="btn-default"
                />
                <Button
                  text={language[this.props.defaultLanguage].submit}
                  onPress={this.onSubmit.bind(this)}
                  isSubmitting={this.state.isSubmitting}
                />
              </div>
            </>
          ) : (
            <Empty title={language[this.props.defaultLanguage].not_found} />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewInventory);
