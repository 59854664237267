import React from "react";
import { DonorBadge } from "../../components/Donors/DonorBadge";
import { getStorage } from "../../utils/storage";
import AlertBookAppointment from "../../components/BookAppointment/AlertBookAppointment";
class HomeScreen extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };

  render() {
    return (
      <div style={{ margin: "1rem 0" }}>
        <div className="row">
          <div className="col-md-4 mx-auto ">
            <DonorBadge donorId={this.state.user.id} />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-6 mx-auto ">
            <AlertBookAppointment />
          </div>
        </div>
      </div>
    );
  }
}

export default HomeScreen;
