import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import CheckboxQuestionnaire from "../../Input/CheckboxQuestionnaire";
import BulkDonation from "./BulkDonation";
import DonationByDonation from "./DonationByDonation";
class NewSeparation extends React.Component {
  state = {
    category: "donationByDonation",
  };

  handleCheck(field, value) {
    this.setState({
      [field]: value,
    });
  }

  render() {
    return (
      <>
        <div className="card">
          <div className="card-body">
            <CheckboxQuestionnaire
              answers={[
                {
                  key: "donationByDonation",
                  value:
                    language[this.props.defaultLanguage].donation_by_donation,
                  onPress: () =>
                    this.handleCheck("category", "donationByDonation"),
                },
                {
                  key: "bulkDonation",
                  value: language[this.props.defaultLanguage].bulk_donation,
                  onPress: () => this.handleCheck("category", "bulkDonation"),
                },
              ]}
              checked={this.state.category}
            />
            <hr />
          </div>
        </div>

        <>
          {this.state.category === "donationByDonation" && (
            <DonationByDonation />
          )}

          {this.state.category === "bulkDonation" && <BulkDonation />}
        </>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewSeparation);
