import React from "react";
import { connect } from "react-redux";
import { BloodUsage } from "../../components/BloodUsage";
import { DashboardHeader } from "../../components/Header";
import language from "../../language";

class HemovigilanceScreen extends React.Component {
  render() {
    const { haemovigilance } = language[this.props.defaultLanguage];
    return (
      <div className="card-body">
        <DashboardHeader title={haemovigilance} />
        <BloodUsage />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(HemovigilanceScreen);
