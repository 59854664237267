// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-landing-container {
  height: 100vh;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.page-landing-wrapper {
  height: 100vh;
  overflow-y: auto;
  scroll-behavior: smooth;
  padding-top: 2rem;
  z-index: 1;
}

.landing-section {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 0;
}

.landing-section.half-height {
  min-height: 70vh;
}

.landing-section.light {
  background-color: var(--light-color);
}

.landing-section.dark-primary {
  background-color: var(--dark-primary-color-150);
}

.landing-section-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
`, "",{"version":3,"sources":["webpack://./src/app/Layout/Landing/layout.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,uBAAuB;EACvB,iBAAiB;EACjB,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB","sourcesContent":[".main-landing-container {\r\n  height: 100vh;\r\n  overflow-y: auto;\r\n  scroll-behavior: smooth;\r\n}\r\n\r\n.page-landing-wrapper {\r\n  height: 100vh;\r\n  overflow-y: auto;\r\n  scroll-behavior: smooth;\r\n  padding-top: 2rem;\r\n  z-index: 1;\r\n}\r\n\r\n.landing-section {\r\n  min-height: 100vh;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  padding: 4rem 0;\r\n}\r\n\r\n.landing-section.half-height {\r\n  min-height: 70vh;\r\n}\r\n\r\n.landing-section.light {\r\n  background-color: var(--light-color);\r\n}\r\n\r\n.landing-section.dark-primary {\r\n  background-color: var(--dark-primary-color-150);\r\n}\r\n\r\n.landing-section-content {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  flex-wrap: wrap;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
