// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.print-header-item {
  display: flex;
  align-items: center;
  min-height: 30px;
  gap: 1rem;
}

.print-header-title {
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/app/common/styles/printing.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,qBAAqB;EACrB,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".print-header-item {\r\n  display: flex;\r\n  align-items: center;\r\n  min-height: 30px;\r\n  gap: 1rem;\r\n}\r\n\r\n.print-header-title {\r\n  margin-bottom: 1.5rem;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
