import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import departmentQuery from "../../../utils/queries/departmentQuery";
import jobPositionQuery from "../../../utils/queries/jobPositionQuery";
import accessRoleQuery from "../../../utils/queries/accessRoleQuery";
import validateEmail from "../../../utils/validateEmail";
import { activeOptions } from "../../../constants/account";
import icons from "../../../constants/icons";
import { getStorage } from "../../../utils/storage";
import bloodCenterQuery from "../../../utils/queries/bloodCenterQuery";
import hospitalQuery from "../../../utils/queries/hospitalQuery";
import bloodCenterSiteQuery from "../../../utils/queries/bloodCenterSiteQuery";
import { defineRole } from "../../../utils/handleAccessRoles";
import { ROLE_ACCOUNT } from "../../../constants/ROLES";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class NewAccount extends React.Component {
  state = {
    error: {},
    isSubmitting: false,
    showAccessRoles: false,
    name: "",
    user: {},
    body: "",
    subject: "",
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    if (this.props._id) {
      this.setState({
        ...this.props,
      });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e.target ? e.target.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });
  };

  validateForm() {
    let { name, subject, body, error } = this.state;

    if (name === "") {
      error.name = language[this.props.defaultLanguage].name_required;
    }

    if (subject === "") {
      error.subject = language[this.props.defaultLanguage].subject_required;
    }

    if (body === "") {
      error.body = language[this.props.defaultLanguage].body_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let { name, subject, body, _id } = this.state,
        url = `${API_URL}/emailTemplate`,
        method = "POST",
        user = await getStorage();

      let requestedBody = {
        name,
        body,
        subject,
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestedBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage].success_data_added
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage("error", error);

          this.setState({ isSubmitting: false });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].name_placeholder
                }
                label={language[this.props.defaultLanguage].name}
                required
                value={this.state.name}
                onChange={(e) => this.onChangeText("name", e)}
                error={this.state.error.name}
              />
            </div>
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].subject_placeholder
                }
                label={language[this.props.defaultLanguage].subject}
                required
                value={this.state.subject}
                onChange={(e) => this.onChangeText("subject", e)}
                error={this.state.error.subject}
                textarea
              />
            </div>
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].body_placeholder
                }
                label={language[this.props.defaultLanguage].body}
                required
                value={this.state.body}
                onChange={(e) => this.onChangeText("body", e)}
                error={this.state.error.body}
                textarea
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          {defineRole({
            roles: this.state.user.accessRole,
            menu: ROLE_ACCOUNT,
            operation: "create,update",
          }) && (
            <Button
              text={language[this.props.defaultLanguage].submit}
              onPress={this.onSubmit.bind(this)}
              isSubmitting={this.state.isSubmitting}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewAccount);
