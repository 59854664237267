// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-footer {
  gap: 0.5rem;
  display: flex;
  align-items: center;
  padding: 0.2rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/common/styles/modals.styles.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".modal-footer {\r\n  gap: 0.5rem;\r\n  display: flex;\r\n  align-items: center;\r\n  padding: 0.2rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
