import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import hospitalQuery from "../../utils/queries/hospitalQuery";
import icons from "../../constants/icons";
import { getStorage } from "../../utils/storage";
import {
  getCells,
  getDistricts,
  getProvinces,
  getSectors,
  getVillages,
} from "../../utils/rwanda";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class NewPatient extends React.Component {
  state = {
    requesterName: "",
    requesterPosition: "",
    error: {},
    isSubmitting: false,
    hospitals: [],
    firstName: "",
    lastName: "",
    contact: "",
    identityNumber: "",
    identityNumberTypes: [],
  };

  componentDidMount = async () => {
    await this.getHospitals(true);

    if (this.props._id) {
      this.setState({
        ...this.props,
        hospital: this.props.hospital
          ? {
              label: this.props.hospital.name,
              value: this.props.hospital._id,
            }
          : null,
        province: this.props.province
          ? {
              label: this.props.province,
              value: this.props.province,
            }
          : null,
        district: this.props.district
          ? {
              label: this.props.district,
              value: this.props.district,
            }
          : null,
        sector: this.props.sector
          ? {
              label: this.props.sector,
              value: this.props.sector,
            }
          : null,
        sector: this.props.sector
          ? {
              label: this.props.sector,
              value: this.props.sector,
            }
          : null,
        cell: this.props.cell
          ? {
              label: this.props.cell,
              value: this.props.cell,
            }
          : null,
        village: this.props.village
          ? {
              label: this.props.village,
              value: this.props.village,
            }
          : null,
      });
    }
  };

  getHospitals = async (isFetchingHospitals) => {
    try {
      this.setState({
        isFetchingHospitals,
      });

      const data = await hospitalQuery(this.props.defaultLanguage, {
        type: "dropdown",
      });

      this.setState({
        hospitals: data,
        isFetchingHospitals: false,
      });
    } catch (error) {
      this.setState({ isFetchingHospitals: false });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e.target ? e.target.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });
  };

  validateForm() {
    let {
      hospital,
      firstName,
      lastName,
      error,
      contact,
      identityNumber,
      village,
      cell,
      sector,
      province,
      district,
      patientId,
    } = this.state;

    if (!hospital || hospital === "") {
      error.hospital = language[this.props.defaultLanguage].hospital_required;
    }

    if (firstName === "") {
      error.firstName =
        language[this.props.defaultLanguage].first_name_required;
    }

    if (lastName === "") {
      error.lastName = language[this.props.defaultLanguage].last_name_required;
    }

    if (contact === "") {
      error.contact = language[this.props.defaultLanguage].contact_required;
    }

    if (identityNumber === "") {
      error.identityNumber =
        language[this.props.defaultLanguage].identity_number_required;
    }

    if (!province) {
      error.province = language[this.props.defaultLanguage].province_required;
    }

    if (!district) {
      error.district = language[this.props.defaultLanguage].district_required;
    }

    if (!sector) {
      error.sector = language[this.props.defaultLanguage].sector_required;
    }

    if (!village) {
      error.village = language[this.props.defaultLanguage].village_required;
    }

    if (!cell) {
      error.cell = language[this.props.defaultLanguage].cell_required;
    }

    if (patientId === "") {
      error.patientId =
        language[this.props.defaultLanguage].patient_id_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let {
          hospital,
          firstName,
          lastName,
          contact,
          identityNumber,
          province,
          district,
          cell,
          village,
          sector,
          patientId,
        } = this.state,
        url = `${API_URL}/patient`,
        { _id } = this.props,
        method = "POST",
        user = await getStorage();

      let requestedBody = {
        hospital,
        firstName,
        lastName,
        contact,
        identityNumber,
        hospital: hospital.value,
        addedBy: user.id,
        province: province.value,
        district: district.value,
        cell: cell.value,
        village: village.value,
        sector: sector.value,
        patientId: patientId + "",
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestedBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT" ? "update_data_success" : "add_data_success"
            ]
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage("error", error);

          this.setState({ isSubmitting: false });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Select
                options={this.state.hospitals}
                leftIcon={icons.hospital}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].hospital}
                required
                value={this.state.hospital}
                onChange={(e) => this.onChangeText("hospital", e)}
                error={this.state.error.hospital}
                isLoading={this.state.isFetchingHospitals}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Input
                leftIcon={icons.user}
                placeholder={
                  language[this.props.defaultLanguage].patient_id_placeholder
                }
                label={language[this.props.defaultLanguage].patient_id}
                required
                value={this.state.patientId}
                onChange={(e) => this.onChangeText("patientId", e)}
                error={this.state.error.patientId}
              />
            </div>
            <div className="col-md-6">
              <Input
                leftIcon={icons.user}
                placeholder={
                  language[this.props.defaultLanguage].first_name_placeholder
                }
                label={language[this.props.defaultLanguage].first_name}
                required
                value={this.state.firstName}
                onChange={(e) => this.onChangeText("firstName", e)}
                error={this.state.error.firstName}
              />
            </div>
            <div className="col-md-6">
              <Input
                leftIcon={icons.user}
                placeholder={
                  language[this.props.defaultLanguage].last_name_placeholder
                }
                label={language[this.props.defaultLanguage].last_name}
                required
                value={this.state.lastName}
                onChange={(e) => this.onChangeText("lastName", e)}
                error={this.state.error.lastName}
              />
            </div>
            <div className="col-md-6">
              <Input
                leftIcon={icons.phone}
                placeholder={
                  language[this.props.defaultLanguage].contact_placeholder
                }
                label={language[this.props.defaultLanguage].contact}
                required
                value={this.state.contact}
                onChange={(e) => this.onChangeText("contact", e)}
                error={this.state.error.contact}
              />
            </div>
            <div className="col-md-6">
              <Input
                leftIcon={icons.identification}
                placeholder={
                  language[this.props.defaultLanguage]
                    .identity_number_placeholder
                }
                label={language[this.props.defaultLanguage].identity_number}
                required
                value={this.state.identityNumber}
                onChange={(e) => this.onChangeText("identityNumber", e)}
                error={this.state.error.identityNumber}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={getProvinces(this.state.province)}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].province}
                required
                value={this.state.province}
                onChange={(e) => this.onChangeText("province", e)}
                error={this.state.error.province}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={getDistricts(this.state.province?.value)}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].district}
                required
                value={this.state.district}
                onChange={(e) => this.onChangeText("district", e)}
                error={this.state.error.district}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={getSectors(
                  this.state.province?.value,
                  this.state.district?.value
                )}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].sector}
                required
                value={this.state.sector}
                onChange={(e) => this.onChangeText("sector", e)}
                error={this.state.error.sector}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={getCells(
                  this.state.province?.value,
                  this.state.district?.value,
                  this.state.sector?.value
                )}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].cell}
                required
                value={this.state.cell}
                onChange={(e) => this.onChangeText("cell", e)}
                error={this.state.error.cell}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={getVillages(
                  this.state.province?.value,
                  this.state.district?.value,
                  this.state.sector?.value,
                  this.state.cell?.value
                )}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].village}
                required
                value={this.state.village}
                onChange={(e) => this.onChangeText("village", e)}
                error={this.state.error.village}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewPatient);
