export const { REACT_APP_BBMS_REPORT_API, REACT_APP_BBMS_BASE_API } =
  process.env;

const API_ACCESS = "/api/access";
const EVENT_ACCESS = "api/event";
const REPORT_ACCESS = `/analytics`;

export const accountApi = `${API_ACCESS}/account`;
export const accessRoleApi = `${API_ACCESS}/accessRole`;
export const departmentApi = `${API_ACCESS}/department`;
export const alertApi = `${EVENT_ACCESS}/alert`;
export const serviceCategoryApi = `${API_ACCESS}/serviceCategory`;
export const preAssessmentsApi = `${API_ACCESS}/preDonationAssessments`;
export const appointmentsApi = `${API_ACCESS}/preDonationAssessments`;
export const vitalSignApi = `${API_ACCESS}/vitalSign`;
export const bloodTestApi = `${API_ACCESS}/bloodtest`;
export const equipmentApi = `${API_ACCESS}/equipment`;
export const clubsApi = `${API_ACCESS}/clubs`;
export const sitesApi = `${API_ACCESS}/bloodcentersite`;
export const collectionTypeApi = `${API_ACCESS}/collectionType`;
export const productionLabelingTransformationApi = `${API_ACCESS}/production/labeling`;
export const productionComponentSeparationApi = `${API_ACCESS}/bloodSeparation`;
export const productionComponentTransformationApi = `${API_ACCESS}/bloodTransformation`;
export const productionInventoryApi = `${API_ACCESS}/bloodInventory'`;
export const bloodRecipientApi = `${API_ACCESS}/bloodRecipient`;
export const bloodUsageApi = `${API_ACCESS}/bloodUsage`;
export const requestApi = `${API_ACCESS}/bloodRequest`;
export const bloodCenterApi = `${API_ACCESS}/bloodCenter`;
export const bloodCenterSiteApi = `${API_ACCESS}/bloodcentersite`;
export const centerAnalyticsCenterSite = `${REPORT_ACCESS}/centerSite`;
