import React from "react";
import { Accounts } from "../../components/Accounts";
import language from "../../language";
import { connect } from "react-redux";
import { Tabs } from "../../components/Tabs";

class AccountScreen extends React.Component {
  render() {
    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <Tabs
              options={[
                {
                  title: language[this.props.defaultLanguage].active_accounts,
                  data: <Accounts isActive />,
                },
                {
                  title: language[this.props.defaultLanguage].inactive_accounts,
                  data: <Accounts isActive={false} />,
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(AccountScreen);
