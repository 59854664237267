import "./styles.css";

import logoImage from "../../assets/logo.png";
import icons from "../../constants/icons";

const Logo = (props) => {
  return (
    <div className={`logo-container ${props.className}`}>
      <div
        className={`mobile-container ${
          props.type === "dashboard" ? "show" : ""
        }`}
        onClick={props.handleMenuIconClicked}
      >
        <div className="icon-container no-hover-bg">
          <span className={process.env.REACT_APP_ICONS_TYPE}>{icons.menu}</span>
        </div>
      </div>
      <div
        className="image-container"
        onClick={() => (window.location.href = "/landing")}
      >
        <img src={logoImage} alt="BBMS LOGO" />
      </div>
      <span
        className="logo-text"
        onClick={() => (window.location.href = "/landing")}
      >
        BBMS
        {/* <span>PSRP</span> */}
      </span>
    </div>
  );
};

export default Logo;
