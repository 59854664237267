import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import { getStorage } from "../../../utils/storage";
import { defineRole } from "../../../utils/handleAccessRoles";
import { ROLE_ACCOUNT } from "../../../constants/ROLES";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

const types = [
  {
    label: "Donation Interval",
    value: "donationInterval",
  },
];
class NewConfiguration extends React.Component {
  state = {
    error: {},
    isSubmitting: false,
    showAccessRoles: false,
    name: "",
    user: {},
    configuration: {},
    subject: "",
    type: types[0],
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    if (this.props._id) {
      this.setState({
        ...this.props,
        donationInterval: this.props.configuration.donationInterval || "",
      });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e.target ? e.target.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });
  };

  validateForm() {
    let { error, type } = this.state;

    if (!type) {
      error.type = language[this.props.defaultLanguage].type_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let { config } = this.props;
      let { configuration, _id, type } = this.state,
        url = `${API_URL}/configuration`,
        method = "POST",
        user = await getStorage();

      if (type.value) {
        configuration[type.value] = this.state[type.value];
      }

      let requestedBody = {
        configuration,
      };

      if (config._id && config._id !== "") {
        method = "PUT";
        requestedBody.id = config._id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };

      console.log(options);

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage].success_data_added
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage("error", error);

          this.setState({ isSubmitting: false });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Select
                options={types}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].type}
                required
                value={this.state.type}
                onChange={(e) => this.onChangeText("type", e)}
                error={this.state.error.type}
              />
            </div>
            {this.state.type.value && (
              <div className="col-md-12">
                <Input
                  placeholder={`${language[this.props.defaultLanguage].enter} ${
                    this.state.type.value
                  }`}
                  label={this.state.type.label}
                  required
                  value={this.state[this.state.type.value]}
                  onChange={(e) => this.onChangeText(this.state.type.value, e)}
                  error={this.state.error[this.state.type.value]}
                />
              </div>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          {defineRole({
            roles: this.state.user.accessRole,
            menu: ROLE_ACCOUNT,
            operation: "create,update",
          }) && (
            <Button
              text={language[this.props.defaultLanguage].submit}
              onPress={this.onSubmit.bind(this)}
              isSubmitting={this.state.isSubmitting}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewConfiguration);
