import toastMessage from "../../toastMessage";
import { getStorage } from "../../storage";
import axios from "axios";

const API_URL = process.env.REACT_APP_BBMS_REPORT_API;

export const fetchAgeDonationBloodGroupData = async (
  lng = "english",
  params
) => {
  try {
    const user = await getStorage();

    const options = {
      method: "GET",
      // url: `${API_URL}/analytics/donors/age`,
      url: `${API_URL}/analytics/donors/age`,
      params,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await axios(options);

    let data = queryRes.data;

    return { data };
  } catch (error) {
    toastMessage("error", error);
  }
};
