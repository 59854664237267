import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import { Modal, DeleteModal } from "../Modal";
import { CSVLink } from "react-csv";
import {
  fetchVitalSignsQuery,
  deleteVitalSign,
} from "../../utils/queries/vitalSignQuery";
import exportPDF from "../../utils/exportPDF";
import NewSign from "./NewSign";
import icons from "../../constants/icons";
import ViewSingleSign from "./ViewSingleSign";
import moment from "moment";
import { getStorage } from "../../utils/storage";
import AnswerResult from "../Donors/Pre-assessment/Answers/AnswerResult";
import accessMenu from "../../utils/accessMenu";

let copyData = [];

class VitalSigns extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    await this.setState({ user });

    await this.getData(true);
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target.value,
      error,
    });
  }

  getData = async (search = {}) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, signId, donorId, siteId, centerId } = this.props;

      this.setState({ isLoading: true });

      const { data, count } = await fetchVitalSignsQuery(defaultLanguage, {
        page,
        limit,
        id: signId,
        donor: donorId,
        ...search,
      });

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].vital_sign_number,
        key: "vitalSignNumber",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].donor,
        key: "donor.donorNumber",
        type: "link",
        onPress: (item) =>
          this.handleShowModal(
            "showVitalSignsModal",
            item?.donor?.donorNumber +
              " - " +
              moment(item.createdAt).format("lll"),
            item
          ),
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].appointment,
        key: "preDonationAssessment.requestedDate",
        type: "link",
        isMoment: true,
        formatTime: "lll",
        onPress: (item) =>
          this.handleShowModal(
            "showAssessmentAnswer",
            language[this.props.defaultLanguage].preassessment,
            item
          ),
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].eligible,
        key: "eligible.isEligible",
      },
      {
        title: language[this.props.defaultLanguage].reason,
        key: "eligible.reason",
      },
      {
        title: language[this.props.defaultLanguage].status,
        key: "status",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].created_at,
        key: "preDonationAssessment.requestedDate",
        isMoment: true,
        formatTime: "lll",
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];

    return headers;
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deleteVitalSign(
        this.props.defaultLanguage,
        this.state.selectedData._id
      );

      this.setState({ isDeleting: false });

      this.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      this.setState({
        isDeleting: false,
      });
    }
  };

  downloadExcel = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      const { page, limit } = this.state;
      const { defaultLanguage, signId } = this.props;

      const { data } = await fetchVitalSignsQuery(defaultLanguage, {
        page,
        limit,
        signId,
      });

      this.setState({
        isLoading: false,
        csvData: data,
      });

      this.refs.csvDownload?.link.click();
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  downloadPDF = async () => {
    this.setState({
      isLoading: true,
    });

    const { page, limit } = this.state;
    const { defaultLanguage, signId } = this.props;

    const headers = this.returnTableHeaders();

    const { data } = await fetchVitalSignsQuery(defaultLanguage, {
      page,
      limit,
      signId,
    });

    this.setState({
      isLoading: false,
    });

    exportPDF("Vital Signs", headers, data);
  };

  handleSearch = async (e) => {
    const search = e.target.value;
    this.setState({ search_text: search });
  };

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });

    await this.getData({
      vitalSignNumber: search || search !== "" ? search : undefined,
    });

    this.setState({
      searching: false,
    });
  };

  render() {
    const { add_vital_sign, edit_vital_sign, edit, delete_ } =
      language[this.props.defaultLanguage];

    const { canReadVital, canCreateVital, canDeleteVital, canUpdateVital } =
      accessMenu();

    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          showAdd={canCreateVital}
          addButtonText={add_vital_sign}
          handleSearch={this.handleSearch.bind(this)}
          addSearchButton
          searching={this.state.searching}
          handlePressSearch={() =>
            this.handleRemoteSearch(this.state.search_text)
          }
          handleAddPressed={this.handleShowModal.bind(
            this,
            "showModal",
            language[this.props.defaultLanguage].add
          )}
          rowPress={(item) =>
            this.handleShowModal("showModal", item.name, item)
          }
          filters={[
            {
              type: "button",
              title: language[this.props.defaultLanguage].refresh,
              button_type: "button",
              icon: icons.refresh,
              onPress: (item) => this.getData(true),
            },
            // {
            //   type: "export",
            //   title: export_,
            //   button_type: "dropdown",
            //   icon: icons.export,
            //   options: [
            //     {
            //       name: "PDF",
            //       onPress: this.downloadPDF.bind(this),
            //     },
            //     {
            //       name: "CSV",
            //       onPress: this.downloadExcel.bind(this),
            //     },
            //   ],
            // },
          ]}
          actions={[
            {
              canAccess: canUpdateVital,
              name: edit,
              onPress: (item) =>
                this.handleShowModal("showModal", edit_vital_sign, item),
              icon: icons.edit,
            },
            {
              canAccess: canReadVital,
              name: language[this.props.defaultLanguage].view_signs,
              onPress: (item) =>
                this.handleShowModal(
                  "showVitalSignsModal",
                  item?.donor?.donorNumber +
                    " " +
                    moment(item?.createdAt).format("lll"),
                  item
                ),
              icon: icons.eye_on,
            },
            {
              canAccess: canDeleteVital,
              name: delete_,
              onPress: this.handleShowModal.bind(
                this,
                "showDeleteModal",
                delete_
              ),
              icon: icons.delete,
            },
          ]}
        />

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showAssessmentAnswer")}
          show={this.state.showAssessmentAnswer}
          title={this.state.modalTitle}
          size="lg"
        >
          <AnswerResult
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showAssessmentAnswer"
            )}
            getData={this.getData.bind(this)}
            selectedData={this.state.selectedData.preDonationAssessment}
            donorId={
              this.state.selectedData.donor && this.state.selectedData.donor._id
            }
            route={this.props.route}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
          backdrop="static"
        >
          <NewSign
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            getData={this.getData.bind(this)}
            {...this.state.selectedData}
            donorId={this.props.donorId || this.state.selectedData?.donor?._id}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showVitalSignsModal")}
          show={this.state.showVitalSignsModal}
          title={
            this.state.selectedData?.donor?.donorNumber +
            " - " +
            moment(this.state.selectedData?.createdAt)?.format("lll")
          }
          showHeaderBottomBorder={false}
          backdrop="static"
        >
          <ViewSingleSign
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showVitalSignsModal"
            )}
            getData={this.getData.bind(this)}
            {...this.state.selectedData}
            donorId={this.props.donorId}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selectedData.name}
            getData={this.getData.bind(this)}
          />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={"Vital Signs" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(VitalSigns);
