import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import icons from "../../constants/icons";
import { getStorage } from "../../utils/storage";
import bloodCenterQuery from "../../utils/queries/bloodCenterQuery";
import availableOptions from "../../constants/availableOptions";
import {
  getCells,
  getDistricts,
  getProvinces,
  getSectors,
  getVillages,
} from "../../utils/rwanda";
import bloodCommitteeQuery from "../../utils/queries/bloodCommitteeQuery";
import validateEmail from "../../utils/validateEmail";
import validatePhone from "../../utils/validatePhone";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

const categories = [
  {
    label: "School",
    value: "school",
  },
  {
    label: "Rural",
    value: "rural",
  },
  {
    label: "Church",
    value: "church",
  },
  {
    label: "Private Institution",
    value: "private_institution",
  },
  {
    label: "RDF",
    value: "RDF",
  },
  {
    label: "RNP",
    value: "RNP",
  },
  {
    label: "Public Institution",
    value: "public_institution",
  },
  {
    label: "International Organization",
    value: "international_organization",
  },
];
class NewSite extends React.Component {
  state = {
    name: "",
    error: {},
    isSubmitting: false,
    centers: [],
    committeeLeaders: [],
    code: "",
    contactNumber: "",
    email: "",
    isActive: availableOptions[0],
    latitude: "",
    longitude: "",
    accounts: [],
  };

  componentDidMount = async () => {
    await this.getCenters(true);

    await this.getCommitteeLeader(true);

    this.setState({ ...this.props });

    if (this.props._id && this.props._id !== "") {
      this.setState({
        province: {
          label: this.props?.address?.province,
          value: this.props?.address?.province,
        },
        district: {
          label: this.props?.address?.district,
          value: this.props?.address?.district,
        },
        category: categories.find(
          (el) => el.value === this.props?.locationType
        ),
        sector: {
          label: this.props?.address?.sector,
          value: this.props?.address?.sector,
        },
        cell: {
          label: this.props?.address?.cell,
          value: this.props?.address?.cell,
        },
        village: {
          label: this.props?.address?.village,
          value: this.props?.address?.village,
        },
        isActive: {
          label: this.props?.available + "",
          value: this.props?.available + "",
        },
        committeeLeader: {
          label: this.props?.committeeLeader?.name,
          value: this.props?.committeeLeader?._id,
        },
        center: {
          label: this.props?.center?.name,
          value: this.props?.center?._id,
        },
        latitude: this.props?.address?.latitude,
        longitude: this.props?.address?.longitude,
      });
    }
  };

  getCommitteeLeader = async (isFetchingLeader, center) => {
    try {
      this.setState({
        isFetchingLeader,
      });

      const data = await bloodCommitteeQuery(this.props.defaultLanguage, {
        type: "dropdown",
        center,
      });

      this.setSelectedCommitteeLeader(data);

      this.setState({
        committeeLeaders: data,
        isFetchingLeader: false,
      });
    } catch (error) {
      this.setState({ isFetchingLeader: false });
    }
  };

  setSelectedCommitteeLeader(committeeLeaders) {
    const { committeeLeader } = this.props;
    if (committeeLeader) {
      const selectedCommitteeLeader = committeeLeaders.find(
        (el) => el._id === committeeLeader._id
      );

      if (selectedCommitteeLeader._id) {
        this.setState({
          committeeLeader: {
            label: selectedCommitteeLeader.name,
            value: selectedCommitteeLeader._id,
          },
        });
      }
    }
  }

  getCenters = async (isFetchingCenter) => {
    try {
      this.setState({
        isFetchingCenter,
      });

      const data = await bloodCenterQuery(this.props.defaultLanguage, {
        type: "dropdown",
      });

      this.setSelectedCenter(data);

      this.setState({
        centers: data,
        isFetchingCenter: false,
      });
    } catch (error) {
      this.setState({ isFetchingCenter: false });
    }
  };

  setSelectedCenter(centers) {
    const { center } = this.props;
    if (center) {
      const selectedCenter = centers.find((el) => el._id === center._id);

      if (selectedCenter._id) {
        this.setState(
          {
            center: {
              label: selectedCenter.name,
              value: selectedCenter._id,
            },
          },
          () => {
            this.getCommitteeLeader(true, selectedCenter._id);
          }
        );
      }
    }
  }

  onChangeText(field, e) {
    let { error } = this.state;
    delete error[field];

    this.setState({
      [field]: e.target ? e.target.value : e,
      error,
    });
  }

  validateForm() {
    let {
      name,
      error,
      province,
      sector,
      cell,
      district,
      village,
      email,
      contactNumber,
      longitude,
      latitude,
      code,
      committeeLeader,
      center,
    } = this.state;

    if (name === "") {
      error.name = language[this.props.defaultLanguage].site_name_required;
    }

    if (!center) {
      error.center =
        language[this.props.defaultLanguage].centersite_name_required;
    }

    // if (contactNumber === "") {
    //   error.contactNumber =
    //     language[this.props.defaultLanguage].contact_number_required;
    // } else if (!validatePhone(contactNumber)) {
    //   error.contactNumber =
    //     language[this.props.defaultLanguage].invalid_phone_number;
    // }

    // if (!committeeLeader) {
    //   error.committeeLeader =
    //     language[this.props.defaultLanguage].committee_leader_required;
    // }

    // if (code === "") {
    // error.code = language[this.props.defaultLanguage].code_required;
    // }

    // if (email === "") {
    //   error.email = language[this.props.defaultLanguage].email_required;
    // } else if (!validateEmail(email)) {
    //   error.email = language[this.props.defaultLanguage].invalid_email;
    // }

    // if (!province) {
    //   error.province = language[this.props.defaultLanguage].province_required;
    // }

    // if (!district) {
    //   error.district = language[this.props.defaultLanguage].district_required;
    // }

    // if (!sector) {
    //   error.sector = language[this.props.defaultLanguage].sector_required;
    // }

    // if (!village) {
    //   error.village = language[this.props.defaultLanguage].village_required;
    // }

    // if (!cell) {
    //   error.cell = language[this.props.defaultLanguage].cell_required;
    // }

    // if (!province) {
    //   error.province = language[this.props.defaultLanguage].province_required;
    // }

    // if (longitude === "") {
    //   error.longitude = language[this.props.defaultLanguage].longitude_required;
    // }

    // if (latitude === "") {
    //   error.latitude = language[this.props.defaultLanguage].latitude;
    // }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    const {
      error_add_site,
      error_update_site,
      add_site_success,
      update_site_success,
    } = language[this.props.defaultLanguage];

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let {
          name,
          _id,
          center,
          committeeLeader,
          province,
          district,
          sector,
          village,
          cell,
          contactNumber,
          email,
          code,
          latitude,
          longitude,
          category,
        } = this.state,
        url = `${API_URL}/bloodcentersite`,
        method = "POST",
        user = await getStorage();

      let requestBody = {
        name,
        committeeLeader: committeeLeader ? committeeLeader.value : undefined,
        center: center.value,
        email,
        contactNumber,
        code,
        address: {
          province: province?.value,
          district: district?.value,
          sector: sector?.value,
          cell: cell?.value,
          village: village?.value,
          latitude,
          longitude,
        },
        locationType: category?.value || "",
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        data: requestBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            method === "PUT" ? update_site_success : add_site_success
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage(
            "error",
            method === "PUT" ? error_update_site : error_add_site
          );

          this.setState({
            isSubmitting: false,
          });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <Input
                leftIcon={icons.name}
                placeholder={
                  language[this.props.defaultLanguage].site_name_placeholder
                }
                label={language[this.props.defaultLanguage].site_name}
                required
                value={this.state.name}
                onChange={(e) => this.onChangeText("name", e)}
                error={this.state.error.name}
                style={{
                  textTransform: "capitalize",
                }}
              />
            </div>
            <div className="col-md-6">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].site_code_placeholder
                }
                label={language[this.props.defaultLanguage].site_code}
                required
                value={this.state.code}
                onChange={(e) => this.onChangeText("code", e)}
                error={this.state.error.code}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={this.state.centers}
                leftIcon={icons.site}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].center}
                required
                value={this.state.center}
                onChange={(e) => this.onChangeText("center", e)}
                error={this.state.error.center}
                isLoading={this.state.isFetchingCenter}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={this.state.committeeLeaders}
                leftIcon={icons.user}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].committee_leader}
                value={this.state.committeeLeader}
                onChange={(e) => this.onChangeText("committeeLeader", e)}
                error={this.state.error.committeeLeader}
                isLoading={this.state.isFetchingLeader}
              />
            </div>

            <div className="col-md-6">
              <Input
                leftIcon={icons.phone}
                placeholder={
                  language[this.props.defaultLanguage]
                    .contact_number_placeholder
                }
                label={language[this.props.defaultLanguage].contact_number}
                required
                value={this.state.contactNumber}
                onChange={(e) => this.onChangeText("contactNumber", e)}
                error={this.state.error.contactNumber}
                maxLength={10}
              />
            </div>
            <div className="col-md-6">
              <Input
                leftIcon={icons.email}
                placeholder={
                  language[this.props.defaultLanguage].email_placeholder
                }
                label={language[this.props.defaultLanguage].email_address}
                required
                value={this.state.email}
                onChange={(e) => this.onChangeText("email", e)}
                error={this.state.error.email}
              />
            </div>
            <div className="col-md-12">
              <Select
                leftIcon={icons.status}
                options={availableOptions}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].active}
                required
                value={this.state.isActive}
                onChange={(e) => this.onChangeText("isActive", e)}
                error={this.state.error.isActive}
              />
            </div>

            <div className="col-md-6">
              <Select
                options={categories}
                leftIcon={icons.category}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].category}
                value={this.state.category}
                onChange={(e) => this.onChangeText("category", e)}
                error={this.state.error.category}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={getProvinces(this.state.province)}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].province}
                required
                value={this.state.province}
                onChange={(e) => this.onChangeText("province", e)}
                error={this.state.error.province}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={getDistricts(this.state.province?.value)}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].district}
                required
                value={this.state.district}
                onChange={(e) => this.onChangeText("district", e)}
                error={this.state.error.district}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={getSectors(
                  this.state.province?.value,
                  this.state.district?.value
                )}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].sector}
                required
                value={this.state.sector}
                onChange={(e) => this.onChangeText("sector", e)}
                error={this.state.error.sector}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={getCells(
                  this.state.province?.value,
                  this.state.district?.value,
                  this.state.sector?.value
                )}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].cell}
                required
                value={this.state.cell}
                onChange={(e) => this.onChangeText("cell", e)}
                error={this.state.error.cell}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={getVillages(
                  this.state.province?.value,
                  this.state.district?.value,
                  this.state.sector?.value,
                  this.state.cell?.value
                )}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].village}
                required
                value={this.state.village}
                onChange={(e) => this.onChangeText("village", e)}
                error={this.state.error.village}
              />
            </div>
            <div className="col-md-6">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].latitude_placeholder
                }
                label={language[this.props.defaultLanguage].latitude}
                required
                value={this.state.latitude}
                onChange={(e) => this.onChangeText("latitude", e)}
                error={this.state.error.latitude}
                type="number"
              />
            </div>
            <div className="col-md-6">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].longitude_placeholder
                }
                label={language[this.props.defaultLanguage].longitude}
                required
                value={this.state.longitude}
                onChange={(e) => this.onChangeText("longitude", e)}
                error={this.state.error.longitude}
                type="number"
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewSite);
