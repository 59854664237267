import React from "react";
import { connect } from "react-redux";
import language from "../../../../language";
import { Table } from "../../../Table";
import { Modal, DeleteModal } from "../../../Modal";
import { CSVLink } from "react-csv";
import exportPDF from "../../../../utils/exportPDF";
import NeNewTestHistoryAccount from "../../BloodGrouping/NewTest";
import { defineRole } from "../../../../utils/handleAccessRoles";
import { getStorage } from "../../../../utils/storage";
import icons from "../../../../constants/icons";
import { ROLE_BLOOD_TEST_BLOOD_GROUPING } from "../../../../constants/ROLES";
import {
  fetchBloodTestHistory,
  deleteBloodTestHistory,
} from "../../../../utils/queries/bloodTestHistory";
import { Empty } from "../../../Empty";
import accessMenu from "../../../../utils/accessMenu";

class MismatchBloodGrouping extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    totalPageCount: 10,
    delete_password: "",
    error: {},
    csvData: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    await this.getData(true);
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({ error, [field]: e.target.value });
  }

  getData = async (isLoading, search = {}) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      this.setState({
        isLoading,
      });

      const { data, count } = await fetchBloodTestHistory(defaultLanguage, {
        page,
        limit,
        questionnaireType: "bloodGrouping",
        ...search,
        status: "mismatch",
      });

      this.setState({
        data,
        totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].code,
        key: "code",
        type: "link",
        onPress: (item) =>
          this.handleShowModal("showModal", "NO:" + item.code, item),
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].blood_group,
        key: "bloodGroup",
      },
      {
        title: language[this.props.defaultLanguage].tested_by,
        key: "testBy.name",
      },
      {
        title: language[this.props.defaultLanguage].status,
        key: "status",
        type: "status",
      },
      {
        title: language[this.props.defaultLanguage].date,
        key: "createdAt",
        formatTime: "lll",
        isMoment: true,
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ].filter(Boolean);

    return headers;
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deleteBloodTestHistory(
        this.props.defaultLanguage,
        this.state.selectedData._id
      );

      this.setState({ isDeleting: false });

      this.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      console.log(error);
      this.setState({
        isDeleting: false,
      });
    }
  };

  downloadExcel = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      const { data } = await fetchBloodTestHistory(defaultLanguage, {
        page,
        limit,
        questionnaireType: "bloodGrouping",
        status: "mismatch",
      });

      this.setState({
        isLoading: false,
        csvData: data,
      });

      this.refs.csvDownload?.link.click();
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  downloadPDF = async () => {
    this.setState({
      isLoading: true,
    });

    const { page, limit } = this.state;
    const { defaultLanguage } = this.props;

    const headers = this.returnTableHeaders();

    const { data } = await fetchBloodTestHistory(defaultLanguage, {
      page,
      limit,
      bloodTest: this.props.testId,
      questionnaireType: "bloodGrouping",
    });

    this.setState({
      isLoading: false,
    });

    exportPDF("Blood Grouping", headers, data);
  };

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });

    await this.getData(true, {
      code: search || search !== "" ? search : undefined,
    });

    this.setState({
      searching: false,
    });
  };

  handleSearch = async (e) => {
    const search = e.target.value;
    this.setState({ search_text: search });
  };

  render() {
    const { export_, edit, delete_ } = language[this.props.defaultLanguage];
    const { canUpdateBloodTest, canDeleteBloodTest } = accessMenu();

    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          addSearchButton
          searching={this.state.searching}
          handleSearch={this.handleSearch.bind(this)}
          handlePressSearch={() =>
            this.handleRemoteSearch(this.state.search_text)
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          rowPress={(item) =>
            this.handleShowModal("showModal", item.name, item)
          }
          filters={[
            {
              type: "button",
              title: language[this.props.defaultLanguage].refresh,
              button_type: "button",
              icon: icons.refresh,
              onPress: (item) => this.getData(true),
            },
            {
              type: "export",
              title: export_,
              button_type: "dropdown",
              icon: "bx bxs-download",
              options: [
                {
                  name: "PDF",
                  onPress: this.downloadPDF.bind(this),
                },
                {
                  name: "CSV",
                  onPress: this.downloadExcel.bind(this),
                },
              ],
            },
          ]}
          actions={[
            {
              canAccess: canUpdateBloodTest,
              icon: icons.edit,
              name: edit,
              onPress: (item) =>
                this.handleShowModal(
                  "showModal",
                  language[this.props.defaultLanguage].edit,
                  item
                ),
            },
            {
              canAccess: canDeleteBloodTest,
              icon: icons.delete,
              name: delete_,
              onPress: this.handleShowModal.bind(
                this,
                "showDeleteModal",
                language[this.props.defaultLanguage].delete_
              ),
            },
          ]}
        />

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
          size="lg"
        >
          {this.state.selectedData.bloodTest ? (
            <NeNewTestHistoryAccount
              handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
              getData={this.getData.bind(this)}
              {...this.state.selectedData}
              testId={
                this.state.selectedData?.bloodTest?._id ||
                this.state.selectedData?.bloodTest
              }
              canConfirm={true}
            />
          ) : (
            <Empty title={language[this.props.defaultLanguage].no_data} />
          )}
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selectedData.name}
            getData={this.getData.bind(this)}
          />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={"BloodGrouping-Mismatch" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(MismatchBloodGrouping);
