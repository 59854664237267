import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import { getStorage } from "../../utils/storage";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class NewStatus extends React.Component {
  state = {
    label: "",
    value: "",
    error: {},
    isSubmitting: false,
    min: "",
    max: "",
    statuses: [],
  };

  componentDidMount = async () => {
    let statuses = [
      {
        label: "Received",
        value: "received",
      },
      {
        label: "Quarantined",
        value: "quarantined",
      },
      {
        label: "Reject",
        value: "rejected",
      },
    ];

    this.setState({ statuses, status: this.state.statuses[0] });

    if (this.props._id) {
      this.setState({
        ...this.props,
        status: this.props.status
          ? { label: this.props.status, value: this.props.status }
          : null,
      });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e.target ? e.target.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });
  };

  validateForm() {
    let { value, label, min, max, error } = this.state;

    if (value === "") {
      error.value = language[this.props.defaultLanguage].value_required;
    }

    if (label === "") {
      error.label = language[this.props.defaultLanguage].label_required;
    }

    if (min === "") {
      error.min = language[this.props.defaultLanguage].min_required;
    }

    if (max === "") {
      error.max = language[this.props.defaultLanguage].max_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let { min, max, label, value, status } = this.state,
        url = `${API_URL}/bagStatus`,
        { _id } = this.props,
        method = "POST",
        user = await getStorage();

      let requestedBody = {
        value,
        label,
        min,
        max,
        status: status.value,
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestedBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT" ? "update_data_success" : "add_data_success"
            ]
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage("error", error);

          this.setState({ isSubmitting: false });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].label_placeholder
                }
                label={language[this.props.defaultLanguage].label}
                required
                value={this.state.label}
                onChange={(e) => this.onChangeText("label", e)}
                error={this.state.error.label}
              />
            </div>
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].value_placeholder
                }
                label={language[this.props.defaultLanguage].value}
                required
                value={this.state.value}
                onChange={(e) => this.onChangeText("value", e)}
                error={this.state.error.value}
              />
            </div>
            <div className="col-md-6">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].min_placeholder
                }
                label={language[this.props.defaultLanguage].min}
                required
                value={this.state.min}
                onChange={(e) => this.onChangeText("min", e)}
                error={this.state.error.min}
                type="number"
              />
            </div>

            <div className="col-md-6">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].max_placeholder
                }
                label={language[this.props.defaultLanguage].max}
                required
                value={this.state.max}
                onChange={(e) => this.onChangeText("max", e)}
                error={this.state.error.max}
                type="number"
              />
            </div>

            <div className="col-md-12">
              <Select
                options={this.state.statuses}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].status}
                required
                value={this.state.status}
                onChange={(e) => this.onChangeText("status", e)}
                error={this.state.error.status}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewStatus);
