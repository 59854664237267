import { Button } from "../Button";
import "./styles.css";

const ListItem = (props) => {
  return (
    <div className={`list-item ${props.className}`} onClick={props.onPress}>
      {props.image && (
        <div
          className="list-img-container"
          style={{ width: props.imageWidth, height: props.imageHeight }}
        >
          <img
            src={props.image}
            alt={props.name}
            style={{ width: props.imageWidth, height: props.imageHeight }}
          />
        </div>
      )}
      {props.icon && (
        <div className={`icon-container mr-0-5 ${props.iconClassName}`}>
          <span className={process.env.REACT_APP_ICONS_TYPE}>{props.icon}</span>
        </div>
      )}
      <div className="list-middle-content">
        <span className={`list-item-name text-bold`}>{props.name}</span>
        {props.description && (
          <div>
            <span className={`text-disabled ${props.classNameDescription}`}>
              {props.description}
            </span>
          </div>
        )}
      </div>
      {props.rightIcon && (
        <div className="list-right">
          <span className={process.env.REACT_APP_ICONS_TYPE}>
            {props.rightIcon}
          </span>
        </div>
      )}
      {props.rightDescription && (
        <div className="list-right">
          <span className="text-primary">{props.rightDescription}</span>
        </div>
      )}
      {props.rightButton && (
        <div>
          <Button
            className="btn-transparent primary"
            text={props.rightButton}
            onPress={props.rightButtonPressed}
          />
        </div>
      )}
    </div>
  );
};

export default ListItem;
