import DonationBloodGroupPerCenter from "./DonationBloodGroupPerCenter";
import CenterDonorsByBloodGroup from "./CenterDonorsByBloodGroup";
import BloodGroupByAge from "./BloodGroupByAge";
import Regularity from "./Regularity";
import ConfirmedHIV from "./ConfirmedHIV";
import Donors from "./Donors";
import DonationBloodGroupPerCenterSite from "./DonationBloodGroupPerCenterSite";

export {
  DonationBloodGroupPerCenter,
  CenterDonorsByBloodGroup,
  BloodGroupByAge,
  Regularity,
  ConfirmedHIV,
  Donors,
  DonationBloodGroupPerCenterSite,
};
