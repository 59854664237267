import React from "react";
import { connect } from "react-redux";
import { Map } from "../../components/Map";
import centerQuery from "../../utils/queries/bloodCenterQuery";
import bloodCenterSiteQuery from "../../utils/queries/bloodCenterSiteQuery";

let copyData = [],
  copyCenterSiteData = [];
class MapInsightsScreen extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selected_data: {},
    totalPageCount: 10,
    delete_password: "",
    error: {},
    csvData: [],
    centerSiteData: [],
    positions: [],
  };

  componentDidMount = async () => {
    await this.getData(true);
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({ error, [field]: e.target.value });
  }

  getData = async (isLoading) => {
    try {
      const { defaultLanguage } = this.props;
      let { positions } = this.state;

      this.setState({ isLoading });

      const data = await centerQuery(defaultLanguage);

      this.setState({
        data,
        isLoading: false,
      });

      console.log("centers", data);

      copyData = data.slice(0);

      for (let el of data) {
        const siteInfo = await this.getCenterSite(true, el._id);

        positions = positions.concat(siteInfo);
      }

      this.setState({ positions });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
    }
  };

  handleSearch(e) {
    const { data } = this.state;
    const search = e.target.value;
    this.setState({ search });

    let results = [];

    for (let el of copyData) {
      if (
        JSON.stringify(el).toUpperCase().indexOf(search.toUpperCase()) !== -1
      ) {
        results.push(el);
      }
    }

    this.setState({ data: results });
  }

  handlerPressCenter(el) {
    console.log("center clicked", el);
    this.getCenterSite(true, el._id);
  }

  getCenterSite = async (isLoading, center) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const data = await bloodCenterSiteQuery(defaultLanguage, {
        center,
      });

      this.setState({
        positions: data,
        isLoading: false,
      });

      copyCenterSiteData = data.slice(0);

      return data;
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    return (
      <div>
        <Map
          isLoading={this.state.isLoading}
          data={this.state.data}
          handleSearch={this.handleSearch.bind(this)}
          search={this.state.search}
          handlerPressCenter={this.handlerPressCenter.bind(this)}
          positions={this.state.positions}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(MapInsightsScreen);
