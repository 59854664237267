import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import icons from "../../constants/icons";
import { getStorage } from "../../utils/storage";
import availableOptions from "../../constants/availableOptions";
import donorsQuery from "../../utils/queries/donorsQuery";
import { ListItem } from "../List";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class NewClubMember extends React.Component {
  state = {
    error: {},
    isSubmitting: false,
    donors: [],
    available: availableOptions[0],
  };

  componentDidMount = async () => {
    this.getDonors(true);

    this.setState({ ...this.props });
  };

  getDonors = async (isFetchingDonor) => {
    try {
      const { siteId, centerId } = this.state;

      this.setState({
        isFetchingDonor,
      });

      const data = await donorsQuery(this.props.defaultLanguage, {
        type: "dropdown",
        center: centerId,
        centerSite: siteId,
      });

      this.setSelectedDonor(data);

      this.setState({
        donors: data,
        isFetchingDonor: false,
      });
    } catch (error) {
      this.setState({ isFetchingDonor: false });
    }
  };

  setSelectedDonor(donors) {
    const { donor } = this.props;
    if (donor) {
      const selectedDonor = donors.find((el) => el._id === donor._id);

      if (selectedDonor._id) {
        this.setState({
          donor: {
            label: selectedDonor.donorNumber,
            value: selectedDonor._id,
          },
        });
      }
    }
  }

  onChangeText(field, e) {
    let { error } = this.state;
    delete error[field];

    this.setState({
      [field]: e.target ? e.target.value : e,
      error,
    });
  }

  validateForm() {
    let { donor, error } = this.state;

    if (!donor === "") {
      error.donor = language[this.props.defaultLanguage].donor_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let { _id, donor, available } = this.state,
        { clubId } = this.props,
        url = `${API_URL}/club/addMember`,
        method = "POST",
        user = await getStorage();

      let requestBody = {
        donor: donor.value,
        club: clubId,
        available: available.value,
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        data: requestBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            method === "PUT"
              ? language[this.props.defaultLanguage].update_data_success
              : language[this.props.defaultLanguage].add_data_success
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage("error", error);

          this.setState({
            isSubmitting: false,
          });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Select
                leftIcon={icons.donor}
                options={this.state.donors}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].donor}
                required
                value={this.state.donor}
                onChange={(e) => this.onChangeText("donor", e)}
                error={this.state.error.donor}
                isLoading={this.setState.isFetchingDonor}
              />
            </div>

            <div className="col-md-12">
              <Select
                leftIcon={icons.status}
                options={availableOptions}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].available}
                required
                value={this.state.available}
                onChange={(e) => this.onChangeText("available", e)}
                error={this.state.error.available}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewClubMember);
