import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import CheckboxQuestionnaire from "../../Input/CheckboxQuestionnaire";
import Separator from "./Separator";
class PersonalInformation extends React.Component {
  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-6">
            <CheckboxQuestionnaire
              questionText={
                language[this.props.defaultLanguage].are_you_married
              }
              answers={[
                {
                  field: "areYouMarried",
                  value: "Yes",
                  onPress: () => this.props.handleCheck("areYouMarried", "Yes"),
                },
                {
                  field: "areYouMarried",
                  value: "No",
                  onPress: () => this.props.handleCheck("areYouMarried", "No"),
                },
              ]}
              checked={this.props.areYouMarried}
              error={this.props.error.areYouMarried}
            />
            <CheckboxQuestionnaire
              questionText={
                language[this.props.defaultLanguage]
                  .are_feeling_healthy_and_well
              }
              answers={[
                {
                  field: "areYouFeelingHealthAndWell",
                  value: "Yes",
                  onPress: this.props.handleCheck,
                },
                {
                  field: "areYouFeelingHealthAndWell",
                  value: "No",
                  onPress: this.props.handleCheck,
                },
              ]}
              error={this.props.error.areYouFeelingHealthAndWell}
              checked={this.props.areYouFeelingHealthAndWell}
            />
            <CheckboxQuestionnaire
              questionText={
                language[this.props.defaultLanguage]
                  .are_you_taking_any_medication
              }
              answers={[
                {
                  field: "areYouTakingAnyMedication",
                  value: "Yes",
                  onPress: this.props.handleCheck,
                },
                {
                  field: "areYouTakingAnyMedication",
                  value: "No",
                  onPress: this.props.handleCheck,
                },
              ]}
              error={this.props.error.areYouTakingAnyMedication}
              checked={this.props.areYouTakingAnyMedication}
            />
            <CheckboxQuestionnaire
              questionText={
                language[this.props.defaultLanguage]
                  .do_you_have_any_wound_or_cutaneous_disease
              }
              answers={[
                {
                  field: "doYouHaveAnyWoundOrCutaneousDisease",
                  value: "Yes",
                  onPress: () =>
                    this.props.handleCheck(
                      "doYouHaveAnyWoundOrCutaneousDisease",
                      "Yes"
                    ),
                },
                {
                  field: "doYouHaveAnyWoundOrCutaneousDisease",
                  value: "No",
                  onPress: () =>
                    this.props.handleCheck(
                      "doYouHaveAnyWoundOrCutaneousDisease",
                      "No"
                    ),
                },
              ]}
              error={this.props.error.doYouHaveAnyWoundOrCutaneousDisease}
              checked={this.props.doYouHaveAnyWoundOrCutaneousDisease}
            />

            <Separator
              title={
                language[this.props.defaultLanguage]
                  .in_the_last_fourty_eight_hours
              }
            />
            <CheckboxQuestionnaire
              questionText={
                language[this.props.defaultLanguage].have_taken_an_aspirin
              }
              answers={[
                {
                  field: "haveYouTakenAspirin",
                  value: "Yes",
                  onPress: () =>
                    this.props.handleCheck("haveYouTakenAspirin", "Yes"),
                },
                {
                  field: "haveYouTakenAspirin",
                  value: "No",
                  onPress: () =>
                    this.props.handleCheck("haveYouTakenAspirin", "No"),
                },
              ]}
              error={this.props.error.haveYouTakenAspirin}
              checked={this.props.haveYouTakenAspirin}
            />
            {/* <CheckboxQuestionnaire
              questionText={
                language[this.props.defaultLanguage].have_you_bitten_by_insect
              }
              answers={[
                {
                  field: "haveYouBittenByInsect",
                  value: "Yes",
                  onPress: () =>
                    this.props.handleCheck("haveYouBittenByInsect", "Yes"),
                },
                {
                  field: "haveYouBittenByInsect",
                  value: "No",
                  onPress: () =>
                    this.props.handleCheck("haveYouBittenByInsect", "No"),
                },
              ]}
              error={this.props.error.haveYouBittenByInsect}
              checked={this.props.haveYouBittenByInsect}
            /> */}
            <Separator
              title={language[this.props.defaultLanguage].in_the_last_one_month}
            />
            <CheckboxQuestionnaire
              questionText={
                language[this.props.defaultLanguage].have_you_had_malaria
              }
              answers={[
                {
                  field: "haveYouHadMalaria",
                  value: "Yes",
                  onPress: () =>
                    this.props.handleCheck("haveYouHadMalaria", "Yes"),
                },
                {
                  field: "hadMalaria",
                  value: "No",
                  onPress: () =>
                    this.props.handleCheck("haveYouHadMalaria", "No"),
                },
              ]}
              error={this.props.error.haveYouHadMalaria}
              checked={this.props.haveYouHadMalaria}
            />
            <CheckboxQuestionnaire
              questionText={language[this.props.defaultLanguage].vaccine}
              answers={[
                {
                  field: "vaccine",
                  value: "Yes",
                  onPress: () => this.props.handleCheck("vaccine", "Yes"),
                },
                {
                  field: "vaccine",
                  value: "No",
                  onPress: () => this.props.handleCheck("vaccine", "No"),
                },
              ]}
              error={this.props.error.vaccine}
              checked={this.props.vaccine}
            />
            <CheckboxQuestionnaire
              questionText={
                language[this.props.defaultLanguage].dental_extraction
              }
              answers={[
                {
                  field: "dentalExtraction",
                  value: "Yes",
                  onPress: () =>
                    this.props.handleCheck("dentalExtraction", "Yes"),
                },
                {
                  field: "dentalExtraction",
                  value: "No",
                  onPress: () =>
                    this.props.handleCheck("dentalExtraction", "No"),
                },
              ]}
              error={this.props.error.dentalExtraction}
              checked={this.props.dentalExtraction}
            />
            <CheckboxQuestionnaire
              questionText={
                language[this.props.defaultLanguage]
                  .did_you_travel_outside_rwanda_recently
              }
              answers={[
                {
                  field: "didYouRecentlyTravelOutsideRwandaRecently",
                  value: "Yes",
                  onPress: () =>
                    this.props.handleCheck(
                      "didYouRecentlyTravelOutsideRwandaRecently",
                      "Yes"
                    ),
                },
                {
                  field: "recentTravel",
                  value: "No",
                  onPress: () =>
                    this.props.handleCheck(
                      "didYouRecentlyTravelOutsideRwandaRecently",
                      "No"
                    ),
                },
              ]}
              error={this.props.error.didYouRecentlyTravelOutsideRwandaRecently}
              checked={this.props.didYouRecentlyTravelOutsideRwandaRecently}
            />
            <Separator
              title={language[this.props.defaultLanguage].in_the_past_six_month}
            />
            <CheckboxQuestionnaire
              questionText={language[this.props.defaultLanguage].weight_loss}
              answers={[
                {
                  field: "weightLoss",
                  value: "Yes",
                  onPress: () => this.props.handleCheck("weightLoss", "Yes"),
                },
                {
                  field: "weightLoss",
                  value: "No",
                  onPress: () => this.props.handleCheck("weightLoss", "No"),
                },
              ]}
              error={this.props.error.weightLoss}
              checked={this.props.weightLoss}
            />
            <CheckboxQuestionnaire
              questionText={
                language[this.props.defaultLanguage].repeated_diarrhea
              }
              answers={[
                {
                  field: "repeatedDiarrhea",
                  value: "Yes",
                  onPress: () =>
                    this.props.handleCheck("repeatedDiarrhea", "Yes"),
                },
                {
                  field: "repeatedDiarrhea",
                  value: "No",
                  onPress: () =>
                    this.props.handleCheck("repeatedDiarrhea", "No"),
                },
              ]}
              error={this.props.error.repeatedDiarrhea}
              checked={this.props.repeatedDiarrhea}
            />
            <CheckboxQuestionnaire
              questionText={language[this.props.defaultLanguage].swollen_glands}
              answers={[
                {
                  field: "swollenGlands",
                  value: "Yes",
                  onPress: () => this.props.handleCheck("swollenGlands", "Yes"),
                },
                {
                  field: "swollenGlands",
                  value: "No",
                  onPress: () => this.props.handleCheck("swollenGlands", "No"),
                },
              ]}
              error={this.props.error.swollenGlands}
              checked={this.props.swollenGlands}
            />
            <CheckboxQuestionnaire
              questionText={
                language[this.props.defaultLanguage]
                  .continuous_low_grade_fever_any_disease
              }
              answers={[
                {
                  field: "continuousLowGradeFeverOrAnyDisease",
                  value: "Yes",
                  onPress: () =>
                    this.props.handleCheck(
                      "continuousLowGradeFeverOrAnyDisease",
                      "Yes"
                    ),
                },
                {
                  field: "continuousLowGradeFeverOrAnyDisease",
                  value: "No",
                  onPress: () =>
                    this.props.handleCheck(
                      "continuousLowGradeFeverOrAnyDisease",
                      "No"
                    ),
                },
              ]}
              error={this.props.error.continuousLowGradeFeverOrAnyDisease}
              checked={this.props.continuousLowGradeFeverOrAnyDisease}
            />
            <CheckboxQuestionnaire
              questionText={
                language[this.props.defaultLanguage].tattooing_or_ear_piercing
              }
              answers={[
                {
                  field: "tattooingOrEarPiercing",
                  value: "Yes",
                  onPress: () =>
                    this.props.handleCheck("tattooingOrEarPiercing", "Yes"),
                },
                {
                  field: "tattooingOrEarPiercing",
                  value: "No",
                  onPress: () =>
                    this.props.handleCheck("tattooingOrEarPiercing", "No"),
                },
              ]}
              error={this.props.error.tattooingOrEarPiercing}
              checked={this.props.tattooingOrEarPiercing}
            />
            <CheckboxQuestionnaire
              questionText={
                language[this.props.defaultLanguage].surgery_or_circumcision
              }
              answers={[
                {
                  field: "surgeryOrCircumcision",
                  value: "Yes",
                  onPress: () =>
                    this.props.handleCheck("surgeryOrCircumcision", "Yes"),
                },
                {
                  field: "surgeryOrCircumcision",
                  value: "No",
                  onPress: () =>
                    this.props.handleCheck("surgeryOrCircumcision", "No"),
                },
              ]}
              error={this.props.error.surgeryOrCircumcision}
              checked={this.props.surgeryOrCircumcision}
            />
            <CheckboxQuestionnaire
              questionText={language[this.props.defaultLanguage].endoscopy}
              answers={[
                {
                  field: "endoscopy",
                  value: "Yes",
                  onPress: () => this.props.handleCheck("endoscopy", "Yes"),
                },
                {
                  field: "endoscopy",
                  value: "No",
                  onPress: () => this.props.handleCheck("endoscopy", "No"),
                },
              ]}
              error={this.props.error.endoscopy}
              checked={this.props.endoscopy}
            />
            <Separator
              title={
                language[this.props.defaultLanguage]
                  .in_the_past_twelve_month_have_got
              }
            />
            <CheckboxQuestionnaire
              questionText={
                language[this.props.defaultLanguage].blood_transfusion
              }
              answers={[
                {
                  field: "bloodTransfusion",
                  value: "Yes",
                  onPress: () =>
                    this.props.handleCheck("bloodTransfusion", "Yes"),
                },
                {
                  field: "bloodTransfusion",
                  value: "No",
                  onPress: () =>
                    this.props.handleCheck("bloodTransfusion", "No"),
                },
              ]}
              error={this.props.error.bloodTransfusion}
              checked={this.props.bloodTransfusion}
            />
            <CheckboxQuestionnaire
              questionText={language[this.props.defaultLanguage].heart_disease}
              answers={[
                {
                  field: "heartDisease",
                  value: "Yes",
                  onPress: () => this.props.handleCheck("heartDisease", "Yes"),
                },
                {
                  field: "heartDisease",
                  value: "No",
                  onPress: () => this.props.handleCheck("heartDisease", "No"),
                },
              ]}
              error={this.props.error.heartDisease}
              checked={this.props.heartDisease}
            />
            <CheckboxQuestionnaire
              questionText={language[this.props.defaultLanguage].kidney_disease}
              answers={[
                {
                  field: "kidneyDisease",
                  value: "Yes",
                  onPress: () => this.props.handleCheck("kidneyDisease", "Yes"),
                },
                {
                  field: "kidneyDisease",
                  value: "No",
                  onPress: () => this.props.handleCheck("kidneyDisease", "No"),
                },
              ]}
              error={this.props.error.kidneyDisease}
              checked={this.props.kidneyDisease}
            />
            <CheckboxQuestionnaire
              questionText={language[this.props.defaultLanguage].cancer}
              answers={[
                {
                  field: "cancer",
                  value: "Yes",
                  onPress: () => this.props.handleCheck("cancer", "Yes"),
                },
                {
                  field: "cancer",
                  value: "No",
                  onPress: () => this.props.handleCheck("cancer", "No"),
                },
              ]}
              error={this.props.error.cancer}
              checked={this.props.cancer}
            />
            <CheckboxQuestionnaire
              questionText={language[this.props.defaultLanguage].epilepsy}
              answers={[
                {
                  field: "epilepsy",
                  value: "Yes",
                  onPress: () => this.props.handleCheck("epilepsy", "Yes"),
                },
                {
                  field: "epilepsy",
                  value: "No",
                  onPress: () => this.props.handleCheck("epilepsy", "No"),
                },
              ]}
              error={this.props.error.epilepsy}
              checked={this.props.epilepsy}
            />
            <CheckboxQuestionnaire
              questionText={language[this.props.defaultLanguage].asthma}
              answers={[
                {
                  field: "asthma",
                  value: "Yes",
                  onPress: () => this.props.handleCheck("asthma", "Yes"),
                },
                {
                  field: "asthma",
                  value: "No",
                  onPress: () => this.props.handleCheck("asthma", "No"),
                },
              ]}
              error={this.props.error.asthma}
              checked={this.props.asthma}
            />
            <CheckboxQuestionnaire
              questionText={language[this.props.defaultLanguage].diabetes}
              answers={[
                {
                  field: "diabetes",
                  value: "Yes",
                  onPress: () => this.props.handleCheck("diabetes", "Yes"),
                },
                {
                  field: "diabetes",
                  value: "No",
                  onPress: () => this.props.handleCheck("diabetes", "No"),
                },
              ]}
              error={this.props.error.diabetes}
              checked={this.props.diabetes}
            />
            <CheckboxQuestionnaire
              questionText={language[this.props.defaultLanguage].tuberculosis}
              answers={[
                {
                  field: "tuberculosis",
                  value: "Yes",
                  onPress: () => this.props.handleCheck("tuberculosis", "Yes"),
                },
                {
                  field: "tuberculosis",
                  value: "No",
                  onPress: () => this.props.handleCheck("tuberculosis", "No"),
                },
              ]}
              error={this.props.error.tuberculosis}
              checked={this.props.tuberculosis}
            />
            <CheckboxQuestionnaire
              questionText={
                language[this.props.defaultLanguage].abnormal_bleeding_tendency
              }
              answers={[
                {
                  field: "abnormalBleedingTendency",
                  value: "Yes",
                  onPress: () =>
                    this.props.handleCheck("abnormalBleedingTendency", "Yes"),
                },
                {
                  field: "abnormalBleedingTendency",
                  value: "No",
                  onPress: () =>
                    this.props.handleCheck("abnormalBleedingTendency", "No"),
                },
              ]}
              error={this.props.error.abnormalBleedingTendency}
              checked={this.props.abnormalBleedingTendency}
            />
            <CheckboxQuestionnaire
              questionText={
                language[this.props.defaultLanguage].hepatitis_b_or_c
              }
              answers={[
                {
                  field: "hepatitisBOrC",
                  value: "Yes",
                  onPress: () => this.props.handleCheck("hepatitisBOrC", "Yes"),
                },
                {
                  field: "hepatitisBOrC",
                  value: "No",
                  onPress: () => this.props.handleCheck("hepatitisBOrC", "No"),
                },
              ]}
              error={this.props.error.hepatitisBOrC}
              checked={this.props.hepatitisBOrC}
            />
            <CheckboxQuestionnaire
              questionText={language[this.props.defaultLanguage].HIVAIDS}
              answers={[
                {
                  field: "HIVAIDS",
                  value: "Yes",
                  onPress: () => this.props.handleCheck("HIVAIDS", "Yes"),
                },
                {
                  field: "HIVAIDS",
                  value: "No",
                  onPress: () => this.props.handleCheck("HIVAIDS", "No"),
                },
              ]}
              error={this.props.error.HIVAIDS}
              checked={this.props.HIVAIDS}
            />
            <CheckboxQuestionnaire
              questionText={language[this.props.defaultLanguage].syphillis}
              answers={[
                {
                  field: "syphillis",
                  value: "Yes",
                  onPress: () => this.props.handleCheck("syphillis", "Yes"),
                },
                {
                  field: "syphillis",
                  value: "No",
                  onPress: () => this.props.handleCheck("syphillis", "No"),
                },
              ]}
              error={this.props.error.syphillis}
              checked={this.props.syphillis}
            />
            <CheckboxQuestionnaire
              questionText={language[this.props.defaultLanguage].gonorrhea}
              answers={[
                {
                  field: "gonorrhea",
                  value: "Yes",
                  onPress: () => this.props.handleCheck("gonorrhea", "Yes"),
                },
                {
                  field: "gonorrhea",
                  value: "No",
                  onPress: () => this.props.handleCheck("gonorrhea", "No"),
                },
              ]}
              error={this.props.error.gonorrhea}
              checked={this.props.gonorrhea}
            />
            <CheckboxQuestionnaire
              questionText={
                language[this.props.defaultLanguage]
                  .neighbor_with_infectious_such_as_hapatitis_b_or_c
              }
              answers={[
                {
                  field: "neighborWithInfectious",
                  value: "Yes",
                  onPress: () =>
                    this.props.handleCheck("neighborWithInfectious", "Yes"),
                },
                {
                  field: "neighborWithInfectious",
                  value: "No",
                  onPress: () =>
                    this.props.handleCheck("neighborWithInfectious", "No"),
                },
              ]}
              error={this.props.error.neighborWithInfectious}
              checked={this.props.neighborWithInfectious}
            />

            {this.props.gender === "female" && (
              <>
                <Separator
                  title={
                    language[this.props.defaultLanguage].for_female_donors_only
                  }
                />
                <CheckboxQuestionnaire
                  questionText={
                    language[this.props.defaultLanguage].are_you_pregnant
                  }
                  answers={[
                    {
                      field: "areYouPregnant",
                      value: "Yes",
                      onPress: () =>
                        this.props.handleCheck("areYouPregnant", "Yes"),
                    },
                    {
                      field: "areYouPregnant",
                      value: "No",
                      onPress: () =>
                        this.props.handleCheck("areYouPregnant", "No"),
                    },
                  ]}
                  error={this.props.error.areYouPregnant}
                  checked={this.props.areYouPregnant}
                />
                <CheckboxQuestionnaire
                  questionText={
                    language[this.props.defaultLanguage]
                      .do_you_have_a_child_who_is_under_9_months
                  }
                  answers={[
                    {
                      field: "doYouHaveChildUnder9Months",
                      value: "Yes",
                      onPress: () =>
                        this.props.handleCheck(
                          "doYouHaveChildUnder9Months",
                          "Yes"
                        ),
                    },
                    {
                      field: "doYouHaveChildUnder9Months",
                      value: "No",
                      onPress: () =>
                        this.props.handleCheck(
                          "doYouHaveChildUnder9Months",
                          "No"
                        ),
                    },
                  ]}
                  error={this.props.error.doYouHaveChildUnder9Months}
                  checked={this.props.doYouHaveChildUnder9Months}
                />
                <CheckboxQuestionnaire
                  questionText={
                    language[this.props.defaultLanguage]
                      .have_you_had_abortion_in_the_last_siz_month
                  }
                  answers={[
                    {
                      field: "haveYouHadAbortionInTheLastSixMonths",
                      value: "Yes",
                      onPress: () =>
                        this.props.handleCheck(
                          "haveYouHadAbortionInTheLastSixMonths",
                          "Yes"
                        ),
                    },
                    {
                      field: "haveYouHadAbortionInTheLastSixMonths",
                      value: "No",
                      onPress: () =>
                        this.props.handleCheck(
                          "haveYouHadAbortionInTheLastSixMonths",
                          "No"
                        ),
                    },
                  ]}
                  error={this.props.error.haveYouHadAbortionInTheLastSixMonths}
                  checked={this.props.haveYouHadAbortionInTheLastSixMonths}
                />
                <CheckboxQuestionnaire
                  questionText={
                    language[this.props.defaultLanguage]
                      .are_you_menstrual_period
                  }
                  answers={[
                    {
                      field: "areYouMenstrualPeriod",
                      value: "Yes",
                      onPress: () =>
                        this.props.handleCheck("areYouMenstrualPeriod", "Yes"),
                    },
                    {
                      field: "areYouMenstrualPeriod",
                      value: "No",
                      onPress: () =>
                        this.props.handleCheck("areYouMenstrualPeriod", "No"),
                    },
                  ]}
                  error={this.props.error.areYouMenstrualPeriod}
                  checked={this.props.areYouMenstrualPeriod}
                />
              </>
            )}
          </div>
        </div>
        {/* {this.props.showFooter && (
          <div className="card-footer">
            <div className="d-flex gap-3">
              <Button
                className="btn-default"
                onPress={() => console.log("back")}
                text={language[this.props.defaultLanguage].previous}
              />
              <Button
                text={language[this.props.defaultLanguage].next}
                isSubmitting={this.props.isSubmitting}
                onPress={() => this.props.goNext()}
              />
            </div>
          </div>
        )} */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(PersonalInformation);
