import language from "../language";
export const donorCategories = (lng) => [
  {
    label: language[lng].new_donors,
    value: "new",
  },
  {
    label: language[lng].regular_donors,
    value: "regular",
  },
  {
    label: language[lng].irregular_donors,
    value: "irregular",
  },
  {
    label: language[lng].dropped_donors,
    value: "dropped",
  },
];
