import React from "react";
import { connect } from "react-redux";
import axios from "axios";

import icons from "../../../constants/icons";
import language from "../../../language";
import contactVector from "../../../assets/contactus.png";

import validateEmail from "../../../utils/validateEmail";
import toastMessage from "../../../utils/toastMessage";

import { Button } from "../../Button";
import { Input } from "../../Input";
import { Title } from "../Title";

import "./styles.css";

class ContactUs extends React.Component {
  state = {
    names: "",
    email: "",
    phone: "",
    message: "",
    isSubmitting: false,
    error: {},
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({ error, [field]: e.target.value });
  }

  validateForm() {
    let { names, phone, email, message, error } = this.state;
    let { defaultLanguage } = this.props;

    const languageTexts = language[defaultLanguage];
    const {
      names_required,
      email_required,
      invalid_email,
      phone_number_required,
      message_required,
    } = languageTexts;

    if (names === "") {
      error.names = names_required;
    }

    if (email === "") {
      error.email = email_required;
    } else if (!validateEmail(email)) {
      error.email = invalid_email;
    }

    if (phone === "") {
      error.phone = phone_number_required;
    }

    if (message === "") {
      error.message = message_required;
    }

    this.setState({
      error,
    });
  }

  onSubmit = async () => {
    await this.validateForm();

    const { error, names, phone, email, message } = this.state;

    if (Object.keys(error).length === 0) {
      this.setState({ isSubmitting: true });

      const options = {
        method: "POST",
        url: `${process.env.REACT_APP_BBMS_BASE_API}/feedback`,
        data: {
          name: names,
          email,
          phoneNumber: phone,
          message,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
            names: "",
            email: "",
            message: "",
            phone: "",
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage].data_submitted_success
          );
        })
        .catch((error) => {
          this.setState({ isSubmitting: false });
          toastMessage("error", error);
        });
    }
  };

  render() {
    const { defaultLanguage } = this.props;
    const languageTexts = language[defaultLanguage];

    return (
      <div className="row">
        <Title title="Contact Us" />
        <div className="row">
          <div className="col-sm-12 col-md-6" style={{ marginBottom: "3rem" }}>
            <div>
              <Input
                label={languageTexts.names}
                leftIcon={icons.user}
                placeholder={languageTexts.names_placeholder}
                className="underline"
                error={this.state.error.names}
                value={this.state.names}
                onChange={(e) => this.onChangeText("names", e)}
              />
              <Input
                label={languageTexts.email_address}
                leftIcon={icons.email}
                placeholder={languageTexts.email_placeholder}
                className="underline"
                error={this.state.error.email}
                value={this.state.email}
                onChange={(e) => this.onChangeText("email", e)}
              />
              <Input
                label={languageTexts.phone_number}
                leftIcon={icons.phone}
                placeholder={languageTexts.phone_placeholder}
                className="underline"
                error={this.state.error.phone}
                value={this.state.phone}
                onChange={(e) => this.onChangeText("phone", e)}
              />
              <Input
                label={languageTexts.message}
                leftIcon={icons.chat}
                placeholder={languageTexts.message_placeholder}
                className="underline"
                inputType="textarea"
                error={this.state.error.message}
                value={this.state.message}
                onChange={(e) => this.onChangeText("message", e)}
              />
              <Button
                text={languageTexts.submit}
                onPress={this.onSubmit.bind(this)}
                isSubmitting={this.state.isSubmitting}
              />
            </div>
          </div>
          <div className="col-md-2" />
          <div className="col-md-4">
            <div className="contact-box-with-number-container">
              <div className="contact-box-icon">
                <img src={contactVector} />
              </div>
              <center className="contact-box-numbers">
                <h3>
                  We love to <span className="text-primary">help</span>
                </h3>
                <div className="contact-box-number">
                  <p className="contact-box-number-title-sm">
                    Toll Free Number
                  </p>
                </div>
                <div className="contact-box-number">
                  <p className="text-bold">114 or +250 788 202 080</p>
                </div>
                <div className="contact-box-number">
                  <p className="contact-box-number-title-sm">Call Us</p>
                </div>
                <div className="contact-box-number">
                  <p className="text-bold">114 or +250 788 202 080</p>
                </div>
                <div className="contact-box-number">
                  <p className="text-bold">Monday - Sunday</p>
                </div>
                <div className="contact-box-number">
                  <p className="text-bold text-primary">Every hour 24/7</p>
                </div>
              </center>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ContactUs);
