import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import { Footer } from "../../components/Landing/Footer";
import { LandingPageLayout } from "../../Layout";
import { Partners } from "../../components/Landing/Partners";
import { ContactUs } from "../../components/Landing/ContactUs";
import { withRouter } from "../../utils/withRouter";
import Sites from "../../components/Landing/CentersAndSites/Sites";

class SitesLandingScreen extends React.Component {
  render() {
    console.log(this.props);
    return (
      <>
        <Navbar {...this.props} isFixed isShadowActive />
        <div className="main-landing-container" id="landingContainer">
          <LandingPageLayout id="sites">
            <div>
              <Sites centerId={this.props.routeParams.centerId} />
            </div>
          </LandingPageLayout>
          <LandingPageLayout className="half-height light" id="partners">
            <Partners />
          </LandingPageLayout>
          <LandingPageLayout id="contactUs">
            <ContactUs />
          </LandingPageLayout>
          <Footer />
        </div>
      </>
    );
  }
}

export default withRouter(SitesLandingScreen);
