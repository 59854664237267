import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import "./styles.css";
import AppMarker from "./AppMarker";
// import MarkerClusterGroup from "react-leaflet-markercluster";
import L from "leaflet";
import { Loading } from "../Loader";
import { Input } from "../Input";

const Map = (props) => {
  const createClusterCustomIcon = function (cluster) {
    return L.divIcon({
      html: `<div class='marker-cluster-child' ><span>${cluster.getChildCount()}</span></div>`,
      className: "marker-cluster-custom",
      iconSize: L.point(40, 40, true),
    });
  };

  let group_by_address = {};

  for (let i = 0; i < props.data.length; i++) {
    const { province, district, sector, village, cell } =
      props.data[i].address || {};
    const address = `${province} ${district} ${sector} ${village} ${cell}`;

    if (!group_by_address[address]) {
      group_by_address[address] = [];
    }

    group_by_address[address].push(props.data[i]);
  }

  const addresses = Object.keys(group_by_address);
  // console.log(group_by_address);
  return (
    <div className="open-street-map-container">
      {props.isLoading ? (
        <Loading className="loader-fullscreen" />
      ) : (
        <MapContainer
          scrollWheelZoom={true}
          zoomControl={true}
          center={[-1.960539, 30.125772]}
          zoom={13}
          keyboard={false}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {props.positions.map((marker, i) => {
            return <AppMarker marker={marker} key={i} />;
          })}
        </MapContainer>
      )}
      <div className="mapCentersContainer">
        <div className="card-body">
          <h3 style={{ fontSize: 14 }}>Blood Collection Sites</h3>
          <Input
            placeholder="Search..."
            onChange={props.handleSearch}
            value={props.search}
          />
        </div>
        {props?.data?.map((el, i) => {
          return (
            <div
              key={i}
              className="item"
              onClick={() => props.handlerPressCenter(el)}
            >
              <div className="dot"></div>
              <div>
                <span>{el.name}</span>
              </div>
            </div>
          );
        })}
      </div>

      {/* {props.isLoading && <MapDataLoader />}
      <div className="legend-mobile-btn">
        <div>
          <i className="bx bx-chevron-up"></i>
        </div>
      </div>
      {props.legend && (
        <div className={`legend `} style={{ ...props.legend.styles }}>
          {props.legend.items &&
            props.legend.items.map((item, i) => {
              return (
                <div className={`legend-item `} key={i}>
                  {item.color && (
                    <div
                      className={`icon-container ${item.className}`}
                      style={{ backgroundColor: item.color }}
                    >
                      <i className={`bx ${item.icon}`} />
                    </div>
                  )}
                  <span>{item.name}</span>
                </div>
              );
            })}
        </div>
      )} */}
    </div>
  );
};

export default Map;
