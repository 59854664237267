// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-box-with-number-container {
  background-color: var(--light-color);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1.5rem 0;
  border-radius: var(--border-radius);
  border-bottom: 4px solid var(--primary-color);
}

.contact-box-with-number-container .contact-box-icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 2rem;
}

.contact-box-with-number-container .contact-box-icon img {
  width: 100%;
}

.contact-box-with-number-container .contact-box-numbers h3 {
  margin-bottom: 1.5rem;
}

.contact-box-with-number-container .contact-box-number {
  min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Landing/ContactUs/styles.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,iBAAiB;EACjB,mCAAmC;EACnC,6CAA6C;AAC/C;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".contact-box-with-number-container {\n  background-color: var(--light-color);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  padding: 1.5rem 0;\n  border-radius: var(--border-radius);\n  border-bottom: 4px solid var(--primary-color);\n}\n\n.contact-box-with-number-container .contact-box-icon {\n  width: 80px;\n  height: 80px;\n  border-radius: 50%;\n  margin-bottom: 2rem;\n}\n\n.contact-box-with-number-container .contact-box-icon img {\n  width: 100%;\n}\n\n.contact-box-with-number-container .contact-box-numbers h3 {\n  margin-bottom: 1.5rem;\n}\n\n.contact-box-with-number-container .contact-box-number {\n  min-height: 55px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
