import "./styles.css";
import { Title } from "../Title";
import registerVector from "../../../assets/register.png";
import screenedVector from "../../../assets/screened.png";
import giveBloodVector from "../../../assets/giveBlood.png";
import FeatureCard from "../FeatureCard/FeatureCard";
import { connect } from "react-redux";
import language from "../../../language";

const HowWorks = (props) => {
  return (
    <>
      <Title title={language[props.defaultLanguage].how_works} center />
      <div className="row">
        <FeatureCard
          className="col-sm-6 col-md-4"
          title={language[props.defaultLanguage].register}
          description={
            language[props.defaultLanguage].register_feature_description
          }
          vector={registerVector}
          buttonText="Create Account"
          btnStyles="btn-transparent bordered"
          onPress={() => (window.location.href = "common/start-application")}
        />
        <FeatureCard
          className="col-sm-6 col-md-4 "
          primary
          title={language[props.defaultLanguage].get_screened}
          description={
            language[props.defaultLanguage].screened_feature_description
          }
          vector={screenedVector}
          buttonText={language[props.defaultLanguage].learn_more}
          btnStyles="btn-transparent bordered light"
        />
        <FeatureCard
          className="col-md-4"
          title={language[props.defaultLanguage].donate}
          description={
            language[props.defaultLanguage].donate_feature_description
          }
          vector={giveBloodVector}
          buttonText={language[props.defaultLanguage].donate_now}
          btnStyles="btn-transparent bordered"
          onPress={() => (window.location.href = "common/start-application")}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(HowWorks);
