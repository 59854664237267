import "./styles.css";

const Separator = (props) => {
  return (
    <div className="separator">
      <div className="separator-content">
        {props.title && (
          <div className="title-container">
            <span className={`text-bold ${props.titleClassName}`}>
              {props.title}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Separator;
