import toastMessage from "../toastMessage";
import { getStorage } from "../storage";
import axios from "axios";
import language from "../../language";
import { defineRoleCenterSite } from "../handleAccessRoles";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

const fetchData = async (lng = "english", params) => {
  const { page, limit, name, type, id, q, code } = params || {};
  try {
    const { centerRole } = await defineRoleCenterSite();

    const options = {
      method: "GET",
      url: `${API_URL}/bloodCenter`,
      params: {
        page: page,
        limit: limit,
        name,
        id: id || centerRole,
        q,
        code
      },
    };

    const queryRes = await axios(options);

    let { data, count } = queryRes.data;

    if (type === "dropdown") {
      return (queryRes?.data?.data || queryRes.data).map((el) => {
        return {
          ...el,
          label: el.code + " - " + el.name,
          value: el._id,
        };
      });
    }

    return !page && !limit ? queryRes.data : { data, count };
  } catch (error) {
    toastMessage("error", language[lng].unable_fetch_centers);
  }
};

export default fetchData;

export const deleteCenter = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `${API_URL}/bloodCenter`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await axios(options);

    toastMessage("success", language[lng].success_delete_center);
  } catch (error) {
    toastMessage("error", language[lng].error_delete_center);
  }
};

export const fetchCenterCountSite = async (lng = "english", params) => {
  try {
    const { centerRole } = await defineRoleCenterSite();
    const user = await getStorage();

    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_BBMS_REPORT_API}/analytics/centerSite`,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const { data } = await axios(options);
    console.log("fetch count center", data);

    return data;
  } catch (error) {
    toastMessage("error", error);
  }
};
