import React from "react";
import { DonorProfile } from "../../components/Donors";
import { withRouter } from "../../utils/withRouter";

class DonorProfileScreen extends React.Component {
  render() {
    return <DonorProfile donorId={this.props.routeParams.id} />;
  }
}

export default withRouter(DonorProfileScreen);
