import "./App.css";
import "./app/common/styles/scrollbar.styles.css";
import "./app/common/styles/auth.form.styles.css";
import "./app/common/styles/media.queries/general.media.queries.css";
import "./app/common/styles/border.styles.css";
import "./app/common/styles/card.styles.css";
import "./app/common/styles/dropdown.styles.css";
import "./app/common/styles/icons.styles.css";
import "./app/common/styles/label.styles.css";
import "./app/common/styles/margin.styles.css";
import "./app/common/styles/modals.styles.css";
import "./app/common/styles/accrodion.styles.css";
import "./app/common/styles/text.styles.css";
import "./app/common/styles/printing.styles.css";
import "./app/common/styles/ul.styles.css";
import "./app/common/styles/badge.styles.css";
import "./app/common/styles/alerts.styles.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { AdminRoutes, CommonRoutes } from "./app/routes";
import UserRoutes from "./app/routes/user.routes";

function App() {
  return (
    <div className="App">
      <CommonRoutes />
      <AdminRoutes />
      <UserRoutes />
      <ToastContainer
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        progress={undefined}
      />
    </div>
  );
}

export default App;
