import React from "react";
import { connect } from "react-redux";
import { defineRoleCenterSite } from "../../utils/handleAccessRoles";
import handleFilters from "../../utils/handleFilters";
import { fetchDonationSessionData } from "../../utils/queries/analytics/bloodCollectionSession";
import { Loading } from "../Loader";
import { Bar } from "react-chartjs-2";
import moment from "moment";

const options = {
  maintainAspectRatio: false,
  animation: false,
  offset: true,
};

class BloodCollectionSession extends React.Component {
  state = {
    data: [],
  };

  componentDidMount() {
    this.getData(true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  getData = async (isLoading) => {
    try {
      const { defaultLanguage, filters } = this.props;

      this.setState({ isLoading });

      const { centerRole, centerSiteRole } = await defineRoleCenterSite();

      const { data } = await fetchDonationSessionData(defaultLanguage, {
        center: centerRole,
        centerSite: centerSiteRole,
        ...handleFilters(filters),
      });

      const formateData = this.handleDataFormation(data);

      this.setState({
        data: formateData,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
    }
  };

  handleDataFormation(data) {
    const chartData = {},
      labels = [];
    data.forEach((item) => {
      const separator = item?.center?.name;

      labels.push(separator);

      if (!chartData[separator]) {
        chartData[separator] = {
          planned: 0,
          performed: 0,
          total: 0,
        };
      }

      chartData[separator].total += item.total;
      chartData[separator].planned += item.planned;
      chartData[separator].performed += item.performed;
    });

    let performed = [],
      planned = [],
      total = [];

    Object.keys(chartData).forEach((key) => {
      performed.push(chartData[key].performed);
      planned.push(chartData[key].planned);
      total.push(chartData[key].total);
    });

    return {
      labels: labels,
      datasets: [
        {
          label: "Planned",
          data: planned,
          backgroundColor: "#AC0207",
          borderColor: "#AC0207",
          radius: 5,
        },
        {
          label: "Performed",
          data: performed,
          backgroundColor: "#F7B811",
          borderColor: "#F7B811",
          radius: 5,
        },
        {
          label: "Total",
          data: total,
          backgroundColor: "#DDDDDD",
          borderColor: "#DDDDDD",
          radius: 5,
        },
      ],
    };
  }
  render() {
    return (
      <div className="card">
        <div className="card-header">
          <h3>Blood Collection Sessions - </h3>
          <small>
            {moment(this.props.filters.startDate).format("DD-MM-YYYY")}
            {" - "}
            {moment(this.props.filters.ENDdATE).format("DD-MM-YYYY")}
          </small>
        </div>

        <div className="card-body">
          {this.state.isLoading ? (
            <div
              style={{
                height: 400,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Loading />
            </div>
          ) : this.state.data.length === 0 ? (
            <div
              style={{
                height: 400,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span className="text-disabled">
                No Data for Blood Collection Sites
              </span>
            </div>
          ) : (
            <Bar
              width="100%"
              height="400px"
              data={this.state.data}
              options={options}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(BloodCollectionSession);
