import React from "react";
import { connect } from "react-redux";
import { ListItem } from "../List";
import language from "../../language";
import ImagePlaceholder from "../../assets/userPhotoPlaceholder.png";
class TestBy extends React.Component {
  render() {
    return (
      <div className="card test-side-container">
        <div className="card-body">
          <h3 className="mb-3">
            {language[this.props.defaultLanguage].tested_by}
          </h3>
          <ListItem
            image={ImagePlaceholder}
            name={this.props.testedBy}
            description={this.props.currentDate}
            classNameDescription="text-primary"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;

  return { defaultLanguage };
};

export default connect(mapStateToProps)(TestBy);
