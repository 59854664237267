import React from "react";
import { connect } from "react-redux";
import language from "../../../../language";
import { Loading } from "../../../Loader";
import { Empty } from "../../../Empty";
import { fetchInventories } from "../../../../utils/queries/inventories/center";
import toastMessage from "../../../../utils/toastMessage";
import moment from "moment";
import { PrintComponent } from "../../../Print";
import Barcode from "react-barcode";
import { Checkbox } from "../../../Input";
import { CardHeader } from "../../../Header";
import { Button } from "../../../Button";
import axios from "axios";
import { getStorage } from "../../../../utils/storage";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class PrintableLabel extends React.Component {
  state = {
    isLoading: false,
    data: [],
  };

  componentDidMount() {
    this.getData(true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.productId !== this.props.productId) {
      this.getData(true);
    }
  }

  getData = async (isLoading) => {
    try {
      this.setState({ isLoading });

      const data = await fetchInventories(this.props.defaultLanguage, {
        id: this.props.productId,
      });

      this.setState({ data, isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });

      toastMessage(error);
    }
  };

  renderInfo = ({ title, description, barCode }) => {
    return (
      <div className="d-flex align-items-center" style={{ minHeight: "1rem" }}>
        <div style={{ flex: 1 }}>
          <span>{title}</span>
        </div>
        {description && (
          <div>
            <span className="text-bold">{description}</span>
          </div>
        )}
        {barCode && (
          <div>
            <Barcode
              displayValue={false}
              value={barCode}
              width={2}
              height={25}
            />
          </div>
        )}
      </div>
    );
  };

  handleCheck(field) {
    this.setState({
      [field]: !this.state[field],
    });
  }

  handleUpdate = async (id) => {
    try {
      this.setState({ isUpdating: true });

      const user = await getStorage();

      const options = {
        url: `${API_URL}/inventory`,
        method: "PUT",
        data: {
          status:
            this.state.checkDonation &&
            this.state.checkComponent &&
            this.state.checkBloodGroup
              ? "completed"
              : "pending",
          id,
          checkBloodGroup: this.state.checkBloodGroup,
          checkComponent: this.state.checkComponent,
          checkDonation: this.state.checkDonation,
        },
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      await axios(options);

      this.setState({ isUpdating: false });

      this.getData(true);
    } catch (error) {
      this.setState({ isUpdating: false });

      toastMessage("error", error);
    }
  };

  render() {
    if (this.state.isLoading) {
      return (
        <center>
          <Loading />
        </center>
      );
    }

    if (this.state.data.length === 0) {
      return <Empty title={language[this.props.defaultLanguage].no_data} />;
    }

    return (
      <div style={{ paddingTop: "1rem" }}>
        <PrintComponent>
          <div className="card">
            {this.state.data.map((el, i) => {
              return (
                <div
                  key={i}
                  style={{
                    height: "106.8mm",
                  }}
                >
                  <div className="noPrint">
                    <div className="card-body">
                      {[
                        {
                          name: "Check donation number",
                          type: "checkDonation",
                        },
                        {
                          name: " Check component type",
                          type: "checkComponent",
                        },
                        {
                          name: "Check blood group",
                          type: "checkBloodGroup",
                        },
                      ].map((checkEl, i) => {
                        return (
                          <Checkbox
                            key={i}
                            label={checkEl.name}
                            onCheck={() => this.handleCheck(checkEl.type)}
                            checked={
                              this.state[checkEl.type] || el[checkEl.type]
                            }
                          />
                        );
                      })}
                    </div>
                  </div>
                  <div className="card-header">
                    <center>
                      <h3>BTD RWANDA KIGALI</h3>
                    </center>
                    <h3>{el?.center?.name}</h3>
                  </div>
                  <div className="card-body">
                    {this.renderInfo({ title: el.name, barCode: el.code })}
                    {this.renderInfo({
                      title: "Volume",
                      description: el.volume + " ML",
                    })}
                    {this.renderInfo({
                      title: "Phebotomy Date",
                      description: moment(el?.donation?.createdAt).format(
                        "lll"
                      ),
                    })}
                    {this.renderInfo({
                      title: "Expires In",
                      description: moment(el.expiredDate).format(
                        "YYYY-MM-DD HH:mm"
                      ),
                    })}
                    {this.renderInfo({
                      title: "Donation Number",
                      barCode: el.donation?.donationNumber,
                    })}
                    {this.renderInfo({
                      title: "Blood Group",
                      barCode: el.bloodGroup,
                    })}
                    <hr />
                    {this.renderInfo({
                      title: "Temperature",
                      description: el?.component?.storageTemperature?.name,
                    })}
                    <hr />
                    {this.renderInfo({
                      title: "",
                      description: el.serologyResult,
                    })}
                    {this.renderInfo({
                      title: "",
                      description: el.bloodGroupingResult,
                    })}
                  </div>
                  <div className="noPrint">
                    <div className="modal-footer ">
                      <Button
                        text={language[this.props.defaultLanguage].cancel}
                        onPress={this.props.handleCloseModal}
                        className="btn-default"
                      />
                      <Button
                        text={language[this.props.defaultLanguage].update}
                        isSubmitting={this.state.isUpdating}
                        onPress={this.handleUpdate.bind(this, el._id)}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </PrintComponent>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(PrintableLabel);
