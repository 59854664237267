import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import { Modal, DeleteModal } from "../Modal";
import {
  fetchMeasureData,
  deleteMeasure,
} from "../../utils/queries/measureQuery.js";
import icons from "../../constants/icons";
import { defineRole } from "../../utils/handleAccessRoles";
import { getStorage } from "../../utils/storage";
import { ROLE_VITAL_SIGN_MEASURE } from "../../constants/ROLES";
import NewMeasure from "./NewMeasure";

class Measures extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    await this.setState({ user });

    await this.getData(true);
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target.value,
      error,
    });
  }

  getData = async (isLoading, search = {}) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await fetchMeasureData(defaultLanguage, {
        page,
        limit,
        ...search,
      });

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].code,
        key: "code",
      },
      {
        title: language[this.props.defaultLanguage].name,
        key: "name",
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];

    return headers;
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deleteMeasure(
        this.props.defaultLanguage,
        this.state.selectedData._id
      );

      this.setState({ isDeleting: false });

      this.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      this.setState({
        isDeleting: false,
      });
    }
  };

  handleSearch = async (e) => {
    const search = e.target.value;
    this.setState({ search_text: search });
  };

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });

    await this.getData(true, {
      code: search || search !== "" ? search : undefined,
    });

    this.setState({
      searching: false,
    });
  };

  render() {
    const { add, edit_vital_sign, edit, delete_ } =
      language[this.props.defaultLanguage];

    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          showAdd={defineRole({
            roles: this.state.user.accessRole,
            menu: ROLE_VITAL_SIGN_MEASURE,
            operation: "create",
          })}
          addButtonText={add}
          handleSearch={this.handleSearch.bind(this)}
          addSearchButton
          searching={this.state.searching}
          handlePressSearch={() =>
            this.handleRemoteSearch(this.state.search_text)
          }
          handleAddPressed={this.handleShowModal.bind(
            this,
            "showModal",
            language[this.props.defaultLanguage].add
          )}
          rowPress={(item) =>
            this.handleShowModal("showModal", item.name, item)
          }
          filters={[
            {
              type: "button",
              title: language[this.props.defaultLanguage].refresh,
              button_type: "button",
              icon: icons.refresh,
              onPress: (item) => this.getData(true),
            },
          ]}
          actions={[
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_VITAL_SIGN_MEASURE,
                operation: "update",
              }),
              name: edit,
              onPress: (item) =>
                this.handleShowModal("showModal", edit_vital_sign, item),
              icon: icons.edit,
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_VITAL_SIGN_MEASURE,
                operation: "delete",
              }),
              name: delete_,
              onPress: this.handleShowModal.bind(
                this,
                "showDeleteModal",
                delete_
              ),
              icon: icons.delete,
            },
          ]}
        />

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
        >
          <NewMeasure
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            getData={this.getData.bind(this)}
            {...this.state.selectedData}
            donorId={this.props.donorId || this.state.selectedData?.donor?._id}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selectedData.name}
            getData={this.getData.bind(this)}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Measures);
