import React from "react";
import bloodDistributorQuery from "../../../../utils/queries/bloodDistributorQuery";
import { Button } from "../../../Button";
import { connect } from "react-redux";
import { Select } from "../../../Input";
import language from "../../../../language";
import toastMessage from "../../../../utils/toastMessage";
import axios from "axios";
import { getStorage } from "../../../../utils/storage";

class AddDistributor extends React.Component {
  state = {
    error: {},
    distributors: [],
    isLoading: true,
  };

  componentDidMount = async () => {};

  getData = async (isLoading) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const data = await bloodDistributorQuery(defaultLanguage, {
        type: "dropdown",
      });

      this.setState({
        isLoading: false,
        distributors: data,
      });
      return data;
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleChange = (field, e) => {
    let { error } = this.state;

    delete error[field];

    this.setState({ error, [field]: e });
  };

  validateForm = () => {
    let { error, distributor } = this.state;
    let { defaultLanguage } = this.props;

    if (!distributor) {
      error.distributor = language[defaultLanguage].distributor_required;
    }

    this.setState({ error });
  };

  onSubmit = async () => {
    try {
      await this.validateForm();

      const { error, distributor } = this.state;
      const { requestId, bloodBank, requestNo } = this.props;
      const user = await getStorage();

      if (Object.keys(error).length === 0) {
        this.setState({ isSubmitting: true });
        const options = {
          method: "PUT",
          url: `${process.env.REACT_APP_BBMS_BASE_API}/bloodrequest`,
          data: {
            id: requestId,
            distributor: distributor.value,
            bloodBank,
            requestNo,
            status: "pending-pickup",
          },
          headers: {
            authorization: "Bearer " + user.token,
          },
        };

        await axios(options);

        this.setState({ isSubmitting: false });

        this.props.handleCloseModal && this.props.handleCloseModal();
        this.props.getRequestInfo && this.props.getRequestInfo();
        this.props.handleRefresh && this.props.handleRefresh(true);
      }
    } catch (error) {
      toastMessage("error", error);
      this.setState({ isSubmitting: false });
    }
  };
  render() {
    return (
      <div className="card-body">
        <Select
          onChange={(e) => this.handleChange("distributor", e)}
          error={this.state.error.distributor}
          isLoading={this.state.isLoading}
          value={this.state.distributor}
          loadOptions={this.getData}
        />
        <Button
          text={language[this.props.defaultLanguage].submit}
          onPress={this.onSubmit.bind(this)}
          isSubmitting={this.state.isSubmitting}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(AddDistributor);
