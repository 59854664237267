import React from "react";
import "./styles.css";
import { Logo } from "../Logo";
import { Button, DropdownButton } from "../Button";
import MobileNavbar from "./MobileNavbar";
import language from "../../language";
import { connect } from "react-redux";
import { ListItem } from "../List";
import { setDefaultLanguage } from "../../actions/Language";
import { getStorage } from "../../utils/storage";
import userPhotoPlaceholder from "../../assets/userPhotoPlaceholder.png";
import { authMenus, nonAuthMenus } from "../../constants/profileMenus";
import icons from "../../constants/icons";
import { NotificationBadgeCount } from "../Notifications";
import { SearchDonor } from "../Donors";
import { Modal } from "../Modal";
class Navbar extends React.Component {
  constructor() {
    super();

    this.mobileNavbarRef = React.createRef();

    this.state = {
      showMoreMenus: false,
      user: {},
    };
  }
  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });

    document.addEventListener("mousedown", this.handleClickOutside.bind(this));

    window.addEventListener("scroll", this.onScroll.bind(this), {
      capture: true,
    });
  };

  onScroll() {
    var landingContainer = document.getElementById("landingContainer");

    const scrollY = landingContainer?.scrollTop;

    const navbar_container = document.getElementById("navbar-container");

    if (navbar_container.classList && scrollY) {
      let nav_class_list = navbar_container.classList;

      if (scrollY > 100) {
        if (navbar_container) {
          nav_class_list.add("navbar-sticky-top");
          nav_class_list.add("text-color-dark");
        }
      } else {
        nav_class_list.remove("navbar-sticky-top");
        nav_class_list.remove("text-color-dark");
      }
    }
  }

  handleShowMoreMenus = (e) => {
    e.preventDefault();

    const { type } = this.props;

    if (type === "dashboard") {
      return this.handleToggleSidebar();
    }
    this.setState({ showMoreMenus: !this.state.showMoreMenus });
  };

  handleToggleSidebar() {
    const container = document.getElementById("sidebar-container");

    if (container.classList) {
      let nav_class_list = container.classList;

      if (container && nav_class_list.contains("hide")) {
        nav_class_list.add("show");
        nav_class_list.remove("hide");
      } else if (container && nav_class_list.contains("show")) {
        nav_class_list.add("hide");
        nav_class_list.remove("show");
      }
    }
  }

  handleClickOutside(event) {
    if (
      this.mobileNavbarRef.current &&
      !this.mobileNavbarRef.current.contains(event.target) &&
      this.state.showMoreMenus
    ) {
      this.setState({ showMoreMenus: false });
    }
  }

  handleShowModal(modal) {
    this.setState({
      [modal]: true,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  render() {
    return (
      <>
        <nav
          className={`landing-navbar-container ${
            this.props.isFixed ? "nav-fixed" : ""
          }

           ${this.props.isMenuLight ? "nav-menu-light" : ""}
           ${this.props.isShadowActive ? "navbar-shadow" : ""}
           ${this.props.isLight ? "nav-light" : ""}
           ${this.props.isTransparent ? "nav-transparent" : ""}`}
          id="navbar-container"
        >
          <div
            className={`${
              this.props.className || "container"
            } menu-landing-container `}
          >
            <div className="left-container">
              <Logo
                className="light"
                handleMenuIconClicked={this.handleShowMoreMenus.bind(this)}
                type={this.props.type}
              />
            </div>
            <div className="right-container">
              {this.state.user.token && this.state.account_type !== "donor" && (
                <>
                  {/* <DropdownButton
                    text={language[this.props.defaultLanguage].find_application}
                    className="btn-sm btn-transparent bordered"
                    leftIcon={icons.search}
                  >
                    <ListItem
                      name={
                        language[this.props.defaultLanguage]
                          .start_new_application
                      }
                      onPress={() =>
                        (window.location.href =
                          "/blood-donation-assessment/start-application")
                      }
                      icon={icons.add}
                    />
                    <ListItem
                      name={language[this.props.defaultLanguage].existing_donor}
                      onPress={() =>
                        this.handleShowModal("showSearchDonorModal")
                      }
                      icon={icons.user}
                    />
                  </DropdownButton> */}
                  <div
                    className="navbar-notification-container"
                    onClick={() =>
                      (window.location.href =
                        this.state.user.role === "user"
                          ? "/public-servant/notifications"
                          : "/dashboard/notifications")
                    }
                  >
                    <Button
                      className="btn-transparent light"
                      icon={icons.notification}
                    />
                    <NotificationBadgeCount
                      // count={2}
                      borderClassName="primary-border"
                      styles={{
                        position: "absolute",
                      }}
                    />
                  </div>
                </>
              )}
              <div className="hidden-xs hidden-sm d-flex">
                {nonAuthMenus(this.props.defaultLanguage)?.map((menu, i) => {
                  if (
                    this.state.user.token &&
                    menu.access.includes("protected")
                  ) {
                    return (
                      <Button
                        type="hyperlink"
                        to={menu.route}
                        key={i}
                        text={menu.name}
                        styles={{ minWidth: 100, minHeight: 35 }}
                        className={`btn-transparent ${
                          this.props.isMenuLight ? "light" : ""
                        }`}
                      />
                    );
                  } else if (
                    !this.state.user.token &&
                    menu.access.includes("public")
                  ) {
                    return (
                      <Button
                        type="hyperlink"
                        to={menu.route}
                        key={i}
                        text={menu.name}
                        styles={{ minWidth: 100, minHeight: 35 }}
                        className={`btn-transparent ${
                          this.props.isMenuLight ? "light" : ""
                        }`}
                      />
                    );
                  }
                  return <></>;
                })}

                <DropdownButton
                  text={this.props.defaultLanguage}
                  className={`btn-transparent black ${
                    this.props.isMenuLight ? "light" : ""
                  }`}
                  leftImage={language[this.props.defaultLanguage].image}
                >
                  {Object.keys(language).map((key, i) => {
                    return (
                      <ListItem
                        key={i}
                        name={key}
                        onPress={() =>
                          this.props.dispatch(setDefaultLanguage(key))
                        }
                        image={language[key].image}
                      />
                    );
                  })}
                </DropdownButton>
              </div>
              {this.state.user.token ? (
                <>
                  <DropdownButton
                    text={this.state.user.displayName}
                    className="btn-transparent black menu-container-no-shadow"
                    leftImage={userPhotoPlaceholder}
                  >
                    {authMenus(this.props.defaultLanguage).map((el, i) => {
                      return (
                        <ListItem
                          key={i}
                          name={el.name}
                          onPress={el.onPress}
                          icon={el.icon}
                          iconClassName="sm"
                        />
                      );
                    })}
                  </DropdownButton>
                </>
              ) : (
                <Button
                  text={language[this.props.defaultLanguage].login_text}
                  styles={{ minWidth: 100, minHeight: 35 }}
                  onPress={() => (window.location.href = "/login")}
                />
              )}
            </div>
          </div>
          <MobileNavbar
            ref={this.mobileNavbarRef}
            className={this.state.showMoreMenus ? "show" : "hide"}
            {...this.props}
            user={this.state.user}
          />
        </nav>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showSearchDonorModal")}
          show={this.state.showSearchDonorModal}
          title={language[this.props.defaultLanguage].search_donor}
        >
          <SearchDonor
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showSearchDonorModal"
            )}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Navbar);
