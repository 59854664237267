import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import icons from "../../constants/icons";
import { getStorage } from "../../utils/storage";
import availableOptions from "../../constants/availableOptions";
import {
  getCells,
  getDistricts,
  getProvinces,
  getSectors,
  getVillages,
} from "../../utils/rwanda";
import validateEmail from "../../utils/validateEmail";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;
class NewDistributor extends React.Component {
  state = {
    name: "",
    error: {},
    isSubmitting: false,
    contactNumber: "",
    email: "",
    isActive: availableOptions[0],
    latitude: "",
    longitude: "",
  };

  componentDidMount = async () => {
    this.setState({ ...this.props });

    if (this.props._id && this.props._id !== "") {
      this.setState({
        province: {
          label: this.props?.address?.province,
          value: this.props?.address?.province,
        },
        district: {
          label: this.props?.address?.district,
          value: this.props?.address?.district,
        },
        sector: {
          label: this.props?.address?.sector,
          value: this.props?.address?.sector,
        },
        cell: {
          label: this.props?.address?.cell,
          value: this.props?.address?.cell,
        },
        village: {
          label: this.props?.address?.village,
          value: this.props?.address?.village,
        },
        isActive: {
          label: this.props?.available + "",
          value: this.props?.available + "",
        },
        latitude: this.props?.address?.lat,
        longitude: this.props?.address?.long,
      });
    }
  };

  onChangeText(field, e) {
    let { error } = this.state;
    delete error[field];

    this.setState({
      [field]: e.target ? e.target.value : e,
      error,
    });
  }

  validateForm() {
    let {
      name,
      error,
      province,
      sector,
      cell,
      district,
      village,
      email,
      contactNumber,
      latitude,
      longitude,
    } = this.state;

    if (name === "") {
      error.name =
        language[this.props.defaultLanguage].distributor_name_required;
    }

    if (contactNumber === "") {
      error.contactNumber =
        language[this.props.defaultLanguage].contact_number_required;
    }

    if (email === "") {
      error.email = language[this.props.defaultLanguage].email_required;
    } else if (!validateEmail(email)) {
      error.email = language[this.props.defaultLanguage].invalid_email;
    }

    if (latitude === "") {
      error.latitude = language[this.props.defaultLanguage].latitude_required;
    }

    if (longitude === "") {
      error.longitude = language[this.props.defaultLanguage].longitude_required;
    }

    if (!province) {
      error.province = language[this.props.defaultLanguage].province_required;
    }

    if (!district) {
      error.district = language[this.props.defaultLanguage].district_required;
    }

    if (!sector) {
      error.sector = language[this.props.defaultLanguage].sector_required;
    }

    if (!village) {
      error.village = language[this.props.defaultLanguage].village_required;
    }

    if (!cell) {
      error.cell = language[this.props.defaultLanguage].cell_required;
    }

    if (!province) {
      error.province = language[this.props.defaultLanguage].province_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    const {
      error_add_distributor,
      error_update_distributor,
      add_distributor_success,
      update_distributor_success,
    } = language[this.props.defaultLanguage];

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let {
          name,
          _id,
          province,
          district,
          sector,
          village,
          cell,
          contactNumber,
          email,
          latitude,
          longitude,
        } = this.state,
        url = `${API_URL}/blooddistributor`,
        method = "POST",
        user = await getStorage();

      let requestBody = {
        name,
        email,
        contactNumber,
        address: {
          province: province.value,
          district: district.value,
          sector: sector.value,
          cell: cell.value,
          village: village.value,
          lat: latitude,
          long: longitude,
        },
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        data: requestBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            method === "PUT"
              ? update_distributor_success
              : add_distributor_success
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage(
            "error",
            method === "PUT" ? error_update_distributor : error_add_distributor
          );

          this.setState({
            isSubmitting: false,
          });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Input
                leftIcon={icons.name}
                placeholder={
                  language[this.props.defaultLanguage]
                    .distributor_name_placeholder
                }
                label={language[this.props.defaultLanguage].distributor_name}
                required
                value={this.state.name}
                onChange={(e) => this.onChangeText("name", e)}
                error={this.state.error.name}
              />
            </div>
            <div className="col-md-6">
              <Input
                leftIcon={icons.phone}
                placeholder={
                  language[this.props.defaultLanguage]
                    .contact_number_placeholder
                }
                label={language[this.props.defaultLanguage].contact_number}
                required
                value={this.state.contactNumber}
                onChange={(e) => this.onChangeText("contactNumber", e)}
                error={this.state.error.contactNumber}
              />
            </div>
            <div className="col-md-6">
              <Input
                leftIcon={icons.email}
                placeholder={
                  language[this.props.defaultLanguage].email_placeholder
                }
                label={language[this.props.defaultLanguage].email_address}
                required
                value={this.state.email}
                onChange={(e) => this.onChangeText("email", e)}
                error={this.state.error.email}
              />
            </div>
            <div className="col-md-12">
              <Select
                leftIcon={icons.status}
                options={availableOptions}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].is_active}
                required
                value={this.state.isActive}
                onChange={(e) => this.onChangeText("isActive", e)}
                error={this.state.error.isActive}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={getProvinces(this.state.province)}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].province}
                required
                value={this.state.province}
                onChange={(e) => this.onChangeText("province", e)}
                error={this.state.error.province}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={getDistricts(this.state.province?.value)}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].district}
                required
                value={this.state.district}
                onChange={(e) => this.onChangeText("district", e)}
                error={this.state.error.district}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={getSectors(
                  this.state.province?.value,
                  this.state.district?.value
                )}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].sector}
                required
                value={this.state.sector}
                onChange={(e) => this.onChangeText("sector", e)}
                error={this.state.error.sector}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={getCells(
                  this.state.province?.value,
                  this.state.district?.value,
                  this.state.sector?.value
                )}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].cell}
                required
                value={this.state.cell}
                onChange={(e) => this.onChangeText("cell", e)}
                error={this.state.error.cell}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={getVillages(
                  this.state.province?.value,
                  this.state.district?.value,
                  this.state.sector?.value,
                  this.state.cell?.value
                )}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].village}
                required
                value={this.state.village}
                onChange={(e) => this.onChangeText("village", e)}
                error={this.state.error.village}
              />
            </div>
            <div className="col-md-6">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].latitude_placeholder
                }
                label={language[this.props.defaultLanguage].latitude}
                required
                value={this.state.latitude}
                onChange={(e) => this.onChangeText("latitude", e)}
                error={this.state.error.latitude}
                type="number"
              />
            </div>
            <div className="col-md-6">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].longitude_placeholder
                }
                label={language[this.props.defaultLanguage].longitude}
                required
                value={this.state.longitude}
                onChange={(e) => this.onChangeText("longitude", e)}
                error={this.state.error.longitude}
                type="number"
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewDistributor);
