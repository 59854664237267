import React from "react";
import { Popup } from "react-leaflet";
import "./styles.css";
// import { Circular } from "../Progress";
// import moment from "moment";
// import { Link } from "react-router-dom";

const AppPopup = (props) => {
  return (
    <Popup>
      <div className="app-map-popup">
        {Object.keys(props.data || {}).map((key, i) => {
          if (typeof props.data[key] === "string") {
            return (
              <div key={i} className="item-container">
                <div>
                  <span style={{ textTransform: "capitalize" }}>{key}</span>
                </div>
                <div className="description">
                  <span className="text-bold">{props.data[key]}</span>
                </div>
              </div>
            );
          }
        })}
      </div>
    </Popup>
  );
};

export default AppPopup;
