import Navbar from "../components/Navbar/Navbar";
import "./layout.css";

const AuthFormLayout = (props) => {
  return (
    <>
      <Navbar isShadowActive />
      <div className="auth-form-layout">
        <div className="container">
          <div className="row">
            <div className="col-md-10">{props.children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthFormLayout;
