import React, { useEffect } from "react";

const BarcodeScannerInput = ({ onScan, targetInputRef }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (document.activeElement === targetInputRef) {
        // Check if the focused element is the target input field
        if (event.key === "Enter") {
          onScan(targetInputRef.value);
        } else {
          // Append the key to the input field value
          targetInputRef.value += event.key;
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onScan, targetInputRef]);

  return null; // This component doesn't need to render anything
};

export default BarcodeScannerInput;
