import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import BloodTesting from "../../../components/BloodTesting/BloodTesting";
import MismatchBloodGrouping from "../../../components/BloodTesting/mismatch/bloodGrouping";
import MismatchSerology from "../../../components/BloodTesting/mismatch/serology";
import { withRouter } from "../../../utils/withRouter";
import SearchDonation from "../../../components/BloodCollection/SearchDonation";
import { Tabs } from "../../../components/Tabs";
import { getStorage } from "../../../utils/storage";
import accessMenus from "../../../utils/accessMenu";
import BloodTestStatistics from "../../../components/BloodTesting/BloodTestStatistics";

class BloodTestingScreen extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });
  };
  render() {
    const {
      canReadBloodTest,
      canConfirmBloodTestBloodGrouping,
      canConfirmBloodTestSerology,
    } = accessMenus();
    let tabOptions = [
      {
        title: language[this.props.defaultLanguage].search_by_donation,
        data: (
          <div className="card">
            <div className="col-md-6">
              <div className="card-bold">
                <SearchDonation
                  filters={this.props.filters}
                  showResultModal
                  navigationUrl="/dashboard/donations"
                />
              </div>
            </div>
          </div>
        ),
      },
    ];

    if (canReadBloodTest) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].blood_test_blood_grouping,
        data: <BloodTestStatistics type="bloodGroupingTested" />,
      });
    }

    if (canReadBloodTest) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].blood_test_serology,
        data: <BloodTestStatistics type="serologyTested" />,
      });
    }

    if (canReadBloodTest) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].blood_test_results,
        data: <BloodTesting />,
      });
    }

    if (canConfirmBloodTestBloodGrouping) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].mismatch_blood_grouping,
        data: <MismatchBloodGrouping />,
      });
    }
    if (canConfirmBloodTestSerology) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].mismatch_serology,
        data: <MismatchSerology />,
      });
    }
    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <Tabs options={tabOptions} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(BloodTestingScreen));
