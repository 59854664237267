import {
  ROLE_APPOINTMENT,
  ROLE_ASSESSMENT,
  ROLE_BLOOD_TEST,
  ROLE_BLOOD_TEST_BLOOD_GROUPING,
  ROLE_BLOOD_TEST_SEROLOGY,
  ROLE_COLLECTIONS,
  ROLE_COMPONENT_SEPARATION,
  ROLE_COMPONENT_TRANSFORMATION,
  ROLE_CONTROL_PANEL,
  ROLE_DONORS,
  ROLE_EQUIPMENT,
  ROLE_INVENTORY,
  ROLE_LABELING,
  ROLE_VITAL_SIGNS,
  ROLE_REQUEST,
} from "../../constants/ROLES";
import { defineRole } from "../handleAccessRoles";

export default () => {
  const userInfo = JSON.parse(sessionStorage.getItem("RBBMS"));

  const user = userInfo ? userInfo : {};

  const canReadDonorDemography = defineRole({
    roles: user.accessRole,
    menu: ROLE_DONORS,
    operation: "demography",
  });

  const canReadPreAssessment = defineRole({
    roles: user.accessRole,
    menu: ROLE_ASSESSMENT,
    operation: "read",
  });

  //blood testing
  const canReadBloodTest = defineRole({
    roles: user.accessRole,
    menu: ROLE_BLOOD_TEST,
    operation: "read",
  });

  const canUpdateBloodTest = defineRole({
    roles: user.accessRole,
    menu: ROLE_BLOOD_TEST,
    operation: "update",
  });

  const canDeleteBloodTest = defineRole({
    roles: user.accessRole,
    menu: ROLE_BLOOD_TEST,
    operation: "delete",
  });

  //assessments
  const canReadAssessment = defineRole({
    roles: user.accessRole,
    menu: ROLE_ASSESSMENT,
    operation: "read",
    pass: user.role === "donor" || user.account_type === "donor",
  });

  const canUpdateAssessment = defineRole({
    roles: user.accessRole,
    menu: ROLE_ASSESSMENT,
    operation: "update",
    pass: user.role === "donor" || user.account_type === "donor",
  });

  const canDeleteAssessment = defineRole({
    roles: user.accessRole,
    menu: ROLE_ASSESSMENT,
    operation: "delete",
    pass: user.role === "donor" || user.account_type === "donor",
  });

  const canCreateAssessment = defineRole({
    roles: user.accessRole,
    menu: ROLE_ASSESSMENT,
    operation: "create",
    pass: user.role === "donor" || user.account_type === "donor",
  });

  // vital signs
  const canReadVital = defineRole({
    roles: user.accessRole,
    menu: ROLE_VITAL_SIGNS,
    operation: "read",
  });

  const canCreateVital = defineRole({
    roles: user.accessRole,
    menu: ROLE_VITAL_SIGNS,
    operation: "create",
  });

  const canUpdateVital = defineRole({
    roles: user.accessRole,
    menu: ROLE_VITAL_SIGNS,
    operation: "update",
  });

  const canDeleteVital = defineRole({
    roles: user.accessRole,
    menu: ROLE_VITAL_SIGNS,
    operation: "delete",
  });

  // appointments

  const canReadAppointment = defineRole({
    roles: user.accessRole,
    menu: ROLE_APPOINTMENT,
    operation: "read",
  });

  const canUpdateAppointment = defineRole({
    roles: user.accessRole,
    menu: ROLE_APPOINTMENT,
    operation: "update",
  });

  const canDeleteAppointment = defineRole({
    roles: user.accessRole,
    menu: ROLE_APPOINTMENT,
    operation: "delete",
  });

  const canCreateAppointment = defineRole({
    roles: user.accessRole,
    menu: ROLE_APPOINTMENT,
    operation: "create",
  });

  // collections

  const canUpdateCollection = defineRole({
    roles: user.accessRole,
    menu: ROLE_COLLECTIONS,
    operation: "update",
  });

  const canDeleteCollection = defineRole({
    roles: user.accessRole,
    menu: ROLE_COLLECTIONS,
    operation: "delete",
  });

  const canCreateCollection = defineRole({
    roles: user.accessRole,
    menu: ROLE_COLLECTIONS,
    operation: "create",
  });

  const canReadCollection = defineRole({
    roles: user.accessRole,
    menu: ROLE_COLLECTIONS,
    operation: "read",
  });

  // blood test
  const canCreateBloodTestBloodGrouping = defineRole({
    roles: user.accessRole,
    menu: ROLE_BLOOD_TEST_BLOOD_GROUPING,
    operation: "create",
  });

  const canUpdateBloodTestBloodGrouping = defineRole({
    roles: user.accessRole,
    menu: ROLE_BLOOD_TEST_BLOOD_GROUPING,
    operation: "update",
  });

  const canConfirmBloodTestBloodGrouping = defineRole({
    roles: user.accessRole,
    menu: ROLE_BLOOD_TEST_BLOOD_GROUPING,
    operation: "confirm",
  });

  //serology

  const canCreateBloodTestSerology = defineRole({
    roles: user.accessRole,
    menu: ROLE_BLOOD_TEST_SEROLOGY,
    operation: "create",
  });

  const canUpdateBloodTestSerology = defineRole({
    roles: user.accessRole,
    menu: ROLE_BLOOD_TEST_SEROLOGY,
    operation: "update",
  });

  const canConfirmBloodTestSerology = defineRole({
    roles: user.accessRole,
    menu: ROLE_BLOOD_TEST_SEROLOGY,
    operation: "confirm",
  });

  //production
  const canReadProductionInventory = defineRole({
    roles: user.accessRole,
    menu: ROLE_INVENTORY,
    operation: "read",
  });

  const canReadComponentSeparation = defineRole({
    roles: user.accessRole,
    menu: ROLE_COMPONENT_SEPARATION,
    operation: "read",
  });

  const canReadComponentTransformation = defineRole({
    roles: user.accessRole,
    menu: ROLE_COMPONENT_TRANSFORMATION,
    operation: "read",
  });

  const canReadLabeling = defineRole({
    roles: user.accessRole,
    menu: ROLE_LABELING,
    operation: "read",
  });

  //Equipment
  const canReadEquipment = defineRole({
    roles: user.accessRole,
    menu: ROLE_EQUIPMENT,
    operation: "read",
  });

  const canCreateEquipment = defineRole({
    roles: user.accessRole,
    menu: ROLE_EQUIPMENT,
    operation: "create",
  });

  const canDeleteEquipment = defineRole({
    roles: user.accessRole,
    menu: ROLE_EQUIPMENT,
    operation: "delete",
  });

  const canUpdateEquipment = defineRole({
    roles: user.accessRole,
    menu: ROLE_EQUIPMENT,
    operation: "update",
  });

  //Control Panel
  const canPanelCreateEquipment = defineRole({
    roles: user.accessRole,
    menu: ROLE_CONTROL_PANEL,
    operation: "createEquipment",
  });

  const canPanelCreateCollectionType = defineRole({
    roles: user.accessRole,
    menu: ROLE_CONTROL_PANEL,
    operation: "createCollectionType",
  });

  const canPanelCreateVitalSign = defineRole({
    roles: user.accessRole,
    menu: ROLE_CONTROL_PANEL,
    operation: "createVitalSign",
  });

  const canPanelCreateAccessRoles = defineRole({
    roles: user.accessRole,
    menu: ROLE_CONTROL_PANEL,
    operation: "createAccessRoles",
  });

  const canPanelCreateBloodTestQuestionnaire = defineRole({
    roles: user.accessRole,
    menu: ROLE_CONTROL_PANEL,
    operation: "createBloodTestingQuestionnaire",
  });

  const canPanelCreateComponentProfile = defineRole({
    roles: user.accessRole,
    menu: ROLE_CONTROL_PANEL,
    operation: "createComponentProfile",
  });

  const canPanelCreateMeasures = defineRole({
    roles: user.accessRole,
    menu: ROLE_CONTROL_PANEL,
    operation: "createMeasures",
  });

  const canPanelGeneralAccess = defineRole({
    roles: user.accessRole,
    menu: ROLE_CONTROL_PANEL,
    operation: "general",
  });

  //REQUEST

  const canReadBloodRequestAccess = defineRole({
    roles: user.accessRole,
    menu: ROLE_REQUEST,
    operation: "read",
  });

  const canDeleteBloodRequestAccess = defineRole({
    roles: user.accessRole,
    menu: ROLE_REQUEST,
    operation: "delete",
  });

  const canUpdateBloodRequestAccess = defineRole({
    roles: user.accessRole,
    menu: ROLE_REQUEST,
    operation: "update",
  });

  const canBloodBankReceiveBloodRequestAccess = defineRole({
    roles: user.accessRole,
    menu: ROLE_REQUEST,
    operation: "bloodBankPreparation",
  });

  const canDistributorReceiveBloodRequestAccess = defineRole({
    roles: user.accessRole,
    menu: ROLE_REQUEST,
    operation: "distributorReceive",
  });

  const canHospitalReceiveBloodRequestAccess = defineRole({
    roles: user.accessRole,
    menu: ROLE_REQUEST,
    operation: "hospitalReception",
  });

  const canCreateBloodRequestAccess = defineRole({
    roles: user.accessRole,
    menu: ROLE_REQUEST,
    operation: "create",
  });

  return {
    canReadAppointment,
    canReadPreAssessment,
    canReadCollection,
    canReadVital,
    canReadDonorDemography,
    canReadBloodTest,
    canDeleteAssessment,
    canReadAssessment,
    canUpdateAssessment,
    canCreateAssessment,
    canCreateCollection,
    canDeleteCollection,
    canUpdateCollection,
    canCreateAppointment,
    canDeleteAppointment,
    canUpdateAppointment,
    canDeleteVital,
    canUpdateVital,
    canCreateVital,
    canCreateBloodTestBloodGrouping,
    canConfirmBloodTestBloodGrouping,
    canUpdateBloodTestBloodGrouping,
    canReadComponentTransformation,
    canReadComponentSeparation,
    canReadProductionInventory,
    canReadLabeling,
    canReadEquipment,
    canUpdateEquipment,
    canDeleteEquipment,
    canCreateEquipment,
    canPanelCreateComponentProfile,
    canPanelCreateBloodTestQuestionnaire,
    canPanelCreateAccessRoles,
    canPanelCreateVitalSign,
    canPanelCreateCollectionType,
    canPanelCreateEquipment,
    canPanelCreateMeasures,
    canPanelGeneralAccess,
    canConfirmBloodTestSerology,
    canCreateBloodTestSerology,
    canUpdateBloodTestSerology,
    canReadBloodRequestAccess,
    canDeleteBloodRequestAccess,
    canUpdateBloodRequestAccess,
    canCreateBloodRequestAccess,
    canDistributorReceiveBloodRequestAccess,
    canHospitalReceiveBloodRequestAccess,
    canBloodBankReceiveBloodRequestAccess,
    canUpdateBloodTest,
    canDeleteBloodTest,
  };
};
