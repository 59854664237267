import "./styles.css";

const SlideItem = (props) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="slide-item-container">
        <div className="slide-item-image">
          <img
            src={props.image}
            styles={{
              width: props.imageWidth || 160,
              height: props.imageHeight || 160,
            }}
          />
        </div>
        <div className="slide-item-content">
          {props.title && <h3>{props.title}</h3>}
          {props.description && <p>{props.description}</p>}
        </div>
      </div>
    </div>
  );
};

export default SlideItem;
