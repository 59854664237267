import React from "react";
import { Button } from "../Button";
import { Input, Select } from "../Input";
import language from "../../language";
import { connect } from "react-redux";

const ConfirmationModal = (props) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="confirmation-modal-container">
          {props.status && (
            <div className="confirmation-modal-header">
              <div
                className={`confirmation-modal-icon-container ${
                  props?.status?.value || props.status
                }`}
              >
                <span className="material-symbols-outlined">{props.icon}</span>
              </div>
            </div>
          )}
          {props.title && (
            <center className="confirmation-modal-content">
              <h2>{props.title}</h2>
              <p>{props.description}</p>
            </center>
          )}
          <div>
            {props.statuses && (
              <Select
                options={props.statuses || []}
                label={language[props.defaultLanguage].status}
                select={language[props.defaultLanguage].select}
                value={props[props.status]}
                onChange={props.onChange}
                error={props.error.reason}
              />
            )}
            <Input
              label={language[props.defaultLanguage].reason}
              value={props.reason}
              onChange={props.onChange}
              textarea
              error={props.error.reason}
            />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        {props.success ? (
          <>
            <Button
              text="Close"
              className="btn-default border"
              onPress={props.handleCloseModal}
            />
          </>
        ) : (
          <>
            <Button
              text={props.negativeButtonText}
              className="btn-default border"
              onPress={props.onPressNegative}
            />

            <Button
              text={props.positiveButtonText}
              className="btn-primary"
              onPress={props.onPressPositive}
              isSubmitting={props.isPositiveSubmitting}
            />
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};
export default connect(mapStateToProps)(ConfirmationModal);
