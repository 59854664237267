// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-shadow {
  border: 0px solid var(--text-color-10);
  box-shadow: var(--card-shadow);
}

.card {
  border: 0;
  box-shadow: var(--card-shadow);
  border-radius: var(--border-radius);
}

.card-header {
  background-color: var(--light-color);
  min-height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}

.card-footer {
  background-color: var(--light-color);
}

.card-dashboard-content {
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.card-shadow .card-header {
  padding: 1rem;
  background-color: var(--primary-color-5);
  border-bottom: 1px solid var(--text-color-10);
}
`, "",{"version":3,"sources":["webpack://./src/app/common/styles/card.styles.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,8BAA8B;AAChC;;AAEA;EACE,SAAS;EACT,8BAA8B;EAC9B,mCAAmC;AACrC;;AAEA;EACE,oCAAoC;EACpC,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,wCAAwC;EACxC,6CAA6C;AAC/C","sourcesContent":[".card-shadow {\r\n  border: 0px solid var(--text-color-10);\r\n  box-shadow: var(--card-shadow);\r\n}\r\n\r\n.card {\r\n  border: 0;\r\n  box-shadow: var(--card-shadow);\r\n  border-radius: var(--border-radius);\r\n}\r\n\r\n.card-header {\r\n  background-color: var(--light-color);\r\n  min-height: 45px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  gap: 0.5rem;\r\n}\r\n\r\n.card-footer {\r\n  background-color: var(--light-color);\r\n}\r\n\r\n.card-dashboard-content {\r\n  height: 100%;\r\n  overflow-y: auto;\r\n  scroll-behavior: smooth;\r\n}\r\n\r\n.card-shadow .card-header {\r\n  padding: 1rem;\r\n  background-color: var(--primary-color-5);\r\n  border-bottom: 1px solid var(--text-color-10);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
