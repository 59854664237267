// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* MEDIA QUERIES - RESPONSIVE CONTROL */
@media screen and (max-width: 500px) {
  .hidden-xs {
    display: none !important;
  }
  .hidden-sm {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1;
  }

  .padding-top-md-22 {
    padding-top: 1rem;
  }

  label {
    font-size: var(--font-size-sm);
  }

  .container {
    padding: 0 2rem;
  }
}

@media screen and (max-width: 768px) {
  .hidden-sm {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1;
  }
  .padding-top-md-22 {
    padding-top: 1rem;
  }

  label {
    font-size: var(--font-size-sm);
  }

  .container {
    padding: 0 2rem;
  }
}

@media screen and (max-width: 980px) {
  .hidden-sm {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1;
  }

  .mb-sm-1 {
    margin-bottom: 1rem;
  }

  .w-sm-100 {
    width: 100%;
  }

  .padding-top-md-22 {
    padding-top: 1rem;
  }

  label {
    font-size: var(--font-size-sm);
  }

  .container {
    padding: 0 2rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/common/styles/media.queries/general.media.queries.css"],"names":[],"mappings":"AAAA,uCAAuC;AACvC;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,wBAAwB;EAC1B;;EAEA;IACE,SAAO;EACT;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,8BAA8B;EAChC;;EAEA;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,wBAAwB;EAC1B;;EAEA;IACE,SAAO;EACT;EACA;IACE,iBAAiB;EACnB;;EAEA;IACE,8BAA8B;EAChC;;EAEA;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,wBAAwB;EAC1B;;EAEA;IACE,SAAO;EACT;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,8BAA8B;EAChC;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":["/* MEDIA QUERIES - RESPONSIVE CONTROL */\n@media screen and (max-width: 500px) {\n  .hidden-xs {\n    display: none !important;\n  }\n  .hidden-sm {\n    display: none !important;\n  }\n\n  .flex-sm-fill {\n    flex: 1;\n  }\n\n  .padding-top-md-22 {\n    padding-top: 1rem;\n  }\n\n  label {\n    font-size: var(--font-size-sm);\n  }\n\n  .container {\n    padding: 0 2rem;\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .hidden-sm {\n    display: none !important;\n  }\n\n  .flex-sm-fill {\n    flex: 1;\n  }\n  .padding-top-md-22 {\n    padding-top: 1rem;\n  }\n\n  label {\n    font-size: var(--font-size-sm);\n  }\n\n  .container {\n    padding: 0 2rem;\n  }\n}\n\n@media screen and (max-width: 980px) {\n  .hidden-sm {\n    display: none !important;\n  }\n\n  .flex-sm-fill {\n    flex: 1;\n  }\n\n  .mb-sm-1 {\n    margin-bottom: 1rem;\n  }\n\n  .w-sm-100 {\n    width: 100%;\n  }\n\n  .padding-top-md-22 {\n    padding-top: 1rem;\n  }\n\n  label {\n    font-size: var(--font-size-sm);\n  }\n\n  .container {\n    padding: 0 2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
