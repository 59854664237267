import React from "react";
import { connect } from "react-redux";
import { PrintComponent } from "../Print";
import Barcode from "react-barcode";

class BarcodeComponent extends React.Component {
  renderCode() {
    return (
      <Barcode
        value={JSON.stringify(this.props.donationInfo || this.props.info)}
      />
    );
  }
  render() {
    if (this.props.showBarCodeOnly) {
      return this.renderCode();
    }
    return (
      <center className="card-body">
        <PrintComponent>{this.renderCode()}</PrintComponent>
      </center>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;

  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(BarcodeComponent);
