import React from "react";
import { connect } from "react-redux";
import { Input } from "../Input";
import {
  DateFilter,
  DepartmentFilter,
  HospitalsFilter,
  BloodCollectionFilter,
  DonorsFilter,
  CommitteesFilter,
  CentersFilter,
  CenterSitesFilter,
  BloodTestsFilter,
  BloodUsageFilter,
  DistributorsFilter,
} from ".";
import icons from "../../constants/icons";
import language from "../../language";
import "./styles.css";
import { Button } from "../Button";
import { onResetFilters } from "../../actions/Filters";

class MainFilters extends React.Component {
  render() {
    return (
      <div className="filters-container ">
        {this.props?.filters?.includes("search") && (
          <Input
            leftIcon={icons.search}
            styles={{ margin: 0, flex: 1 }}
            placeholder={
              language[this.props.defaultLanguage].main_search_placeholder
            }
            className="sm"
          />
        )}
        {this.props?.filters?.includes("hospitals") && <HospitalsFilter />}
        {this.props?.filters?.includes("donors") && <DonorsFilter />}
        {this.props?.filters?.includes("committees") && <CommitteesFilter />}
        {this.props?.filters?.includes("centers") && <CentersFilter />}
        {this.props?.filters?.includes("bloodCollection") && (
          <BloodCollectionFilter />
        )}
        {this.props?.filters?.includes("bloodTests") && <BloodTestsFilter />}
        {this.props?.filters?.includes("bloodUsage") && <BloodUsageFilter />}
        {this.props?.filters?.includes("departments") && <DepartmentFilter />}
        {this.props?.filters?.includes("distributors") && (
          <DistributorsFilter />
        )}
        {this.props?.filters?.includes("distributors") && <CenterSitesFilter />}
        {this.props?.filters?.includes("date_range") && <DateFilter />}
        <Button
          text="Clear Filter"
          className="btn-default btn-sm"
          onPress={() => this.props.dispatch(onResetFilters())}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(MainFilters);
