import React from "react";
import { connect } from "react-redux";
import language from "../../language/index.js";
import { Table } from "../Table/index.js";
import { fetchBloodRecipient } from "../../utils/queries/bloodRecipient.js";
import { getStorage } from "../../utils/storage.js";
import toastMessage from "../../utils/toastMessage.js";

class RecipientInfo extends React.Component {
  state = {
    isLoading: false,
    data: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    await this.getData(true);
  };

  getData = async (isLoading) => {
    if (!this.props.id)
      return toastMessage(
        "error",
        language[this.props.defaultLanguage].missing_recipient_id
      );
    try {
      const { defaultLanguage, id } = this.props;

      this.setState({ isLoading });

      const data = await fetchBloodRecipient(defaultLanguage, {
        id,
      });

      this.setState({
        data,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].hospital,
        key: "hospital.name",
      },
      {
        title: language[this.props.defaultLanguage].first_name,
        key: "firstName",
      },
      {
        title: language[this.props.defaultLanguage].last_name,
        key: "lastName",
      },
      {
        title: language[this.props.defaultLanguage].contact,
        key: "contact",
      },
      {
        title: language[this.props.defaultLanguage].identity_number,
        key: "identityNumber",
      },
      {
        title: language[this.props.defaultLanguage].province,
        key: "province",
      },
      {
        title: language[this.props.defaultLanguage].district,
        key: "district",
      },
      {
        title: language[this.props.defaultLanguage].sector,
        key: "sector",
      },
      {
        title: language[this.props.defaultLanguage].cell,
        key: "cell",
      },
      {
        title: language[this.props.defaultLanguage].village,
        key: "village",
      },
      {
        title: language[this.props.defaultLanguage].created_at,
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",
      },
    ];

    return headers;
  }

  render() {
    console.log("this.state.data", this.state.data);
    return (
      <>
        <Table
          data={this.state.data}
          isLoading={this.state.isLoading}
          headers={this.returnTableHeaders()}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(RecipientInfo);
