import React from "react";
import { connect } from "react-redux";
import { Sites } from "../../../components/Sites";
import { Tabs } from "../../../components/Tabs";
import language from "../../../language";
import { Centers } from "../../../components/Center";
import Distributors from "../../../components/Distributors/Distributors";
import { Commitees } from "../../../components/Committee";
class CentersScreen extends React.Component {
  render() {
    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <Tabs
              options={[
                {
                  title: language[this.props.defaultLanguage].centers,
                  data: <Centers />,
                },
                {
                  title: language[this.props.defaultLanguage].sites,
                  data: <Sites />,
                },
                {
                  title: language[this.props.defaultLanguage].committee,
                  data: <Commitees />,
                },
                {
                  title: language[this.props.defaultLanguage].distributors,
                  data: <Distributors />,
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(CentersScreen);
