import React from "react";
import { connect } from "react-redux";
import bloodCenterQuery from "../../../utils/queries/bloodCenterQuery";
import bloodCenterSiteQuery from "../../../utils/queries/bloodCenterSiteQuery";
import { Input, Select } from "../../Input";
import { defineRoleCenterSite } from "../../../utils/handleAccessRoles";
import language from "../../../language";
import { Button } from "../../Button";
import icons from "../../../constants/icons";
import bloodGroups, { aboGroups } from "../../../constants/bloodGroups";
import bloodTestQuery from "../../../utils/queries/bloodTest/bloodTestQuery";
import exportPDF from "../../../utils/exportPDF";
import { CSVLink } from "react-csv";
import toastMessage from "../../../utils/toastMessage";
import generateABODonorWorksheet from "../../../utils/export/donorABOWorksheet";
import moment from "moment";
import generateVirologyScreeningWorksheet from "../../../utils/export/virologyScreeningWorksheet";

const worksheets = [
  {
    label: "ABO RH worksheet",
    value: "ABO",
  },
  {
    label: "Virology Screening worksheet",
    value: "Virology",
  },
];

class BloodTestExport extends React.Component {
  state = {
    error: {},
    limit: 50,
    csvData: [],
    page: 1,
    type: worksheets[0],
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().endOf().format("YYYY-MM-DD HH:mm"),
  };

  componentDidMount() {
    this.getCenters();
  }
  getCenters = async (search = {}) => {
    try {
      this.setState({
        isFetchingCenter: true,
      });

      const data = await bloodCenterQuery(this.props.defaultLanguage, {
        type: "dropdown",
        search,
        page: 1,
        limit: 50,
      });

      let center = null;
      const { centerRole } = await defineRoleCenterSite();
      if (!this.props._id && centerRole) {
        data.forEach((el) => {
          if (centerRole.includes(el._id)) {
            center = el;
          }
        });

        this.setState({ center }, () =>
          this.getCenterSites({ center: center?._id || undefined })
        );
      }

      this.setState({
        centers: data,
        isFetchingCenter: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingCenter: false });

      return [];
    }
  };

  getCenterSites = async (search = {}) => {
    try {
      this.setState({
        isFetchingCenterSite: true,
      });

      const data = await bloodCenterSiteQuery(this.props.defaultLanguage, {
        type: "dropdown",
        page: 1,
        limit: 50,
        ...search,
      });

      const { centerSiteRole } = await defineRoleCenterSite();
      let centerSite = null;

      if (!this.props._id && centerSiteRole) {
        data.forEach((el) => {
          if (centerSiteRole.includes(el._id)) {
            centerSite = el;
          }
        });

        this.setState({ centerSite });
      }

      this.setState({
        centerSites: data,
        isFetchingCenterSite: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingCenterSite: false });

      return [];
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e.target ? e.target.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });

    if (field === "center") {
      this.getCenterSites({ center: inputValue.value });
    }
  };

  donorTableHeaders() {
    let headers = [
      {
        title: "Donation No",
        key: "donationNumber",
      },
      {
        title: "Donor",
        key: "donor",
      },
      {
        title: "ABO RHDR",
        key: "bloodGroup",
      },
      {
        title: "Date",
        key: "date",
      },
      {
        title: "",
        key: "col3",
      },
      {
        title: "",
        key: "col4",
      },
    ];

    return headers;
  }

  virologyScreeningTableHeaders() {
    let headers = [
      {
        title: "Donation No",
        key: "donationNumber",
      },
      {
        title: "HIV",
        key: "hiv",
      },
      {
        title: "HBBAG",
        key: "hbbag",
      },
      {
        title: "HCV",
        key: "hcv",
      },
      {
        title: "Syphilis",
        key: "syphilis",
      },
    ];

    return headers;
  }

  handleReset() {
    // this.props.fetchData && this.props.fetchData({});
    // this.props.handleCloseModal && this.props.handleCloseModal({});
    this.setState({
      center: null,
      centerSite: null,
      limit: 50,
    });
  }

  onSubmit = async (type) => {
    try {
      this.setState({
        isSubmitting: true,
      });

      const {
        page,
        limit,
        center,
        centerSite,
        bloodGroup,
        type,
        startDate,
        endDate,
      } = this.state;
      const { defaultLanguage } = this.props;

      const { data } = await bloodTestQuery(defaultLanguage, {
        page,
        limit,
        center: center?.value || undefined,
        centerSite: centerSite?.value || undefined,
        bloodGroup: bloodGroup?.value || undefined,
        startDate,
        endDate,
      });

      let resData = [];
      console.log(data);

      data.forEach((el) => {
        resData.push({
          donationNumber: el.donation.donationNumber,
          donorNumber: el?.donor?.donorNumber,
          name: el?.donor?.firstName + " " + el?.donor?.lastName,
          bloodGroup: el.bloodGroup,
          date: moment(el.createdAt).format("YYYY-MM-DD"),

          // donationInfo: `${el.donation.donationNumber}/ W / ${moment(
          //   el.createdAt
          // ).format("YYYY-MM-DD")} ${
          //   el.donor.firstName + " " + el.donor.lastName
          // }`,

          col1: "",
          col2: "",
          col3: "",
          col4: "",
          col5: "",
          col6: "",
          col7: "",
          col8: "",
          col9: "",
        });
      });

      this.setState({
        isSubmitting: false,
      });

      const fileName = `${type.label}${center ? "-" + center.label : ""}${
        centerSite ? "-" + centerSite.label : ""
      }.pdf`;

      if (type.value === "ABO") {
        generateABODonorWorksheet({
          center: center?.label,
          centerSite: centerSite?.label,
          headers: this.donorTableHeaders(),
          data: resData,
          fileName,
        });
      } else {
        generateVirologyScreeningWorksheet({
          center: center?.label,
          centerSite: centerSite?.label,
          headers: this.virologyScreeningTableHeaders(),
          data: resData,
          fileName,
        });
      }

      // const fileName =
      //   (center?.value ? center?.label + "-" : "") +
      //   "" +
      //   (centerSite?.value ? centerSite?.label + "-" : "") +
      //   "Donor worksheet";

      // exportPDF(fileName, headers, data);
    } catch (error) {
      this.setState({ isSubmitting: false });
      toastMessage("error", error);
      console.log(error);
    }
  };

  render() {
    return (
      <>
        <div className="col-md-12">
          <Select
            options={worksheets}
            placeholder={language[this.props.defaultLanguage].select}
            label={language[this.props.defaultLanguage].worksheet_type}
            required
            value={this.state.type}
            onChange={(e) => this.onChangeText("type", e)}
            error={this.state.error.type}
          />
        </div>
        <div className="col-md-6">
          <Select
            loadOptions={(e) => this.getCenters({ name: e })}
            leftIcon={icons.center}
            placeholder={language[this.props.defaultLanguage].select}
            label={language[this.props.defaultLanguage].center}
            required
            value={this.state.center}
            onChange={(e) => this.onChangeText("center", e)}
            error={this.state.error.center}
            isLoading={this.state.isFetchingCenter}
          />
        </div>
        <div className="col-md-6">
          <Select
            loadOptions={(e) => this.getCenterSites({ search: e })}
            leftIcon={icons.site}
            placeholder={language[this.props.defaultLanguage].select}
            label={language[this.props.defaultLanguage].center_site}
            value={this.state.centerSite}
            onChange={(e) => this.onChangeText("centerSite", e)}
            error={this.state.error.centerSite}
            isLoading={this.state.isFetchingCenterSite}
          />
        </div>
        {/* <div className="col-md-12">
          <Select
            options={aboGroups}
            leftIcon={icons.bloodGroup}
            placeholder={language[this.props.defaultLanguage].select}
            label={language[this.props.defaultLanguage].blood_group}
            value={this.state.bloodGroup}
            onChange={(e) => this.onChangeText("bloodGroup", e)}
            error={this.state.error.bloodGroup}
          />
        </div> */}
        <div className="col-md-6">
          <Input
            leftIcon={icons.calendar}
            label={language[this.props.defaultLanguage].start_date}
            value={this.state.startDate}
            onChange={(e) => this.onChangeText("startDate", e)}
            error={this.state.error.startDate}
            type="date"
          />
        </div>
        <div className="col-md-6">
          <Input
            leftIcon={icons.calendar}
            label={language[this.props.defaultLanguage].end_date}
            value={this.state.endDate}
            onChange={(e) => this.onChangeText("endDate", e)}
            error={this.state.error.endDate}
            type="datetime-local"
          />
        </div>
        <div className="col-md-12">
          <Input
            placeholder={language[this.props.defaultLanguage].enter_limit}
            label={language[this.props.defaultLanguage].limit}
            value={this.state.limit}
            onChange={(e) => this.onChangeText("limit", e)}
            error={this.state.error.limit}
            type="number"
          />
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].reset}
            onPress={this.handleReset.bind(this)}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this, this.state.type?.value)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};
export default connect(mapStateToProps)(BloodTestExport);
