import React from "react";
import { Button } from "../Button";
import { Modal } from "../Modal";
import BookAppointment from "./BookAppointment";
import language from "../../language";
import { connect } from "react-redux";
import { getStorage } from "../../utils/storage";
class AlertBookAppointment extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };

  handleShowModal = (modal, modalTitle) => {
    this.setState({
      [modal]: true,
      modalTitle,
    });
  };

  handleCloseModal(modal) {
    this.setState({ [modal]: false });
  }

  render() {
    return (
      <>
        <div className="alert alert-info mb-2 mt-3">
          <div className="mb-3">
            <h3 className="mb-1">
              {language[this.props.defaultLanguage].book_appointment_title}
            </h3>
            <p>
              {
                language[this.props.defaultLanguage]
                  .book_appointment_description
              }
            </p>
          </div>
          <div>
            <Button
              text="Book Now"
              onPress={() =>
                this.handleShowModal(
                  "showBookAppointmentModal",
                  "Book Appointment"
                )
              }
            />
          </div>
        </div>
        <Modal
          handleClose={this.handleCloseModal.bind(
            this,
            "showBookAppointmentModal"
          )}
          show={this.state.showBookAppointmentModal}
          title={this.state.modalTitle}
        >
          <BookAppointment
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showBookAppointmentModal"
            )}
            navigationUrl="/user"
            donorId={this.state.user.id}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(AlertBookAppointment);
