// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-tabs-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
.login-tabs-item {
  min-height: 40px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  justify-content: center;
  border-radius: 32px;
  gap: 0.5rem;
  cursor: pointer;
  flex: 1 1;
}

.login-tabs-item {
  font-weight: 600;
  color: var(--text-color);
}

.login-tabs-item.active {
  border: 2px solid var(--primary-color);
  background-color: var(--primary-color-10);
}

.login-tabs-item.active,
.login-tabs-item.active span {
  color: var(--primary-color);
}
`, "",{"version":3,"sources":["webpack://./src/app/components/LoginTabs/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,eAAe;EACf,SAAO;AACT;;AAEA;EACE,gBAAgB;EAChB,wBAAwB;AAC1B;;AAEA;EACE,sCAAsC;EACtC,yCAAyC;AAC3C;;AAEA;;EAEE,2BAA2B;AAC7B","sourcesContent":[".login-tabs-container {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  margin-bottom: 2rem;\r\n}\r\n.login-tabs-item {\r\n  min-height: 40px;\r\n  display: flex;\r\n  align-items: center;\r\n  padding: 0 1rem;\r\n  justify-content: center;\r\n  border-radius: 32px;\r\n  gap: 0.5rem;\r\n  cursor: pointer;\r\n  flex: 1;\r\n}\r\n\r\n.login-tabs-item {\r\n  font-weight: 600;\r\n  color: var(--text-color);\r\n}\r\n\r\n.login-tabs-item.active {\r\n  border: 2px solid var(--primary-color);\r\n  background-color: var(--primary-color-10);\r\n}\r\n\r\n.login-tabs-item.active,\r\n.login-tabs-item.active span {\r\n  color: var(--primary-color);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
