import React from "react";
import { fetchPreAssessmentInformation } from "../../utils/queries/preAssessmentQuery";
import { getStorage } from "../../utils/storage";
import language from "../../language";
import { Table } from "../Table";
import { connect } from "react-redux";
import BookAppointment from "./BookAppointment";
import { DeleteModal, Modal } from "../Modal";
import {
  defineRole,
  defineRoleCenterSite,
} from "../../utils/handleAccessRoles";
import toastMessage from "../../utils/toastMessage";
import axios from "axios";
import icons from "../../constants/icons";
import AnswerResult from "../Donors/Pre-assessment/Answers/AnswerResult";
import moment from "moment";
import { ROLE_APPOINTMENT } from "../../constants/ROLES";
class Appointment extends React.Component {
  state = {
    data: [],
    user: {},
    error: {},
    selectedData: {},
    page: 1,
    limit: 10,
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.getData();

    this.setState({
      user,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.status !== this.props.status) {
      this.getData();
    }
  }

  onChangeText(field, e) {
    const { error } = this.state;

    delete error[field];
    this.setState({
      [field]: e.target ? e.target.value : e,
      error,
    });
  }

  getData = async (search = {}) => {
    try {
      const user = await getStorage();
      const { page, limit } = this.state;
      const { centerId, siteId, status } = this.props;

      this.setState({ isLoading: true });

      const { centerRole, centerSiteRole } = await defineRoleCenterSite();

      const data = await fetchPreAssessmentInformation(
        this.props.defaultLanguage,
        {
          donor: this.props.donorId
            ? this.props.donorId
            : this.props.route === "/user"
            ? user.id
            : undefined,
          center: centerId || centerRole,
          centerSite: siteId || centerSiteRole,
          status,
          page,
          limit,
          ...search,
        }
      );

      this.setState({
        data,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
    }
  };

  returnTableHeaders = () => {
    const user = this.state;
    let headers = [
      {
        title: language[this.props.defaultLanguage].appointment_number,
        key: "appointmentNumber",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].requested_date,
        key: "requestedDate",
        isMoment: true,
        formatTime: "lll",
        type: "link",
        onPress: (item) =>
          this.props.selection
            ? (window.location.href = `${
                this.props.route
              }/donors/questionnaire/${item.donor._id}/${
                item.donor.donorNumber
              }/${item.donor.firstName + " " + item.donor.lastName}/${
                item._id
              }/${item.requestedDate}`)
            : this.handleShowModal(
                "showAssessmentAnswer",
                moment(item.requestedDate).format("lll"),
                item
              ),
      },
      {
        title: language[this.props.defaultLanguage].donor_number,
        key: "donor.donorNumber",
        type: "link",
        styles: {
          textTransform: "uppercase",
        },
        onPress: (item) =>
          (window.location.href = `/${
            user.account_type === "donor" ? "user" : "dashboard"
          }/donors/${item.donor._id}/${item.donor.firstName} ${
            item.donor.lastName
          }`),

        copyEnabled: true,
      },
      {
        title: language[this.props.defaultLanguage].center,
        key: "center.name",
      },
      {
        title: language[this.props.defaultLanguage].center_site,
        key: "centerSite.name",
      },
      {
        title: language[this.props.defaultLanguage].appointment_category,
        key: "category",
      },
      {
        title: language[this.props.defaultLanguage].status,
        key: "status",
        type: "status",
      },
      {
        title: language[this.props.defaultLanguage].comment,
        key: "comment",
      },
      {
        title: "",
        key: "multiple-actions",
        actions: [
          {
            title: language[this.props.defaultLanguage].survey,
            onPress: (item) =>
              (window.location.href = `/${
                user.account_type === "donor" ? "user" : "dashboard"
              }/donors/questionnaire/${item.donor._id}/${
                item.donor.donorNumber
              }/${item.donor.firstName + " " + item.donor.lastName}/${
                item._id
              }/${item.requestedDate}`),
            className: "btn-default",
          },
        ],
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];

    return headers;
  };

  handleShowModal(modal, modalTitle, item = {}) {
    this.setState({
      [modal]: true,
      selectedData: item,
      modalTitle,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  onCancel = async () => {
    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let url = `${process.env.REACT_APP_BBMS_BASE_API}/preDonationAssessment`,
        method = "PUT",
        user = await getStorage(),
        { selectedData } = this.state,
        { status } = this.props;

      if (status === "cancelled") {
        method = "DELETE";
      }

      const options = {
        method,
        url,
        data: {
          status: "cancelled",
          id: selectedData._id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT"
                ? "cancel_appointment_success"
                : "delete_appointment_success"
            ]
          );

          this.handleCloseModal("showDeleteModal");
          this.getData(true);
        })
        .catch((error) => {
          this.setState({ isSubmitting: false });
          toastMessage(
            "error",
            language[this.props.defaultLanguage][
              method === "PUT"
                ? "error_cancel_appointment"
                : "error_delete_appointment"
            ]
          );
        });
    }
  };

  onDelete = async () => {
    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isDeleting: true,
      });

      let url = `${process.env.REACT_APP_BBMS_BASE_API}/preDonationAssessment`,
        user = await getStorage(),
        { selectedData } = this.state,
        method = "DELETE";

      const options = {
        method,
        url,
        data: {
          id: selectedData._id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({
            isDeleting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage]["delete_data_success"]
          );

          this.handleCloseModal("showDeleteModal");
          this.getData(true);
        })
        .catch((error) => {
          this.setState({ isDeleting: false });
          toastMessage(
            "error",
            language[this.props.defaultLanguage]["error_delete_data"]
          );
        });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData();
      }
    );
  }

  handleSearch = async (e) => {
    const search = e.target.value;
    this.setState({ search_text: search });
  };

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });

    await this.getData({
      appointmentNumber: search || search !== "" ? search : undefined,
    });

    this.setState({
      searching: false,
    });
  };

  render() {
    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          isLoading={this.state.isLoading}
          headers={this.returnTableHeaders()}
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={this.handleSearch.bind(this)}
          addSearchButton
          searching={this.state.searching}
          showAdd={defineRole({
            roles: this.state.user.accessRole,
            menu: ROLE_APPOINTMENT,
            operation: "create",
            pass:
              this.state.user.role === "donor" ||
              this.state.user.account_type === "donor",
          })}
          placeholder={
            language[this.props.defaultLanguage].search_appointment_number
          }
          handlePagination={this.handlePagination.bind(this)}
          handleAddPressed={() =>
            this.handleShowModal(
              "showBookModal",
              language[this.props.defaultLanguage].new_appointment
            )
          }
          handlePressSearch={() =>
            this.handleRemoteSearch(this.state.search_text)
          }
          filters={[
            // {
            //   type: "dropdown",
            //   filterName: "appointment_status",
            //   title: language[this.props.defaultLanguage].status,
            //   button_type: "dropdown",
            //   handleApplyFilters: () => this.getData({

            //   }),
            // },
            {
              type: "button",
              title: language[this.props.defaultLanguage].refresh,
              button_type: "button",
              icon: icons.refresh,
              onPress: (item) => this.getData(true),
            },
          ]}
          actions={[
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_APPOINTMENT,
                operation: "update",
                pass:
                  this.state.user.role === "donor" ||
                  this.state.user.account_type === "donor",
              }),
              name: language[this.props.defaultLanguage].edit,
              onPress: (item) =>
                this.handleShowModal(
                  "showBookModal",
                  language[this.props.defaultLanguage].edit_appointment,
                  item
                ),
              icon: icons.edit,
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_APPOINTMENT,
                operation: "update",
              }),
              name: language[this.props.defaultLanguage].approve,
              onPress: (item) =>
                this.handleShowModal(
                  "showBookModal",
                  language[this.props.defaultLanguage].approve,
                  {
                    ...item,
                    status: "approved",
                  }
                ),
              icon: icons.approve,
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_APPOINTMENT,
                operation: "update",
                pass:
                  this.state.user.role === "donor" ||
                  this.state.user.account_type === "donor",
              }),
              name: language[this.props.defaultLanguage].cancel,
              onPress: (item) =>
                this.handleShowModal(
                  "showBookModal",
                  language[this.props.defaultLanguage].cancel,
                  {
                    ...item,
                    status: "cancelled",
                  }
                ),
              icon: icons.close,
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_APPOINTMENT,
                operation: "delete",
                pass:
                  this.state.user.role === "donor" ||
                  this.state.user.account_type === "donor",
              }),
              name: language[this.props.defaultLanguage].delete,
              onPress: (item) => {
                if (item.status !== "pending")
                  return toastMessage(
                    "error",
                    language[this.props.defaultLanguage].can_not_be_deleted
                  );
                this.handleShowModal(
                  "showDeleteModal",
                  language[this.props.defaultLanguage].delete,
                  item
                );
              },
              icon: icons.delete,
            },
          ]}
        />

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showBookModal")}
          show={this.state.showBookModal}
          title={this.state.modalTitle}
          size="lg"
        >
          <BookAppointment
            handleCloseModal={this.handleCloseModal.bind(this, "showBookModal")}
            getData={this.getData.bind(this)}
            {...this.state.selectedData}
            donorId={this.props.donorId}
            route={this.props.route}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showAssessmentAnswer")}
          show={this.state.showAssessmentAnswer}
          title={this.state.modalTitle}
          size="lg"
        >
          <AnswerResult
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showAssessmentAnswer"
            )}
            getData={this.getData.bind(this)}
            selectedData={this.state.selectedData}
            donorId={this.props.donorId}
            route={this.props.route}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selectedData.requestedDate}
            getData={this.getData.bind(this)}
            title={language[this.props.defaultLanguage].confirm}
            icon={icons.schedule}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;

  return {
    defaultLanguage,
  };
};
export default connect(mapStateToProps)(Appointment);
