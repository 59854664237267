import React from "react";
import { connect } from "react-redux";
import { Tabs } from "../../../components/Tabs";
import language from "../../../language";
import { withRouter } from "../../../utils/withRouter";
import { Donors } from "../../../components/Donors";
import { Appointments } from "../../../components/BookAppointment";
import { Commitees } from "../../../components/Committee";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { DashboardHeader } from "../../../components/Header";

class CenterSiteScreen extends React.Component {
  render() {
    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <Breadcrumb
              homeUrl="/dashboard/home"
              data={[
                {
                  name: this.props.routeParams.centerName,
                  url: "/dashboard/centers",
                },
                {
                  name: this.props.routeParams.siteName,
                  active: true,
                },
              ]}
            />
            <DashboardHeader title={this.props.routeParams.siteName} />
            <Tabs
              options={[
                {
                  title: language[this.props.defaultLanguage].appointments,
                  data: (
                    <Appointments
                      centerId={this.props.routeParams.centerId}
                      siteId={this.props.routeParams.siteId}
                    />
                  ),
                },
                {
                  title: language[this.props.defaultLanguage].donors,
                  data: (
                    <Donors
                      centerId={this.props.routeParams.centerId}
                      siteId={this.props.routeParams.siteId}
                    />
                  ),
                },
                {
                  title: language[this.props.defaultLanguage].committee,
                  data: (
                    <Commitees
                      centerId={this.props.routeParams.centerId}
                      siteId={this.props.routeParams.siteId}
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(CenterSiteScreen));
