import language from "../language";

export default (lng) => {
  return [
    {
      label: language[lng].rwanda_national_id,
      value: "Rwanda National Id",
      nationality: "Rwandan",
      requiredInput: {
        field: "identityDocNumber",
        label: language[lng].rwanda_national_id,
        placeholder: language[lng].rwanda_national_id_placeholder,
        fieldType: "number",
        minLength: 16,
        maxLength: 16,
      },
      search: "NIDA",
    },
    {
      label: language[lng].resident_permit,
      value: "Resident Permit",
      nationality: "Foreign",
      requiredInput: {
        field: "identityDocNumber",
        label: language[lng].resident_permit,
        placeholder: language[lng].resident_permit_placeholder,
        fieldType: "number",
      },
      search: "NIDA",
    },
    {
      label: language[lng].refugee_id,
      value: language[lng].refugee_id,
      nationality: "Foreign",
      requiredInput: {
        field: "identityDocNumber",
        label: language[lng].refugee_id,
        placeholder: language[lng].refugee_id_placeholder,
        fieldType: "number",
      },
      search: "NIDA",
    },
    {
      label: language[lng].passport,
      value: language[lng].passport,
      nationality: "Passport",
      requiredInput: {
        field: "identityDocNumber",
        label: language[lng].passport,
        placeholder: language[lng].passport_placeholder,
        fieldType: "number",
      },
    },
    {
      label: language[lng].no_document,
      value: "noDocument",
      nationality: "Rwandan",
    },
  ];
};

export const nationalities = (lng = "english") => [
  {
    label: language[lng].rwandan,
    value: "Rwandan",
  },
  {
    label: language[lng].foreign,
    value: "Foreign",
  },
];
