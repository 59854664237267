import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import hospitalQuery from "../../utils/queries/hospitalQuery";
import bloodCenterQuery from "../../utils/queries/bloodCenterQuery";
import icons from "../../constants/icons";
import { getStorage } from "../../utils/storage";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class NewPatient extends React.Component {
  state = {
    requesterName: "",
    requesterPosition: "",
    error: {},
    isSubmitting: false,
    hospitals: [],
    centers: [],
    code: "",
    name: "",
  };

  componentDidMount = async () => {
    await this.getHospitals(true);
    // await this.getCenters(true);

    if (this.props._id) {
      this.setState({
        ...this.props,
        hospital: this.props.hospital
          ? {
              label: this.props.hospital.name,
              value: this.props.hospital._id,
            }
          : null,
        center: this.props.center
          ? {
              label: this.props.center.name,
              value: this.props.center._id,
            }
          : null,
      });
    }
  };

  getHospitals = async (isFetchingHospitals) => {
    try {
      this.setState({
        isFetchingHospitals,
      });

      const data = await hospitalQuery(this.props.defaultLanguage, {
        type: "dropdown",
      });

      this.setState({
        hospitals: data,
        isFetchingHospitals: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingHospitals: false });
    }
  };

  getCenters = async (isFetchingCenters) => {
    try {
      this.setState({
        isFetchingCenters,
      });

      const data = await bloodCenterQuery(this.props.defaultLanguage, {
        type: "dropdown",
      });

      this.setState({
        centers: data,
        isFetchingCenters: false,
      });
    } catch (error) {
      this.setState({ isFetchingCenters: false });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e.target ? e.target.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });
  };

  validateForm() {
    let { hospital, center, error, code, name } = this.state;

    if (code === "") {
      error.code = language[this.props.defaultLanguage].code_required;
    }

    if (!hospital || hospital === "") {
      error.hospital = language[this.props.defaultLanguage].hospital_required;
    }

    // if (!center) {
    //   error.center = language[this.props.defaultLanguage].center_required;
    // }

    if (name === "") {
      error.name = language[this.props.defaultLanguage].name_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let { hospital, center, code, name } = this.state,
        url = `${API_URL}/bloodBank`,
        { _id } = this.props,
        method = "POST",
        user = await getStorage();

      let requestedBody = {
        hospital,
        hospital: hospital.value,
        // center: center.value,
        code,
        name,
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestedBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT" ? "update_data_success" : "add_data_success"
            ]
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage("error", error);

          this.setState({ isSubmitting: false });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Input
                options={this.state.code}
                leftIcon={icons.code}
                placeholder={
                  language[this.props.defaultLanguage].code_placeholder
                }
                label={language[this.props.defaultLanguage].code}
                required
                value={this.state.code}
                onChange={(e) => this.onChangeText("code", e)}
                error={this.state.error.code}
              />
            </div>
            <div className="col-md-12">
              <Input
                leftIcon={icons.bloodBank}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].name}
                required
                value={this.state.name}
                onChange={(e) => this.onChangeText("name", e)}
                error={this.state.error.name}
              />
            </div>
            <div className="col-md-12">
              <Select
                options={this.state.hospitals}
                leftIcon={icons.hospital}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].hospital}
                required
                value={this.state.hospital}
                onChange={(e) => this.onChangeText("hospital", e)}
                error={this.state.error.hospital}
                isLoading={this.state.isFetchingHospitals}
              />
            </div>
            {/* <div className="col-md-12">
              <Select
                options={this.state.centers}
                leftIcon={icons.center}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].center}
                required
                value={this.state.center}
                onChange={(e) => this.onChangeText("center", e)}
                error={this.state.error.center}
                isLoading={this.state.isFetchingCenters}
              />
            </div> */}
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewPatient);
