import { connect } from "react-redux";
import { ParagraphOne, ParagraphTwo, TitleLoader } from "../Loader";
import "./styles.css";
import language from "../../language";
import { Button } from "../Button";

const Header = (props) => {
  return (
    <div className="dashboard-header-container">
      <div className="left-container">
        {props.isLoading ? (
          <TitleLoader />
        ) : (
          <>
            <h2 className="text-capitalized text-bold">
              {props.name || props.title}
            </h2>
            {props.description && <p>{props.description} </p>}
          </>
        )}
        <div className="info-container">
          {props.isLoading ? (
            <ParagraphOne />
          ) : (
            props.category && (
              <div className="d-flex">
                <div className="info-item bg">
                  <span>{props?.category?.name || props.category}</span>
                </div>
              </div>
            )
          )}

          {props.isLoading ? (
            <div>
              <ParagraphTwo />
            </div>
          ) : (
            props.maxResponseTime && (
              <div className="info-item">
                <span className="text-primary">
                  {language[props.defaultLanguage].avg_response_time}:{" "}
                  {props.maxResponseTime} Day(s)
                </span>
              </div>
            )
          )}
          {props.isEdit && (
            <div className="info-item">
              <Button
                icon="edit"
                text="Edit"
                className="btn-transparent primary btn-sm"
                onPress={props.onPressEdit}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Header);
