import toastMessage from "../../toastMessage";
import formatSelectData from "../../formatSelectData";
import { getStorage } from "../../storage";
import axios from "axios";
import language from "../../../language";
import { defineRole } from "../../handleAccessRoles";
import { ROLE_MESSAGE_TEMPLATE } from "../../../constants/ROLES";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

export const fetchMessageTemplateData = async (
  lng = "english",
  { page, limit, type, q }
) => {
  try {
    const user = await getStorage();

    const canAccess = defineRole({
      roles: user.accessRole,
      menu: ROLE_MESSAGE_TEMPLATE,
      operation: "read",
    });

    if (!canAccess) return;

    const options = {
      method: "GET",
      url: `${API_URL}/emailTemplate`,
      params: {
        page: page,
        limit: limit,
        q,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await axios(options);

    let { data, count } = queryRes.data;

    if (type === "dropdown") {
      return formatSelectData(queryRes.data, "name", "_id");
    }

    return !page && !limit ? queryRes.data : { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};

export const deleteMessageTemplate = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `${API_URL}/emailTemplate`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await axios(options);

    toastMessage("success", language[lng].success_delete_data);
  } catch (error) {
    toastMessage("error", error);
  }
};
