// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.who-can-give-item {
  display: flex;
  padding: 1.5rem 0;
}

.who-can-give-item .who-can-give-item-icon {
  height: 24px;
  width: 24px;
  border: 1px solid var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 1rem;
}

.who-can-give-item .who-can-give-item-icon span {
  color: var(--primary-color);
  font-size: var(--font-size-sm);
}

.who-can-give-item .who-can-give-item-description {
  flex: 1 1;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Landing/WhoDonate/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,sCAAsC;EACtC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;EAC3B,8BAA8B;AAChC;;AAEA;EACE,SAAO;AACT","sourcesContent":[".who-can-give-item {\n  display: flex;\n  padding: 1.5rem 0;\n}\n\n.who-can-give-item .who-can-give-item-icon {\n  height: 24px;\n  width: 24px;\n  border: 1px solid var(--primary-color);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50%;\n  margin-right: 1rem;\n}\n\n.who-can-give-item .who-can-give-item-icon span {\n  color: var(--primary-color);\n  font-size: var(--font-size-sm);\n}\n\n.who-can-give-item .who-can-give-item-description {\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
