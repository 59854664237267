export const getDonorBadgeInfo = (bloodGroup = "unspecified") => {
  let color;

  if (!bloodGroup) return;

  switch (bloodGroup.toUpperCase()) {
    case "A+":
      color = "yellow";
      break;
    case "A-":
      color = "yellow";
      break;
    case "AB+":
      color = "pink";
      break;
    case "AB-":
      color = "pink";
      break;
    case "B+":
      color = "white";
      break;
    case "B-":
      color = "white";
      break;
    case "O+":
      color = "green";
      break;
    case "O-":
      color = "green";
      break;
    default:
      break;
  }

  return { color, bloodGroup };
};
