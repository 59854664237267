// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.partners-container {
  display: flex;
  align-content: center;
  justify-content: center;
}

.partners-item img {
  aspect-ratio: 3/2;
  object-fit: contain;
  width: 30%;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Landing/Partners/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;EACrB,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,UAAU;AACZ","sourcesContent":[".partners-container {\n  display: flex;\n  align-content: center;\n  justify-content: center;\n}\n\n.partners-item img {\n  aspect-ratio: 3/2;\n  object-fit: contain;\n  width: 30%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
