import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import { getStorage } from "../../utils/storage";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class NewTemperature extends React.Component {
  state = {
    error: {},
    isSubmitting: false,
    code: "",
    max: "",
    min: "",
    decisions: [],
    code: "",
    name: "",
  };

  componentDidMount = async () => {
    const decisions = [
      {
        label: "ACTIVE",
        value: "received",
      },
      {
        label: "REJECTED",
        value: "rejected",
      },
      {
        label: "QUARANTINED (BLOCKED)",
        value: "quarantined",
      },
    ];

    this.setState({
      decisions,
      decision: decisions[0],
    });

    if (this.props._id) {
      this.setState({
        max: this.props.max,
        code: this.props.code,
        min: this.props.min,
        decision: this.props.decision
          ? {
              label: this.props.decision,
              value: this.props.decision,
            }
          : decisions[0],
      });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e.target ? e.target.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });
  };

  validateForm() {
    let { code, max, error, min, name } = this.state;

    if (name === "") {
      error.name = language[this.props.defaultLanguage].name_required;
    }

    if (code === "") {
      error.code = language[this.props.defaultLanguage].code_required;
    }

    // if (min === "") {
    //   error.min = language[this.props.defaultLanguage].min_required;
    // }

    // if (max === "") {
    //   error.max = language[this.props.defaultLanguage].max_required;
    // }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let { min, max, code, decision, name } = this.state,
        url = `${API_URL}/transportationTemperature`,
        method = "POST",
        { _id } = this.props,
        user = await getStorage();

      let requestedBody = {
        min,
        max,
        code,
        decision: decision?.value,
        name,
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestedBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT" ? "data_update_success" : "add_data_success"
            ]
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage("error", error);
          this.setState({
            isSubmitting: false,
          });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].code_placeholder
                }
                label={language[this.props.defaultLanguage].code}
                required
                value={this.state.code}
                onChange={(e) => this.onChangeText("code", e)}
                error={this.state.error.code}
              />
            </div>

            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].name_placeholder
                }
                label={language[this.props.defaultLanguage].name}
                required
                value={this.state.name}
                onChange={(e) => this.onChangeText("name", e)}
                error={this.state.error.name}
              />
            </div>

            <div className="col-md-6">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].min_placeholder
                }
                label={language[this.props.defaultLanguage].min}
                required
                value={this.state.min}
                onChange={(e) => this.onChangeText("min", e)}
                error={this.state.error.min}
                type="number"
                min={-100}
              />
            </div>

            <div className="col-md-6">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].max_placeholder
                }
                label={language[this.props.defaultLanguage].max}
                value={this.state.max}
                onChange={(e) => this.onChangeText("max", e)}
                error={this.state.error.max}
                type="number"
                min={-100}
              />
            </div>
            <div className="col-md-12">
              <Select
                options={this.state.decisions}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].decision}
                value={this.state.decision}
                onChange={(e) => this.onChangeText("decision", e)}
                error={this.state.error.decision}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewTemperature);
