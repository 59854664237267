// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert-info {
  background-color: rgb(253, 237, 237);
  border: 0;
}
`, "",{"version":3,"sources":["webpack://./src/app/common/styles/alerts.styles.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,SAAS;AACX","sourcesContent":[".alert-info {\r\n  background-color: rgb(253, 237, 237);\r\n  border: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
