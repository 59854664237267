import React from "react";

import { VerifyPhoneEmail } from "../../components/VerifyPhoneEmail";
import { withRouter } from "../../utils/withRouter";

class VerifyPhoneEmailScreen extends React.Component {
  render() {
    return (
      <>
        <VerifyPhoneEmail {...this.props} />
      </>
    );
  }
}

export default withRouter(VerifyPhoneEmailScreen);
