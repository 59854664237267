import React from "react";
import { Tabs } from "../../components/Tabs";
import { connect } from "react-redux";
import language from "../../language";
import { MyProfile, ChangePassword } from "../../components/Profile";
import DonorProfile from "../../components/Donors/DonorProfile";

class SettingScreen extends React.Component {
  render() {
    const { my_profile, change_password, registration_info } =
      language[this.props.defaultLanguage];
    return (
      <>
        <div className="card-dashboard-content">
          <div className="card-body">
            <Tabs
              options={[
                { title: my_profile, data: <MyProfile /> },
                { title: "Registration Info", data: <DonorProfile /> },
                { title: change_password, data: <ChangePassword /> },
              ]}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(SettingScreen);
