import React from "react";
import PreAssessment from "../../components/Donors/Pre-assessment/Answers/Index";
import { withRouter } from "../../utils/withRouter";
import { DashboardHeader } from "../../components/Header";
import { connect } from "react-redux";
import language from "../../language";
import { getStorage } from "../../utils/storage";

class DonorInformationScreen extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };
  render() {
    return (
      <div className="card-body">
        <DashboardHeader
          title={language[this.props.defaultLanguage].preassessments}
        />
        <PreAssessment
          donorId={this.props.routeParams.id || this.state.user.id}
          route={this.props.route}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(DonorInformationScreen));
