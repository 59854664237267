import React from "react";
import { withRouter } from "../../utils/withRouter";
import { ChangePassword } from "../../components/ChangePassword";

class ChangePasswordScreen extends React.Component {
  render() {
    const { routeParams } = this.props;
    return (
      <>
        <ChangePassword email={routeParams?.email} type={routeParams?.type} />
      </>
    );
  }
}

export default withRouter(ChangePasswordScreen);
