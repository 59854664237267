import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import bloodCenterQuery from "../../utils/queries/bloodCenterQuery";
import { getStorage } from "../../utils/storage";
import { defineRoleCenterSite } from "../../utils/handleAccessRoles";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class NewRequest extends React.Component {
  state = {
    error: {},
    isSubmitting: false,
    donors: [],
    centers: [],
  };

  componentDidMount = async () => {
    const { defaultLanguage } = this.props;

    const statusOptions = [
      {
        label: language[defaultLanguage].processing,
        value: "processing",
      },
    ];

    await this.getCenters(true);
    await this.setState({
      ...this.props,
      status: statusOptions[0],
      statusOptions,
    });

    if (this.props._id) {
      this.setState({
        center: this.props?.center?._id
          ? {
              label: this.props.center.name,
              value: this.props.center._id,
            }
          : null,
        status: statusOptions.find((el) => el.value === this.props.status),
      });
    }
  };

  getCenters = async (isFetchingCenters) => {
    try {
      this.setState({
        isFetchingCenters,
      });

      const { centerRole } = defineRoleCenterSite;

      const data = await bloodCenterQuery(this.props.defaultLanguage, {
        type: "dropdown",
        center: centerRole,
      });

      this.setState({
        centers: data,
        isFetchingCenters: false,
      });
    } catch (error) {
      this.setState({ isFetchingCenters: false });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e.target ? e.target.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });
  };

  validateForm() {
    let { error, center } = this.state;

    if (!center) {
      error.center = language[this.props.defaultLanguage].center_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    try {
      await this.validateForm();

      const { add_bloodrequest_success, update_bloodrequest_success } =
        language[this.props.defaultLanguage];

      if (Object.keys(this.state.error).length === 0) {
        this.setState({
          isSubmitting: true,
        });

        let { requestId, status, center } = this.state,
          url = `${API_URL}/bloodrequest`,
          method = "POST",
          user = await getStorage();

        let requestedBody = {
          status: status.value,
          center: center.value,
        };

        if (requestId && requestId !== "") {
          method = "PUT";
          requestedBody.id = requestId;

          const options = {
            method,
            url,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + user.token,
            },
            data: requestedBody,
          };

          await axios(options);

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT"
                ? update_bloodrequest_success
                : add_bloodrequest_success
            ]
          );

          this.setState({
            isSubmitting: false,
          });

          this.props.getData(true);
          this.props.handleCloseModal();
        } else {
          toastMessage(
            "error",
            language[this.props.defaultLanguage].blood_request_missing
          );
          return;
        }
      }
    } catch (error) {
      toastMessage("error", error);
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="alert alert-info">
            <p className="text-bold">
              {language[this.props.defaultLanguage].processing_request}{" "}
              {this.props.requestId}
            </p>
            <p>
              {
                language[this.props.defaultLanguage]
                  .processing_request_description
              }
            </p>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Select
                options={this.state.centers}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].center}
                required
                value={this.state.center}
                onChange={(e) => this.onChangeText("center", e)}
                error={this.state.error.center}
                isLoading={this.state.isFetchingCenters}
              />
            </div>
          </div>
          <div className="col-md-12">
            <Select
              options={this.state.statusOptions}
              placeholder={language[this.props.defaultLanguage].select}
              label={language[this.props.defaultLanguage].status}
              required
              value={this.state.status}
              onChange={(e) => this.onChangeText("status", e)}
              error={this.state.error.status}
            />
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewRequest);
