import Navbar from "../components/Navbar/Navbar.js";
import { Sidebar } from "../components/Sidebar/index.js";
import extractUrlToMenus from "../utils/extractUrlToMenus.js";
import { withRouter } from "../utils/withRouter.js";
import "./layout.css";

const CenterLayout = (props) => {
  let res = ["dashboard"];
  Object.values(props.routeParams).forEach((strEl) => {
    const exMenus = extractUrlToMenus(strEl);

    exMenus.forEach((sEl) => {
      console.log(sEl);

      if (!res.includes(sEl)) {
        res.push(sEl);
      }
    });
  });
  return (
    <>
      <Navbar className="container-fluid" type="dashboard" isLight />
      <div className="home-dashboard-layout-container ">
        {props.sidebarMenus && props.sidebarMenus.length > 0 && (
          <Sidebar {...props} menus={props.sidebarMenus} />
        )}
        <div className="dashboard-layout">{props.children}</div>
      </div>
    </>
  );
};

export default withRouter(CenterLayout);
