import LandingPageLayout from "./Landing/LandingPageLayout";
import HomeDashboardLayout from "./HomeDashboardLayout";
import HomeAnalyticsLayout from "./HomeAnalyticsLayout";
import CenterLayout from "./CenterLayout";

export {
  LandingPageLayout,
  HomeDashboardLayout,
  HomeAnalyticsLayout,
  CenterLayout,
};
