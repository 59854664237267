import React from "react";
import { connect } from "react-redux";
import "./styles.css";
import moment from "moment";
import { PrintComponent } from "../Print";
import { Loading } from "../Loader";
import language from "../../language";
import toastMessage from "../../utils/toastMessage";
import bloodTestQuery from "../../utils/queries/bloodTest/bloodTestQuery";
import { fetchComponentSeparationData } from "../../utils/queries/production/componentSeparationQuery";
import Barcode from "../Barcode/Barcode";

class BloodBagLabelling extends React.Component {
  state = {
    data: {},
    isLoading: false,
    bloodSeparation: {},
  };

  componentDidMount() {
    this.getBloodTest(true);

    if (this.props._id) {
      this.getBloodSeparation(true);
    }
  }

  getBloodTest = async (isLoading) => {
    try {
      const { donationId, defaultLanguage } = this.props;

      if (!donationId)
        return toastMessage(
          "error",
          language[defaultLanguage].missing_donation
        );

      this.setState({ isLoading });

      const data = await bloodTestQuery(defaultLanguage, {
        donation: donationId,
      });

      this.setState({
        data: Array.isArray(data) && data.length > 0 ? data[0] : data,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  getBloodSeparation = async (isLoading) => {
    try {
      const { _id, defaultLanguage } = this.props;

      if (!_id) return toastMessage("error", "Missing inventory");

      this.setState({ isLoading });

      const data = await fetchComponentSeparationData(defaultLanguage, {
        inventory: _id,
      });

      this.setState({
        bloodSeparation: data?.length > 0 ? data[0] : {},
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  renderBloodTestResults() {
    const { data } = this.state;

    const {
      serologyHealthQuestionnaire = [],
      bloodGroupingHealthQuestionnaire = [],
    } = data;

    let resultSerology = "",
      resultBloodGrouping = "";

    serologyHealthQuestionnaire.map((el) => {
      if (el.printingEnabled && el.answer && el.settings) {
        let abbrev = el.settings[el.answer.value]?.abbreviation;

        resultSerology += `${abbrev}(${el.answer.value}) `;
      }
    });

    bloodGroupingHealthQuestionnaire.map((el) => {
      if (el.printingEnabled && el.answer && el.settings) {
        let abbrev = el.settings[el.answer.value]?.abbreviation;

        resultBloodGrouping += `${abbrev}(${el.answer.value}) `;
      }
    });

    return `${resultBloodGrouping} ${resultSerology}`;
  }

  render() {
    if (this.state.isLoading) {
      return <Loading className="fullscreen" />;
    }

    return (
      <PrintComponent>
        <center className="lb-header">
          <h3>CNTS KIGALI-Rwanda</h3>
        </center>
        <div className="lb-separator" />
        <div className="lb-section">
          <div className="item">
            <div style={{ flex: 1 }}>
              <p>
                {this.state.bloodSeparation?.components?.map((el) => (
                  <span>{el.name} </span>
                ))}
              </p>
            </div>
            <div>
              <Barcode
                info={this.state.bloodSeparation?.code}
                showBarCodeOnly
              />
            </div>
          </div>
          <div className="item">
            <div>
              <p>Volume:</p>
            </div>
            <div>
              <p>
                <b>{this.props?.donation?.bloodInfo?.volume} ML</b>
              </p>
            </div>
          </div>

          <div className="item">
            <div>
              <p>Phebotomy Date:</p>
            </div>
            <div>
              <p>
                <b>{moment(this.state.data.createdAt).format("ll")}</b>
              </p>
            </div>
          </div>

          <div className="item">
            <div>
              <p>Expires on:</p>
            </div>
            <div>
              <p>
                <b>{moment(this.props.expirationDate).format("lll")}</b>
              </p>
            </div>
          </div>
        </div>
        <hr />
        <div className="lb-section">
          <div className="item">
            <div style={{ flex: 1 }}>
              <p>Donation Number:</p>
            </div>
            <div>
              <Barcode
                info={this.props?.donation?.donationNumber}
                showBarCodeOnly
              />
            </div>
          </div>
        </div>
        <div className="lb-section">
          <div className="item">
            <div>
              <h1>{this.props.bloodGroup}</h1>
            </div>
          </div>
        </div>
        <div className="lb-section">
          <div className="item">
            {this.state?.data?.code && (
              <div style={{ flex: 1 }}>
                <Barcode info={this.state?.data?.code} showBarCodeOnly />
              </div>
            )}

            <div style={{ maxWidth: 200 }}>
              <p>{this.renderBloodTestResults()}</p>
            </div>
          </div>
        </div>
      </PrintComponent>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(BloodBagLabelling);
