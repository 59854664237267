import language from "../../../../language";
import { connect } from "react-redux";
import moment from "moment";
import { ListItem } from "../../../List";
import { Button } from "../../../Button";

const AnswerResult = (props) => {
  return (
    <>
      <div className="card-header">
        <div className="card-header-item">
          <span>{language[props.defaultLanguage].scheduled_date}:</span>
          <span className="text-bold ">
            {moment(props.selectedData?.requestedDate).format("lll")}
          </span>
        </div>
        <div className="card-header-item">
          <span>{language[props.defaultLanguage].center}:</span>
          <span className="text-bold ">{props.selectedData?.center?.name}</span>
        </div>
        <div className="card-header-item">
          <span>{language[props.defaultLanguage].center_site}:</span>
          <span className="text-bold ">
            {props.selectedData?.centerSite?.name}
          </span>
        </div>
        <div className="card-header-item">
          <span>{language[props.defaultLanguage].status}:</span>
          <div className={`badge badge-${props.selectedData?.status}`}>
            <span className="text-capitalize">
              {props.selectedData?.status}
            </span>
          </div>
        </div>
      </div>

      <div className="card-body">
        <div className="row">
          {props.selectedData?.healthQuestionnaire?.length > 0 ? (
            props.selectedData?.healthQuestionnaire?.map((el, i) => {
              return (
                <div key={i} className="col-md-6">
                  <ListItem
                    name={el.questionText}
                    className="list-item-card mb-3"
                    rightDescription={el.answer}
                  />
                </div>
              );
            })
          ) : (
            <div>
              <Button
                text={
                  language[props.defaultLanguage].start_pre_assessment_donation
                }
                onPress={() =>
                  (window.location.href = `${
                    props.route
                  }/donors/questionnaire/${props.selectedData.donor._id}/${
                    props.selectedData.donor.donorNumber
                  }/${
                    props.selectedData.donor.firstName +
                    " " +
                    props.selectedData.donor.lastName
                  }/${props.selectedData._id}/${
                    props.selectedData.requestedDate
                  }`)
                }
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};
export default connect(mapStateToProps)(AnswerResult);
