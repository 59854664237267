import React from "react";
import "./styles.css";
import donorsQuery from "../../../utils/queries/donorsQuery";
import { getStorage } from "../../../utils/storage";
import toastMessage from "../../../utils/toastMessage";
import { Loading } from "../../Loader";
import language from "../../../language";
import { connect } from "react-redux";
import RBCLOGO from "../../../assets/rbc-logo.png";
import { getDonorBadgeInfo } from "../../../utils/bloodGroupInfo";
import { PrintComponent } from "../../Print";
class DonorBadge extends React.Component {
  state = {
    data: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState(
      {
        user,
      },
      () => {
        this.getData(true);
      }
    );
  };

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const data = await donorsQuery(defaultLanguage, {
        page,
        limit,
        id: this.props.donorId,
      });

      console.log(data);

      let res = {};

      if (data?.length > 0) {
        res = data[0];
      }

      const { color } = getDonorBadgeInfo(res.bloodGroup);

      console.log("color *****", color);

      this.setState({
        data: res,
        isLoading: false,
        color,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      toastMessage("error", error);
    }
  };

  render() {
    return (
      <>
        <div className={`donor-badge-container ${this.state.color}`}>
          {this.state.isLoading ? (
            <center>
              <Loading />
            </center>
          ) : (
            <PrintComponent
              buttonText={language[this.props.defaultLanguage].print_card}
            >
              <div className="donor-badge-card-container mb-3">
                <div
                  className={`donor-badge-card-details-container ${this.state.color}`}
                >
                  <div className="title-container">
                    <h2>
                      {language[this.props.defaultLanguage].blood_donor_card}
                    </h2>
                  </div>
                  <div>
                    <div className="donor-badge-card-details-item">
                      <p style={{ flex: 0.5, textTransform: "capitalize" }}>
                        {language[this.props.defaultLanguage].name}:
                      </p>
                      <p
                        className="text-bold"
                        style={{ textTransform: "uppercase" }}
                      >
                        {this.state.data.firstName +
                          " " +
                          this.state.data.lastName}
                      </p>
                    </div>
                    <div className="donor-badge-card-details-item">
                      <p style={{ flex: 0.5, textTransform: "capitalize" }}>
                        {language[this.props.defaultLanguage].donor_id}:
                      </p>
                      <p className="text-bold">{this.state.data.donorNumber}</p>
                    </div>
                    <div className="donor-badge-card-details-item-blood-group">
                      <div className="badge-card-item-blood-group">
                        <p className="text-bold">
                          {this.state.data.bloodGroup !== "unspecified"
                            ? this.state.data.bloodGroup
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="donor-badge-card-footer">
                  <div className="card-logo">
                    <img src={RBCLOGO} />
                  </div>
                  <div style={{ flex: 1 }} />
                  <div>
                    <span>
                      Proud to be a <br />
                      Blood Donor
                    </span>
                  </div>
                </div>
              </div>
              {/* <center className="badge-card-actions">
                <div className="badge-card-action-button">
                  <span className="text-bold text-disabled">
                    {" "}
                    {language[this.props.defaultLanguage].print_card}
                  </span>
                </div>
              </center> */}
            </PrintComponent>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(DonorBadge);
