import { Button } from "../../Button";
import ScrollDownMouse from "../ScrollDown/ScrollDownMouse";
import "./styles.css";

const Introduction = () => {
  return (
    <div className="intro-wrapper">
      <div className="intro-container container">
        <div className="col-md-5">
          <div className="intro-description">
            <div className="intro-title-container">
              <h1>Donate & Save a Life</h1>
            </div>
            <div className="intro-paragraph-container">
              <p>
                {" "}
                Donate blood and make a difference today. Every drop is vital in
                spreading hope and saving lives throughout our diverse
                community.
              </p>
            </div>
            <div className="intro-buttons-container">
              <Button
                text="Donate today"
                type="link"
                to="/common/start-application"
                className="btn-primary"
              />
              <Button
                type="hyperlink"
                to="/landing#howWorks"
                text="How it works"
                className="btn-transparent light"
              />
            </div>
            {/* <div className="intro-footer">
              <ScrollDownMouse navigateTo="#howWorks" />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
