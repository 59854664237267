export const activeOptions = [
  {
    label: "Active",
    value: "active",
    available: true,
  },
  {
    label: "Inactive",
    value: "inactive",
    available: false,
  },
];
