import toastMessage from "../../toastMessage";
import { getStorage } from "../../storage";
import axios from "axios";

const API_URL = process.env.REACT_APP_BBMS_REPORT_API;

export const fetchDonors = async (lng = "english", params) => {
  const { page, limit, donorCategory } = params;
  try {
    const user = await getStorage();

    const options = {
      method: "GET",
      url: `${API_URL}/analytics/donors/${donorCategory}`,
      params: {
        page: page,
        limit: limit,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await axios(options);

    let data = queryRes.data;

    return { data };
  } catch (error) {
    toastMessage("error", error);
  }
};

export const fetchDonorsCounts = async (lng = "english") => {
  try {
    const user = await getStorage();

    const options = {
      method: "GET",
      url: `${API_URL}/analytics/donors/category`,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await axios(options);

    let data = queryRes.data;

    return data;
  } catch (error) {
    toastMessage("error", error);
  }
};
