import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import bloodDonationQuery from "../../../utils/queries/bloodDonationQuery";
import { getStorage } from "../../../utils/storage";
import availableOptions from "../../../constants/availableOptions";
import { fetchComponentsSeparationProfiles } from "../../../utils/queries/production/profiles/separation";
import icons from "../../../constants/icons";
import { Modal } from "../../Modal";
import { fetchInventories } from "../../../utils/queries/inventories/center";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class NewTransformation extends React.Component {
  state = {
    username: "",
    error: {},
    isSubmitting: false,
    donation: {},
    isLoading: true,
    available: availableOptions[0],
    profiles: [],
    bagStatuses: [],
    volume: "",
    donationNumber: "",
    donations: [],
    componentCode: "",
  };

  getDonation = async (searchDonation) => {
    try {
      if (this.state.donationNumber === "") {
        let { error } = this.state;

        error.donationNumber =
          language[this.props.defaultLanguage].donation_number_required;

        this.setState({ error });

        return;
      }
      this.setState({
        searchDonation,
      });

      const data = await bloodDonationQuery(this.props.defaultLanguage, {
        donationNumber: this.state.donationNumber?.replace(/\s+/g, ""),
      });

      let donation = {};

      if (data.length > 0) {
        donation = data[0];

        this.getProduct(donation._id);
      }

      this.setState({
        donation,
      });
    } catch (error) {
      this.setState({ searchDonation: false });
    }
  };

  getComponentProfiles = async (isFetchingProfile, code) => {
    try {
      this.setState({
        isFetchingProfile,
      });

      const data = await fetchComponentsSeparationProfiles(
        this.props.defaultLanguage,
        {
          type: "transformation",
          inputType: "dropdown",
          code,
        }
      );

      this.setState({
        isFetchingProfile: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingProfile: false });
    }
  };

  getProduct = async (donation) => {
    try {
      const { componentCode } = this.state;
      const data = await fetchInventories(this.props.defaultLanguage, {
        donation,
        code: componentCode,
      });

      let productInfo = {};

      if (data.length > 0) {
        productInfo = data[0];
      }

      this.setState(
        {
          productInfo,
          searchDonation: false,
        },
        () => {
          this.getComponentProfiles(true);
          if (productInfo.status === "transformed") {
            toastMessage(
              "error",
              "Donation: " +
                this.state.donationNumber +
                " has already been transformed"
            );
          } else if (productInfo.status !== "missingTest") {
            this.handleShowModal("showModal");
          } else {
            toastMessage(
              "error",
              "Missing blood test for donation: " + this.state.donationNumber
            );
          }
        }
      );

      return data;
    } catch (error) {
      toastMessage("error", error);

      this.setState({
        searchDonation: false,
      });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e.target ? e.target.value : e;

    delete error[field];
    delete error["errorMessage"];

    await this.setState({
      [field]: inputValue,
      error,
    });
  };

  handleChangeComponent = (idex, field, e) => {
    let { profile = {} } = this.state;
    let { components = [] } = profile;

    components[idex][field] = e.target ? e.target.value : e;
    this.setState({ profile });
  };

  validateForm() {
    let { profile, componentCode, error, donationNumber } = this.state;

    if (!profile) {
      error.profile =
        language[this.props.defaultLanguage].component_profile_required;
    }

    if (donationNumber === "") {
      error.donationNumber =
        language[this.props.defaultLanguage].donation_number_required;
    }

    if (componentCode === "") {
      error.componentCode =
        language[this.props.defaultLanguage].component_code_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      const { donation } = this.state;
      this.setState({
        isSubmitting: true,
      });
      await this.handleUpdateInventory();
      await this.handleTransformedData(donation);

      this.setState({
        isSubmitting: false,
        profile: null,
        donationNumber: "",
      });
    }
  };

  handleTransformedData = async (donation) => {
    let { _id } = this.props;
    let { profile, available } = this.state,
      url = `${API_URL}/componentTransformation`,
      method = "POST",
      user = await getStorage();

    let requestedBody = {
      donation: donation._id,
      components: profile?.components?.flatMap((el) => {
        if (el.splits && Array.isArray(el.splits) && el.splits.length > 0) {
          return el.splits.map((elSplit) => ({
            _id: el._id,
            name: el.name,
            code: el.code,
            volume: elSplit.volume,
          }));
        } else {
          return {
            _id: el._id,
            name: el.name,
            code: el.code,
            volume: el.volume,
          };
        }
      }),
      available: available.value,
      profile: profile._id,
    };

    if (method === "PUT") {
      requestedBody.modifiedBy = user.id;
    } else {
      requestedBody.addedBy = user.id;
    }

    if (_id && _id !== "") {
      method = "PUT";
      requestedBody.id = _id;

      delete requestedBody.addedBy;
    }

    const options = {
      method,
      url,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
      data: requestedBody,
    };
    return axios(options)
      .then((data) => {
        this.setState({
          isSubmitting: false,
          donation: {},
        });

        toastMessage(
          "success",
          language[this.props.defaultLanguage][
            method === "PUT" ? "update_data_success" : "add_data_success"
          ]
        );
        this.handleCloseModal("showModal");
        return data;
      })
      .catch((error) => {
        this.setState({ isSubmitting: false });
        toastMessage("error", error);

        return error;
      });
  };

  handleCheckBox(type) {
    this.setState({
      [type]: !this.state[type],
    });
  }

  onReset = () => {
    this.setState({
      donation: {},
    });
  };

  handleCheck(field, value) {
    let { error } = this.state;
    delete error[field];
    this.setState({
      [field]: value,
      error,
    });
  }

  handleShowModal(modal) {
    this.setState({
      [modal]: true,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  renderComponentProfiles() {
    const { profile = {} } = this.state;
    const { splits } = profile || {};

    let res = [];

    if (splits && Number(splits)) {
      for (let i = 0; i < Number(splits); i++) {
        res.push(this.loopingProfiles(i));
      }
    } else {
      res.push(this.loopingProfiles(0));
    }

    return res;
  }

  loopingProfiles = (splitIndex) => {
    return (
      <div key={splitIndex}>
        {this.state?.profile?.components?.map((component, idx) => {
          return (
            <div key={`${splitIndex}-${idx}`} className="col-md-12">
              <div className="col-md-6">
                <Input
                  label={language[this.props.defaultLanguage].component_name}
                  required
                  value={component.code + " - " + component.name}
                  onChange={(e) =>
                    this.onChangeText(`${component.code}_name_${idx}`, e)
                  }
                  error={this.state.error[`${component.code}_name_${idx}`]}
                  disabled
                />
              </div>
              <div className="col-md-6">
                <Input
                  label={language[this.props.defaultLanguage].volume}
                  required
                  value={component.splits?.[splitIndex]?.volume || ""}
                  type="number"
                  onChange={(e) =>
                    this.handleChangeComponent(
                      splitIndex,
                      idx,
                      "volume",
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  handleUpdateInventory = async () => {
    try {
      const { productInfo } = this.state;

      if (productInfo._id) {
        const body = {
          id: productInfo._id,
          status: "transformed",
        };

        const user = await getStorage();

        const options = {
          url: `${API_URL}/inventory`,
          method: "PUT",
          data: body,
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        };

        return axios(options);
      }
    } catch (error) {
      toastMessage("error", error);
    }
  };

  handleChangeComponent = (splitIndex, componentIndex, field, value) => {
    this.setState((prevState) => {
      const updatedComponents = prevState.profile.components.map(
        (component, idx) => {
          if (idx === componentIndex) {
            const updatedSplits = component.splits ? [...component.splits] : [];
            updatedSplits[splitIndex] = {
              ...updatedSplits[splitIndex],
              [field]: value === "" ? "" : value, // Explicitly handle empty string
            };
            return {
              ...component,
              splits: updatedSplits,
            };
          }
          return component;
        }
      );

      return {
        profile: {
          ...prevState.profile,
          components: updatedComponents,
        },
      };
    });
  };

  render() {
    console.log(this.state.profile);
    return (
      <>
        <div className="card-body row">
          <div className="col-md-12">
            <Input
              placeholder={
                language[this.props.defaultLanguage].donation_number_placeholder
              }
              label={language[this.props.defaultLanguage].donation_number}
              required
              value={this.state.donationNumber}
              error={this.state.error.donationNumber}
              onChange={(e) => this.onChangeText("donationNumber", e)}
            />
            <Input
              label={language[this.props.defaultLanguage].component_code}
              required
              value={this.state.componentCode}
              error={this.state.error.componentCode}
              onChange={(e) => this.onChangeText("componentCode", e)}
            />
            <Button
              text={language[this.props.defaultLanguage].search}
              onPress={() => this.getDonation(true)}
              isSubmitting={this.state.searchDonation}
            />
          </div>
        </div>

        <Modal
          show={this.state.showModal}
          handleClose={() => this.handleCloseModal("showModal")}
          title={this.state.productInfo?.name}
        >
          {this.state.productInfo?._id ? (
            <div className="card-body">
              <Select
                loadOptions={(v) => this.getComponentProfiles(true, v)}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].component_profiles}
                required
                value={this.state.profile}
                onChange={(e) => this.onChangeText("profile", e)}
                error={this.state.error.profile}
                isLoading={this.state.isFetchingProfile}
              />

              {console.log(this.state.profile)}

              {this.renderComponentProfiles()}
            </div>
          ) : (
            <>No data found</>
          )}
          <div className="modal-footer">
            <Button
              text={language[this.props.defaultLanguage].cancel}
              onPress={() => this.handleCloseModal("showModal")}
              className="btn-default"
            />
            <Button
              text={language[this.props.defaultLanguage].submit}
              onPress={() => this.onSubmit()}
              isSubmitting={this.state.isSubmitting}
            />
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewTransformation);
