import "./styles.css";

const NotificationBadgeCount = (props) => {
  if (!props.count || props.count === 0) {
    return <></>;
  }
  return (
    <div
      className={`notification-counter ${props.borderClassName}`}
      style={props.styles}
    >
      <span>{props.count}</span>
    </div>
  );
};

export default NotificationBadgeCount;
