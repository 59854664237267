import toastMessage from "../../toastMessage";
import { getStorage } from "../../storage";
import axios from "axios";

const API_URL = process.env.REACT_APP_BBMS_REPORT_API;

export const fetchDonationBloodGroupData = async (
  lng = "english",
  { page, limit, center }
) => {
  try {
    const user = await getStorage();

    const options = {
      method: "GET",
      url: `${API_URL}/analytics/donation/bloodGroup`,
      params: {
        page: page,
        limit: limit,
        center,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await axios(options);

    let data = queryRes.data;

    return { data };
  } catch (error) {
    toastMessage("error", error);
  }
};

export const fetchDonationBloodGroupCenterSiteData = async (
  lng = "english",
  { page, limit, centerSite }
) => {
  try {
    const user = await getStorage();

    const options = {
      method: "GET",
      url: `${API_URL}/analytics/donation/bloodGroup/centerSite`,
      params: {
        page: page,
        limit: limit,
        centerSite,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await axios(options);

    let data = queryRes.data;

    return { data };
  } catch (error) {
    toastMessage("error", error);
  }
};
