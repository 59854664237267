import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input/index.js";
import { Button } from "../../Button/index.js";
import language from "../../../language/index.js";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage.js";
import { getStorage } from "../../../utils/storage.js";
import availableOptions from "../../../constants/availableOptions.js";
import requestQuery from "../../../utils/queries/requestQuery.js";
import icons from "../../../constants/icons.js";
import Loading from "../../Loader/Loading.js";
import { Empty } from "../../Empty/index.js";
import { fetchBloodRequestProcessedHistory } from "../../../utils/queries/request/bloodRequestProcessedHistory.js";
import { Modal } from "../../Modal/index.js";
import Table from "../../Table/Table.js";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

const statusOptions = [
  {
    label: "Delivered",
    value: "Delivered",
  },
];

class DistributorPickup extends React.Component {
  state = {
    error: {},
    isSubmitting: false,
    distributors: [],
    isActive: availableOptions[0],
    status: statusOptions[0],
    isLoading: false,
    requestInfo: {},
    requestProductInfo: [],
    isSearching: false,
    preparePackage: [],
  };

  componentDidMount = async () => {
    this.getRequestInfo(true);

    await this.setState({ ...this.props });
  };

  getRequestInfo = async (isLoading) => {
    try {
      this.setState({
        isLoading,
      });

      const { requestId } = this.props;

      const data = await requestQuery(this.props.defaultLanguage, {
        id: requestId,
      });

      let requestInfo = {};

      if (data?.data?.length > 0) {
        requestInfo = data?.data[0];
      } else {
        toastMessage("error", "Couldn't find request information");
      }

      this.setState({
        requestInfo,
        packages: requestInfo?.packages,
        isLoading: false,
      });
    } catch (error) {
      this.setState({ isLoading: false });

      toastMessage("error", error);
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e.target ? e.target.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });

    if (field === "hospital") {
      this.getBloodBanks(true);
    }
  };

  validateSearchForm() {
    let { error, code } = this.state;

    if (code === "") {
      error.code = language[this.props.defaultLanguage].code_required;
    }

    this.setState({ error });
  }

  validateForm() {
    let { error, givenNumberOfPackages, givenVolume } = this.state;

    if (givenNumberOfPackages === "") {
      error.givenNumberOfPackages =
        language[this.props.defaultLanguage].number_of_packages_required;
    }

    if (givenVolume === "") {
      error.givenVolume = language[this.props.defaultLanguage].volume_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let { selectedItem, status } = this.state,
        url = `${API_URL}/bloodRequestProductHistory`,
        method = "PUT",
        user = await getStorage();

      let requestedBody = {
        id: selectedItem._id,
        status: status?.value,
      };

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT" ? "update_data_success" : "add_data_success"
            ]
          );

          this.handleSearch();
          this.handleCloseModal("showModal");
        })
        .catch((error) => {
          toastMessage("error", error);
          this.setState({
            isSubmitting: false,
          });
        });
    }
  };

  handleSearch = async () => {
    await this.validateSearchForm();

    try {
      if (Object.keys(this.state.error).length > 0) return;

      const { code, requestInfo } = this.state;

      this.setState({
        isSearching: true,
      });

      const data = await fetchBloodRequestProcessedHistory(
        this.props.defaultLanguage,
        {
          code: code?.replace(/\s+/g, ""),
          center: requestInfo?.bloodBank?.center?._id,
          bloodRequest: requestInfo._id,
        }
      );

      let requestProductInfo = [];

      data.forEach((el) => {
        requestProductInfo.push(el);
      });

      if (data.length === 0) {
        toastMessage("error", "Product is not available in the request");
      }

      this.setState({
        isSearching: false,
        requestProductInfo,
      });
    } catch (error) {
      this.setState({ isSearching: false });
    }
  };

  handleShowModal(modal, modalTitle, selectedItem) {
    this.setState({
      [modal]: true,
      modalTitle,
      selectedItem,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  render() {
    if (this.state.isLoading) {
      return (
        <center>
          <Loading />
        </center>
      );
    }

    if (!this.state.requestInfo?._id) {
      return <Empty title={language[this.props.defaultLanguage].no_data} />;
    }

    return (
      <>
        <div className="card" style={{ marginBottom: "1rem" }}>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6" style={{ marginBottom: "1rem" }}>
                <Input
                  placeholder={
                    language[this.props.defaultLanguage]
                      .request_number_placeholder
                  }
                  label={language[this.props.defaultLanguage].request_number}
                  required
                  value={this.state?.requestInfo?.requestNo}
                  onChange={(e) => this.onChangeText("requestNo", e)}
                  error={this.state.error.requestNo}
                  disabled
                />
                <Input
                  placeholder={
                    language[this.props.defaultLanguage].search_product + "..."
                  }
                  label={language[this.props.defaultLanguage].product_code}
                  value={this.state.code}
                  onChange={(e) => this.onChangeText("code", e)}
                  error={this.state.error.product}
                  leftIcon={icons.package}
                />
                <Button
                  text={language[this.props.defaultLanguage].search}
                  isSubmitting={this.state.isSearching}
                  onPress={this.handleSearch.bind(this)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <Table
              data={this.state.requestProductInfo}
              headers={[
                {
                  title: language[this.props.defaultLanguage].product_code,
                  key: "code",
                  styles: {
                    textTransform: "uppercase",
                  },
                },
                {
                  title: language[this.props.defaultLanguage].product_name,
                  key: "product.name",
                  styles: {
                    textTransform: "uppercase",
                  },
                },
                {
                  title: language[this.props.defaultLanguage].volume,
                  key: "volume",
                },
                {
                  title:
                    language[this.props.defaultLanguage].number_of_packages,
                  key: "numberOfPackages",
                },
                {
                  title: language[this.props.defaultLanguage].status,
                  key: "status",
                  type: "status",
                },
                {
                  title: language[this.props.defaultLanguage].actions,
                  key: "action",
                },
              ]}
              actions={[
                {
                  canAccess: true,
                  name: language[this.props.defaultLanguage].confirm,
                  onPress: (item) =>
                    this.handleShowModal("showModal", item.code, item),
                  icon: icons.eye_on,
                },
              ]}
            />
          </div>
        </div>

        <Modal
          show={this.state.showModal}
          title={this.state.modalTitle}
          handleClose={() => this.handleCloseModal("showModal")}
        >
          {this.state.selectedItem && (
            <div>
              <div className="card-body">
                {this.state.selectedItem.status ===
                  "confirmPickupAndDispatched" && (
                  <span>
                    {
                      language[this.props.defaultLanguage]
                        .select_and_confirm_pickup
                    }
                  </span>
                )}
                <Input
                  placeholder={
                    language[this.props.defaultLanguage].product_code
                  }
                  label={language[this.props.defaultLanguage].product_code}
                  value={this.state.selectedItem.code}
                  disabled
                />
                <Input
                  placeholder={
                    language[this.props.defaultLanguage].product_name
                  }
                  label={language[this.props.defaultLanguage].product_name}
                  value={this.state?.selectedItem?.product?.name}
                  disabled
                />
                <Input
                  placeholder={language[this.props.defaultLanguage].volume}
                  label={language[this.props.defaultLanguage].volume}
                  value={this.state?.selectedItem?.volume}
                  disabled
                />
                <Input
                  placeholder={
                    language[this.props.defaultLanguage].number_of_packages
                  }
                  label={
                    language[this.props.defaultLanguage].number_of_packages
                  }
                  value={this.state.selectedItem.numberOfPackages}
                  disabled
                />
                <Select
                  options={statusOptions}
                  placeholder={language[this.props.defaultLanguage].select}
                  label={language[this.props.defaultLanguage].status}
                  value={this.state.status}
                  onChange={(e) => this.onChangeText("status", e)}
                  error={this.state.error.status}
                />
              </div>

              {/* {this.state?.selectedItem?.status ===
                "confirmPickupAndDispatched" && ( */}
              <div className="modal-footer">
                <Button
                  text={language[this.props.defaultLanguage].confirm}
                  onPress={this.onSubmit.bind(this)}
                  isSubmitting={this.state.isSubmitting}
                />
              </div>
              {/* )} */}
            </div>
          )}
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(DistributorPickup);
