import englishImage from "../assets/english.png";
import frenchImage from "../assets/french.png";
import kinyarwandaImage from "../assets/kinyarwanda.png";

const data = {
  english: {
    image: englishImage,
    names: "Names",
    email_address: "Email Address",
    login_text: "Login",
    forgot_password: "Forgot Password",
    names_required: "Names is required",
    phone_number_required: "Phone number is required",
    message_required: "Message is required",
    names_placeholder: "Enter your name",
    email_placeholder: "Enter your email address",
    phone_placeholder: "Enter your phone number",
    message_placeholder: "Type your message",
    submit: "Submit",
    contact_us: "Contact Us",
    partners: "Partners",
    where_can_donate: "Where can I Donate?",
    search_sites: "Search Sites",
    find_nearest_sites: "Find nearest site...",
    find_below_sites: "Find out below the sites where you can donate",
    who_can_give_blood: "Who Can Donate Blood",
    how_works: "How it Works",
    register: "Register",
    get_screened: "Health Check",
    donate: "Donate",
    donate_now: "Donate Now",
    create_account: "Create Account",
    learn_more: "Learn More",
    register_feature_description:
      "Sign up to join our community of donors. Your contribution is invaluable in saving lives and creating a positive impact. Be a donor today!",
    screened_feature_description:
      "Ensure your eligibility by going through a quick, safe, and essential health screening. Your well-being is our priority in this noble cause.",
    donate_feature_description:
      "Donate now to make a lasting impact. Your contribution can be the difference between life and death. Be the change, save a life today!",
    hospitals_or_sites: "Donation Sites",
    home: "Home",
    we_love_to_help: "We love to help",
    toll_free_number: "Toll Free Number",
    or: "or",
    call_use: "Call Us",
    every_24_7: "Every hour 24/7",
    password_placeholder: "Enter Password",
    welcome_title: "welcome",
    password: "Password",
    analytics: "Analytics",
    map_insights: "Map Insights",
    reports: "Reports",
    donors: "Donors",
    requests: "Requests",
    dispatch: "Dispatch",
    haemovigilance: "Haemovigilance",
    mobilization: "Mobilization",
    Hospitals: "Entities & Hospitals",
    alerts: "Alerts",
    feedback: "Feedback",
    accounts: "Accounts",
    sites: "Sites",
    logout: "Logout",
    dashboard: "Dashboard",
    message: "Message",
    settings: "Settings",
    two_factor_tab_name: "2FA Authentication",
    my_profile: "My Profile",
    change_password: "Change Password",
    roles_and_permissions: "Roles and Permissions",
    two_factor_authentication_description:
      "You will be able to login with OTP(One Time Password) next time you log in",
    new_password_placeholder: "Enter new password",
    new_password: "New Password",
    confirm_password_placeholder: "Enter password again",
    confirm_password: "Retype Password",
    change_password_success: "Password changed successfully",
    otp_code: "OTP Code",
    otp_code_will_be_sent_confirm_reset:
      "An OTP code will be sent to you for confirming the reset of your password.",
    reset_password: "Reset Password",
    send_otp: "Send OTP",
    enter_otp_code: "Enter OTP Code",
    accounts_title: "Accounts",
    export_: "Export",
    add_account: "Add Account",
    active_accounts: "Active Account",
    inactive_accounts: "Inactive Account",
    firstName: "First Name",
    lastName: "Last Name",
    first_name_placeholder: "Enter first name",
    last_name_placeholder: "Enter last name",
    edit_account: "Edit Account",
    delete_account: "Delete Account",
    account: "Account",
    cancel: "Cancel",
    update_account_success: "Account updated successfully",
    add_account_success: "Account added successfully",
    error_update_account: "Unable to update account",
    error_add_account: "Unable to add account",
    department: "Service",
    phone_number: "Phone Number",
    job_position: "Job Position",
    select: "Select",
    access_role: "Access Role",
    created_at: "Created At",
    actions: "Actions",
    unable_fetch_sites: "Unable to fetch sites",
    site_name: "Site Name",
    add_site: "Add Site",
    edit_site: "Edit Site",
    delete_site: "Delete Site",
    site: "Site",
    job_positions: "Job Positions",
    departments: "Services",
    job_position_name: "Position Name",
    feedbacks: "Feedbacks",
    error_delete_feedback: "Unable to delete feedback",
    success_delete_feedback: "Feedback successfully deleted",
    campaign_name: "Campaign Name",
    district: "District",
    province: "Province",
    sector: "Sector",
    cell: "cell",
    village: "Village",
    delete_mobilized_campaign: "Delete Mobilized Service",
    edit_mobilized_campaign: "Edit Mobilized campaign",
    add_campaign: "Add Campaign",
    error_delete_campaign: "Unable to add campaign",
    success_delete_campaign: "Campaign deleted successfully",
    id: "ID",
    date: "Date",
    blood: "Blood",
    liters: "Liters",
    receiver: "Receiver",
    shipped_by: "Shipped By",
    status: "Status",
    unable_fetch_dispatch: "Unable to fetch dispatch information",
    all: "All",
    pending: "Pending",
    completed: "Completed",
    processing: "Processing",
    blocked_donors: "Blocked Donors",
    blood_testing: "Blood Testing",
    blood_donation: "Collection",
    date_birth_date: "Date of Birth",
    gender: "Gender",
    name: "Name",
    donor_id: "Donor ID",
    site_name_placeholder: "Enter site name",
    success_delete_center: "Center deleted successfully",
    error_delete_center: "Unable to delete center",
    success_delete_distributor: "Blood distributor deleted successfully",
    error_delete_distributor: "Unable to delete blood distributor",
    unable_fetch_site: "Unable to fetch site",
    unable_fetch_distributor: "Unable to fetch distributors",
    unable_fetch_centers: "Unable to fetch centers",
    success_delete_center_site: "Center site deleted successfully",
    error_delete_center_site: "Unable to delete center site",
    unable_fetch_campaign: "Unable to fetch campaign information",
    success_delete_campaign: "Campaign deleted successfully",
    error_delete_campaign: "Unable to delete campaign",
    unable_fetch_committee: "Unable to fetch committee information",
    success_delete_committee: "Campaign deleted successfully",
    error_delete_committee: "Unable to delete committee",
    unable_fetch_donation: "Unable to fetch donation information",
    success_delete_donation: "blood collection deleted successfully",
    error_delete_donation: "Unable to delete donation",
    success_delete_request: "Request deleted successfully",
    error_delete_request: "Unable to delete request",
    unable_fetch_request: "Unable to fetch request",
    unable_fetch_test: "Unable to fetch blood tests",
    success_delete_test: "Successfully deleted",
    error_delete_test: "Unable to delete blood test",
    unable_fetch_usage: "Unable to fetch blood usage",
    success_delete_usage: "Data was successfully deleted",
    error_delete_usage: "Unable to delete blood usage",
    unable_fetch_donors: "Unable to fetch donors",
    success_delete_donors: "Donors was successfully deleted",
    error_delete_donors: "Unable to delete donor",
    success_delete_hospitals: "Data was successfully deleted",
    error_delete_hospitals: "Unable to delete data",
    unable_fetch_assessment: "Unable to fetch assessment",
    success_delete_assessment: "Assessment was successfully deleted",
    error_delete_assessment: "Unable to delete assessment",
    email_required: "Email address is required",
    password_required: "Password is required",
    wrong_email_password: "Wrong email address or password",
    username_required: "Username is required",
    invalid_email: "Invalid email address",
    username: "Username",
    username_placeholder: "Enter user name",
    department_required: "Service is required",
    job_position_required: "Job position is required",
    access_role_required: "Access role is required",
    access_role_required: "Access role is required",
    hospitals: "Entities or Hospitals",
    add_hospital: "Add",
    edit_hospital: "Edit Hospital",
    delete_hospital: "Delete Hospital",
    hospital: "Hospital",
    hospital_name: "Hospital Name",
    hospital_name_placeholder: "Enter hospital name",
    village_required: "Village is required",
    province_required: "Province is required",
    district_required: "District is required",
    sector_required: "Sector is required",
    cell_required: "Cell is required",
    error_add_hospital: "Unable to add hospital",
    error_update_hospital: "Unable to update hospital",
    add_hospital_success: "Hospital added successfully",
    update_hospital_success: "Hospital updated successfully",
    public: "Public",
    private: "Private",
    type: "Type",
    access_roles: "Access-Roles",
    job_positions: "Job positions",
    add_department: "Add Service",
    delete_department: "Delete Service",
    edit_department: "Edit Service",
    department_name_placeholder: "Enter Service name",
    department_name: "Service Name",
    error_add_department: "Unable to add Service",
    error_update_department: "Unable to update Service",
    update_department_success: "Service updated successfully",
    add_department_success: "Service added successfully",
    department_name: "Service Name",
    department_name_required: "Service name is required",
    add_job_position: "Add Job Position",
    edit_job_position: "Edit Job Position",
    delete_job_position: "Delete Job Position",
    job_position: "Job Position",
    job_position_name_required: "Position name is required",
    add_position_success: "Add Position",
    error_add_position: "Unable to add position",
    error_update_position: "Unable to update position",
    update_position_success: "Position updated successfully",
    error_add_position: "Unable to add position",
    job_position_name_placeholder: "Enter Job position",
    add_access_role: "Add access role",
    edit_access_role: "Edit access role",
    delete_access_role: "Delete access role",
    access_role: "Access role",
    menus: "Menus",
    available: "Available",
    error_add_access_role: "Unable to add access role",
    error_update_access_role: "Unable to update access role",
    add_access_role_success: "Access role added successfully",
    update_access_role_success: "Access role updated successfully",
    access_role_name_required: "Access role name is required",
    access_role_menus_required: "Access role menus are required",
    access_role_name_placeholder: "Enter access role name",
    centers: "Centers",
    center_leader: "Center leader",
    center_name: "Center name",
    center_name_placeholder: "Enter center name",
    center_code: "Center Code",
    center_code_required: "Center Code is required",
    center_code_placeholder: "Enter center code",
    contact_number: "Contact Number",
    contact_number_placeholder: "Enter contact number",
    add_center: "Add Center",
    edit_center: "Edit Center",
    delete_center: "Delete Center",
    is_active: "Is Active",
    center: "Center",
    contact_number_required: "Contact Number is required",
    latitude: "Latitude",
    longitude: "Longitude",
    latitude_required: "Latitude is required",
    longitude_required: "Longitude is required",
    latitude_placeholder: "Enter latitude",
    longitude_placeholder: "Enter longitude",
    hospital_name_required: "Hospital name is required",
    error_add_site: "Unable to add site",
    error_update_site: "Unable to update site",
    add_site_success: "Site added successfully",
    update_site_success: "Site updated successfully",
    committee_leader: "Committee Leader",
    committee_leader_required: "Committee Leader is required",
    center_required: "Center is required",
    edit: "Edit",
    delete_: "Delete",
    manager: "Manager",
    code: "Code",
    error_add_center: "Unable to add center",
    error_update_center: "Unable to update center",
    add_center_success: "Center added successfully",
    update_center_success: "Center updated successfully",
    site_code: "Site Code",
    site_code_placeholder: "Enter site code",
    site_name_required: "Site name is required",
    committee_leader_required: "Committee Leader is required",
    add_distributor: "Add Distributor",
    edit_distributor: "Edit Distributor",
    delete_distributor: "Delete Distributor",
    distributor: "Distributor",
    distributor_name_required: "Distributor name is required",
    error_add_distributor: "Unable to add Distributor",
    error_update_distributor: "Unable to update Distributor",
    add_distributor_success: "Distributor added successfully",
    update_distributor_success: "Distributor updated successfully",
    distributors: "Distributors",
    distributor_name: "Distributor Name",
    distributor_name_placeholder: "Enter distribution name",
    campaign_name_required: "Campaign name is required",
    site_required: "Site is required",
    campaign_name: "Campaign name",
    campaign_name_placeholder: "Enter campaign name",
    campaign_date: "Campaign Date",
    campaign_date_required: "Campaign date is required",
    estimated_collection: "Estimated collection",
    estimated_collection_placeholder: "Enter Estimation of blood collection",
    actual_collection: "Actual collection",
    actual_collection_placeholder: "Enter actual collection",
    campaign_name: "Campaign Name",
    campaign_name_placeholder: "Enter campaign name",
    campaigns: "Campaigns",
    center: "Center",
    committee: "Committee",
    committee_name: "Committee Name",
    committee_name_placeholder: "Enter committee name",
    error_add_committee: "Unable to add committee",
    error_update_committee: "Unable to update committee",
    add_committee_success: "Committee added successfully",
    update_committee_success: "Committee updated successfully",
    committee_name_required: "Committee name is required",
    site_required: "Site is required",
    success_delete_committee: "Committee deleted successfully",
    error_delete_committee: "Unable to delete committee",
    add_committee: "Add Committee",
    edit_committee: "Edit Committee",
    delete_committee: "Delete Committee",
    committee: "Committee",
    phone_number_placeholder: "Enter phone number",
    error_add_donor: "Unable to add donor",
    error_update_donor: "Unable to update donor",
    add_donor_success: "Donor added successfully",
    update_donor_success: "Donor updated successfully",
    error_donor_exist:
      "Donor already exists, try another phone number or email address",
    donor_number_required: "Donor number is required",
    donor_number: "Donor Number",
    donor_number_placeholder: "Enter donor number",
    add_donor: "Add Donor",
    donors: "Donors",
    donor: "Donor",
    collected_by: "Phlebotomist",
    negative: "Negative",
    positive: "Positive",
    rh_factor: "RH Factor",
    volume_placeholder: "Enter volume",
    volume: "Volume",
    expiration_date: "Expiration Date",
    add_collection: "Add Collection",
    edit_collection: "Edit Collection",
    delete_collection: "Delete Collection",
    collection: "Collection",
    collected_by_placeholder: "Enter name",
    blood_group: "Blood Group",
    volume_required: "Volume is required",
    donor_required: "Donor is required",
    blood_group_required: "Blood group is required",
    rh_factor_required: "RH factor is required",
    collected_by_required: "User collected the blood is required",
    expiration_date_required: "Expiration date is required",
    error_add_blooddonation: "Unable to add blood collection",
    error_update_blooddonation: "Unable to update blood collection",
    add_blooddonation_success: "blood collection has been added successfully",
    update_blooddonation_success:
      "blood collection has been updated successfully",
    success_delete_request: "Request has been deleted successfully",
    error_delete_request: "Unable to delete request",
    request: "Request",
    add_request: "Add Request",
    edit_request: "Edit Request",
    delete_request: "Delete Request",
    description_required: "Description is required",
    hospital_required: "Hospital is required",
    number_of_packages_required: "Number of package is required",
    distributor_required: "Distribution is required",
    error_add_bloodrequest: "Unable to add blood request",
    error_update_bloodrequest: "Unable to update blood request",
    add_bloodrequest_success: "Blood request added successfully",
    update_bloodrequest_success: "Blood request updated successfully",
    packages_required: "Packages is required",
    add_package: "Add package",
    description: "Description",
    description_placeholder: "Enter Description",
    save: "Save",
    number_of_packages: "Number of packages",
    blood_group_a_plus: "Group A+",
    blood_group_a_minus: "Group A-",
    blood_group_b_plus: "Group B+",
    blood_group_b_minus: "Group B-",
    blood_group_ab_plus: "Group AB+",
    blood_group_ab_minus: "Group AB-",
    blood_group_o_plus: "Group O+",
    blood_group_o_minus: "Group O-",
    donations_units_by_blood_group: "Donations/Units by Blood Group",
    blood_donors_by_blood_groups: "Blood Donors by Blood Groups",
    blood_donation_by_ages: "Blood Donation by Age",
    regularity: "Regularity",
    confirmed_hiv: "Confirmed HIV",
    male: "Male",
    male_percentage: "Male Percentage",
    male: "Female",
    male_percentage: "Female Percentage",
    rcbts: "RCBTS",
    total: "Total",
    find_application: "Find Application",
    start_new_application: "Start New Application",
    existing_donor: "Existing Donor",
    center_site: "Center Site",
    center_site_required: "Center site is required",
    donor_number: "Donor Number",
    request_info: "Request Information",
    packages: "Packages",
    approvals: "Approvals",
    level_required: "Level is required",
    level: "Level",
    approver_required: "Approver is required",
    approver_exist: "Approval already exists",
    add_approval_success: "Approval has been added successfully",
    update_approval_success: "Approval has been updated successfully",
    update_approval_error: "Unable to update Approval",
    add_approval_error: "Unable to add Approval",
    delete_approval_success: "Approval has been deleted successfully",
    delete_approval_error: "Unable to delete Approval",
    approver: "Approver",
    approved_at: "Approved At",
    add_approval_level: "Add Approval",
    approval_level: "Approval Level",
    level_placeholder: "Enter level",
    approved: "Approved",
    edit_approval_level: "Edit Approval",
    delete_distributor_success: "Distributor has been deleted successfully",
    delete_distributor_error: "Unable to delete distributor",
    delete_distributor: "Delete Distributor",
    update_distributor_success: "Update Distributor successfully",
    add_distributor_success: "Add Distributor successfully",
    update_distributor_error: "Unable to update Distributor",
    add_distributor_error: "Unable to add Distributor",
    contact_number: "Contact Number",
    distributor_required: "Distributor is required",
    reason: "Reason",
    requester: "Requester",
    usage_date: "Usage Date",
    blood_usage: "Blood Usage",
    requester_name_required: "Requester name is required",
    requester_position_required: "Requester position is required",
    error_add_blood_usage: "Unable to add blood usage",
    error_update_blood_usage: "Unable to update blood usage",
    add_blood_usage_success: "Add blood usage successfully",
    update_blood_usage_success: "Update blood usage successfully",
    requester_position: "Requester Position",
    requester_position_placeholder: "Enter a requester position",
    requester_name: "Requester Name",
    requester_name_placeholder: "Enter requester name",
    reason: "Reason",
    reason_placeholder: "Enter reason",
    donation: "Donation",
    name_placeholder: "Enter name",
    tested: "Tested",
    position: "Position",
    usage_date: "Usage Date",
    active: "Active",
    pre_donation_assessment: "Pre-donation Assessment",
    assessment_personal_info_title: "QUESTIONS FOR BLOOD DONORS",
    assessment_personal_info_description:
      "Answer the questions they ask correctly, this will protect you and prevent you from receiving your blood. Thank you very much!",
    start_application_title: "DONOR DECLARATION",
    start_application: "Start Application",
    next: "Next",
    previous: "Previous",
    first_name: "First Name",
    last_name: "Last Name",
    phone_number: "Phone Number",
    phone_number_placeholder: "Enter phone number",
    male: "Male",
    female: "Female",
    gender: "Gender",
    birth_place: "Birth Place",
    date_of_birth: "Date of Birth",
    place_placeholder: "Enter place",
    occupation: "Occupation",
    occupation_placeholder: "Enter Occupation",
    where_do_you_live: "Where Do You Live?",
    province: "Province",
    district: "District",
    yes: "Yes",
    no: "No",
    sector: "Sector",
    cell: "Cell",
    village: "Village",
    are_you_married: "Are you married?",
    have_you_donated_blood_recently: "Have you donated blood previously?",
    yes_how_many_times: "Number of donation(s)?",
    yes_how_many_times_placeholder: "Enter number of donation(s)?",
    last_donation_location: "Where?",
    last_donation_location_placeholder: "Enter last donation location",
    last_time_donated_did_had_problem:
      "Did you have any discomfort during/after donation?",
    category: "Category",
    first_name_required: "First name is required",
    last_name_required: "Last name is required",
    phone_number_required: "Phone number is required",
    gender_required: "Gender is required",
    birth_place_required: "Birth place is required",
    birth_date_required: "Date of birth is required",
    occupation_required: "Occupation is required",
    category_required: "Category is required",
    district_required: "District is required",
    province_required: "Province is required",
    sector_required: "Sector is required",
    cell_required: "Cell is required",
    village_required: "Village is required",
    martial_status: "martial status is required",
    have_you_ever_donated: "select the answer",
    personal_information: "Personal Information",
    health_information: "Health Information",
    are_feeling_healthy_and_well: "Are you feeling healthy and well?",
    in_the_last_fourty_eight_hours: "In the past 48 Hours",
    are_you_taking_any_medication: "Are you taking any medication?",
    do_you_have_wound_other_skin_illness:
      "Do you have a wound or other skin condition?",
    did_you_travel_outside_rwanda_recently:
      "Did you travel outside of Rwanda recently?",
    have_taken_an_aspirin: "Have you taken the medicine named Aspirin?",
    do_you_take_medication: "Do you take any medecine?",
    in_the_last_one_month: "In the past 1 Month",
    have_you_had_malaria: "Have you had malaria?",
    dental_extraction: "Dental extraction?",
    are_immunized: "Are you immunized?",
    in_the_past_six_month: "In past 6 Months have you had?",
    vaccine: "Vaccine?",
    weight_lost: "Weight lost?",
    repeated_diarrhea: "Repeated diarrhea?",
    in_last_siz_month: "In the past 6 Months",
    lost_weight: "Have you lost weight?",
    swollen_glands: "Swollen glands",
    recurringDiarrhea: "You have recurring diarrhea?",
    continuous_low_grade_fever_any_disease: "Continuous fever or any disease?",
    tattooing_or_ear_piercing: "Tattoos or Ear-piercing?",
    surgery_or_circumcision: "Surgery or Circumcision?",
    endoscopy: "Endoscopy?",
    constantFeverIllness: "You have a constant fever or other illness?",
    blood_transfusion: "Blood Transfusion?",
    heart_disease: "Heart Disease?",
    kidney_disease: "Kidney Disease?",
    cancer: "Cancer?",
    diabetes: "Diabetes?",
    tuberculosis: "tuberculosis?",
    asthma: "Asthma?",
    epilepsy: "Epilepsy?",
    skin_irritation:
      "You have skin irritations, cuts and bruises, ear piercings?",
    diagnosed: "You've been diagnosed or circumcised?",
    abnormal_bleeding_tendency: "Abnormal bleeding tendency?",
    hepatitis_b_or_c: "Hepatitis B or C?",
    are_you_pregnant: "Are you Pregnant?",
    HIVAIDS: "HIV/AIDS",
    syphillis: "Syphillis?",
    gonorrhea: "Gonorrhea?",
    have_you_had_abortion_in_the_last_six_month:
      "Have you had abortion in the last 6 Months?",
    neighbor_with_infectious_such_as_hapatitis_b_or_c:
      "A neighbor with infectious disease such as Hepatitis B, C, HIV, etc...?",
    had_stomach_exam: "You have had a stomach exam (endoscopy)?",
    hadBloodTransfusion: "You have had a blood transfusion?",
    acknowledgment_title: "I don't want to donate blood to get tested for HIV:",
    acknowledgment_description: `I agree that the National blood collection Center collects my blood, tests it, and gives it to a patient who needs it. I also agree that if necessary my blood can be used in research aimed at improving medicine. I am sure that a person can be infected and the tests do not show the disease. I am sure that I will receive answers and I am ready to receive them no matter what. I have read the instructions carefully and I am sure that I have answered the questions correctly, I know that not telling the truth can have a negative effect on the recipient of my blood and myself. I asked them all the questions I had and they were all answered`,
    acknowledgment: "Acknowledgement",
    health_information: "Health Information",
    martial_status_required: "Martial Status is Required",
    tell_us_your_age: "Tell us your age",
    enter: "Enter",
    cancel: "Cancel",
    date_birth_required: "Date Birth is required",
    age_not_allowed: "Age is not allowed, must be between 17 to 66",
    pin: "PIN",
    pin_required: "PIN is required",
    pin_placeholder: "Enter PIN",
    last_date_donation: "Last date of the donation",
    date_of_last_donation: "Date of last donation",
    last_donation_location: "Last donation location",
    birth_date: "Birth Date",
    donor_number: "Donor Number",
    donation_number_placeholder: "Enter donation number",
    pre_assessment: "Pre Assessment",
    donor_registration: "Donor Registration",
    near_center: "Nearest Center",
    near_center_site: "Nearest Center Site",
    go_back: "Go Back",
    services: "Services",
    your_donor_number: "Your donation number",
    donation_number_required: "Donation number is required",
    search_donor_number_email_placeholder:
      "Search by donation number or email address",
    donor_does_not_exist:
      "Can't find your donation information, please check your search input and try again",
    invalid_phone_number: "Invalid phone number",
    unable_fetch_pre_assessment_info: "Unable to fetch pre-assessment info",
    nationality: "Nationality",
    national_id_number: "National ID Number",
    passport_number: "Passport Number",
    passport_number_placeholder: "Enter Passport Number",
    national_id_number_placeholder: "Enter national ID Number",
    passport_number_placeholder: "Enter national ID Number",
    national_id_number_required: "National ID Number is required",
    passport_number_required: "Passport Number is required",
    invalid_national_id_number: "Invalid national ID Number",
    nationality_required: "Nationality is required",
    appointments: "Appointments",
    hospitals_and_sites: "Hospitals & Sites",
    find_where_to_donate: "Find out below the Hospitals where you can donate",
    view_address_map: "View Address on Map",
    date_birth_date_checker_title: "Please let us know your date of birth",
    date_birth_date_checker_description:
      "We are honored that you would like to become a blood donor. Before we continue we would like to make sure that age restrictions do not apply to you to ensure your safety while donating blood.",
    below_year_old: `Unfortunately, we are not able to take you as a
blood donor for now because of age restrictions while donating blood.
We accept donors of age at least 18 years old for your own safety. We
thank you nevertheless and look forward to seeing you in a few years.`,
    above_year_old: `Unfortunately, we are not able to take you as a
blood donor because of age restrictions while donating blood. We accept
donors of age below 60 years old for your own safety. We thank you very
much for your interest and would appreciate your support in telling
friends and family to have the same commitment you just showed.`,
    register: "Register",
    nearest_center: "Nearest Center",
    nearest_center_site: "Nearest Center Site",
    employment_category: "Employment Category",
    employee_id: "Employee ID",
    employee_id_placeholder: "Enter Employee ID",
    other_occupation: "Name of occupation",
    other_occupation_placeholder: "Please Enter name of your occupation",
    what_is_your_blood_group: "What is your blood group",
    have_you_bitten_by_insect: "Have you bitten by insect",
    for_female_donors_only: "For female donors only",
    do_you_have_a_child_who_is_under_9_months:
      "Do you have a child breastfeeding  who is under 9 months",
    have_you_had_abortion_in_the_last_siz_month:
      "Have you had abortion in the last  6 months",
    tuberculosis: "Tuberculosis",
    weight_loss: "Weight Loss?",
    do_you_have_any_wound_or_cutaneous_disease:
      "Do you have any wound or cutaneous disease?",
    center_site_required: "Center Site is required",
    center_required: "Center is required",
    password_must: "Password must contain",
    password_min_error: "Minimum 8 characters",
    password_contains_uppercase_letter: "At least one uppercase letter",
    password_contains__lowercase_letter: "At least one lowercase letter",
    password_contains_number: "At lease one number.",
    donor_questionnaire: "Donor Questionnaire",
    answer_this_question: "This question is required",
    searching: "Search...",
    search: "Search",
    donor_information: "Donor Information",
    registration_date: "Registration Date",
    preassessment: "Pre-Assessment",
    preassessments: "Pre-Assessments",
    hemoglobin: "Hemoglobin",
    hepatitisB: "HepatitisB",
    hepatitisC: "hepatitisC",
    hiv: "HIV",
    syphilis: "Syphilis",
    new_blood_test: "New Blood Test",
    blood_test: "Blood Test",
    donation_required: "Donation is required",
    hemoglobin_required: "Hemoglobin  field is required",
    hepatitisB_required: "Hepatitis B  field is required",
    hepatitisC_required: "Hepatitis C field  is required",
    hiv_required: "HIV  field is required",
    syphilis_required: "synopsis  field  is required",
    add_bloodtest_success: "Test added successfully",
    update_bloodtest_success: "Test updated successfully",
    invalid_phone_number: "Invalid phone number",
    success_delete_preassessment:
      "Pre assessment data has been deleted successfully",
    error_delete_preassessment: "Unable to delete assessment data",
    identity_doc_type: "Identity doc type",
    identity_doc_number: "Identity doc Number",
    login_as_donor: "Login as Donor",
    admin_login: "Staff Login",
    donor_login: "Donor Login",
    admin: "admin",
    login_as_admin: "Login as staff",
    all_fields: "All fields",
    are_mandatory: "are mandatory",
    blood_donor_card: "Blood Donor Card",
    print_card: "Print Card",
    schedule_date: "Schedule Date",
    book_appointment_title: "Book Appointment to your nearest center today",
    book_appointment_description:
      "You can schedule appointments at your nearest centers. Once scheduled, you'll receive a confirmation message upon approval",
    date_required: "Date is required",
    requested_date: "Requested Date",
    new_assessment: "New Assessment",
    no_data_found: "No data found",
    search_donor: "Search by donor",
    vital_signs: "Vital Signs",
    equipments: "Equipments",
    add_vital_sign: "Add Vital Sign",
    edit_vital_sign: "Edit Vital Sign",
    delete_vital_sign: "Delete Vital Sign",
    vital_sign: "Vital Sign",
    unable_fetch_vital_sign: "Unable to fetch sign vital",
    unable_delete_vital_sign: "Unable to delete sign vital",
    measure: "Measure",
    equipment: "Equipment",
    update_vital_sign_success: "Updated vital sign successfully",
    deleted_vital_sign_success: "Deleted vital sign successfully",
    error_update_vital_sign: "Unable to update vital sign",
    error_add_vital_sign: "Unable to add vital sign",
    measure_placeholder: "Enter measure",
    unable_fetch_equipment: "Unable to fetch equipment",
    success_delete_equipment: "Equipment deleted successfully",
    error_delete_equipment: "Unable to delete equipment",
    create_equipment: "Create Equipment",
    vital_sign_equipments: "Vital Sign Equipments",
    add_equipment: "Add Equipment",
    update_equipment_success: "Equipment updated successfully",
    delete_equipment_success: "Equipment deleted successfully",
    error_update_equipment: "Unable to update equipment",
    delete_equipment_success: "Unable to delete equipment",
    value_placeholder: "Enter a value",
    value: "Value",
    date_and_time: "Date and Time",
    value_required: "Value is required",
    clubs: "Clubs",
    update_club_success: "Update club successfully",
    delete_club_success: "Delete club successfully",
    error_update_club: "Unable to update club",
    error_delete_club: "Unable to delete club",
    unable_fetch_clubs: "Unable to fetch club information",
    unable_fetch_clubs: "Unable to fetch club information",
    success_delete_club: "Club is successfully deleted",
    add_club: "New Club",
    search_by_name_or_donor_number: "Search by name or donor number",
    search_donor: "Search Donor",
    provide_donor_number_or_identity_doc_number:
      "Please provide donor number or identity doc number",
    identification_document_number: "Identification Document Number",
    identification_document_number_placeholder:
      "Enter national ID or passport number",
    optional: "Optional",
    donor_found: "Donor found(s)",
    donation_history: "Donation history",
    gender: "Gender",
    no_of_times: "No of times",
    unable_fetch_donor: "Unable to fetch donor information",
    view_donor_badge_card: "View Donation Card",
    my_donation_card: "My Donation Card",
    cancel_appointment: "Cancel Appointment",
    cancel_appointment_success: "Appointment has been cancelled successfully",
    error_cancel_appointment: "unable to cancel the appointment",
    delete_appointment_success: "Appointment has been deleted permanently",
    all: "All",
    pending: "Pending",
    approved: "Approved",
    cancelled: "Cancelled",
    confirm: "Confirm",
    cancel: "Cancel",
    ask_to_cancel_appointment:
      "Are your sure you want to cancel this appointment?",
    yes: "Yes",
    no: "No",
    new_appointment: "New Appointment",
    edit_appointment: "Update Appointment",
    delete: "Delete",
    update: "Update",
    request_date: "Request Date",
    view: "View",
    questionnaire: "Questionnaire",
    start_time: "Start Time",
    end_time: "End Time",
    any_donor_reaction: "Any Donor reaction?",
    used_equipment_id: "Used Equipment",
    suspend: "Suspend",
    blocked_donations: "Blocked Donations",
    block_permanent: "Block Permanent",
    barcode: "Barcode",
    approve: "Approve",
    add_equipment: "New Equipment",
    edit_equipment: "Edit Equipment",
    name_required: "Name is required",
    time: "Time",
    time_required: "Time is required",
    value_required: "Value is required",
    view_reason: "View Reason",
    members: "Members",
    active_donor: "Active Donor",
    active_member: "Active Member",
    unable_fetch_club_member: "Unable to fetch club members",
    success_delete_club_member: "Member successfully deleted",
    error_delete_club_member: "Unable to delete club member",
    add_data_success: "Data added successfully",
    update_data_success: "Data updated successfully",
    delete_data_success: "Data deleted successfully",
    error_update_data: "Error updating data",
    error_delete_data: "Error deleting data",
    error_add_data: "Error add data",
    add: "Add",
    or_search_by: "Or search by",
    change: "Change",
    block: "Block",
    error_exist_data: "Error exist data",
    reason_type_required: "Reason type is required",
    reason_required: "Reason is required",
    temporary: "Temporary",
    permanent: "Permanent",
    reason: "Reason",
    reason_type: "Reason type",
    block: "Block",
    unblock: "Unblock",
    refresh: "Refresh",
    time_required: "Time is required",
    eligible: "Eligible",
    comment: "Comment",
    comment_placeholder: "Enter a comment",
    comment_required: "Comment is required",
    more_search_options: "More Search Options",
    less_search_options: "Less Search Options",
    view_signs: "View Signs",
    fetch_data_error: "Error fetching data",
    scheduled_date: "Schedule Date",
    start_pre_assessment_donation: "Start pre assessment donation",
    go_back: "Go Back",
    unable_to_fetch_data: "Unable to fetch data",
    pre_assessment_donation: "Pre assessment donation",
    print_this_out: " Print this out",
    collections: "Collections",
    select_appointment: "Select Appointment",
    vital_sign_required: "Vital sign is required",
    no_vital_sign_selected: "No vital sign selected",
    date_time: "Date & Time",
    weightKg: "Weight Kg",
    // weightKg_placeholder: "Enter Weight KG",
    pulse_bt_min: "Pulse(bt/min)",
    hemoglobin_g_d: "Hemoglobin(g/d)",
    tot_protein: "Tot Protein",
    Hemotocrit_pc: "Hemotocrit(%)",
    temperature: "Temperature",
    unspecified: "unspecified",
    resident_permit: "Resident Permit",
    resident_permit_placeholder: "Enter Resident Permit",
    refugee_id: "Refugee ID",
    refugee_id_placeholder: "Enter Refugee ID",
    severe: "Severe",
    mild: "Mild",
    moderate: "Moderate",
    reaction_category: "Reaction Category",
    rwanda_national_id: "Rwanda National ID",
    rwanda_national_id_placeholder: "Enter Rwanda National ID",
    identityDocNumberType_required: "Identity Doc Number type is required",
    rwanda_national_id_number_required:
      "Rwnanda National ID number is required",
    invalid_rwanda_national_id_number: "Invalid Identity Number",
    identityDocNumberType: "Identity Document Type",
    component_separation: "Component Separation",
    inventory: "Inventory",
    component_transformation: "Component Transformation",
    labelling: "Labelling",
    production: "Production",
    testIS: "IS",
    repeat_one: "Repeat One",
    repeat_two: "Repeat Two",
    observation: "Observation",
    is_required: "is required",
    phone_number_invalid: "Phone number is invalid",
    phone_number: "Phone number",
    inventory: "Inventory",
    collection_type_required: "Collection type is required",
    control_panel: "Control Panel",
    collection_type: "Collection Type",
    collection_type_required: "Collection type is required",
    equipment_required: "Equipment type is required",
    reaction: "Reaction",
    reaction_category: "Reaction Category",
    reaction_category: "Reaction Category",
    reaction_comment: "Comment",
    end_time_required: "End Time is required",
    start_time_required: "Start time is required",
    tested_by: "Tested By",
    results: "Results",
    blood_test_results: "Blood Test Results",
    donation_number_required: "Donation Number is required",
    donation_number: "Donation Number",
    donation_number_placeholder: "Enter donation number",
    not_found: "Not found",
    search: "Search",
    result_found: "Result(s) Found",
    ineligible: "Ineligible",
    id: "ID",
    list_donors: "List of Donors",
    vital_signs: "Vital Signs",
    production_labeling: "Labeling",
    labeling: "Labeling",
    production_component_transformation: "Component Transformation",
    production_component_separation: "Component Separation",
    production_inventory: "Inventory",
    label: "Label",
    weight: "Weight",
    weight_placeholder: "Enter Weight",
    label_required: "Label is required",
    weight_required: "Weight is required",
    inventories: "Inventories",
    type_required: "Type is required",
    bagType_required: "Bag Type is required",
    type: "Type",
    bagType: "Bag Type",
    added_by: "Added By",
    no_inventory_found: "No inventory found",
    donor_required: "Donor is required",
    not_yet: "Not Yet",
    inventory_bar_code: "Inventory QRCode",
    component_transformation: "Component Transformation",
    inventory_required: "Inventory is required",
    requester_position: "Requester Position",
    request_reason: "Reason of Request",
    requester_hospital: "Hospital Requested",
    recipient_address: "Recipient Address",
    recipient_contact: "Recipient Contact",
    recipient_id_number: "Recipient Id Number",
    recipient_name: "Recipient Name",
    blood_recipient: "Blood Recipients",
    blood_usage: "Blood Usage",
    contact: "Contact",
    identity_number_placeholder: "Enter Identity Number",
    identity_number: "Identity Number",
    identity_number_required: "Identity Number is required",
    contact_required: "Contact is required",
    hospital_required: "Hospital is required",
    contact_placeholder: "Enter contact",
    address_placeholder: "Enter address",
    address: "Address",
    recipient: "Recipient",
    missing_recipient_id: "Missing recipient ID",
    data_submitted_success: "Data submitted successfully",
    separation: "Separation",
    transformation: "Transformation",
    used_equipment: "Used Equipment",
    appointment: "Appointment",
    appointment_required: "Appointment is required",
    blood_collection_sites: "Blood Collection Sites",
    blood_donor_representative: "Blood Donor Representative",
    blood_collection_sessions: "Blood Collection Sessions",
    blood_collection_units: "Blood Collection Units",
    blood_donation_gender: "Blood Donation by Gender",
    filter_centers: "Filter Centers",
    date_range: "Date Range",
    today: "Today",
    this_week: "This Week",
    this_month: "This Month",
    filter_by_date: "Filter By Date",
    packages_requested: "Packages Requested",
    close: "Close",
    requests_and_dispatch: "Requests & Dispatch",
    blood_requests: "Blood Requests",
    delivered: "Delivered",
    requester: "Requester",
    requester_name: "Requester Name",
    requester_contact: "Requester Contact",
    donation_by_gender: "Donation By Gender",
    donors_per_blood_group: "Donors per Blood Group",
    filter_blood_group: "Filter by Blood Group",
    only_for_center_site_account:
      "Only for the centers or center sites account",
    donor_category: "Donor Category",
    filter_donor_category: "Filter by Donor Category",
    new_donors: "New Donors",
    regular_donors: "Regular Donors",
    irregular_donors: "Irregular Donors",
    dropped_donors: "Dropped Donors",
    view_donors: "View Donors",
    no_data: "No Data",
    donation_blood_group_per_center: "Donation per Center",
    donation_blood_group_per_center_site: "Donation per Center Site",
    filter_center_site: "Filter Center Site",
    analytics_blood_collection_sites: "Analytics - blood collection sites",
    blocked_donor: "Blocked Donor",
    can_not_be_deleted: "Data can not be deleted",
    copy: "Copy",
    print: "Print",
    blocked: "Blocked",
    yes: "Yes",
    no: "No",
    no_access: "No Access",
    no_access_description: "Missing right access to view Blood test results",
    total_packages: "Total Package",
    available_volume: "Available Volume",
    patient_id_required: "Patient ID is required",
    patient_id: "Patient ID",
    patient_id_placeholder: "Enter patient ID",
    processing: "Processing",
    blood_request_missing: "Blood request is missing",
    processing_request: "Processing Request",
    processing_request_description:
      "Request to be processed and center receiving this request",
    requestee: "Requestee",
    receiver: "Receiver",
    request_packages: "Request Packages",
    dispatched: "Dispatched",
    last_updated: "Last Updated",
    requested_by: "Requested By",
    processed_by: "Processed By",
    distributor_required: "Distributor is required",
    choose_package: "Choose Package",
    requested_by: "Requested By",
    status_required: "Status is required",
    inventory_required: "Inventory is required",
    rejected: "Rejected",
    processed_packages: "Processed Packages",
    only_entity_or_hospital_account: "Only for entity or hospital account",
    entity_or_hospital: "Entity or Hospital",
    delivered_packages: "Delivered Packages",
    report: "Report",
    used_inventories: "Used Inventories",
    passport: "Passport",
    passport_placeholder: "Enter passport number",
    last_donation_site: "Last Donation Site",
    last_donation_site_placeholder: "Enter last donation site",
    all_fields: "All fields",
    are_mandatory: "are mandatory",
    donor_information: "Donor Information",
    place_of_birth: "Place of Birth",
    place_of_birth_placeholder: "Enter place of birth",
    last_donation_information: "Last donation information",
    donor_current_address: "Donor Current Address",
    survey: "Survey",
    civil_servant: "Civil Servant",
    self_employee: "Self Employee",
    other: "Other",
    donation_number: "Donation Number",
    enter_donation_number: "Enter donation number",
    reset_password_title: "Reset Password",
    new_password_required: "New password is required",
    confirm_password_required: "Enter your password again",
    different_password_required: "Password does not match",
    donor_category: "Donor Category",
    walk_in_donor: "Walk in donor",
    called_to_came_and_donate: "Called to come and donate",
    appointment_category_required: "Donor category is required",
    online_request: "Online Request",
    appointment_category: "Appointment Category",
    appointment_number: "Appointment Number",
    appointment_number_placeholder: "Enter appointment number",
    search_appointment_number: "Search appointment number...",
    vital_sign_number: "Vital Sign Number",
    vital_sign_number_required: "Vital sign number is required",
    phlebotomist: "Phlebotomist",
    phlebotomist_required: "Phlebotomist is required",
    donation_number_required: "Donation number is required",
    photo: "Photo",
    filters: "Filters",
    rwandan: "Rwandan",
    foreign: "Foreign",
    nationality_required: "Nationality is required",
    reset: "Reset",
    apply: "Apply",
    donor_worksheet: "Donor Worksheet",
    limit: "Limit",
    worksheet_type: "Worksheet Type",
    vital_sign_measurements: "Vital Sign Measurements",
    add: "Add",
    code_placeholder: "Enter code",
    code_required: "Code is required",
    blood_test_configurations: "Blood Test Configurations",
    pre_assessment_questions: "Pre-Assessment Questions",
    blood_grouping: "Blood Grouping",
    serology: "Serology",
    blood_test_blood_grouping: "Blood grouping",
    blood_test_serology: "Serology",
    blood_test_questionnaire: "Blood Test - Questionnaire",
    question_text: "Question Text",
    question_text_placeholder: "Enter Question text",
    question_text_required: "Question text required",
    answer_type: "Answer Type",
    answer_type_required: "Answer type is required",
    options: "Options",
    required: "Required",
    block_donation: "Block Donation",
    message_template: "Message Template",
    body_required: "Body is required",
    subject_required: "Subject is required",
    body: "Body",
    subject: "Subject",
    body_placeholder: "Enter message body",
    subject_placeholder: "Enter subject",
    configuration: "Configuration",
    configurations: "Configurations",
    donation_interval: "Donation Interval",
    collection_type_number: "Collection Type Number",
    collection_type_number_required: "Collection Type Number is required",
    collection_type_number_placeholder: "Enter collection type number",
    is_active: "Is Active",
    activate_donation: "Activate Donation",
    report_configurations: "Report Configurations",
    general_configurations: "General Configurations",
    period: "Period",
    access_roles: "Access Roles",
    period_required: "Period is required",
    type_required: "Type is required",
    access_role_required: "Access role is required",
    transportation_temperature_required:
      "Transportation temperature is required",
    transportation_temperature: "Transportation temperature",
    transportation_temperature: "Transportation temperature",
    transportation_temperature_placeholder: "Enter temperature temperature",
    center_site_code: "Center Site Code",
    abbreviation: "Abbreviation",
    abbreviation_placeholder: "Enter abbreviation",
    printing_enabled: "Printing Enabled",
    printing_enabled_blood_receipt_description:
      "Answer should be visible on the blood bag receipt",
    confirmation: "Confirmation",
    different_blood_test_title: "Confirm",
    different_blood_test_description:
      "Are you sure you want to confirm blood testing ",
    component_separation_profiles: "Component Separation Profiles",
    component_transformation_profiles: "Component Transformation Profiles",
    min_temperature: "Min Temperature",
    max_temperature: "Max Temperature",
    expiration_value_placeholder: "Enter expiration value",
    expiration_value: "Expiration value",
    expiration_value_required: "Expiration value is required",
    code: "Code",
    code_placeholder: "Enter code",
    component_name: "Component name",
    component_name_required: "Component name is required",
    component_name_placeholder: "Enter component name",
    expiration_type: "Expiration type",
    expiration_type_required: "Expiration type is required",
    expiration_value_required: "Expiration value is required",
    expiration_value: "Expiration Value",
    min_temperature: "Min Temperature",
    max_temperature: "Max Temperature",
    max_temperature_placeholder: "Max",
    min_temperature_placeholder: "Min",
    max_temperature_value_required: "Max temperature value is required",
    min_temperature_value_required: "Min temperature value is required",
    component_separation_profile: "Component Separation Profile",
    component_transformation_profile: "Component Transformation Profile",
    component_profile_required: "Component profile is required",
    component_profiles: "Component Profiles",
    drop_inventory_description:
      "Mark as the original inventory as fully separated",
    component: "Component",
    missing_donation: "Missing donation information",
    code_placeholder: "Enter code",
    donations: "Donations",
    center_sites: "Center Sites",
    confirmed_blood_test: "Confirmed blood test",
    confirm_blood_test_not_same:
      "Blood test provided are different to the previous blood test and will disActivate the donation",
    color: "Color",
    color_placeholder: "Enter color name or color code",
    components: "Components",
    profiles: "Profiles",
    component_required: "Component is required",
    result_component: "Result Components",
    view_all: "View All",
    centers_search_description: "Find out below centers where you can donate",
    sites_search_description: "Find out below sites where you can donate",
    center_missing: "Center is missing",
    back: "Back",
    reception: "Reception",
    components_and_profiles: "Components and Profiles",
    separation: "Separation",
    transformation: "Transformation",
    list: "List",
    min: "Min",
    max: "Max",
    min_required: "Minimum number is required",
    max_required: "Maximum number is required",
    min_placeholder: "Enter minimum",
    max_placeholder: "Enter maximum",
    temperature_required: "Temperature is required",
    decision: "Decision",
    rejected: "Rejected",
    quarantined: "Quarantined",
    active: "Active",
    donor_profile: "Donor Profile",
    search_by: "Search by",
    demography: "Demography",
    actions: "Action(s)",
    pre_assessments: "Pre-assessments",
    total_donation: "Total Donation",
    max: "Max",
    min: "Min",
    max_placeholder: "Enter max",
    min_placeholder: "Enter Min",
    label: "Label",
    value: "Value",
    general: "General",
    bag_status: "Weight Status",
    label_placeholder: "Enter label name",
    weight_status: "Weight Status",
    scan_now: "Scan Now",
    scan: "Scan",
    component_to_label: "Component Labeling",
    component_code: "Component Code",
    component_code_placeholder: "Search component code",
    serology_result: "Serology Result",
    blood_grouping_result: "Blood Grouping Result",
    component_labeling: "Component Labeling",
    code_required: "Code is required",
    component_production: "Component Production",
    generate_label: "Generate Label",
    patients: "Patients",
    patient: "Patient",
    blood_banks: "Blood Banks",
    blood_bank: "Blood Bank",
    patient_id_required: "Patient id is required",
    phlebotomy: "Phlebotomy",
    block_info: "Block Information",
    search_by_donation: "Search by donation",
    equipment_code: "Equipment Code",
    equipment_name: "Equipment Name",
    resident_address_placeholder: "Enter your address",
    resident_address: "Resident Address",
    last_donation_date: "Last Donation Date",
    in_case_donor_has_donated_previously:
      "In case donor has donated previously",
    total_collected: "Total Collected",
    total_received: "Total Received",
    total_processed: "Total Processed",
    total_rejected: "Total Rejected",
    donation_by_donation: "Donation by Donation",
    bulk_donation: "Bulk donation",
    separation_type: "Separation Type",
    donation_not_found: "Donation not found!",
    component_code: "Component Code",
    component_code_required: "Component code is required",
    splits: "Splits",
    splits_required: "Splits is required",
    filter_hospitals: "Filter Entity/Hospitals",
    product_required: "Product is required",
    product: "Product",
    request_number_required: "Request number is required",
    request_number: "Request Number",
    request_number_placeholder: "Enter Request Number",
    blood_bank_contact: "Blood Bank Contact",
    return_package: "Return package",
    reject_package: "Reject package",
    search_product: "Search Product",
    number_of_packages: "Number of Packages",
    requested_volume: "Requested Volume",
    product_info: "Product Info",
    given_volume: "Given Volume",
    given_number_of_packages: "Given Number of Packages",
    product_name: "Product Name",
    request_information: "Request Information",
    no_document: "No Document",
    start_date: "Start Date",
    end_date: "End Date",
    print_worksheet: "Print Worksheet",
    clinical_information: "Clinical Information/Diagnosis",
    clinical_information_placeholder: "Description",
    previously_transfused: "Previously Transfused",
    if_yes_when_last_transfused: "If yes, when last transfused",
    history_of_pregnancy: "History of pregnancy within the preceding 3 months",
    urgent: "Urgent",
    blood_component_needed_on: "Blood component needed on",
    sample_collection_date: "Sample collection date",
    product_code: "Product Code",
    product_request_history: "Packages Preparation History",
    preparation: "Preparation",
    pickup: "Pickup & Dispatch Blood Request Package",
    hospital_received_blood_request: "Hospital Receive Blood Request",
    confirm: "Confirm",
    select_and_confirm_pickup: "Select product and confirm pickup",
    received_blood_request: "Received blood request",
    patient_information: "Patient information",
    temperature_name: "Temperature name",
    storage_temperature: "Storage temperature",
    note: "Note",
    note_placeholder: "Enter note",
    test_required: "Test required",
    mismatch_blood_grouping: "Mismatch blood grouping",
    mismatch_serology: "Mismatch serology",
    view_my_profile: "View my profile",
    print_label_enabled: "Print Label Enabled",
    last_transfused: "Last Transfused",
    history_pregnancy: "History Pregnancy",
    blood_donation_tracking: "Blood Donation Tracking",
    simplifying_donation_process:
      "Simplifying the donation process, providing a clear and concise overview for effective monitoring.",
    donation_analytics: "Donation Analytics",
    comprehensive_analytics:
      "Comprehensive analytics on donation contribution and usage trends per blood centers and respective sites.",
    blood_testing_eligibility: "Blood Testing & Eligibility",
    personalized_feedback:
      "Personalized feedback on blood testing results and eligibility. Streamline donation process with quality monitoring.",
    lab_results: "Lab Result(s)",
    badge: "Badge",
    student: "Student",
    security_organ: "Security Organ",
    farmer: "Farmer",
    repeated_diarrhea: "Repeated Diarrhea",
    in_the_past_twelve_month_have_got: "In the past 12 month have you got",
    are_you_menstrual_period:
      "have you been in menstrual period within the last 7 days?",
    isEligible: "Is eligible",
    collection_date: "Collection Date",
    blood_donation_recorder: "Blood Recorder",
    new_donor: "New Donor",
    repeat_donor: "Repeat Donor",
    army: "Army",
    police: "Police",
    blood_grouping_tested: "Blood Grouping Tested",
    blood_grouping_tested_by: "Blood Grouping - Tested By",
    serology_tested: "Serology Tested",
    serology_tested_by: "Serology Tested By",
    blood_test_blood_grouping: "Blood Test Blood Grouping",
    blood_test_serology: "Blood Test Serology",
    total_pending: "Total Pending",
    total_tested: "Total Tested",
    blood_recorder: "Blood Recorder",
    selector: "Selector",
    blood_recorder_required: "Blood recorder is required",
  },
  french: {
    image: frenchImage,
  },
  kinyarwanda: {
    image: kinyarwandaImage,
  },
};

export default data;
