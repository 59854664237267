export const handleValidateMatching = ({
  that,
  field,
  value,
  questionnaireIndex,
}) => {
  let { error, questionnaire, misMatchFields, previousTestResults } =
    that.state;

  previousTestResults?.forEach((elv, i) => {
    Object.keys(elv).forEach((key) => {
      if (questionnaireIndex !== undefined && key === "questionnaire") {
        elv.questionnaire.forEach((qEl) => {
          if (
            qEl.questionText?.toUpperCase() === field?.toUpperCase() &&
            (qEl.answer?.value?.toUpperCase() || qEl.answer?.toUpperCase()) !==
              value?.toUpperCase()
          ) {
            questionnaire[questionnaireIndex]["error"] = "Mismatch";
            if (!misMatchFields.includes(qEl.questionText)) {
              misMatchFields.push(qEl.questionText);
            }
          }
        });
      } else if (
        key === field &&
        (elv[key]?.value
          ? elv[key]?.value?.toUpperCase()
          : elv[key]?.toUpperCase()) !==
          (value?.value ? value?.value?.toUpperCase() : value?.toUpperCase())
      ) {
        error[field] = "Mismatch";

        if (!misMatchFields.includes(field)) {
          misMatchFields.push(field);
        }
      }
    });
  });
  console.log(error);

  that.setState({ error });
};
