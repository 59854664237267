import React from "react";
import { connect } from "react-redux";
import { Input } from "../Input";
import { DateFilter, DepartmentFilter } from "../Filters";
import icons from "../../constants/icons";

class DashboardSearchHeader extends React.Component {
  render() {
    return (
      <div className="header-filter-container">
        <div className="header-filter-search-container">
          <div className="col-md-6">
            <Input
              leftIcon={icons.search}
              styles={{ margin: 0 }}
              placeholder="Search Employee ID, Request Number..."
            />
          </div>
        </div>
        <div className="filters-menus">
          <div>
            <DepartmentFilter />
          </div>
          <div>
            <DateFilter />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(DashboardSearchHeader);
