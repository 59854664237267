import "./layout.css";

const LandingPageLayout = (props) => {
  return (
    <div className={`landing-section ${props.className}`} id={props.id}>
      <div className="container">
        <div className="landing-section-content">
          <div className={`col-md-${props.size || 10}`}>{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default LandingPageLayout;
