import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../Input";
import language from "../../language";
import bloodGroups from "../../constants/bloodGroups";
import { Button } from "../Button";
import icons from "../../constants/icons";
import { fetchAllComponents } from "../../utils/queries/production/components";

class Package extends React.Component {
  state = {
    data: [],
    error: {},
    components: [],
  };

  componentDidMount() {
    this.getComponents(true);
    this.setState({
      data: this.props.data,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({ data: this.props.data });
    }
  }

  getComponents = async (isFetchingComponent, code) => {
    try {
      this.setState({
        isFetchingComponent,
      });

      const data = await fetchAllComponents(this.props.defaultLanguage, {
        inputType: "dropdown",
        code: code ? code : undefined,
      });

      this.setState({
        components: data,
        isFetchingComponent: false,
      });

      return data;
    } catch (error) {
      console.log(error);
      this.setState({ isFetchingComponent: false });
    }
  };

  onChangeText(field, index, e) {
    let { data, error } = this.state;

    delete error[field + "_" + index];

    if (field === "product") {
      data[index]["productName"] = e.name;
      data[index]["productCode"] = e.code;
    }

    data[index][field] = e.target ? e.target.value : e.value ? e.value : e;
    this.setState(
      {
        data,
      },
      () => {
        this.props.onSavePackages(data);
      }
    );
  }

  validateForm() {
    let { data, error } = this.state;

    if (data.length === 0) return;

    let currentIndex = data.length - 1;

    if (!data[currentIndex].product) {
      error["product_" + currentIndex] =
        language[this.props.defaultLanguage].product_required;
    }

    if (!data[currentIndex].volume) {
      error["volume_" + currentIndex] =
        language[this.props.defaultLanguage].volume_required;
    }

    if (!data[currentIndex].bloodGroup) {
      error["bloodGroup_" + currentIndex] =
        language[this.props.defaultLanguage].blood_group_required;
    }

    if (data[currentIndex].numberOfPackages === "") {
      error["numberOfPackages_" + currentIndex] =
        language[this.props.defaultLanguage].number_of_packages;
    }

    this.setState({ error });
  }

  handleAdd = async () => {
    await this.validateForm();

    let { data, error } = this.state;

    if (Object.keys(error).length === 0) {
      data.push({
        bloodGroup: null,
        numberOfPackages: "",
      });

      this.setState({ data });
    }
  };

  handleRemovePackage(index) {
    let { data } = this.state;

    data.splice(index, 1);

    this.setState({ data });
  }
  render() {
    return (
      <div>
        {this.state.data.map((el, i) => {
          return (
            <div style={{ marginBottom: "1.5rem" }}>
              <div className="row">
                <div className="col-md-12">
                  <div className="col-md-12">
                    <Select
                      loadOptions={(e) => this.getComponents(true, e)}
                      placeholder={language[this.props.defaultLanguage].select}
                      label={language[this.props.defaultLanguage].product}
                      required
                      value={
                        el.product
                          ? {
                              label: el.productCode + " " + el.productName,
                              value: el.product,
                            }
                          : null
                      }
                      onChange={(e) => this.onChangeText("product", i, e)}
                      error={this.state.error["product_" + i]}
                      isLoading={this.state.isFetchingComponent}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <Select
                    options={bloodGroups}
                    placeholder={language[this.props.defaultLanguage].select}
                    label={language[this.props.defaultLanguage].blood_group}
                    required
                    value={
                      el.bloodGroup
                        ? {
                            label: el.bloodGroup,
                            value: el.bloodGroup,
                          }
                        : null
                    }
                    onChange={(e) => this.onChangeText("bloodGroup", i, e)}
                    error={this.state.error["bloodGroup_" + i]}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    placeholder={0}
                    label={
                      language[this.props.defaultLanguage].number_of_packages
                    }
                    required
                    value={el.numberOfPackages}
                    onChange={(e) =>
                      this.onChangeText("numberOfPackages", i, e)
                    }
                    error={this.state.error["numberOfPackages_" + i]}
                    type="number"
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    placeholder={0}
                    label={language[this.props.defaultLanguage].volume}
                    required
                    value={el.volume}
                    onChange={(e) => this.onChangeText("volume", i, e)}
                    error={this.state.error["volume_" + i]}
                    type="number"
                  />
                </div>
                <div className="col-md-12">
                  <Input
                    label={language[this.props.defaultLanguage].note}
                    value={el.note}
                    onChange={(e) => this.onChangeText("note", i, e)}
                    error={this.state.error["note_" + i]}
                    textarea
                  />
                </div>
              </div>
              <Button
                icon={icons.delete}
                onPress={() => this.handleRemovePackage(i)}
                className="btn-default"
              />
            </div>
          );
        })}
        <div className="col-md-6">
          <Button
            text={language[this.props.defaultLanguage].add_package}
            onPress={() => this.handleAdd()}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Package);
