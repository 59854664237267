// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing-title-container {
  display: flex;
  margin-bottom: 4rem;
  flex-direction: column;
}

.landing-title-container.center {
  align-items: center;
}

.landing-title-container h1 {
  font-weight: var(--font-weight-md);
  font-size: 40px;
  color: var(--text-color);
  font-family: "Montagu Slab", serif;
}

.landing-title-container p {
  margin-top: 1.5rem;
}

.landing-title-container.light h1,
.landing-title-container.light p {
  color: var(--light-color);
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Landing/Title/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kCAAkC;EAClC,eAAe;EACf,wBAAwB;EACxB,kCAAkC;AACpC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;;EAEE,yBAAyB;AAC3B","sourcesContent":[".landing-title-container {\n  display: flex;\n  margin-bottom: 4rem;\n  flex-direction: column;\n}\n\n.landing-title-container.center {\n  align-items: center;\n}\n\n.landing-title-container h1 {\n  font-weight: var(--font-weight-md);\n  font-size: 40px;\n  color: var(--text-color);\n  font-family: \"Montagu Slab\", serif;\n}\n\n.landing-title-container p {\n  margin-top: 1.5rem;\n}\n\n.landing-title-container.light h1,\n.landing-title-container.light p {\n  color: var(--light-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
