import React from "react";
import WrapperLandingPageLayout from "../../../Layout/Landing/WrapperLandingPageLayout";
import PreAssessmentRegistration from "../../../components/Donors/Pre-assessment/PreAssessmentRegistration";
import { withRouter } from "../../../utils/withRouter";
import { Breadcrumb } from "../../../components/Breadcrumb";
import language from "../../../language";
import { connect } from "react-redux";

class PreAssessmentQuestionnaire extends React.Component {
  renderNavigation() {
    return (
      <Breadcrumb
        homeUrl={`${this.props.route}/home`}
        data={[
          {
            name: language[this.props.defaultLanguage].donors,
            url: `${this.props.route}/donors`,
          },
          {
            name: this.props.routeParams.donorName,
            url: `${this.props.route}/donors/${this.props.routeParams.donorId}/${this.props.routeParams.donorName}`,
          },
          {
            name: language[this.props.defaultLanguage].pre_assessment_donation,
            active: true,
          },
        ]}
      />
    );
  }
  render() {
    if (this.props.layoutEnabled) {
      return (
        <WrapperLandingPageLayout>
          {this.renderNavigation()}
          <PreAssessmentRegistration {...this.props.routeParams} />
        </WrapperLandingPageLayout>
      );
    }

    return (
      <div className="card-body">
        {this.renderNavigation()}
        <div className="row">
          <div className="col-md-8">
            <PreAssessmentRegistration
              route={this.props.route}
              {...this.props.routeParams}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(PreAssessmentQuestionnaire));
