import toastMessage from "../../../toastMessage";
import { getStorage } from "../../../storage";
import axios from "axios";
import language from "../../../../language";
import formatSelectData from "../../../formatSelectData";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

export const fetchComponentsTransformationProfiles = async (
  lng = "english",
  params
) => {
  try {
    const user = await getStorage();

    const options = {
      method: "GET",
      url: `${API_URL}/componentProfile`,
      params,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await axios(options);

    let { data, count } = queryRes.data;

    if (params.inputType === "dropdown") {
      return formatSelectData(
        queryRes?.data?.data || queryRes?.data,
        "name",
        "_id"
      );
    }

    return !params.page && !params.limit ? queryRes.data : { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};

export const deleteComponentsTransformationProfile = async (
  lng = "english",
  id
) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `${API_URL}/componentProfile`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await axios(options);

    toastMessage("success", language[lng].delete_data_success);
  } catch (error) {
    toastMessage("error", error);
  }
};
