import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import icons from "../../constants/icons";
import { getStorage } from "../../utils/storage";
import availableOptions from "../../constants/availableOptions";
import bloodCenterQuery from "../../utils/queries/bloodCenterQuery";
import bloodCenterSiteQuery from "../../utils/queries/bloodCenterSiteQuery";
import moment from "moment";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class NewCampaign extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: {},
      isSubmitting: false,
      centers: [],
      campaignDate: "",
      estimatedCollection: "",
      actualCollection: "",
      isActive: availableOptions[0],
      estimatedCollection: "",
    };
  }

  componentDidMount = async () => {
    await this.getCenters(true);

    this.setState({ ...this.props });

    if (this.props._id && this.props._id !== "") {
      this.setState({
        campaignDate: moment(this.props.campaignDate).format("YYYY-MM-DD"),
        center: {
          label: this.props?.center?.name,
          value: this.props?.center?._id,
        },
        site: {
          label: this.props?.centerSite?.name,
          value: this.props?.centerSite?._id,
        },
        isActive: {
          label: this.props?.isActive + "",
          value: this.props?.isActive + "",
        },
      });
    }
  };

  getCenters = async (isFetchingCenter) => {
    try {
      this.setState({
        isFetchingCenter,
      });

      const data = await bloodCenterQuery(this.props.defaultLanguage, {
        type: "dropdown",
      });

      this.setSelectedCenter(data);

      this.setState({
        centers: data,
        isFetchingCenter: false,
      });
    } catch (error) {
      this.setState({ isFetchingCenter: false });
    }
  };

  setSelectedCenter(campaigns) {
    const { centerSite } = this.props;
    if (centerSite) {
      const selectedCenter = campaigns.find((el) => el._id === centerSite._id);

      if (selectedCenter._id) {
        this.setState(
          {
            center: {
              label: selectedCenter.name,
              value: selectedCenter._id,
            },
          },
          () => {
            this.getSites(true, selectedCenter._id);
          }
        );
      }
    }
  }

  getSites = async (isFetchingSite, center) => {
    try {
      this.setState({
        isFetchingSite,
      });

      const data = await bloodCenterSiteQuery(this.props.defaultLanguage, {
        type: "dropdown",
        center,
      });

      this.setSelectedSite(data);

      this.setState({
        sites: data,
        isFetchingSite: false,
      });
    } catch (error) {
      this.setState({ isFetchingSite: false });
    }
  };

  setSelectedSite(sites) {
    const { site } = this.props;
    if (site) {
      const selectedSite = sites.find((el) => el._id === site._id);

      if (selectedSite._id) {
        this.setState({
          site: {
            label: selectedSite.name,
            value: selectedSite._id,
          },
        });
      }
    }
  }

  onChangeText(field, e) {
    let { error } = this.state;
    delete error[field];

    this.setState({
      [field]: e.target ? e.target.value : e,
      error,
    });

    if (field === "center") {
      this.getSites(true, e.value);
    }
  }

  validateForm() {
    let { error, site, center, campaignDate } = this.state;

    if (campaignDate === "") {
      error.campaignDate =
        language[this.props.defaultLanguage].campaign_date_required;
    }

    if (!site) {
      error.site = language[this.props.defaultLanguage].site_required;
    }

    if (!center) {
      error.center = language[this.props.defaultLanguage].center_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    const {
      error_add_campaign,
      error_update_campaign,
      add_campaign_success,
      update_campaign_success,
    } = language[this.props.defaultLanguage];

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let {
          _id,
          isActive,
          campaignDate,
          estimatedCollection,
          actualCollection,
          center,
          site,
        } = this.state,
        url = `${API_URL}/blooddonationcampaign`,
        method = "POST",
        user = await getStorage();

      let requestBody = {
        campaignDate,
        actualCollection,
        estimatedCollection,
        isActive: isActive.value,
        center: center.value,
        centerSite: site.value,
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        data: requestBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            method === "PUT" ? update_campaign_success : add_campaign_success
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage(
            "error",
            method === "PUT" ? error_update_campaign : error_add_campaign
          );

          this.setState({
            isSubmitting: false,
          });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Input
                label={language[this.props.defaultLanguage].campaign_date}
                required
                value={this.state.campaignDate}
                onChange={(e) => this.onChangeText("campaignDate", e)}
                error={this.state.error.campaignDate}
                type="date"
                min={moment().add("d", 1).format("YYY-MM-DD")}
              />
            </div>
            <div className="col-md-12">
              <Select
                options={this.state.centers}
                leftIcon={icons.campaign}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].center}
                required
                value={this.state.center}
                onChange={(e) => this.onChangeText("center", e)}
                error={this.state.error.center}
                isLoading={this.state.isFetchingCenter}
              />
            </div>
            <div className="col-md-12">
              <Select
                options={this.state.sites}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].site}
                required
                value={this.state.site}
                onChange={(e) => this.onChangeText("site", e)}
                error={this.state.error.site}
                isLoading={this.state.isFetchingSite}
              />
            </div>
            <div className="col-md-6">
              <Input
                placeholder={
                  language[this.props.defaultLanguage]
                    .estimated_collection_placeholder
                }
                label={
                  language[this.props.defaultLanguage].estimated_collection
                }
                value={this.state.estimatedCollection}
                onChange={(e) => this.onChangeText("estimatedCollection", e)}
                error={this.state.error.estimatedCollection}
                type="number"
              />
            </div>
            <div className="col-md-6">
              <Input
                placeholder={
                  language[this.props.defaultLanguage]
                    .actual_collection_placeholder
                }
                label={language[this.props.defaultLanguage].actual_collection}
                value={this.state.actualCollection}
                onChange={(e) => this.onChangeText("actualCollection", e)}
                error={this.state.error.actualCollection}
                type="number"
              />
            </div>
            <div className="col-md-12">
              <Select
                options={availableOptions}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].is_active}
                required
                value={this.state.isActive}
                onChange={(e) => this.onChangeText("isActive", e)}
                error={this.state.error.isActive}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewCampaign);
