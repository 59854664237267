// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge-default,
.badge-incoming,
.badge-pending {
  background-color: var(--text-color-10) !important;
  color: var(--text-color);
}

.badge-primary {
  background-color: var(--primary-color-10) !important;
  color: var(--primary-color);
}

.badge-cancelled,
.badge-failed,
.badge-rejected,
.badge-blocked {
  background-color: var(--error-color-10) !important;
  color: var(--error-color);
}

.badge-processing,
.badge-used,
.badge-USED {
  background-color: var(--info-color-10) !important;
  color: var(--info-color);
}

.badge-success,
.badge-approved {
  background-color: var(--success-color-10) !important;
  color: var(--success-color);
}

.badge-dispatched,
.badge-info {
  background-color: var(--info-color-10) !important;
  color: var(--info-color);
}
`, "",{"version":3,"sources":["webpack://./src/app/common/styles/badge.styles.css"],"names":[],"mappings":"AAAA;;;EAGE,iDAAiD;EACjD,wBAAwB;AAC1B;;AAEA;EACE,oDAAoD;EACpD,2BAA2B;AAC7B;;AAEA;;;;EAIE,kDAAkD;EAClD,yBAAyB;AAC3B;;AAEA;;;EAGE,iDAAiD;EACjD,wBAAwB;AAC1B;;AAEA;;EAEE,oDAAoD;EACpD,2BAA2B;AAC7B;;AAEA;;EAEE,iDAAiD;EACjD,wBAAwB;AAC1B","sourcesContent":[".badge-default,\r\n.badge-incoming,\r\n.badge-pending {\r\n  background-color: var(--text-color-10) !important;\r\n  color: var(--text-color);\r\n}\r\n\r\n.badge-primary {\r\n  background-color: var(--primary-color-10) !important;\r\n  color: var(--primary-color);\r\n}\r\n\r\n.badge-cancelled,\r\n.badge-failed,\r\n.badge-rejected,\r\n.badge-blocked {\r\n  background-color: var(--error-color-10) !important;\r\n  color: var(--error-color);\r\n}\r\n\r\n.badge-processing,\r\n.badge-used,\r\n.badge-USED {\r\n  background-color: var(--info-color-10) !important;\r\n  color: var(--info-color);\r\n}\r\n\r\n.badge-success,\r\n.badge-approved {\r\n  background-color: var(--success-color-10) !important;\r\n  color: var(--success-color);\r\n}\r\n\r\n.badge-dispatched,\r\n.badge-info {\r\n  background-color: var(--info-color-10) !important;\r\n  color: var(--info-color);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
