import React, { useEffect } from "react";
import { Route, Routes, Outlet, useParams } from "react-router-dom";
import { CenterLayout } from "../../Layout";
import { clearStorage, getStorage } from "../../utils/storage";
import userSidebarMenus from "../../constants/userSideMenus";
import PreAssessmentInformationScreen from "./PreAssessmentInformationScreen";
import PreAssessmentQuestionnaireScreen from "../common/Donor/PreAssessmentQuestionnaireScreen";
import HomeScreen from "./HomeScreen";
import DonorScreen from "../AdminDashboard/Donors/DonorScreen";
import { jwtDecode } from "jwt-decode";

const HomeDashboardScreen = () => {
  const routes = [
    ...userSidebarMenus(),
    {
      path: "/donors",
      element: <HomeScreen />,
    },
    {
      path: "/donors/:donorId",
      element: <HomeScreen />,
    },
    {
      path: "/donors/:donorId/:donorName",
      element: <HomeScreen />,
    },
    {
      path: "/blood-donation-assessment/*",
      element: <PreAssessmentQuestionnaireScreen route="user" />,
    },
    {
      path: "/donors/questionnaire",
      element: <PreAssessmentQuestionnaireScreen route="/user" />,
    },
    {
      path: "/donors/questionnaire/:donorId/:donorName",
      element: <PreAssessmentQuestionnaireScreen route="/user" />,
    },
    {
      path: "/donors/questionnaire/:donorId/:donorName/:preassessmentId",
      element: <PreAssessmentQuestionnaireScreen route="/user" />,
    },
    {
      path: "/donors/questionnaire/:donorId/:donorNumber/:donorName/:preassessmentId/:requestDate",
      element: <PreAssessmentQuestionnaireScreen route="/user" />,
    },
    {
      parentPath: "/user",
      path: "/donors/:donorId",
      element: <DonorScreen />,
    },
    {
      parentPath: "/user",
      path: "/donors/:donorId/:donorName",
      element: <DonorScreen />,
    },
    {
      parentPath: "/user",
      path: "/donors/questionnaire",
      element: <PreAssessmentQuestionnaireScreen />,
    },
    {
      parentPath: "/user",
      path: "/donors/questionnaire/:donorId/:donorName",
      element: <PreAssessmentQuestionnaireScreen />,
    },
    {
      parentPath: "/user",
      path: "/donors/questionnaire/:donorId/:donorName/:preassessmentId",
      element: <PreAssessmentQuestionnaireScreen route="/user" />,
    },
    {
      parentPath: "/user",
      path: "/donors/questionnaire/:donorId/:donorNumber/:donorName/:preassessmentId/:requestDate",
      element: <PreAssessmentQuestionnaireScreen route="/user" />,
    },
  ];

  const secondLevelRoutes = [];

  useEffect(() => {
    async function userInfo() {
      const user = await getStorage();

      if (!user.token) return (window.location.href = "/login");

      const decodeToken = jwtDecode(user.token);
      const now = new Date().getTime();

      if (decodeToken.exp > now) {
        clearStorage();
        window.location.href = "/login";
      }
    }

    userInfo();
  });

  return (
    <CenterLayout {...useParams()} sidebarMenus={routes}>
      <Routes>
        {[...routes, ...secondLevelRoutes].map((route, index) => {
          return (
            <>
              <Route key={index} path={route.path} element={route.element} />

              {route.menus && (
                <Route
                  key={index}
                  path={route.path + "/*"}
                  element={subMenusRoutes(route)}
                />
              )}
            </>
          );
        })}
        <Route
          path="/pre-assessment/:id"
          element={<PreAssessmentInformationScreen />}
        />
      </Routes>
      <Outlet />
    </CenterLayout>
  );
};

const subMenusRoutes = (route) => {
  return (
    <Routes>
      <Route path="/" element={route.element} />
      {route.menus.map((el, index) => {
        return (
          <>
            <Route key={index} path={el.path} element={el.element} />
          </>
        );
      })}
    </Routes>
  );
};

export default HomeDashboardScreen;
