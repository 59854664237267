// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumb-nav-container {
  display: flex;
  align-items: center;
  min-height: 60px;
  gap: 1.5rem;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.breadcrumb-item {
  display: flex;
  align-items: center;
  opacity: 0.8;
}

.breadcrumb-item a {
  color: var(--text-color-60);
  font-weight: 700;
}

.breadcrumb-item .breadcrumb-item-name {
  font-weight: 700;
}

.breadcrumb-item a:hover {
  text-decoration: underline;
}

ol {
  margin: 0;
  padding: 0;
}

.breadcrumb-item.active a {
  color: var(--primary-color);
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Breadcrumb/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,2BAA2B;EAC3B,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".breadcrumb-nav-container {\r\n  display: flex;\r\n  align-items: center;\r\n  min-height: 60px;\r\n  gap: 1.5rem;\r\n}\r\n\r\n.breadcrumb {\r\n  display: flex;\r\n  align-items: center;\r\n  margin-bottom: 0;\r\n}\r\n\r\n.breadcrumb-item {\r\n  display: flex;\r\n  align-items: center;\r\n  opacity: 0.8;\r\n}\r\n\r\n.breadcrumb-item a {\r\n  color: var(--text-color-60);\r\n  font-weight: 700;\r\n}\r\n\r\n.breadcrumb-item .breadcrumb-item-name {\r\n  font-weight: 700;\r\n}\r\n\r\n.breadcrumb-item a:hover {\r\n  text-decoration: underline;\r\n}\r\n\r\nol {\r\n  margin: 0;\r\n  padding: 0;\r\n}\r\n\r\n.breadcrumb-item.active a {\r\n  color: var(--primary-color);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
