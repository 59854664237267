import React from "react";
import { connect } from "react-redux";
import { Table } from "../Table";
import language from "../../language";
import toastMessage from "../../utils/toastMessage";
import { fetchVitalSignsQuery } from "../../utils/queries/vitalSignQuery";
import { Button } from "../Button";
import ReactToPrint from "react-to-print";
import Separator from "../Separator/Separator";

export const ComponentToPrint = React.forwardRef((props, ref) => {
  return (
    <>
      <div className="card-body" ref={ref}>
        <div className="noScreen">
          <div className="print-header-title text-primary">
            <h2>{language[props.defaultLanguage].vital_signs}</h2>
          </div>
          {props?.donor?._id && (
            <div className="d-flex">
              <div style={{ flex: 1 }}>
                <div className="print-header-item">
                  <p>{language[props.defaultLanguage].first_name}</p>
                  <p>
                    <b>{props.donor.firstName}</b>
                  </p>
                </div>

                <div className="print-header-item">
                  <p>{language[props.defaultLanguage].last_name}</p>
                  <p>
                    <b>{props.donor.lastName}</b>
                  </p>
                </div>

                <div className="print-header-item">
                  <p>{language[props.defaultLanguage].nationality}</p>
                  <p>
                    <b>{props.donor.nationality}</b>
                  </p>
                </div>
                <div className="print-header-item">
                  <p>{language[props.defaultLanguage].phone_number}</p>
                  <p>
                    <b>{props.donor.phoneNumber}</b>
                  </p>
                </div>
              </div>
              <div>
                <div className="print-header-item">
                  <p>{language[props.defaultLanguage].donor_number}</p>
                  <p>
                    <b>{props.donor.donorNumber}</b>
                  </p>
                </div>
                <div className="print-header-item">
                  <p>{props.donor.identityDocNumberType}</p>
                  <p>
                    <b>{props.donor.identityDocNumber}</b>
                  </p>
                </div>
              </div>
            </div>
          )}
          <hr />
        </div>
        <Table
          data={props.data}
          isLoading={props.isLoading}
          headers={props.returnTableHeaders}
        />
      </div>
    </>
  );
});

class ViewSingleDepartment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: {},
      csvData: [],
      data: props.examQuestionnaire,
      page: 1,
      limit: 1,
    };

    this.contentToPrint = React.createRef();
  }

  componentDidMount = async () => {
    if (this.props.signId && this.props.examQuestionnaire?.length === 0) {
      this.getSignInfo(true);
    }
  };

  getSignInfo = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, signId } = this.props;

      if (!signId) {
        return toastMessage(
          "error",
          language[defaultLanguage].no_vital_sign_selected
        );
      }

      this.setState({ isLoading });

      const { data } = await fetchVitalSignsQuery(defaultLanguage, {
        page,
        limit,
        id: signId,
      });

      this.setState({
        data,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });

      toastMessage("error", error.message || error);
    }
  };

  returnTableHeaders() {
    return [
      {
        title: language[this.props.defaultLanguage].measure,
        key: "measure.name",
      },
      {
        title: language[this.props.defaultLanguage].value,
        key: "value",
      },
      {
        title: language[this.props.defaultLanguage].equipment,
        key: "equipment.name",
      },
    ];
  }

  render() {
    return (
      <>
        <div style={{ padding: "1rem" }}>
          <ReactToPrint
            trigger={() => (
              <a className="btn btn-primary" href="#">
                {language[this.props.defaultLanguage].print}
              </a>
            )}
            content={() => this.contentToPrint}
          />
        </div>
        <ComponentToPrint
          ref={(el) => (this.contentToPrint = el)}
          isLoading={this.state.isLoading}
          data={this.state.data}
          returnTableHeaders={this.returnTableHeaders()}
          {...this.props}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ViewSingleDepartment);
