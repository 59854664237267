import React from "react";
import { connect } from "react-redux";
import { Inventories } from "../../../../components/Production/inventory";
import { withRouter } from "../../../../utils/withRouter";
import language from "../../../../language";
import { Tabs } from "../../../../components/Tabs";
import Separations from "../../../../components/Production/ComponentSeparation/Separations";
import { Transformations } from "../../../../components/Production/ComponentTransformation";
import accessMenu from "../../../../utils/accessMenu";
import ComponentLabeling from "../../../../components/Production/ComponentLabeling/Labeling";

class InventoryScreen extends React.Component {
  render() {
    let {
      canReadProductionInventory,
      canReadComponentSeparation,
      canReadComponentTransformation,
      canReadLabeling,
    } = accessMenu();
    let tabOptions = [];

    if (canReadProductionInventory) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].inventories,
        data: <Inventories {...this.props.routeParams} isReception />,
      });
    }

    if (canReadComponentSeparation) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].component_separation,
        data: <Separations {...this.props.routeParams} />,
      });
    }

    if (canReadComponentTransformation) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].component_transformation,
        data: <Transformations {...this.props.routeParams} />,
      });
    }

    if (canReadLabeling) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].component_to_label,
        data: <ComponentLabeling {...this.props.routeParams} />,
      });
    }
    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            {/* <Breadcrumb
              homeUrl="/dashboard/home"
              data={[
                {
                  name: language[this.props.defaultLanguage].inventories,
                  url: "/dashboard/production",
                },
                {
                  name: this.props.routeParams.donationId,
                  active: true,
                },
              ]}
            /> */}

            <Tabs options={tabOptions} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(InventoryScreen));
