import React from "react";
import { DropdownButton } from "../Button";

import { Loading } from "../Loader";
import icons from "../../constants/icons";
import { CardHeader } from "../Header";
import { connect } from "react-redux";
import language from "../../language";
import { Checkbox } from "../Input";
import { onAddFilter } from "../../actions/Filters";
import bloodGroups from "../../constants/bloodGroups";

let copyData = [];

class BloodGroupFilter extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 50,
  };

  componentDidMount = async () => {
    this.setState({
      data: bloodGroups,
    });
  };

  onCheck = async (el) => {
    let { filters } = this.props;
    let { bloodGroups = [] } = filters;

    const index = bloodGroups.findIndex((sEl) => sEl.value === el.value);

    if (index === -1) {
      bloodGroups.push(el);
    } else {
      bloodGroups.splice(index, 1);
    }

    await this.props.dispatch(
      onAddFilter({
        bloodGroups,
      })
    );

    this.props.handleApplyFilters && this.props.handleApplyFilters();
  };

  handleSearch(e) {
    const searchValue = e.target.value;
    this.setState({ searchValue });

    let results = [];
    copyData.forEach((el) => {
      if (
        JSON.stringify(el).toLowerCase().indexOf(searchValue.toLowerCase()) !==
        -1
      ) {
        results.push(el);
      }
    });

    this.setState({ data: results });
  }

  render() {
    return (
      <DropdownButton
        leftIcon={icons.bloodGroup}
        text={language[this.props.defaultLanguage].filter_blood_group}
        className="btn-transparent btn-bordered w-sm-100 btn-sm"
        selectedData={this.props?.filters?.bloodGroups}
        clickOutside
      >
        <CardHeader
          title={language[this.props.defaultLanguage].blood_group}
          borderBottom
          closeButton
          isSearch
        />
        <div className="dropdown-content">
          {this.state.isLoading ? (
            <center>
              <Loading />
            </center>
          ) : (
            this.state?.data?.map((el, i) => {
              return (
                <Checkbox
                  label={el.label}
                  key={i}
                  onCheck={() => this.onCheck(el)}
                  checked={this.props?.filters?.bloodGroups?.find(
                    (sEl) => sEl.value === el.value
                  )}
                />
              );
            })
          )}
        </div>
      </DropdownButton>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(BloodGroupFilter);
