import React from "react";
import { connect } from "react-redux";
import { Select, Input } from "../Input";

class TestForm extends React.Component {
  render() {
    const props = this.props;

    const inputProps = {
      label: props.questionText,
      placeholder: props.placeholder,
      value: props.answer,
      onChange: props.onChange,
      required: props.required,
      error: props.error,
      type: props.type,
      disabled: props.disabled,
    };

    let inputContentStyle = {};
    let settings = props?.settings?.[props?.answer?.value || props.answer];

    if (props?.settings && settings) {
      inputContentStyle = {
        borderColor: settings.color,
      };
    }

    return (
      <>
        {props.answerType === "multipleChoice" ? (
          <Select
            options={props.options}
            {...inputProps}
            inputContentStyle={inputContentStyle}
          />
        ) : (
          <Input {...inputProps} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(TestForm);
