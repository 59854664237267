import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Select } from "../Input";
import { Button } from "../Button";
import bloodGroups from "../../constants/bloodGroups";
import { getStorage } from "../../utils/storage";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class BloodGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      bloodGroup: bloodGroups.find((el) => el.value === props.bloodGroup),
      error: {},
    };
  }

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];
    this.setState({
      [field]: e,
    });
  }

  onSubmit = async () => {
    await this.validateForm();

    let url = `${API_URL}/bloodtest`,
      user = await getStorage(),
      { testId } = this.props,
      { bloodGroup } = this.state;

    if (!testId) return;

    this.setState({
      isSubmitting: true,
    });

    let requestedBody = {
      id: testId,
      bloodGroup: bloodGroup.value,
    };

    const options = {
      method: "PUT",
      url,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
      data: requestedBody,
    };

    axios(options)
      .then((data) => {
        this.setState({
          isSubmitting: false,
        });

        toastMessage(
          "success",
          language[this.props.defaultLanguage].update_bloodtest_success
        );
      })
      .catch((error) => {
        this.setState({ isSubmitting: false });
        toastMessage("error", error);
      });
  };
  render() {
    return (
      <div className="card">
        <div className="card-body">
          <h3>{language[this.props.defaultLanguage].blood_group}</h3>
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}
          >
            {this.props.edit ? (
              <div style={{ flex: 1 }}>
                <Select
                  options={bloodGroups}
                  value={this.state.bloodGroup}
                  error={this.state.error.bloodGroup}
                  onChange={(e) => this.onChangeText("bloodGroup", e)}
                />
                <Button
                  className="btn-sm"
                  text={language[this.props.defaultLanguage].update}
                />
              </div>
            ) : (
              <div style={{ flex: 1 }}>
                <span>{this.props.bloodGroup}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;

  return { defaultLanguage };
};

export default connect(mapStateToProps)(BloodGroup);
