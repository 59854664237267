import accessMenu from "../../../utils/accessMenu";
import language from "../../../language";
import icons from "../../../constants/icons";

export const requestActionMenus = ({ that, props }) => {
  const {
    canReadBloodRequestAccess,
    canReceiveBloodRequestAccess,
    canHospitalReceiveBloodRequestAccess,
    canBloodBankReceiveBloodRequestAccess,
    canDistributorReceiveBloodRequestAccess,
    canReturnBloodRequestAccess,
  } = accessMenu();
  const actionMenus = [];
  if (canReadBloodRequestAccess && that) {
    actionMenus.push({
      name: language[props.defaultLanguage].view,
      icon: icons.eye_on,
      type: "view",
      onPress: (item) =>
        that.handleShowModal("showRequestInfoModal", item.requestNo, item),
    });
  }

  if (canBloodBankReceiveBloodRequestAccess) {
    actionMenus.push({
      name: language[props.defaultLanguage].preparation,
      icon: icons.preparation,
      type: "preparation",
      onPress: (item) =>
        (window.location.href = `/dashboard/request&dispatch/blood-bank/preparation/${item._id}`),
    });
  }

  if (canDistributorReceiveBloodRequestAccess) {
    actionMenus.push({
      name: language[props.defaultLanguage].pickup,
      icon: icons.dispatch,
      type: "pickup",
      onPress: (item) =>
        (window.location.href = `/dashboard/request&dispatch/distributor/pickup/${item._id}`),
    });
  }

  if (canHospitalReceiveBloodRequestAccess) {
    actionMenus.push({
      name: language[props.defaultLanguage].hospital_received_blood_request,
      icon: icons.package,
      type: "delivered",
      onPress: (item) =>
        (window.location.href = `/dashboard/request&dispatch/hospital/receive/${item._id}`),
    });
  }

  if (canReturnBloodRequestAccess) {
    actionMenus.push({
      name: language[props.defaultLanguage].return_package,
      icon: icons.return,
      type: "returning",
      onPress: (item) =>
        (window.location.href = `/dashboard/request&dispatch/returned/${item._id}`),
    });
  }

  if (canReceiveBloodRequestAccess) {
    actionMenus.push({
      name: language[props.defaultLanguage].reject_package,
      icon: icons.delete,
      type: "reject",
      onPress: (item) =>
        (window.location.href = `/dashboard/request&dispatch/rejected/${item._id}`),
    });
  }
  return actionMenus;
};
