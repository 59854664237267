import React from "react";

import { Login } from "../../../components/AdminLogin";

class LoginScreen extends React.Component {
  render() {
    return (
      <>
        <Login />
      </>
    );
  }
}

export default LoginScreen;
