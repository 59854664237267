import { Provinces, Districts, Sectors, Cells, Villages } from "rwanda";
import toastMessage from "./toastMessage";

export const getProvinces = () => {
  try {
    return (
      Provinces().map((el) => {
        return {
          label: el,
          value: el,
        };
      }) || []
    );
  } catch (error) {
    toastMessage("error", error);
  }
};

export const getDistricts = (province) => {
  try {
    return (
      Districts(province).map((el) => {
        return {
          label: el,
          value: el,
        };
      }) || []
    );
  } catch (error) {
    toastMessage("error", error);
  }
};

export const getSectors = (province, district) => {
  try {
    if (!province || !district) return;
    return Sectors(province, district).map((el) => {
      return {
        label: el,
        value: el,
      };
    });
  } catch (error) {
    toastMessage("error", error);
  }
};

export const getCells = (province, district, sector) => {
  try {
    if (!province || !district || !sector) return;
    return (
      Cells(province, district, sector)?.map((el) => {
        return {
          label: el,
          value: el,
        };
      }) || []
    );
  } catch (error) {
    toastMessage("error", error);
  }
};

export const getVillages = (province, district, sector, cell) => {
  try {
    if (!province || !district || !sector || !cell) return;
    return (
      Villages(province, district, sector, cell).map((el) => {
        return {
          label: el,
          value: el,
        };
      }) || []
    );
  } catch (error) {
    toastMessage("error", error);
  }
};
