import React from "react";
import { connect } from "react-redux";
import { Barcode } from "../Barcode";
import { fetchInventoryData } from "../../utils/queries/production/inventoryQuery";
import { Loading } from "../Loader";
import { Empty } from "../Empty";
import language from "../../language";
class BarCode extends React.Component {
  state = {
    data: {},
    error: {},
  };
  componentDidMount = async () => {
    await this.getData(true);
  };

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, donationId } = this.props;

      this.setState({ isLoading });

      const { data } = await fetchInventoryData(defaultLanguage, {
        page,
        limit,
        donation: donationId,
      });

      this.setState({
        data: data.length > 0 ? data[0] : {},
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    return (
      <div className="card-body">
        <h3>{language[this.props.defaultLanguage].inventory_bar_code}</h3>
        <div>
          {this.state.isLoading ? (
            <div
              style={{
                height: 200,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Loading />
            </div>
          ) : this.state.data._id ? (
            <Barcode
              info={{
                donation: this.state.data.donation._id,
                donor: this.state.data.donation.donor,
                donationStartTime: this.state.data.donation.startTime,
                donationEndTime: this.state.data.donation.endTime,
                donationCollectedBy: this.state.data.donation.collectedBy,
                bloodGroup: this.state.data.bloodGroup,
                inventoryId: this.state.data.bloodGroup,
                weight: this.state.data.bloodGroup,
                status: this.state.data.status,
                bagType: this.state.data.bloodGroup,
                center: this.state.data.center.name,
                centerSite: this.state.data.centerSite.name,
                label: this.state.data.label,
                equipment: this?.state?.data?.equipment?.name,
                expirationDate: this.state.data.expirationDate,
              }}
            />
          ) : (
            <Empty title={language[this.props.defaultLanguage].not_yet} />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;

  return { defaultLanguage };
};

export default connect(mapStateToProps)(BarCode);
