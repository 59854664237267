import React from "react";
import { connect } from "react-redux";
import { Sites } from "../../../components/Sites";
import { Tabs } from "../../../components/Tabs";
import language from "../../../language";
import { withRouter } from "../../../utils/withRouter";
import { Donors } from "../../../components/Donors";
import { Commitees } from "../../../components/Committee";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { DashboardHeader } from "../../../components/Header";
class CenterScreen extends React.Component {
  render() {
    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <Breadcrumb
              homeUrl="/dashboard/home"
              data={[
                {
                  name: language[this.props.defaultLanguage].centers,
                  url: "/dashboard/centers",
                },
                {
                  name: this.props.routeParams.centerName,
                  active: true,
                },
              ]}
            />
            <DashboardHeader title={this.props.routeParams.centerName} />
            <Tabs
              options={[
                {
                  title: language[this.props.defaultLanguage].sites,
                  data: <Sites centerId={this.props.routeParams.centerId} />,
                },
                {
                  title: language[this.props.defaultLanguage].committee,
                  data: (
                    <Commitees centerId={this.props.routeParams.centerId} />
                  ),
                },
                {
                  title: language[this.props.defaultLanguage].donors,
                  data: <Donors centerId={this.props.routeParams.centerId} />,
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(CenterScreen));
