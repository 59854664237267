import icons from "../../constants/icons";
import { Input } from "../Input";
import "./styles.css";

const CardHeader = (props) => {
  return (
    <div>
      <div
        className={`card-header-container ${
          props.borderBottom ? "border-bottom" : ""
        }`}
      >
        <div style={{ flex: 1 }}>
          <h3>{props.title}</h3>
        </div>
        <div className="card-header-action-btn">
          {props.closeButton && (
            <div className="icon-container">
              <span className={`${process.env.REACT_APP_ICONS_TYPE}`}>
                {icons.close}
              </span>
            </div>
          )}
          {props.expandMore && (
            <div className="icon-container">
              <span className={`${process.env.REACT_APP_ICONS_TYPE}`}>
                {icons.chevronDown}
              </span>
            </div>
          )}
        </div>
      </div>
      {props.isSearch && (
        <div style={{ padding: "0 0.8rem" }}>
          <Input
            placeholder="Search..."
            leftIcon={icons.search}
            value={props.searchValue}
            onChange={props.handleSearch}
          />
        </div>
      )}
    </div>
  );
};

export default CardHeader;
