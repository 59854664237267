import language from "../language";

export default (lng = "english") => [
  {
    label: language[lng].mild,
    value: "mild",
  },
  {
    label: language[lng].moderate,
    value: "moderate",
  },
  {
    label: language[lng].severe,
    value: "severe",
  },
];
