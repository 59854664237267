import React from "react";
import { connect } from "react-redux";
import language from "../../../language/index.js";
import { Modal } from "../../Modal/index.js";
import { CSVLink } from "react-csv";
import Table from "../../Table/Table.js";
import ListDistributor from "../Dispatch/Distributor/ListDistributor.js";
import { fetchBloodRequestProcessedHistory } from "../../../utils/queries/request/bloodRequestProcessedHistory.js";
import toastMessage from "../../../utils/toastMessage.js";

class Preparation extends React.Component {
  state = {
    error: {},
    distributors: [],
    data: [],
    csvData: [],
  };

  componentDidMount = async () => {
    this.getRequestProductHistory(true);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.refresh !== this.props.refresh) {
      this.getRequestProductHistory(true);
    }
  }

  getRequestProductHistory = async (isFetchingHistory) => {
    try {
      this.setState({
        isFetchingHistory,
      });

      const { requestId } = this.props;

      const data = await fetchBloodRequestProcessedHistory(
        this.props.defaultLanguage,
        {
          bloodRequest: requestId,
        }
      );
      this.setState({
        requestProductHistory: data,
        isFetchingHistory: false,
      });
    } catch (error) {
      this.setState({ isFetchingHistory: false });

      toastMessage("error", error);
    }
  };

  handleCloseModal(modal) {
    this.setState({ [modal]: false });
  }

  handleShowModal(modal) {
    this.setState({ [modal]: true });
  }

  render() {
    return (
      <div>
        <Table
          data={this.state.requestProductHistory}
          isLoading={this.state.isFetchingHistory}
          headers={[
            {
              title: language[this.props.defaultLanguage].request_number,
              key: "bloodRequest.requestNo",
            },
            {
              title: language[this.props.defaultLanguage].product_name,
              key: "product.name",
              styles: {
                textTransform: "uppercase",
              },
            },
            {
              title: language[this.props.defaultLanguage].product_code,
              key: "product.code",
              styles: {
                textTransform: "uppercase",
              },
            },
            {
              title:
                language[this.props.defaultLanguage].given_number_of_packages,
              key: "numberOfPackages",
            },
            {
              title: language[this.props.defaultLanguage].given_volume + "(ML)",
              key: "volume",
            },

            {
              title: language[this.props.defaultLanguage].processed_by,
              key: "processedBy.email",
            },
            {
              title: language[this.props.defaultLanguage].status,
              key: "status",
              type: "status",
            },
          ]}
        />
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
          size="lg"
        ></Modal>

        <CSVLink
          ref="csvDownload"
          filename={"REQUESTS-PREPARATION-HISTORY" + "-" + this.props.requestId}
          data={this.state.csvData}
        ></CSVLink>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Preparation);
