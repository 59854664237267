export default (that, { copyData, value }) => {
  that.setState({ search_text: value });

  let data = [];

  for (let el of copyData) {
    if (JSON.stringify(el).toLowerCase().indexOf(value.toLowerCase()) !== -1) {
      data.push(el);
    }
  }

  that.setState({ data });
};
