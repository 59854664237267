import language from "../language";
import icons from "./icons";
import HomeScreen from "../screens/User/HomeScreen";
import SettingScreen from "../screens/User/SettingScreen";
import PreAssessmentInformationScreen from "../screens/User/PreAssessmentInformationScreen";
import AppointmentsScreen from "../screens/User/AppointmentsScreen";

const userSidebarMenus = (lng = "english") => {
  return [
    {
      parentPath: "/user",
      path: "/home",
      name: language[lng].home,
      icon: icons.home,
      element: <HomeScreen />,
      apiActions: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _DELETE: [],
      },
      operations: [{ label: "Read", value: "read" }],
    },
    {
      parentPath: "/user",
      path: "/appointments",
      name: language[lng].appointments,
      icon: icons.calendar,
      element: <AppointmentsScreen route="/user" />,
      apiActions: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _DELETE: [],
      },
      operations: [{ label: "Read", value: "read" }],
    },
    {
      parentPath: "/user",
      path: "/pre-assessment",
      name: language[lng].preassessment,
      icon: icons.clinicalNote,
      element: <PreAssessmentInformationScreen route="/user" />,
      apiActions: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _DELETE: [],
      },
      operations: [{ label: "Read", value: "read" }],
    },
    {
      parentPath: "/user",
      path: "/settings",
      name: language[lng].settings,
      icon: icons.setting,
      element: <SettingScreen />,
      apiActions: {
        _POST: [],
        _GET: [],
        _PUT: [],
        _DELETE: [],
      },
      operations: [{ label: "Read", value: "read" }],
    },
  ];
};

export default userSidebarMenus;
