import jsPDF from "jspdf";
import "jspdf-autotable";
import JsBarcode from "jsbarcode";
import { createCanvas } from "canvas";

// Function to generate barcode as a base64 image
function generateBarcode(data) {
  const canvas = createCanvas();
  JsBarcode(canvas, data, {
    format: "CODE128",
    displayValue: false,
    height: 30,
  });
  return canvas.toDataURL("image/png");
}

export default async function generateABODonorWorksheet({
  center = "",
  centerSite = "",
  headers,
  data,
  fileName,
}) {
  const doc = new jsPDF("portrait", "pt", "A4");
  const marginLeft = 40;

  // Set font size for the title
  //   doc.setFontSize(15);
  //   doc.text("CNTS Rwanda, Kigali", marginLeft, 30);
  doc.setFontSize(12);
  doc.text("Virology Screening", marginLeft, 50);
  doc.setFontSize(10);
  doc.text(
    `${center ? center + "-" : ""}${centerSite ? centerSite : ""}`,
    marginLeft,
    70
  );

  // Generate barcodes for each donation number
  const barcodes = await Promise.all(
    data.map((row) => generateBarcode(row.donationNumber))
  );

  // Define columns for autoTable
  const autoTableColumns = headers.map((header) => ({
    header: header.title,
    dataKey: header.key,
  }));

  // Adding the table
  doc.autoTable({
    startY: 100,
    head: [autoTableColumns.map((col) => col.header)],
    body: data.map((row) => {
      return headers.map((col) => {
        console.log(row[col.key] || "");
        // if (col.key === "donationNumber") {
        //   console.log(row.donorName);
        //   return {
        //     content: row.donorName,
        //     styles: { halign: "left", valign: "middle" },
        //   };
        // }
        return row[col.key] || "";
      });
    }),
    didDrawCell: (data) => {
      const colIndex = data.column.index;
      if (colIndex === 0 && data.cell.section === "body") {
        const barcodeImage = barcodes[data.row.index];
        const cellWidth = data.cell.width;
        const cellHeight = data.cell.height;
        const imgWidth = cellWidth - 10; // Adjust width for padding
        const imgHeight = cellHeight - 10; // Adjust height for padding
        const imgX = data.cell.x + 5; // Padding from left
        const imgY = data.cell.y + (cellHeight - imgHeight) / 2; // Centering vertically

        // Ensure the barcode image is added correctly
        if (barcodeImage) {
          doc.addImage(barcodeImage, "PNG", imgX, imgY, imgWidth, imgHeight);
        } else {
          console.error(
            `Barcode image is not available for row index: ${data.row.index}`
          );
        }
      }
    },
    styles: { halign: "left", cellWidth: "wrap" },
    headStyles: { fillColor: ["#ac0207"] },
    theme: "grid",
    columnStyles: {
      0: { cellWidth: 120 }, // Width for Donor No column
      1: { cellWidth: 120 }, // Width for ABO Rh Group column
      2: { cellWidth: 80 },
      3: { cellWidth: 120 },
    },
  });

  // Footer details
  doc.setFontSize(8);
  doc.text("CNTS Kigali", marginLeft, doc.internal.pageSize.height - 30);
  doc.text(
    `Generated on: ${new Date().toLocaleDateString()}`,
    doc.internal.pageSize.width - 150,
    doc.internal.pageSize.height - 30
  );
  doc.text(
    `Page ${doc.internal.getNumberOfPages()}`,
    doc.internal.pageSize.width - 40,
    doc.internal.pageSize.height - 30
  );

  doc.save(fileName);
}
