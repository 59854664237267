import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import "./styles.css";
import { Link } from "react-router-dom";

class LoginTabs extends React.Component {
  render() {
    return (
      <div className="login-tabs-container">
        <Link
          to="/login"
          className={`login-tabs-item ${
            this.props.active === "donor" ? "active" : ""
          }`}
        >
          <span class={process.env.REACT_APP_ICONS_TYPE}>person</span>
          <span> {language[this.props.defaultLanguage].donor_login}</span>
        </Link>
        <Link
          to="/admin-login"
          className={`login-tabs-item ${
            this.props.active === "admin" ? "active" : ""
          }`}
        >
          <span class={process.env.REACT_APP_ICONS_TYPE}>shield_person</span>
          <span> {language[this.props.defaultLanguage].admin_login}</span>
        </Link>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(LoginTabs);
