import SlideItem from "./SlideItem";
import Carousel from "react-grid-carousel";

export default (props) => {
  return (
    <Carousel
      cols={1}
      rows={1}
      gap={20}
      showDots
      hideArrow
      loop
      scrollSnap
      autoplay={8000}
      spaceBetween={32}
      grabCursor
      centererSlides
      dotColorActive="#AC0207"
    >
      {props.data.map((item, index) => {
        return (
          <Carousel.Item key={index}>
            <SlideItem {...item} />
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};
