import React from "react";
import { Checkbox } from "../../Input";
import { connect } from "react-redux";
import language from "../../../language";

class Acknowledgment extends React.Component {
  render() {
    return (
      <>
        <div className="acknowledgment-container">
          <Checkbox
            title={language[this.props.defaultLanguage].acknowledgment_title}
            label={
              language[this.props.defaultLanguage].acknowledgment_description
            }
            checked={this.props.isAgree}
            error={this.props.error.isAgree}
            onCheck={() => this.props.handleCheck("isAgree")}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Acknowledgment);
