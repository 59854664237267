import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import { Introduction } from "../../components/Landing/Introduction";
import { HowWorks } from "../../components/Landing/HowWorks";
import { Footer } from "../../components/Landing/Footer";
import LandingPageLayout from "../../Layout/Landing/LandingPageLayout";
import { WhoDonate } from "../../components/Landing/WhoDonate";
import { WhereDonate } from "../../components/Landing/WhereDonate";
import { Partners } from "../../components/Landing/Partners";
import { ContactUs } from "../../components/Landing/ContactUs";

class LandingScreen extends React.Component {
  render() {
    return (
      <>
        <Navbar {...this.props} isFixed isMenuLight isTransparent />
        <div className="main-landing-container" id="landingContainer">
          <Introduction />
          <LandingPageLayout id="howWorks">
            <HowWorks />
          </LandingPageLayout>
          <LandingPageLayout className="light" id="giveBlood">
            <WhoDonate />
          </LandingPageLayout>
          <LandingPageLayout className="dark-primary" id="findSites">
            <WhereDonate />
          </LandingPageLayout>
          <LandingPageLayout className="half-height light" id="partners">
            <Partners />
          </LandingPageLayout>
          <LandingPageLayout id="contactUs">
            <ContactUs />
          </LandingPageLayout>
          <Footer />
        </div>
      </>
    );
  }
}

export default LandingScreen;
