import React from "react";
import donorsQuery from "../../utils/queries/donorsQuery";
import { connect } from "react-redux";
import "./styles.css";
import toastMessage from "../../utils/toastMessage";
import language from "../../language";
import { getStorage } from "../../utils/storage";
import UserImg from "../../assets/userPhotoPlaceholder.png";
import { Loading } from "../Loader";
import { getDonorBadgeInfo } from "../../utils/bloodGroupInfo";
import { Link } from "react-router-dom";
import { Modal } from "../Modal";
import DonorBadge from "./DonorBadge/DonorBadge";
import { Input } from "../Input";
import Separator from "../Separator/Separator";
import moment from "moment";

class DonorProfile extends React.Component {
  state = {
    isLoading: true,
    data: {},
    registrationQuestionnaire: [],
    numberOfTimes: 0,
  };

  componentDidMount() {
    this.getData(true);
  }

  getData = async (isLoading) => {
    try {
      const user = await getStorage();
      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const data = await donorsQuery(defaultLanguage, {
        page,
        limit,
        id: this.props.donorId || user.id,
      });

      let res = {};

      if (data?.length > 0) {
        res = data[0];
      }

      this.setState({
        data: res,
        registrationQuestionnaire: res.registrationQuestionnaire || [],
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      toastMessage(
        "error",
        language[this.props.defaultLanguage].unable_fetch_donor
      );
    }
  };

  renderProfileInfo() {
    const data = this.state.data;

    if (!data._id) return <></>;
    const residentAddress = `${this.state.data.province || ""} ${
      this.state.data.district || ""
    } ${this.state.data.sector || ""} ${this.state.data.cell || ""} ${
      this.state.data.village || ""
    }`;
    return (
      <div className="donor-profile-info-container">
        <div className="row">
          <div className="col-md-4">
            <Input
              disabled
              value={this.state.data.donorNumber}
              label={language[this.props.defaultLanguage].donor_number}
            />
          </div>
          <div className="col-md-4">
            <Input
              disabled
              value={this.state.data.firstName}
              label={language[this.props.defaultLanguage].first_name}
            />
          </div>
          <div className="col-md-4">
            <Input
              disabled
              value={this.state.data.lastName}
              label={language[this.props.defaultLanguage].last_name}
            />
          </div>
          <div className="col-md-4">
            <Input
              disabled
              value={this.state.data.gender}
              label={language[this.props.defaultLanguage].gender}
            />
          </div>
          <div className="col-md-4">
            <Input
              disabled
              value={this.state.data.dateOfBirth}
              label={language[this.props.defaultLanguage].date_of_birth}
            />
          </div>
          <div className="col-md-4">
            <Input
              disabled
              value={this.state.data.nationality}
              label={language[this.props.defaultLanguage].nationality}
            />
          </div>
          <div className="col-md-4">
            <Input
              disabled
              value={residentAddress}
              label={language[this.props.defaultLanguage].resident_address}
            />
          </div>
          <div className="col-md-4">
            <Input
              disabled
              value={this.state.data.email}
              label={language[this.props.defaultLanguage].email_address}
            />
          </div>
          <div className="col-md-4">
            <Input
              disabled
              value={this.state.data.phoneNumber}
              label={language[this.props.defaultLanguage].phone_number}
            />
          </div>
          <div className="col-md-4">
            <Input
              disabled
              value={this.state.data.identityDocNumber}
              label={this.state.data.identityDocNumberType}
            />
          </div>
          <div className="col-md-4">
            <Input
              disabled
              value={
                !this.state?.data?.blockInfo?.blocked
                  ? language[this.props.defaultLanguage].no
                  : language[this.props.defaultLanguage].yes
              }
              label={language[this.props.defaultLanguage].blocked}
            />
          </div>
          <div className="col-md-4">
            <Input
              disabled
              value={this.state?.data?.bloodGroup}
              label={language[this.props.defaultLanguage].blood_group}
            />
          </div>
          <Separator
            title={
              language[this.props.defaultLanguage].last_donation_information
            }
          />

          <div className="col-md-4">
            <Input
              disabled
              value={this.state?.data?.lastDonationSite?.name}
              label={language[this.props.defaultLanguage].last_donation_site}
            />
          </div>

          <div className="col-md-4">
            <Input
              disabled
              value={
                this.state?.data?.lastDonationDate
                  ? moment(this.state?.data?.lastDonationDate).format("ll")
                  : ""
              }
              label={language[this.props.defaultLanguage].last_donation_date}
            />
          </div>

          <div className="col-md-4">
            <Input
              disabled
              value={this.state?.data?.donationCount}
              label={language[this.props.defaultLanguage].total_donation}
            />
          </div>

          {this.state.data.registrationQuestionnaire.map((el) => {
            return (
              <div className="col-md-4">
                <Input disabled label={el.questionText} value={el.answer} />
              </div>
            );
          })}
          <div
            onClick={() => this.handleShowModal("showMyBadgeCard")}
            className="noPrint"
          >
            <Link to="#" className="text-primary hover-decoration">
              <u>
                {language[this.props.defaultLanguage].view_donor_badge_card}
              </u>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  renderBloodGroup() {
    const { data } = this.state;

    const { color, bloodGroup } = getDonorBadgeInfo(data.bloodGroup);
    return <span style={{ color }}>{bloodGroup}</span>;
  }

  handleShowModal(modal) {
    this.setState({ [modal]: true });
  }

  handleCloseModal(modal) {
    this.setState({ [modal]: false });
  }

  renderProfile = () => {
    return (
      <div className="donor-profile-container mb-3">
        <div className="card-body">
          {this.state.isLoading ? (
            <center>
              <Loading />
            </center>
          ) : (
            <div className="row">
              <div
                className={this.props.imageCol || "col-md-2"}
                style={{
                  marginBottom: "1.5rem",
                }}
              >
                <div className="donor-profile-image-container">
                  <img
                    src={
                      this?.state?.data.imageUrl
                        ? `data:image/png;base64,${this?.state?.data.imageUrl}`
                        : UserImg
                    }
                  />
                  <div>
                    <div className="blocked-info">
                      <div>
                        <span className="title">Blocked</span>
                      </div>
                      <div
                        className={`value ${
                          !this.state?.data?.blockInfo?.blocked ? "no" : "yes"
                        }`}
                      >
                        {!this.state?.data?.blockInfo?.blocked ? (
                          <span className="no">
                            {language[this.props.defaultLanguage].no}
                          </span>
                        ) : (
                          <span className="yes">
                            {language[this.props.defaultLanguage].yes}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={this.props.infoCol || "col-md-8"}>
                {this.renderProfileInfo()}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        {this.state?.data?.blockInfo?.blocked && (
          <div className="alert alert-danger">
            <h3>{language[this.props.defaultLanguage].blocked_donor}</h3>
            <p>
              {language[this.props.defaultLanguage].reason}:{" "}
              {this.state?.data?.blockInfo?.blockReason}
            </p>
          </div>
        )}

        {this.renderProfile()}

        {/* <PrintComponent>
          <div className="noScreen" style={{ padding: "1rem" }}>
            {this.renderProfile()}
          </div>
        </PrintComponent> */}

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showMyBadgeCard")}
          show={this.state.showMyBadgeCard}
          title={language[this.props.defaultLanguage].my_donation_card}
        >
          <div className="card-body">
            <DonorBadge donorId={this.props.donorId} />
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;

  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(DonorProfile);
