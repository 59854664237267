import React from "react";
import "./styles.css";
import { Modal } from "react-bootstrap";

const ModalContainer = (props) => (
  <Modal
    size={props.size}
    show={props.show}
    onHide={props.handleClose}
    backdrop={props.backdrop}
  >
    {props.title && (
      <Modal.Header closeButton={props.handleClose}>
        <Modal.Title className="modal_title">
          <span className="text-bold">{props.title}</span>
          {props.description && (
            <span className="text-mutex">{props.description}</span>
          )}
        </Modal.Title>
      </Modal.Header>
    )}
    <div className="modal-content">{props.children}</div>
  </Modal>
);

export default ModalContainer;
