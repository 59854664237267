import React from "react";

import { Splash } from "../../components/Splash";
import { connect } from "react-redux";

class SplashScreen extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      window.location.href = "/landing";
    }, 3000);
  }
  render() {
    return <Splash />;
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(SplashScreen);
