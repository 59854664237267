import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { Modal } from "../../Modal";
import { getStorage } from "../../../utils/storage";
import NewLabel from "./NewLabel";
import { Input, Select } from "../../Input";
import bloodDonationQuery from "../../../utils/queries/bloodDonationQuery";
import toastMessage from "../../../utils/toastMessage";
import { Button } from "../../Button";
import { fetchAllComponents } from "../../../utils/queries/production/components";
class ComponentLabeling extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    donation: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
    components: [],
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target ? e.target.value : e,
      error,
    });
  }

  handleShowModal(modal, modalTitle) {
    this.setState({ [modal]: true, modalTitle });
  }

  handleCloseModal(modal) {
    this.setState({ [modal]: false });
  }

  getDonation = async (searchDonation) => {
    try {
      let { error, donationNumber } = this.state;

      if (donationNumber === "") {
        error.donationNumber =
          language[this.props.defaultLanguage].donation_number_required;

        this.setState({ error });

        return;
      }

      this.setState({
        searchDonation,
      });

      const data = await bloodDonationQuery(this.props.defaultLanguage, {
        donationNumber: this.state.donationNumber?.replace(/\s+/g, ""),
      });

      let donation = {};

      if (data.length > 0) {
        donation = data[0];
      } else {
        toastMessage(
          "error",
          language[this.props.defaultLanguage].donation_not_found
        );
      }

      this.setState(
        {
          donation,
          searchDonation: false,
        },
        () => {
          this.handleShowModal("showModal");
        }
      );
    } catch (error) {
      this.setState({ searchDonation: false });
      toastMessage("error", "Donation not found!");
    }
  };

  getComponents = async (isFetchingComponents, code) => {
    try {
      this.setState({
        isFetchingComponents,
      });

      const data = await fetchAllComponents(this.props.defaultLanguage, {
        inputType: "dropdown",
        code: code ? code : undefined,
      });

      this.setState({
        isFetchingComponents: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingComponents: false });
    }
  };

  render() {
    return (
      <>
        <div className="card">
          <div className="card-body">
            <div className="col-md-6">
              <div className="col-md-12">
                {/* <Select
                  loadOptions={(search) => this.getComponents(true, search)}
                  placeholder={language[this.props.defaultLanguage].select}
                  label={language[this.props.defaultLanguage].component}
                  required
                  value={this.state.component}
                  onChange={(e) => this.onChangeText("component", e)}
                  error={this.state.error.component}
                /> */}
                <Input
                  placeholder={
                    language[this.props.defaultLanguage].search + "..."
                  }
                  label={language[this.props.defaultLanguage].donation_number}
                  required
                  value={this.state.donationNumber}
                  error={this.state.error.donationNumber}
                  onChange={(e) => this.onChangeText("donationNumber", e)}
                />
                <Button
                  text={language[this.props.defaultLanguage].search}
                  onPress={() => this.getDonation(true)}
                  isSubmitting={this.state.searchDonation}
                />
              </div>
            </div>
          </div>
        </div>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          showHeaderBottomBorder={false}
        >
          <NewLabel
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            {...this.state.donation}
            donationId={this.state?.donation?._id}
            componentId={this.state?.component?._id}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ComponentLabeling);
