import React from "react";
import { connect } from "react-redux";
import language from "../../../language/index.js";
import requestQuery from "../../../utils/queries/requestQuery.js";
import Table from "../../Table/Table.js";
import CardHeader from "../../Header/CardHeader.js";
import ListItem from "../../List/ListItem.js";
import { fetchBloodRequestProcessedHistory } from "../../../utils/queries/request/bloodRequestProcessedHistory.js";
import toastMessage from "../../../utils/toastMessage.js";
import Preparation from "./Preparation.js";
import { PrintComponent } from "../../Print";
class RequestInfo extends React.Component {
  state = {
    error: {},
    distributors: [],
    data: [],
    csvData: [],
    packages: [],
  };

  componentDidMount = async () => {
    this.getRequestInfo(true);
    this.getRequestProductHistory(true);
  };

  componentDidUpdate(nextProps) {
    if (this.props.isRefreshing !== nextProps.isRefreshing) {
      this.getRequestInfo(true);
      this.getRequestProductHistory(true);
    }
  }

  getRequestProductHistory = async (isFetchingHistory) => {
    try {
      this.setState({
        isFetchingHistory,
      });

      const { requestId } = this.props;

      const data = await fetchBloodRequestProcessedHistory(
        this.props.defaultLanguage,
        {
          bloodRequest: requestId,
        }
      );
      this.setState({
        requestProductHistory: data,
        isFetchingHistory: false,
      });
    } catch (error) {
      this.setState({ isFetchingHistory: false });

      toastMessage("error", error);
    }
  };

  returnFilters() {
    const { requestId } = this.props;
    return {
      id: requestId,
    };
  }

  getRequestInfo = async (isLoading) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data } = await requestQuery(
        defaultLanguage,
        this.returnFilters()
      );

      let packages = [];

      if (data.length > 0) {
        packages = data[0].packages;
      }

      this.setState({
        data,
        isLoading: false,
        packages,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleShowModal(modal, modalTitle, selected_data = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].request_number,
        key: "requestNo",
        type: "link",
        styles: {
          textTransform: "uppercase",
        },
        copyEnabled: true,
        onPress: (item) =>
          (window.location.href = `/dashboard/request/${item._id}`),
      },
      {
        title: language[this.props.defaultLanguage].date,
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",
      },
      {
        title: language[this.props.defaultLanguage].description,
        key: "description",
      },
      {
        title: language[this.props.defaultLanguage].requested_by,
        key: "hospital.name",
      },
      {
        title: language[this.props.defaultLanguage].blood_bank,
        key: "bloodBank.center.name",
      },
      {
        title: language[this.props.defaultLanguage].blood_bank_contact,
        key: "bloodBank.center.contactNumber",
      },
      {
        title: language[this.props.defaultLanguage].distributor,
        key: "distributor.name",
      },
      {
        title: language[this.props.defaultLanguage].status,
        key: "status",
        type: "status",
      },
    ];

    return headers;
  }

  render() {
    return (
      <PrintComponent>
        <CardHeader
          title={language[this.props.defaultLanguage].request_information}
        />
        <div style={{ marginBottom: "1rem" }} />
        <Table
          data={this.state.data}
          isLoading={this.state.isLoading}
          headers={this.returnTableHeaders()}
        />
        <CardHeader title={language[this.props.defaultLanguage].packages} />
        <div style={{ marginBottom: "1rem" }} />
        <Table
          data={this.state.packages}
          headers={[
            {
              title: language[this.props.defaultLanguage].code,
              key: "productCode",
            },
            {
              title: language[this.props.defaultLanguage].product_name,
              key: "productName",
              styles: {
                textTransform: "uppercase",
              },
            },
            {
              title: language[this.props.defaultLanguage].blood_group,
              key: "bloodGroup",
            },
            {
              title: language[this.props.defaultLanguage].number_of_packages,
              key: "numberOfPackages",
            },
            {
              title:
                language[this.props.defaultLanguage].requested_volume + "(ML)",
              key: "volume",
            },
            {
              title: language[this.props.defaultLanguage].note,
              key: "note",
            },
          ]}
        />
        <CardHeader
          title={language[this.props.defaultLanguage].product_request_history}
        />
        <div style={{ marginBottom: "1rem" }} />
        <Preparation requestId={this.props.requestId} />

        {this.state.data.length > 0 &&
          this.state.data[0].type === "nominative" && (
            <div style={{ marginTop: "1rem" }}>
              <ListItem
                name={language[this.props.defaultLanguage].urgent}
                description={this.state.data[0].isUrgent + ""}
              />
              <hr />
              <ListItem
                name={language[this.props.defaultLanguage].clinical_information}
                description={this.state.data[0].clinicalInformation}
              />
              <hr />
              <ListItem
                name={language[this.props.defaultLanguage].patient}
                description={`${this.state.data[0].patient?.firstName} ${this.state.data[0].patient?.lastName} ${this.state.data[0].patient?.identityNumber}`}
              />
              <hr />
              <ListItem
                name={
                  language[this.props.defaultLanguage].previously_transfused
                }
                description={this.state.data[0].previouslyTransfused + ""}
              />{" "}
              <hr />
              <ListItem
                name={language[this.props.defaultLanguage].history_pregnancy}
                description={this.state.data[0].historyPregnancy}
              />{" "}
              <hr />
              <ListItem
                name={language[this.props.defaultLanguage].last_transfused}
                description={this.state.data[0].lastTransfused}
              />{" "}
              <hr />
              <ListItem
                name={language[this.props.defaultLanguage].test_required}
                description={this.state.data[0].testRequired}
              />{" "}
              <hr />
              <ListItem
                name={
                  language[this.props.defaultLanguage].blood_component_needed_on
                }
                description={this.state.data[0].bloodComponentNeededOn}
              />{" "}
              <hr />
              <ListItem
                name={language[this.props.defaultLanguage].sample_collected_by}
                description={this.state.data[0].sampleCollectedBy}
              />{" "}
              <hr />
              <ListItem
                name={
                  language[this.props.defaultLanguage].sample_collection_date
                }
                description={this.state.data[0].sampleCollectionDate}
              />
            </div>
          )}
      </PrintComponent>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(RequestInfo);
