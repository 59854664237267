// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
  /* margin-top: 1rem; */
  padding: 1rem;
  align-items: center;
  justify-content: center;
  background-color: var(--text-color-5);
}

.pagination li a {
  color: var(--text-color);
  font-weight: 600;
}

.pagination li a:hover {
  color: var(--text-color);
  background-color: var(--text-color-10);
}

.page-item.active .page-link {
  background-color: var(--text-color);
}

.pagination .page-link {
  width: 30px;
  height: 30px;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination.page-link i {
  font-size: 20px;
}

.pagination .count-number {
  margin: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-md);
}

@media screen and (max-width: 500px) {
  .pagination .page-link {
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 768px) {
  .pagination .page-link {
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 980px) {
  .pagination .page-link {
    width: 30px;
    height: 30px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Pagination/styles.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,qCAAqC;AACvC;;AAEA;EACE,wBAAwB;EACxB,gBAAgB;AAClB;;AAEA;EACE,wBAAwB;EACxB,sCAAsC;AACxC;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,6BAA6B;EAC7B,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,8BAA8B;EAC9B,kCAAkC;AACpC;;AAEA;EACE;IACE,WAAW;IACX,YAAY;EACd;AACF;;AAEA;EACE;IACE,WAAW;IACX,YAAY;EACd;AACF;;AAEA;EACE;IACE,WAAW;IACX,YAAY;EACd;AACF","sourcesContent":[".pagination {\r\n  /* margin-top: 1rem; */\r\n  padding: 1rem;\r\n  align-items: center;\r\n  justify-content: center;\r\n  background-color: var(--text-color-5);\r\n}\r\n\r\n.pagination li a {\r\n  color: var(--text-color);\r\n  font-weight: 600;\r\n}\r\n\r\n.pagination li a:hover {\r\n  color: var(--text-color);\r\n  background-color: var(--text-color-10);\r\n}\r\n\r\n.page-item.active .page-link {\r\n  background-color: var(--text-color);\r\n}\r\n\r\n.pagination .page-link {\r\n  width: 30px;\r\n  height: 30px;\r\n  border-radius: 50% !important;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n\r\n.pagination.page-link i {\r\n  font-size: 20px;\r\n}\r\n\r\n.pagination .count-number {\r\n  margin: 0 1rem;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  font-size: var(--font-size-sm);\r\n  font-weight: var(--font-weight-md);\r\n}\r\n\r\n@media screen and (max-width: 500px) {\r\n  .pagination .page-link {\r\n    width: 30px;\r\n    height: 30px;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 768px) {\r\n  .pagination .page-link {\r\n    width: 30px;\r\n    height: 30px;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 980px) {\r\n  .pagination .page-link {\r\n    width: 30px;\r\n    height: 30px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
