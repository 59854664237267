// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sites-landing-header-container {
  margin-bottom: 1rem;
}

.centers.card {
  cursor: pointer;
  opacity: 1;
}

.centers.card:hover {
  opacity: 0.8;
}

.centers .card-body {
  display: flex;
  gap: 1rem;
}

.centers .address-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.centers .center-icon-container {
  background-color: var(--primary-color-5);
  width: 50px;
  height: 50px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.centers .center-icon-container span {
  color: var(--primary-color);
}

.centers .center-info-container {
  flex: 1 1;
}

.center-map {
  min-height: 30vh;
  background-color: var(--text-color-5);
}

.center-info-item {
  display: flex;
  align-items: center;
  min-height: 40px;
}

.center-info-item-name {
  flex: 1 1;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Landing/CentersAndSites/styles.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,wCAAwC;EACxC,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,gBAAgB;EAChB,qCAAqC;AACvC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,SAAO;AACT","sourcesContent":[".sites-landing-header-container {\r\n  margin-bottom: 1rem;\r\n}\r\n\r\n.centers.card {\r\n  cursor: pointer;\r\n  opacity: 1;\r\n}\r\n\r\n.centers.card:hover {\r\n  opacity: 0.8;\r\n}\r\n\r\n.centers .card-body {\r\n  display: flex;\r\n  gap: 1rem;\r\n}\r\n\r\n.centers .address-container {\r\n  display: flex;\r\n  align-items: center;\r\n  gap: 0.5rem;\r\n}\r\n\r\n.centers .center-icon-container {\r\n  background-color: var(--primary-color-5);\r\n  width: 50px;\r\n  height: 50px;\r\n  border-radius: 8px;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n\r\n.centers .center-icon-container span {\r\n  color: var(--primary-color);\r\n}\r\n\r\n.centers .center-info-container {\r\n  flex: 1;\r\n}\r\n\r\n.center-map {\r\n  min-height: 30vh;\r\n  background-color: var(--text-color-5);\r\n}\r\n\r\n.center-info-item {\r\n  display: flex;\r\n  align-items: center;\r\n  min-height: 40px;\r\n}\r\n\r\n.center-info-item-name {\r\n  flex: 1;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
