import { Title } from "../Title";
import "./styles.css";
import language from "../../../language";
import RBCLogo from "../../../assets/rbc-logo.png";
import { Slides } from "../../Slides";
import { connect } from "react-redux";

const Partners = (props) => {
  const partners = [
    {
      imageWidth: 260,
      imageHeight: 260,
      image: RBCLogo,
    },
  ];
  return (
    <div>
      <Title title={language[props.defaultLanguage].partners} center />
      <div className="partners-container">
        <Slides data={partners} viewSize={1} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Partners);
