import toastMessage from "../toastMessage";
import formatSelectData from "../formatSelectData";
import { getStorage } from "../storage";
import axios from "axios";
import language from "../../language";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

const fetchData = async (lng = "english", { page, limit, type }) => {
  try {
    const user = await getStorage();

    const options = {
      method: "GET",
      url: `${API_URL}/accessRole`,
      params: {
        page: page,
        limit: limit,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await axios(options);

    let { data, count } = queryRes.data;

    if (type === "dropdown") {
      return formatSelectData(queryRes.data, "name", "_id");
    }

    return { data, count };
  } catch (error) {
    toastMessage("error", language[lng].unable_fetch_access_role);
  }
};

export default fetchData;

export const deleteAccessRole = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `${API_URL}/accessRole`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await axios(options);

    toastMessage("success", language[lng].success_delete_access_role);
  } catch (error) {
    toastMessage("error", language[lng].error_delete_access_role);
  }
};
