import toastMessage from "../toastMessage";
import formatSelectData from "../formatSelectData";
import { getStorage } from "../storage";
import axios from "axios";
import language from "../../language";


export const fetchNIDAInfo = async (lng = "english", { documentNumber }) => {
  try {
    const user = await getStorage();

    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BBMS_BASE_API}/nida`,
      data: {
        documentNumber,
      },
      headers: {
        Authorization: `Bearer ${user.token}`, },
    };

    const { data } = await axios(options);

    return data;
  } catch (error) {
    toastMessage("error", language[lng].fetch_data_error);
  }
};
