import React from "react";
import "./styles.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { authMenus, nonAuthMenus } from "../../constants/profileMenus";

const MobileNavbarRef = React.forwardRef((props, ref) => {
  const { user } = props;

  return (
    <div className="container">
      <div className={`mobile-navbar ${props.className}`} ref={ref}>
        {nonAuthMenus(props.defaultLanguage).map((menu, i) => {
          return (
            <div className="nav-item" key={i}>
              <a href={menu.route}>{menu.name}</a>
            </div>
          );
        })}
        {user.token &&
          authMenus(props.defaultLanguage).map((menu, i) => {
            return (
              <div className="nav-item" key={i}>
                <Link to={menu.link}>{menu.name}</Link>
              </div>
            );
          })}
      </div>
    </div>
  );
});
const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(MobileNavbarRef);
