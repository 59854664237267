import React from "react";
import WrapperLandingPageLayout from "../../../Layout/Landing/WrapperLandingPageLayout";
import StartApplication from "../../../components/Donors/Pre-assessment/StartApplication";

class StartApplicationScreen extends React.Component {
  render() {
    return (
      <WrapperLandingPageLayout>
        <StartApplication />
      </WrapperLandingPageLayout>
    );
  }
}

export default StartApplicationScreen;
