// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-form {
  background-color: var(--light-text);
  min-height: 100px;
  border-radius: var(--border-radius-md);
  box-shadow: var(--form-box-shadow);
  padding: 1.5rem;
}

.auth-form .title {
  min-height: 45px;
  display: flex;
  margin-bottom: 1.5rem;
  flex-direction: column;
}

.auth-form .title p {
  margin-top: 0.5rem;
}

.auth-form .footer {
  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.auth-form .footer span {
  font-weight: var(--font-weight);
}

@media screen and (max-width: 500px) {
  .category-tabs {
    padding-top: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .category-tabs {
    padding-top: 2rem;
  }
}

@media screen and (max-width: 980px) {
  .category-tabs {
    padding-top: 2rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/common/styles/auth.form.styles.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,iBAAiB;EACjB,sCAAsC;EACtC,kCAAkC;EAClC,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,qBAAqB;EACrB,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".auth-form {\r\n  background-color: var(--light-text);\r\n  min-height: 100px;\r\n  border-radius: var(--border-radius-md);\r\n  box-shadow: var(--form-box-shadow);\r\n  padding: 1.5rem;\r\n}\r\n\r\n.auth-form .title {\r\n  min-height: 45px;\r\n  display: flex;\r\n  margin-bottom: 1.5rem;\r\n  flex-direction: column;\r\n}\r\n\r\n.auth-form .title p {\r\n  margin-top: 0.5rem;\r\n}\r\n\r\n.auth-form .footer {\r\n  min-height: 45px;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  gap: 2rem;\r\n}\r\n\r\n.auth-form .footer span {\r\n  font-weight: var(--font-weight);\r\n}\r\n\r\n@media screen and (max-width: 500px) {\r\n  .category-tabs {\r\n    padding-top: 2rem;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 768px) {\r\n  .category-tabs {\r\n    padding-top: 2rem;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 980px) {\r\n  .category-tabs {\r\n    padding-top: 2rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
