import { Link } from "react-router-dom";
import { TitleLoader } from "../Loader";
import "./styles.css";
const CardCount = (props) => {
  return (
    <div
      className="card card-count-container"
      style={{ borderTopColor: props.color, cursor: "pointer" }}
      onClick={() => (props.onPress ? props.onPress() : null)}
    >
      <div className="card-body">
        <div
          className="card-count-icon-container"
          style={{ backgroundColor: props.color }}
        >
          <span className={process.env.REACT_APP_ICONS_TYPE}>{props.icon}</span>
        </div>
        <div className="card-count-content">
          <h1 className="count" style={{ color: props.color }}>
            {props.isLoading ? <TitleLoader /> : props.count}
          </h1>
          <p>{props.title}</p>
        </div>
      </div>
    </div>
  );
};

export default CardCount;
