export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";

export const onAddNotification = (payload) => {
  return {
    type: ADD_NOTIFICATION,
    payload,
  };
};

export const onRemoveNotification = (notificationID) => {
  return {
    type: REMOVE_NOTIFICATION,
    notificationID,
  };
};
