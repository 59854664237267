import moment from "moment";

export default (filters) => {
  let formateFilters = {};

  if (filters.centers && filters.centers.length > 0) {
    formateFilters.center = [];
    filters.centers.map((el) => {
      formateFilters.center.push(el.value);
    });
  }

  if (filters.centerSites && filters.centerSites.length > 0) {
    formateFilters.centerSite = [];
    filters.centerSites.map((el) => {
      formateFilters.centerSite.push(el.value);
    });
  }

  if (filters.bloodGroups && filters.bloodGroups.length > 0) {
    formateFilters.bloodGroup = [];
    filters.bloodGroups.map((el) => {
      formateFilters.bloodGroup.push(el.value);
    });
  }

  if (filters.startDate) {
    formateFilters.startDate = moment(filters.startDate)
      .startOf("day")
      .format("YYYY-MM-DD HH:mm");
  }

  if (filters.endDate) {
    formateFilters.endDate = moment(filters.endDate)
      .endOf("day")
      .format("YYYY-MM-DD HH:mm");
  }

  console.log("formateFilters", formateFilters);

  return formateFilters;
};
