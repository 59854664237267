import "./styles.css";

const Title = (props) => {
  return (
    <div
      className={`landing-title-container ${props.center ? "center" : "left"} ${
        props.className
      }`}
    >
      <h1>{props.title}</h1>
      {props.description && <p>{props.description}</p>}
    </div>
  );
};

export default Title;
