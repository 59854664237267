import { ON_ASSESSMENT_QUESTIONNAIRE_CHANGES } from "../actions/Assessment";

const initialState = {
  questionnaire: {
    personalInformation: {},
    healthInformation: {},
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ON_ASSESSMENT_QUESTIONNAIRE_CHANGES:
      state.questionnaire[action.payload.type][action.payload.field] =
        action.payload.value;

      return {
        ...state,
        questionnaire: { ...state.questionnaire },
      };
    default:
      return state;
  }
};
export default reducer;
