import React from "react";
import { Input } from "../../Input";
import { Button } from "../../Button";
import icons from "../../../constants/icons";
import "./styles.css";
import { connect } from "react-redux";
import language from "../../../language";

const HeaderSearch = (props) => {
  return (
    <div className="sites-landing-header-container">
      <h1>{props.title}</h1>
      <p>{props.description}</p>
      <br />
      <div className=" d-flex align-items-center">
        <div className="col-md-6">
          <Input
            leftIcon={icons.search}
            placeholder={props.placeholder}
            className="input-lg"
            onChange={props.handleChange}
            value={props.searchText}
            rightButtonIcon={icons.search}
            rightButtonPressed={props.handleSearch}
            rightButtonSubmitting={props.searching}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(HeaderSearch);
