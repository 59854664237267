import React from "react";
import ReactToPrint from "react-to-print";
import { connect } from "react-redux";
import language from "../../language";
import "./styles.css";

class PrintComponent extends React.PureComponent {
  render() {
    return (
      <div>
        {this.props.header && <>{this.props.header}</>}
        <ReactToPrint
          trigger={() => {
            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
            // to the root node of the returned component as it will be overwritten.
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // minHeight: 80,
                }}
              >
                <a href="#" className="btn btn-primary ">
                  {this.props.buttonText ||
                    language[this.props.defaultLanguage].print_this_out}
                </a>
              </div>
            );
          }}
          content={() => this.componentRef}
        />
        <div ref={(el) => (this.componentRef = el)}>
          {this.props.children}
          {/* {this.props.footerContent && (
            <div className="footer-worksheet">
              CNTS Rwanda-Kigali &nbsp;|&nbsp; {new Date().toLocaleString()}
            </div>
          )} */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;

  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(PrintComponent);
