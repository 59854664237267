// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container {
  background-color: var(--light-color);
}

.footer-container .container {
  display: flex;
  align-items: center;
  min-height: 50px;
  justify-content: center;
}

.footer-container .footer-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.footer-container .container span {
  font-size: var(--font-size-sm);
}

.footer-container .middle-container {
  flex: 1 1;
}

.footer-container .footer-left-container {
  display: flex;
  align-items: center;
}

.footer-container .footer-left-container .social-icon-item {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  cursor: pointer;
}

.footer-container .footer-left-container .social-icon-item img {
  aspect-ratio: 3/2;
  object-fit: contain;
  width: 100%;
}

/* MEDIA QUERIES - RESPONSIVE CONTROL */
@media screen and (max-width: 500px) {
  .footer-container .footer-content {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .footer-container .footer-content {
    flex-direction: column;
  }
}

@media screen and (max-width: 980px) {
  .footer-container .footer-content {
    flex-direction: column;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Landing/Footer/styles.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,WAAW;AACb;;AAEA,uCAAuC;AACvC;EACE;IACE,sBAAsB;EACxB;AACF;;AAEA;EACE;IACE,sBAAsB;EACxB;AACF;;AAEA;EACE;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".footer-container {\n  background-color: var(--light-color);\n}\n\n.footer-container .container {\n  display: flex;\n  align-items: center;\n  min-height: 50px;\n  justify-content: center;\n}\n\n.footer-container .footer-content {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 0.5rem;\n}\n\n.footer-container .container span {\n  font-size: var(--font-size-sm);\n}\n\n.footer-container .middle-container {\n  flex: 1;\n}\n\n.footer-container .footer-left-container {\n  display: flex;\n  align-items: center;\n}\n\n.footer-container .footer-left-container .social-icon-item {\n  width: 30px;\n  height: 30px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 1.5rem;\n  cursor: pointer;\n}\n\n.footer-container .footer-left-container .social-icon-item img {\n  aspect-ratio: 3/2;\n  object-fit: contain;\n  width: 100%;\n}\n\n/* MEDIA QUERIES - RESPONSIVE CONTROL */\n@media screen and (max-width: 500px) {\n  .footer-container .footer-content {\n    flex-direction: column;\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .footer-container .footer-content {\n    flex-direction: column;\n  }\n}\n\n@media screen and (max-width: 980px) {\n  .footer-container .footer-content {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
