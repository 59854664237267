import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../../utils/withRouter";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { DashboardHeader } from "../../../components/Header";
import { ClubMembers, Clubs } from "../../../components/Clubs";
import language from "../../../language";

class ClubScreen extends React.Component {
  render() {
    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <Breadcrumb
              homeUrl="/dashboard/home"
              data={[
                {
                  name: this.props.routeParams.clubName,
                  url: "/dashboard/clubs",
                },
              ]}
            />
            <DashboardHeader
              title={language[this.props.defaultLanguage].members}
            />
            <ClubMembers clubId={this.props.routeParams.clubId} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(ClubScreen));
