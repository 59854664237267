import React from "react";
import { Checkbox } from "../../Input";
import { Button } from "../../Button";
import { connect } from "react-redux";
import language from "../../../language";
import { Modal } from "../../Modal";
import DateBirthChecker from "../../DateBirthChecker/DateBirthChecker";

const data = [
  {
    title: "I understand that I should not donate blood if:",
    items: [
      "I consume drugs or use illegal intravenous drug",
      "I have HIV AIDS, Hepatitis B or C, Syphillis or any other sexually transmitted infections",
      "I have or have had sex with a partner of the same sex (Even using condom)",
      "My sexual partner has HIV/AIDS, hapatitis or any other Sexually Transimitted Infections",
    ],
  },
  {
    title: "I undestand that I should wait for 6 months to donate blood if:",
    items: [
      "I have had sex with a person who is not my spouse even using condoms",
      "I don't trust my partner even if I use condoms",
      "I have not got any HIV/AIDS test before marriage",
    ],
  },
];

class BloodAssessment extends React.Component {
  state = {
    showBirthDateModal: true,
    termsAndConditionChecked: false,
  };

  handleOpenModal(modal) {
    this.setState({ [modal]: true });
  }

  handleCloseModal(modal) {
    this.setState({ [modal]: false });
  }

  handleCheckbox() {
    this.setState({
      termsAndConditionChecked: !this.state.termsAndConditionChecked,
    });
  }
  render() {
    return (
      <>
        <div className="card">
          <div className="card-header">
            <h2>
              {language[this.props.defaultLanguage].start_application_title}
            </h2>
          </div>
          <div className="card-body gap-1">
            {data.map((el) => {
              return (
                <div className="mb-3">
                  <h3 className="mb-2">{el.title}</h3>
                  {el?.items?.map((listEl, lIndex) => {
                    return (
                      <div
                        className="d-flex gap-2"
                        style={{ padding: ".6rem 0" }}
                      >
                        <span>{lIndex + 1}.</span>
                        <span>{listEl}</span>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div className="card-footer">
            <div className="mb-2">
              <Checkbox
                label="I confirm that I have read the terms and conditions and comply with them"
                checked={this.state.termsAndConditionChecked}
                onCheck={this.handleCheckbox.bind(this)}
              />
            </div>
            <div>
              <Button
                text={language[this.props.defaultLanguage].start_application}
                onPress={() =>
                  (window.location.href = "/common/donor-registration")
                }
                disabled={!this.state.termsAndConditionChecked}
              />
            </div>
          </div>
        </div>
        <Modal show={this.state.showBirthDateModal}>
          <DateBirthChecker
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showBirthDateModal"
            )}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(BloodAssessment);
