import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import { CSVLink } from "react-csv";
import bloodTestQuery from "../../utils/queries/bloodTest/bloodTestQuery";
import handleSearch from "../../utils/handleSearch";
import { getStorage } from "../../utils/storage";
import icons from "../../constants/icons";
import { defineRoleCenterSite } from "../../utils/handleAccessRoles";

let copyData = [];

class BloodGroups extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    error: {},
    csvData: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    await this.setState({ user });

    await this.getData(true);
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target.value,
      error,
    });
  }

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, donationId, bloodGroup } = this.props;

      this.setState({ isLoading });

      const { centerRole, centerSiteRole } = await defineRoleCenterSite();

      const { data, count } = await bloodTestQuery(defaultLanguage, {
        page,
        limit,
        donation: donationId,
        center: centerRole,
        centerSite: centerSiteRole,
        bloodGroup,
      });

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].id,
        key: "_id",
        type: "link",
        onPress: (item) =>
          (window.location.href = `/dashboard/testing/${
            item.donation._id || item.donation
          }/${item.donor._id || item.donor}`),
      },
      {
        title: language[this.props.defaultLanguage].donor_number,
        key: "donor.donorNumber",
        type: "link",
        onPress: (item) =>
          (window.location.href = `/dashboard/donors/${item.donor._id}/${
            item.donor.firstName + " " + item.donor.lastName
          }`),
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].donation,
        key: "donation._id",
        type: "link",
        onPress: (item) =>
          (window.location.href = `/dashboard/testing/${
            item.donation._id || item.donation
          }/${item.donor._id || item.donor}`),
      },
      {
        title: language[this.props.defaultLanguage].blood_group,
        key: "bloodGroup",
      },
      {
        title: language[this.props.defaultLanguage].first_name,
        key: "donor.firstName",
      },
      {
        title: language[this.props.defaultLanguage].last_name,
        key: "donor.lastName",
      },
      {
        title: language[this.props.defaultLanguage].nationality,
        key: "donor.nationality",
      },
      {
        title: language[this.props.defaultLanguage].identity_doc_type,
        key: "identityDocNumberType",
      },
      {
        title: language[this.props.defaultLanguage].identity_doc_number,
        key: "donor.identityDocNumber",
      },
      // {
      //   title: language[this.props.defaultLanguage].blood_group,
      //   key: "bloodGroup",
      // },
      {
        title: language[this.props.defaultLanguage].email_address,
        key: "donor.email",
        styles: {
          textTransform: "lowercase",
        },
      },
    ];

    return headers;
  }

  downloadExcel = async () => {};

  handleSearchButton = async () => {
    const { search_text } = this.state;

    try {
      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      this.setState({ isLoading: true });

      const { data, count } = await bloodTestQuery(defaultLanguage, {
        page,
        limit,
        donorNumber: search_text,
      });

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { add_donor, donor, delete_, edit } =
      language[this.props.defaultLanguage];

    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={(e) =>
            handleSearch(this, {
              copyData,
              value: e.target.value,
            })
          }
          handleSearchButton={this.handleSearchButton.bind(this)}
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          filters={[
            {
              type: "button",
              title: language[this.props.defaultLanguage].refresh,
              button_type: "button",
              icon: icons.refresh,
              onPress: (item) => this.getData(true),
            },
          ]}
        />
        <CSVLink
          ref="csvDownload"
          filename={"Donors Blood Group" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(BloodGroups);
