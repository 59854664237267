import "./styles.css";
import facebookLogo from "../../../assets/facebook.png";
import twitterLogo from "../../../assets/twitter.png";
import instagramLogo from "../../../assets/instagram.png";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="container">
        <div className="col-md-10">
          <div className="footer-content">
            <div className="footer-left-container">
              <div className="social-icon-item" title="Follow us on Facebook">
                <img src={facebookLogo} />
              </div>
              <div className="social-icon-item" title="Follow us on Twitter">
                <img src={twitterLogo} />
              </div>
              <div className="social-icon-item" title="Follow us on Instagram">
                <img src={instagramLogo} />
              </div>
            </div>
            <div className="middle-container">
              <span></span>
            </div>
            <div className="right-container">
              <span>
                Powered By{" "}
                <a href="" className="text-bold">
                  Huzalabs
                </a>{" "}
                &{" "}
                <a href="" className="text-bold">
                  Ingenuity
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
