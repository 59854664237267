import React from "react";
import WrapperLandingPageLayout from "../../../Layout/Landing/WrapperLandingPageLayout";
import NewDonor from "../../../components/Donors/NewDonor";
import language from "../../../language";
import { connect } from "react-redux";

class DonorRegistrationScreen extends React.Component {
  render() {
    return (
      <WrapperLandingPageLayout size={8}>
        <div className="card">
          <div className="card-header">
            <h2>{language[this.props.defaultLanguage].donor_registration}</h2>
            <p>
              {language[this.props.defaultLanguage].all_fields} (
              <span className="text-error">*</span>){" "}
              {language[this.props.defaultLanguage].are_mandatory}
            </p>
          </div>
          <NewDonor route="/user" />
        </div>
      </WrapperLandingPageLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(DonorRegistrationScreen);
