import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { Input, Select } from "../../Input";
import {
  getProvinces,
  getCells,
  getDistricts,
  getSectors,
  getVillages,
} from "../../../utils/rwanda";
import Separator from "./Separator";
import CheckboxQuestionnaire from "../../Input/CheckboxQuestionnaire";

class PersonalInformation extends React.Component {
  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-4">
            <Select
              options={[
                {
                  label: language[this.props.defaultLanguage].civil_servant,
                  value: "employeeId",
                },
                {
                  label: language[this.props.defaultLanguage].self_employee,
                  value: "selfEmployee",
                },
                {
                  label: language[this.props.defaultLanguage].student,
                  value: "student",
                },
                {
                  label: language[this.props.defaultLanguage].police,
                  value: "POLICE",
                },
                {
                  label: language[this.props.defaultLanguage].army,
                  value: "ARMY",
                },
                {
                  label: "DASSO",
                  value: "dasso",
                },
                {
                  label: "RCS",
                  value: "RCS",
                },
                {
                  label: language[this.props.defaultLanguage].farmer,
                  value: "farmer",
                },
                {
                  label: language[this.props.defaultLanguage].other,
                  value: "other",
                },
              ]}
              label={language[this.props.defaultLanguage].occupation}
              placeholder={language[this.props.defaultLanguage].select}
              value={this.props.employeeCategory}
              error={this.props.error.occupation}
              onChange={(e) => this.props.onChangeText("occupation", e)}
              isCreatable
            />
          </div>
          {this.props?.occupation?.value === "employeeId" && (
            <div className="col-md-4">
              <Input
                label={language[this.props.defaultLanguage].employee_id}
                placeholder={
                  language[this.props.defaultLanguage].employee_id_placeholder
                }
                value={this.props.employeeID}
                error={this.props.error.employeeID}
                onChange={(e) => this.props.onChangeText("employeeID", e)}
              />
            </div>
          )}
          {this.state?.occupation?.value === "other" && (
            <div className="col-md-4">
              <Input
                label={language[this.props.defaultLanguage].other_occupation}
                required
                placeholder={
                  language[this.props.defaultLanguage]
                    .other_occupation_placeholder
                }
                value={this.props.otherOccupation}
                error={this.props.error.otherOccupation}
                onChange={(e) => this.props.onChangeText("otherOccupation", e)}
              />
            </div>
          )}
        </div>

        {/* <div className="col-md-12">
          <Separator
            title={language[this.props.defaultLanguage].where_do_you_live}
          />
          <div className="row">
            <div className="col-md-4">
              <Select
                options={getProvinces()}
                label={language[this.props.defaultLanguage].province}
                required
                placeholder={language[this.props.defaultLanguage].select}
                value={this.props.province}
                error={this.props.error.province}
                onChange={(e) => this.props.onChangeText("province", e)}
              />
            </div>
            <div className="col-md-4">
              <Select
                options={getDistricts(this.props?.province?.value)}
                label={language[this.props.defaultLanguage].district}
                required
                placeholder={language[this.props.defaultLanguage].select}
                value={this.props.district}
                error={this.props.error.district}
                onChange={(e) => this.props.onChangeText("district", e)}
              />
            </div>
            <div className="col-md-4">
              <Select
                options={getSectors(
                  this.props?.province?.value,
                  this.props?.district?.value
                )}
                label={language[this.props.defaultLanguage].sector}
                required
                placeholder={language[this.props.defaultLanguage].select}
                value={this.props.sector}
                error={this.props.error.sector}
                onChange={(e) => this.props.onChangeText("sector", e)}
              />
            </div>
            <div className="col-md-4">
              <Select
                options={getCells(
                  this.props?.province?.value,
                  this.props?.district?.value,
                  this.props?.sector?.value
                )}
                label={language[this.props.defaultLanguage].cell}
                required
                placeholder={language[this.props.defaultLanguage].select}
                value={this.props.cell}
                error={this.props.error.cell}
                onChange={(e) => this.props.onChangeText("cell", e)}
              />
            </div>
            <div className="col-md-4">
              <Select
                options={getVillages(
                  this.props?.province?.value,
                  this.props?.district?.value,
                  this.props?.sector?.value,
                  this.props?.cell?.value
                )}
                label={language[this.props.defaultLanguage].village}
                required
                placeholder={language[this.props.defaultLanguage].select}
                value={this.props.village}
                error={this.props.error.village}
                onChange={(e) => this.props.onChangeText("village", e)}
              />
            </div>
          </div>
        </div> */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(PersonalInformation);
