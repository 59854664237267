import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import { CSVLink } from "react-csv";
import bloodDonationQuery from "../../utils/queries/bloodDonationQuery";
import exportPDF from "../../utils/exportPDF";
import handleSearch from "../../utils/handleSearch";
import { ConfirmationModal, DeleteModal, Modal } from "../Modal";
import NewCollection from "./NewCollection";
import { defineRole } from "../../utils/handleAccessRoles";
import { getStorage } from "../../utils/storage";
import { SearchDonor } from "../Donors";
import icons from "../../constants/icons";
import toastMessage from "../../utils/toastMessage";
import axios from "axios";

let copyData = [];

class BloodCollection extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
    suspendReason: "",
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    await this.getData(true);
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target.value,
      error,
    });
  }

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await bloodDonationQuery(defaultLanguage, {
        page,
        limit,
        donor: this.props.donorId,
        status: ["suspended", "blocked"],
      });

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    console.log("donor selected****", selectedData);
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].id,
        key: "_id",
        type: "link",
        onPress: (item) =>
          (window.location.href = `/dashboard/donations/${item._id}/${
            item.donor._id
          }/${item.donor.firstName + " " + item.donor.lastName}`),
      },
      {
        title: language[this.props.defaultLanguage].donor,
        key: "donor.donorNumber",
        type: "link",
        onPress: (item) =>
          (window.location.href = `/dashboard/donations/${item._id}/${
            item.donor._id
          }/${item.donor.firstName + " " + item.donor.lastName}`),
      },
      {
        title: language[this.props.defaultLanguage].blood_group,
        key: "bloodInfo.bloodGroup",
      },
      {
        title: language[this.props.defaultLanguage].volume,
        key: "bloodInfo.volume",
      },
      {
        title: language[this.props.defaultLanguage].vital_sign,
        key: "vitalSign._id",
        type: "link",
        onPress: (item) =>
          this.handleShowModal(
            "showVitalSignModal",
            item._id || item,
            item?.vitalSign
          ),
      },
      {
        title: language[this.props.defaultLanguage].used_equipment,
        key: "equipment.name",
      },
      {
        title: language[this.props.defaultLanguage].start_time,
        key: "startTime",
        isMoment: true,
        formatTime: "lll",
      },
      {
        title: language[this.props.defaultLanguage].end_time,
        key: "endTime",
        isMoment: true,
        formatTime: "lll",
      },
      {
        title: language[this.props.defaultLanguage].reaction,
        key: "reaction.isReacted",
        type: "status",
      },
      {
        title: language[this.props.defaultLanguage].reaction_category,
        key: "reaction.category",
      },
      {
        title: language[this.props.defaultLanguage].reaction_comment,
        key: "reaction.comment",
      },
      {
        title: language[this.props.defaultLanguage].tested,
        key: "bloodInfo.tested",
      },
      {
        title: language[this.props.defaultLanguage].center,
        key: "center.name",
      },
      {
        title: language[this.props.defaultLanguage].center_site,
        key: "centerSite.name",
      },
      {
        title: language[this.props.defaultLanguage].collected_by,
        key: "collectedBy",
      },
      {
        title: language[this.props.defaultLanguage].date,
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];

    return headers;
  }

  downloadExcel = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      const { data } = await bloodDonationQuery(defaultLanguage, {
        page,
        limit,
      });

      this.setState({
        isLoading: false,
        csvData: data,
      });

      this.refs.csvDownload?.link.click();
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  downloadPDF = async () => {
    this.setState({
      isLoading: true,
    });

    const { page, limit } = this.state;
    const { defaultLanguage } = this.props;

    const headers = this.returnTableHeaders();

    const { data } = await bloodDonationQuery(defaultLanguage, {
      page,
      limit,
    });

    this.setState({
      isLoading: false,
    });

    exportPDF("Blocked Donations", headers, data);
  };

  onSuspend = async () => {
    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let url = `${process.env.REACT_APP_BBMS_BASE_API}/blooddonation`,
        method = "PUT",
        user = await getStorage(),
        { selectedData, status, suspendReason } = this.state;

      const options = {
        method,
        url,
        data: {
          status: status.value,
          id: selectedData._id,
          reason: suspendReason,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage].suspended_success
          );

          this.handleCloseModal("showDeleteModal");
          this.getData(true);
        })
        .catch((error) => {
          this.setState({ isSubmitting: false });
          toastMessage(
            "error",
            language[this.props.defaultLanguage].error_suspend_donation
          );
        });
    }
  };

  render() {
    const { export_, add_collection, delete_collection, edit } =
      language[this.props.defaultLanguage];

    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={(e) =>
            handleSearch(this, {
              copyData,
              value: e.target.value,
            })
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          filters={[
            {
              type: "export",
              title: export_,
              button_type: "dropdown",
              icon: "bx bxs-download",
              options: [
                {
                  name: "PDF",
                  onPress: this.downloadPDF.bind(this),
                },
                {
                  name: "CSV",
                  onPress: this.downloadExcel.bind(this),
                },
              ],
            },
          ]}
          actions={[
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: "BloodCollection",
                operation: "read",
              }),
              name: edit,
              onPress: (item) =>
                (window.location.href = `/dashboard/donations/${item._id}`),
              icon: icons.eye_on,
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: "BloodCollection",
                operation: "update",
              }),
              name: language[this.props.defaultLanguage].block_permanent,
              onPress: this.handleShowModal.bind(
                this,
                "showDeleteModal",
                language[this.props.defaultLanguage].block_permanent
              ),
              icon: icons.close,
            },
          ]}
        />
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
          size="lg"
        >
          <NewCollection
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            getData={this.getData.bind(this)}
            {...this.state.selectedData}
            donorId={this.props.donorId}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "searchDonorModal")}
          show={this.state.searchDonorModal}
          title={language[this.props.defaultLanguage].search_donor}
        >
          <SearchDonor
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "searchDonorModal"
            )}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <ConfirmationModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.suspendReason}
            onDelete={this.onSuspend.bind(this)}
            onChange={this.onChangeText.bind(this)}
            isSubmitting={this.state.isSubmitting}
            getData={this.getData.bind(this)}
            withReason
            suspendReason={this.state.suspendReason}
            reasonFieldName="suspendReason"
            statusFieldName="status"
          />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={"Requests" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(BloodCollection);
