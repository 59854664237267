// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-item-feature {
  height: 450px;
  border-bottom: 5px solid var(--primary-color);
  background-color: var(--light-color);
  margin-bottom: 1.5rem;
}

.card-item-feature.primary {
  background-color: var(--primary-color) !important;
}

.card-item-feature .card-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.card-item-feature .card-item-feature-icon-container {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 2rem;
}

.card-item-feature .card-item-feature-icon-container img {
  width: 80px;
}

.card-item-feature .card-item-feature-description {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 2rem;
  flex: 1 1;
}

.card-item-feature .card-item-feature-description p {
  opacity: 0.8;
}

.card-item-feature .card-item-feature-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.card-item-feature.primary .text-primary,
.card-item-feature.primary p {
  color: var(--light-color) !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Landing/FeatureCard/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6CAA6C;EAC7C,oCAAoC;EACpC,qBAAqB;AACvB;;AAEA;EACE,iDAAiD;AACnD;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,WAAW;EACX,mBAAmB;EACnB,SAAO;AACT;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;;AAEA;;EAEE,oCAAoC;AACtC","sourcesContent":[".card-item-feature {\n  height: 450px;\n  border-bottom: 5px solid var(--primary-color);\n  background-color: var(--light-color);\n  margin-bottom: 1.5rem;\n}\n\n.card-item-feature.primary {\n  background-color: var(--primary-color) !important;\n}\n\n.card-item-feature .card-body {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.card-item-feature .card-item-feature-icon-container {\n  width: 80px;\n  height: 80px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50%;\n  margin-bottom: 2rem;\n}\n\n.card-item-feature .card-item-feature-icon-container img {\n  width: 80px;\n}\n\n.card-item-feature .card-item-feature-description {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  gap: 1.5rem;\n  margin-bottom: 2rem;\n  flex: 1;\n}\n\n.card-item-feature .card-item-feature-description p {\n  opacity: 0.8;\n}\n\n.card-item-feature .card-item-feature-footer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 2rem;\n}\n\n.card-item-feature.primary .text-primary,\n.card-item-feature.primary p {\n  color: var(--light-color) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
