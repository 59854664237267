import React from "react";
import Appointments from "../../components/BookAppointment/Appointments";
import { Tabs } from "../../components/Tabs";
import { connect } from "react-redux";
import language from "../../language";
import { DashboardHeader } from "../../components/Header";
import { getStorage } from "../../utils/storage";
class AppointmentsScreen extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };
  render() {
    return (
      <>
        <div className="card-body">
          <DashboardHeader
            title={language[this.props.defaultLanguage].appointments}
          />
          <Tabs
            options={[
              {
                title: language[this.props.defaultLanguage].all,
                data: (
                  <Appointments
                    route={this.props.route}
                    donorId={this.state.user.id}
                  />
                ),
              },
              {
                title: language[this.props.defaultLanguage].pending,
                data: (
                  <Appointments
                    route={this.props.route}
                    status="pending"
                    donorId={this.state.user.id}
                  />
                ),
              },
              {
                title: language[this.props.defaultLanguage].approved,
                data: (
                  <Appointments
                    route={this.props.route}
                    status="approved"
                    donorId={this.state.user.id}
                  />
                ),
              },
              {
                title: language[this.props.defaultLanguage].cancelled,
                data: (
                  <Appointments
                    route={this.props.route}
                    status="cancelled"
                    donorId={this.state.user.id}
                  />
                ),
              },
            ]}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(AppointmentsScreen);
