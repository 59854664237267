import React from "react";
import { connect } from "react-redux";
import { Tabs } from "../../../components/Tabs";
import language from "../../../language";
import { SearchDonor, Donors } from "../../../components/Donors";
import { VitalSigns } from "../../../components/VitalSigns";
import { Appointments } from "../../../components/BookAppointment";
import { defineRole } from "../../../utils/handleAccessRoles";
import PreDonationAssessment from "../../../components/BloodAssessment/Admin/PreDonationAssessment";
import { getStorage } from "../../../utils/storage";
import {
  ROLE_APPOINTMENT,
  ROLE_ASSESSMENT,
  ROLE_DONORS,
  ROLE_VITAL_SIGNS,
} from "../../../constants/ROLES";
import SearchDonation from "../../../components/BloodCollection/SearchDonation";
class DonorsScreen extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };

  render() {
    let tabOptions = [];

    const canAccessSearchTab = true;
    // defineRole({
    //   roles: this.state.user.accessRole,
    //   menu: ROLE_DONORS,
    //   operation: "search",
    // });

    const canAccessAppointmentTab = defineRole({
      roles: this.state.user.accessRole,
      menu: ROLE_APPOINTMENT,
      operation: "read",
    });

    const canAccessDonorsTab = defineRole({
      roles: this.state.user.accessRole,
      menu: ROLE_DONORS,
      operation: "read",
    });

    const canAccessPreAssessmentTab = defineRole({
      roles: this.state.user.accessRole,
      menu: ROLE_ASSESSMENT,
      operation: "read",
    });

    if (canAccessSearchTab) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].search,
        data: (
          <>
            <div className="col-md-6 mx-auto">
              <div className="card">
                {
                  <Tabs
                    options={[
                      {
                        title:
                          language[this.props.defaultLanguage].search_donor,
                        data: (
                          <div className="card-bold">
                            <SearchDonor
                              filters={this.props.filters}
                              showResultModal
                            />
                          </div>
                        ),
                      },
                      {
                        title:
                          language[this.props.defaultLanguage]
                            .search_by_donation,
                        data: (
                          <div className="card-bold">
                            <SearchDonation
                              filters={this.props.filters}
                              showResultModal
                              navigateToDonor="/dashboard/donors"
                            />
                          </div>
                        ),
                      },
                    ]}
                  />
                }
                {/* <div className="card-header">
                <span className="text-bold">{language[this.props.defaultLanguage].search_donor}</span>
              </div>
              <div className="card-bold">
                <SearchDonor filters={this.props.filters} showResultModal />
              </div> */}
              </div>
            </div>
          </>
        ),
      });
    }

    const canAccessVitalSignTab = defineRole({
      roles: this.state.user.accessRole,
      menu: ROLE_VITAL_SIGNS,
      operation: "read",
    });

    if (canAccessDonorsTab) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].donors,
        data: <Donors filters={this.props.filters} />,
      });
    }

    if (canAccessAppointmentTab) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].appointments,
        data: <Appointments route="/dashboard" />,
      });
    }

    if (canAccessPreAssessmentTab) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].preassessment,
        data: <PreDonationAssessment />,
      });
    }

    if (canAccessVitalSignTab) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].vital_signs,
        data: <VitalSigns />,
      });
    }

    if (canAccessDonorsTab) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].blocked_donors,
        data: (
          <Donors
            filters={{
              ...this.props.filters,
              "blockInfo.blocked": true,
            }}
            unblockActive
            route="/dashboard"
          />
        ),
      });
    }

    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <Tabs options={tabOptions} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(DonorsScreen);
