import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../../../utils/withRouter";
import language from "../../../../language";
import { Tabs } from "../../../../components/Tabs";
import accessMenu from "../../../../utils/accessMenu";
import BloodCollection from "../../../../components/BloodCollection/BloodCollection";
import { Breadcrumb } from "../../../../components/Breadcrumb";

class CenterInventoryScreen extends React.Component {
  render() {
    let { canReadCollection } = accessMenu();
    let tabOptions = [];

    if (canReadCollection) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].phlebotomy,
        data: (
          <BloodCollection
            {...this.props.routeParams}
            isReception
            centerId={this.props.routeParams.centerId}
            centerSiteId={this.props.routeParams.centerSiteId}
          />
        ),
      });
    }

    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <Breadcrumb
              homeUrl="/dashboard/home"
              data={[
                {
                  name: language[this.props.defaultLanguage].inventories,
                  url: "/dashboard/component production",
                },
                {
                  name: this.props.routeParams.centerName,
                  active: true,
                },
              ]}
            />

            <Tabs options={tabOptions} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(CenterInventoryScreen));
