// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-count-container {
  border-top-width: 3px;
  border-top-style: solid;
}

.card-count-container .card-body {
  display: flex;
  min-height: 112px;
  align-items: center;
  gap: 1.5rem;
}

.card-count-container .card-count-icon-container {
  width: 34px;
  height: 34px;
  border-radius: var(--border-radius-half);
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-count-container .card-count-icon-container span {
  color: var(--light-color);
}

.card-count-container .card-count-content {
  flex: 1 1;
}

.card-count-container .card-count-content .count {
  margin-bottom: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/CardCount/styles.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,wCAAwC;EACxC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".card-count-container {\r\n  border-top-width: 3px;\r\n  border-top-style: solid;\r\n}\r\n\r\n.card-count-container .card-body {\r\n  display: flex;\r\n  min-height: 112px;\r\n  align-items: center;\r\n  gap: 1.5rem;\r\n}\r\n\r\n.card-count-container .card-count-icon-container {\r\n  width: 34px;\r\n  height: 34px;\r\n  border-radius: var(--border-radius-half);\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n\r\n.card-count-container .card-count-icon-container span {\r\n  color: var(--light-color);\r\n}\r\n\r\n.card-count-container .card-count-content {\r\n  flex: 1;\r\n}\r\n\r\n.card-count-container .card-count-content .count {\r\n  margin-bottom: 0.5rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
