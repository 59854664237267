import React from "react";
import { connect } from "react-redux";
import { Tabs } from "../../../components/Tabs";
import language from "../../../language";
import { withRouter } from "../../../utils/withRouter";
import { DashboardHeader } from "../../../components/Header";
import {
  DonationBloodGroup,
  BloodCollectionSessions,
  BloodCollectionUnits,
  BloodCollectionSites,
} from "../../../components/Analytics/";
import { CentersFilter, DateFilter } from "../../../components/Filters";
import DonationPerGender from "../../../components/Analytics/DonationPerGender";

class AnalyticsScreen extends React.Component {
  render() {
    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <div style={{ marginBottom: 16 }}>
              <div style={{ flex: 1 }}>
                <DashboardHeader
                  title={language[this.props.defaultLanguage].analytics}
                />
              </div>
              <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
                <CentersFilter />
                <DateFilter />
              </div>
            </div>

            <Tabs
              options={[
                {
                  title:
                    language[this.props.defaultLanguage].blood_collection_sites,
                  data: <BloodCollectionSites />,
                },
                {
                  title:
                    language[this.props.defaultLanguage]
                      .blood_collection_sessions,
                  data: <BloodCollectionSessions />,
                },
                {
                  title:
                    language[this.props.defaultLanguage].blood_collection_units,
                  data: <BloodCollectionUnits />,
                },
                {
                  title:
                    language[this.props.defaultLanguage].donation_by_gender,
                  data: <DonationPerGender />,
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(AnalyticsScreen));
