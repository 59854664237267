import React from "react";
import { connect } from "react-redux";
import { Tabs } from "../../../components/Tabs";
import language from "../../../language";
import BloodCollection from "../../../components/BloodCollection/BloodCollection";
import BloodTesting from "../../../components/BloodTesting/BloodTesting";
import BlockedBloodCollection from "../../../components/BloodCollection/BlockedBloodCollection";
import { ROLE_BLOOD_TEST, ROLE_COLLECTIONS } from "../../../constants/ROLES";
import { getStorage } from "../../../utils/storage";
import { defineRole } from "../../../utils/handleAccessRoles";
import SearchDonation from "../../../components/BloodCollection/SearchDonation";

class BloodDonationScreen extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });
  };
  render() {
    let tabOptions = [
      {
        title: language[this.props.defaultLanguage].search_by_donation,
        data: (
          <div className="card">
            <div className="col-md-6">
              <div className="card-bold">
                <SearchDonation
                  filters={this.props.filters}
                  showResultModal
                  navigationUrl="/dashboard/donations"
                />
              </div>
            </div>
          </div>
        ),
      },
    ];

    const canAccessCollection = defineRole({
      roles: this.state.user.accessRole,
      menu: ROLE_COLLECTIONS,
      operation: "read",
    });

    const canBloodTest = defineRole({
      roles: this.state.user.accessRole,
      menu: ROLE_BLOOD_TEST,
      operation: "read",
    });

    if (canAccessCollection) {
      tabOptions.push(
        {
          title: language[this.props.defaultLanguage].collections,
          data: <BloodCollection />,
        },
        {
          title: language[this.props.defaultLanguage].blocked_donations,
          data: <BlockedBloodCollection />,
        }
      );
    }

    if (canBloodTest) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].blood_testing,
        data: <BloodTesting />,
      });
    }

    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <Tabs options={tabOptions} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(BloodDonationScreen);
