// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print {
  body,
  html {
    height: auto;
    overflow: visible !important;
  }

  .printable-content {
    height: auto;
    overflow: visible !important;
  }
  .footer-worksheet {
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 10px;
    background: #fff;
    font-size: 12px;
  }

  .printable-content::after {
    content: "";
    display: block;
    height: 50px; /* Adjust according to your footer height */
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Print/styles.css"],"names":[],"mappings":"AAAA;EACE;;IAEE,YAAY;IACZ,4BAA4B;EAC9B;;EAEA;IACE,YAAY;IACZ,4BAA4B;EAC9B;EACA;IACE,eAAe;IACf,SAAS;IACT,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,gBAAgB;IAChB,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,cAAc;IACd,YAAY,EAAE,2CAA2C;EAC3D;AACF","sourcesContent":["@media print {\r\n  body,\r\n  html {\r\n    height: auto;\r\n    overflow: visible !important;\r\n  }\r\n\r\n  .printable-content {\r\n    height: auto;\r\n    overflow: visible !important;\r\n  }\r\n  .footer-worksheet {\r\n    position: fixed;\r\n    bottom: 0;\r\n    width: 100%;\r\n    text-align: center;\r\n    padding: 10px;\r\n    background: #fff;\r\n    font-size: 12px;\r\n  }\r\n\r\n  .printable-content::after {\r\n    content: \"\";\r\n    display: block;\r\n    height: 50px; /* Adjust according to your footer height */\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
