import { Logo } from "../Logo";
import "./styles.css";

const Splash = () => {
  return (
    <div className="splash-container">
      <Logo />
    </div>
  );
};

export default Splash;
