import React from "react";
import { connect } from "react-redux";
import CardCount from "../../components/CardCount/CardCount";
import language from "../../language";
class HomeScreen extends React.Component {
  state = {
    isLoading: false,
  };

  componentDidMount() {}

  render() {
    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <div className="row">
              <div className="col-md-3">
                <CardCount
                  count={10}
                  title={language[this.props.defaultLanguage].center_sites}
                  color="#078ECE"
                  icon=""
                />
              </div>
              <div className="col-md-3">
                <CardCount
                  count={10}
                  title={language[this.props.defaultLanguage].centers}
                  color="#FF6D00"
                />
              </div>
              <div className="col-md-3">
                <CardCount
                  count={100}
                  title={language[this.props.defaultLanguage].donors}
                  color="#008000"
                />
              </div>
              <div className="col-md-3">
                <CardCount
                  count={10}
                  title={language[this.props.defaultLanguage].donations}
                  color="#D10000"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(HomeScreen);
