import React from "react";
import { connect } from "react-redux";
import {
  BloodGroupByAge,
  ConfirmedHIV,
  DonationBloodGroupPerCenter,
  DonationBloodGroupPerCenterSite,
  Donors,
} from "../../components/Report";
import language from "../../language";
import { Tabs } from "../../components/Tabs";
import BloodGroupByGender from "../../components/Report/BloodGroupByGender";
import DonorsPerBloodGroup from "../../components/Report/DonorsPerBloodGroup";

class ReportsScreen extends React.Component {
  render() {
    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <Tabs
              options={[
                {
                  title: language[this.props.defaultLanguage].donors,
                  data: <Donors />,
                },
                {
                  title:
                    language[this.props.defaultLanguage].donors_per_blood_group,
                  data: <DonorsPerBloodGroup />,
                },
                {
                  title:
                    language[this.props.defaultLanguage]
                      .donation_blood_group_per_center,
                  data: <DonationBloodGroupPerCenter />,
                },
                {
                  title:
                    language[this.props.defaultLanguage]
                      .donation_blood_group_per_center_site,
                  data: <DonationBloodGroupPerCenterSite />,
                },
                {
                  title:
                    language[this.props.defaultLanguage].blood_donation_gender,
                  data: <BloodGroupByGender />,
                },
                {
                  title:
                    language[this.props.defaultLanguage].blood_donation_by_ages,
                  data: <BloodGroupByAge />,
                },
                // {
                //   title: language[this.props.defaultLanguage].confirmed_hiv,
                //   data: <ConfirmedHIV />,
                // },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ReportsScreen);
