import toastMessage from "../toastMessage";
import { getStorage } from "../storage";
import axios from "axios";
import language from "../../language";
import { defineRole } from "../handleAccessRoles";
import { ROLE_COLLECTION_TYPE } from "../../constants/ROLES";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

export const fetchCollectionTypes = async (
  lng = "english",
  { page, limit, type, id, collectionTypeNumber }
) => {
  try {
    const user = await getStorage();

    const canAccess = defineRole({
      roles: user.accessRole,
      menu: ROLE_COLLECTION_TYPE,
      operation: "read,readOnAction",
    });

    if (!canAccess) return;

    const options = {
      method: "GET",
      url: `${API_URL}/collectionType`,
      params: {
        page: page,
        limit: limit,
        id,
        collectionTypeNumber,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await axios(options);

    let { data, count } = queryRes.data;

    if (type === "dropdown") {
      return queryRes.data.map((el) => {
        return {
          ...el,
          label: el.collectionTypeNumber + " - " + el.name,
          value: el._id,
        };
      });
    }

    return !page && !limit ? queryRes.data : { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};

export const deleteCollectionType = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const canAccess = defineRole({
      roles: user.accessRole,
      menu: ROLE_COLLECTION_TYPE,
      operation: "delete",
    });

    if (!canAccess) return;

    const options = {
      method: "DELETE",
      url: `${API_URL}/collectionType`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await axios(options);

    toastMessage("success", language[lng].add_data_success);
  } catch (error) {
    toastMessage("error", error);
  }
};
