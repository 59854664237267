import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { Table } from "../../Table";
import { CSVLink } from "react-csv";
import {
  fetchPreAssessmentInformation,
  deletePreAssessmentDonorsInfo,
} from "../../../utils/queries/preAssessmentQuery";
import exportPDF from "../../../utils/exportPDF";
import handleSearch from "../../../utils/handleSearch";
import { ListItem } from "../../List";
import { DeleteModal, Modal } from "../../Modal";
import {
  defineRole,
  defineRoleCenterSite,
} from "../../../utils/handleAccessRoles";
import { getStorage } from "../../../utils/storage";
import icons from "../../../constants/icons";
import moment from "moment";
import { Input } from "../../Input";
import { SearchDonor } from "../../Donors";
import { ROLE_ASSESSMENT } from "../../../constants/ROLES";

let copyData = [],
  copyQuestionnaireData = [];

class PreDonationAssessment extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selected_data: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
    healthQuestionnaire: [],
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    await this.getData(true);
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target.value,
      error,
    });
  }

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, centerId, siteId } = this.props;
      const user = await getStorage();

      this.setState({ isLoading });

      const { centerRole, centerSiteRole } = await defineRoleCenterSite();

      const { data, count } = await fetchPreAssessmentInformation(
        defaultLanguage,
        {
          page,
          limit,
          center: centerId || centerRole,
          centerSite: siteId || centerSiteRole,
          donor: this.props.donorId
            ? this.props.donorId
            : this.props.route === "/user"
            ? user.id
            : undefined,
        }
      );

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selected_data = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_data,
      healthQuestionnaire: selected_data?.healthQuestionnaire || [],
    });

    copyQuestionnaireData = selected_data?.healthQuestionnaire?.slice(0);
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].donor,
        key: "donor.donorNumber",
        type: "link",
        onPress: (item) =>
          this.handleShowModal(
            "showHeathQuestionnaireModal",
            item?.donor?.donorNumber,
            item
          ),
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].center,
        key: "center.name",
      },
      {
        title: language[this.props.defaultLanguage].center_site,
        key: "centerSite.name",
      },
      {
        title: language[this.props.defaultLanguage].created_at,
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
      // {
      //   title: "",
      //   key: "multiple-actions",
      //   actions: [
      //     {
      //       icon: icons.eye_on,
      //       title: language[this.props.defaultLanguage].view_application,
      //       onPress: (item) =>
      //         this.handleShowModal(
      //           "showHeathQuestionnaireModal",
      //           item?.donor?.donorNumber,
      //           item
      //         ),
      //     },
      //     {
      //       icon: icons.delete,
      //       className: "btn-danger",
      //
      //     },
      //   ],
      // },
    ];

    return headers;
  }

  downloadExcel = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      const { data } = await fetchPreAssessmentInformation(defaultLanguage, {
        page,
        limit,
      });

      this.setState({
        isLoading: false,
        csvData: data,
      });

      this.refs.csvDownload?.link.click();
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  downloadPDF = async () => {
    this.setState({
      isLoading: true,
    });

    const { page, limit } = this.state;
    const { defaultLanguage } = this.props;

    const headers = this.returnTableHeaders();

    const { data } = await fetchPreAssessmentInformation(defaultLanguage, {
      page,
      limit,
    });

    this.setState({
      isLoading: false,
    });

    exportPDF("Pre-assessments", headers, data);
  };

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deletePreAssessmentDonorsInfo(
        this.props.defaultLanguage,
        this.state.selected_data._id
      );

      this.setState({ isDeleting: false });

      this.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      this.setState({
        isDeleting: false,
      });
    }
  };

  handleSearchHeathQuestionnaire(e) {
    let searchQuestionnaire = e.target.value,
      result = [];

    for (let el of copyQuestionnaireData) {
      if (
        JSON.stringify(el)
          .toLowerCase()
          .indexOf(searchQuestionnaire.toLowerCase()) !== -1
      ) {
        result.push(el);
      }
    }

    this.setState({ healthQuestionnaire: result });
  }

  render() {
    const { export_, edit_donor, delete_donor, delete_, edit } =
      language[this.props.defaultLanguage];

    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={(e) =>
            handleSearch(this, {
              copyData,
              value: e.target.value,
            })
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          showAdd
          addButtonText={language[this.props.defaultLanguage].new_assessment}
          handleAddPressed={() =>
            this.handleShowModal(
              "showSearchModal",
              language[this.props.defaultLanguage].search_donor
            )
          }
          rowPress={(item) =>
            this.handleShowModal("showModal", item.name, item)
          }
          filters={[
            {
              type: "button",
              title: language[this.props.defaultLanguage].refresh,
              button_type: "button",
              icon: icons.refresh,
              onPress: (item) => this.getData(true),
            },
            // {
            //   type: "export",
            //   title: export_,
            //   button_type: "dropdown",
            //   icon: "bx bxs-download",
            //   options: [
            //     {
            //       name: "PDF",
            //       onPress: this.downloadPDF.bind(this),
            //     },
            //     {
            //       name: "CSV",
            //       onPress: this.downloadExcel.bind(this),
            //     },
            //   ],
            // },
          ]}
          actions={[
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_ASSESSMENT,
                operation: "update",
              }),
              name: edit,
              onPress: (item) =>
                this.handleShowModal(
                  "showHeathQuestionnaireModal",
                  item?.donor?.donorNumber,
                  item
                ),
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_ASSESSMENT,
                operation: "delete",
              }),
              name: delete_,
              onPress: (item) =>
                this.handleShowModal(
                  "showDeleteModal",
                  item?.donor?.firstName + "" + item?.donor?.lastName,
                  item
                ),
            },
          ]}
        />

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showSearchModal")}
          show={this.state.showSearchModal}
          title={this.state.modalTitle}
        >
          <SearchDonor
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showSearchModal"
            )}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(
            this,
            "showHeathQuestionnaireModal"
          )}
          show={this.state.showHeathQuestionnaireModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
          size="lg"
        >
          {this.state.selected_data?.createdAt && (
            <div className="card-header">
              <div className="card-header-item">
                <span>Date:</span>
                <span className="text-bold ">
                  {moment(this.state.selected_data?.createdAt).format("lll")}
                </span>
              </div>
              <div className="card-header-item">
                <span>Center:</span>
                <span className="text-bold ">
                  {this.state?.selectedData?.center?.name}
                </span>
              </div>
              <div className="card-header-item">
                <span>Center Site:</span>
                <span className="text-bold ">
                  {this.state?.selectedData?.centerSite?.name}
                </span>
              </div>
              <div className="card-header-item">
                <Input
                  placeholder={language[this.props.defaultLanguage].search}
                  rightButtonIcon={icons.search}
                  value={this.state.searchQuestionnaire}
                  rightButtonPressed={() =>
                    this.handleSearchHeathQuestionnaire.bind(this)
                  }
                  onChange={this.handleSearchHeathQuestionnaire.bind(this)}
                />
              </div>
            </div>
          )}

          <div className="card-body">
            <div className="row">
              {this.state.healthQuestionnaire?.map((el, i) => {
                return (
                  <div key={i} className="col-md-6">
                    <ListItem
                      name={el.questionText}
                      className="list-item-card mb-3"
                      rightDescription={el.answer}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state?.selected_data.donor?.username}
            getData={this.getData.bind(this)}
          />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={"Pre-Assessments" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(PreDonationAssessment);
