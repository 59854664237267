import React from "react";
import { connect } from "react-redux";
import { Input } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import icons from "../../constants/icons";

class ChangePassword extends React.Component {
  state = {
    confirmPassword: "",
    password: "",
    error: {},
    otpIsSent: true,
  };

  onChangeText(field, e) {
    let { error } = this.state;
    delete error[field];

    this.setState({
      [field]: e.target.value,
      error,
    });
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-4">
            <Input
              leftIcon={icons.password}
              placeholder={
                language[this.props.defaultLanguage].new_password_placeholder
              }
              label={language[this.props.defaultLanguage].new_password}
              required
              value={this.state.password}
              type="password"
              onChange={(e) => this.onChangeText("password", e)}
            />
            <Input
              leftIcon={icons.password}
              placeholder={
                language[this.props.defaultLanguage].new_password_placeholder
              }
              label={language[this.props.defaultLanguage].confirm_password}
              required
              value={this.state.confirmPassword}
              onChange={(e) => this.onChangeText("confirmPassword", e)}
              helperText={
                language[this.props.defaultLanguage]
                  .otp_code_will_be_sent_confirm_reset
              }
            />
            {this.state.otpIsSent ? (
              <>
                <Input
                  leftIcon={icons.key}
                  placeholder={
                    language[this.props.defaultLanguage].enter_otp_code
                  }
                  label={language[this.props.defaultLanguage].otp_code}
                  required
                  value={this.state.otpCode}
                  onChange={(e) => this.onChangeText("otpCode", e)}
                />
                <Button
                  text={language[this.props.defaultLanguage].reset_password}
                />
              </>
            ) : (
              <Button text={language[this.props.defaultLanguage].send_otp} />
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ChangePassword);
