import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import { getStorage } from "../../../utils/storage";
import availableOptions from "../../../constants/availableOptions";
import { fetchComponentsSeparationProfiles } from "../../../utils/queries/production/profiles/separation";
import formatSelectData from "../../../utils/formatSelectData";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class UpdateSeparation extends React.Component {
  state = {
    error: {},
    isSubmitting: false,
    donation: {},
    isLoading: true,
    profiles: [],
    volume: "",
    components: [],
  };

  componentDidMount() {
    console.log(this.props);
    if (this.props._id) {
      this.setState({
        ...this.props,
        profile: this.props.componentProfile?._id
          ? {
              ...this.props.componentProfile,
              label:
                this.props.componentProfile.name +
                " - " +
                this.props.componentProfile.code,
              value: this.props.componentProfile._id,
            }
          : null,
        component: this.props.component?._id
          ? {
              ...this.props.component,
              label:
                this.props.component.name + " - " + this.props.component.code,
              value: this.props.component._id,
            }
          : null,
        components: this.props.componentProfile.components?.map((el) => {
          return {
            ...el,
            label: el.name + " " + el.code,
            value: el._id,
          };
        }),
      });
    }
  }

  getComponentProfiles = async (isFetchingProfile, code) => {
    try {
      this.setState({
        isFetchingProfile,
      });

      const data = await fetchComponentsSeparationProfiles(
        this.props.defaultLanguage,
        {
          type: "separation",
          inputType: "dropdown",
          code,
        }
      );

      this.setState({
        isFetchingProfile: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingProfile: false });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e.target ? e.target.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });

    if (field === "profile") {
    }
  };

  validateForm() {
    let { profile, error, component, volume } = this.state;

    if (!profile) {
      error.profile =
        language[this.props.defaultLanguage].component_profile_required;
    }

    if (!component) {
      error.component = language[this.props.defaultLanguage].component_required;
    }

    if (volume === "" || !volume) {
      error.volume = language[this.props.defaultLanguage].volume_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      const { donation } = this.state;
      this.setState({
        isSubmitting: true,
      });

      await this.handleSeparationData(donation);
    }
  };

  handleSeparationData = async (donation) => {
    let { _id } = this.props;
    let { profile, available, volume, component } = this.state,
      url = `${API_URL}/bloodSeparation`,
      method = "POST",
      user = await getStorage();

    try {
      let requestedBody = {
        component: component._id,
        donation: donation,
        name: component.name,
        code: component.code,
        componentProfile: profile.value,
        volume,
      };

      if (method === "PUT") {
        requestedBody.modifiedBy = user.id;
      } else {
        requestedBody.addedBy = user.id;
      }

      if (_id && _id !== "") {
        method = "PUT";
        requestedBody.id = _id;

        delete requestedBody.addedBy;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };
      await axios(options);

      this.setState({
        isSubmitting: false,
      });

      toastMessage(
        "success",
        `${
          language[this.props.defaultLanguage][
            method === "PUT" ? "update_data_success" : "add_data_success"
          ]
        }`
      );

      this.props.getData && this.props.getData(true);
      this.props.handleCloseModal && this.props.handleCloseModal();
    } catch (error) {
      this.setState({ isSubmitting: false });
      toastMessage("error", error);
    }
  };

  render() {
    return (
      <div className="card-body">
        <Select
          loadOptions={(v) => this.getComponentProfiles(true, v)}
          placeholder={language[this.props.defaultLanguage].select}
          label={language[this.props.defaultLanguage].component_profiles}
          required
          value={this.state.profile}
          onChange={(e) => this.onChangeText("profile", e)}
          error={this.state.error.profile}
          isLoading={this.state.isFetchingProfile}
        />

        <Select
          options={this.state.components}
          placeholder={language[this.props.defaultLanguage].select}
          label={language[this.props.defaultLanguage].component}
          required
          value={this.state.component}
          onChange={(e) => this.onChangeText("component", e)}
          error={this.state.error.component}
        />

        <Input
          placeholder={language[this.props.defaultLanguage].volume_placeholder}
          label={language[this.props.defaultLanguage].volume}
          required
          value={this.state.volume}
          onChange={(e) => this.onChangeText("volume", e)}
          error={this.state.error.volume}
        />
        <div className="col-md-12">
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(UpdateSeparation);
