import jsPDF from "jspdf";
import "jspdf-autotable";
import JsBarcode from "jsbarcode";
import { createCanvas } from "canvas";

// Function to generate barcode as a base64 image
function generateBarcode(data) {
  const canvas = createCanvas();
  JsBarcode(canvas, data, {
    format: "CODE128",
    displayValue: false,
    height: 30,
  });
  return canvas.toDataURL("image/png");
}

export default async function generateABODonorWorksheet({
  center = "",
  centerSite = "",
  headers,
  data,
  fileName,
}) {
  const doc = new jsPDF("landscape", "pt", "A4");
  const marginLeft = 10;

  // Set font size for the title
  doc.setFontSize(12);
  doc.text("Donor Worksheet", marginLeft, 50);
  doc.setFontSize(10);
  doc.text("ABO Rh Group", marginLeft, 70);
  doc.text(
    `${center ? center + "-" : ""}${centerSite ? centerSite : ""}`,
    marginLeft,
    85
  );

  // Generate barcodes for each donation number
  const barcodes = await Promise.all(
    data.map((row) => generateBarcode(row.donationNumber))
  );

  // Define columns for autoTable based on the new worksheet format
  const autoTableColumns = [
    { header: "Donation No.", dataKey: "donationNumber" },
    { header: "ABO RHD R", dataKey: "aboRhd" },
    { header: "-A", dataKey: "forwardAlpha" },
    { header: "-B", dataKey: "forwardBeta" },
    { header: "-AB", dataKey: "forwardAlphaBeta" },
    { header: "-D", dataKey: "forwardD" },
    { header: "A", dataKey: "reverseA" },
    { header: "B", dataKey: "reverseB" },
    { header: "O", dataKey: "reverseO" },
    { header: "Kell", dataKey: "kell" },
    { header: "AS", dataKey: "as" },
    { header: "C", dataKey: "c" },
    { header: "First Entry", dataKey: "firstEntry" },
    { header: "Second Entry", dataKey: "secondEntry" },
  ];

  // Adding the table
  doc.autoTable({
    startY: 100,
    head: [autoTableColumns.map((col) => col.header)],
    body: data.map((row) => {
      return autoTableColumns.map((col) => row[col.dataKey] || "");
    }),
    didDrawCell: (data) => {
      const colIndex = data.column.index;
      if (colIndex === 0 && data.cell.section === "body") {
        const barcodeImage = barcodes[data.row.index];
        const cellWidth = data.cell.width;
        const cellHeight = data.cell.height;
        const imgWidth = cellWidth * 0.9; // Adjust width for padding
        const imgHeight = cellHeight * 0.4; // Adjust height for padding and leave space for text
        const imgX = data.cell.x + (cellWidth - imgWidth) / 2; // Center horizontally
        const imgY = data.cell.y + 5; // Padding from top

        // Ensure the barcode image is added correctly
        if (barcodeImage) {
          doc.addImage(barcodeImage, "PNG", imgX, imgY, imgWidth, imgHeight);

          // Add Donation Number and Donor Name below the barcode
          doc.setFontSize(8);
          const donationNumber = data.row.raw.donationNumber || "";
          const donorName = data.row.raw.donorName || "";
          const textY = imgY + imgHeight + 10; // Position text below barcode
          doc.text(donationNumber, data.cell.x + cellWidth / 2, textY, {
            align: "center",
          });
          doc.text(donorName, data.cell.x + cellWidth / 2, textY + 10, {
            align: "center",
          });
        } else {
          console.error(
            `Barcode image is not available for row index: ${data.row.index}`
          );
        }
      }
    },
    styles: { halign: "center", cellWidth: "wrap" },
    headStyles: { fillColor: ["#ac0207"] },
    theme: "grid",
    columnStyles: {
      0: { cellWidth: 120 }, // Width for Donation No. column
      1: { cellWidth: 70 }, // Width for ABO RHD R column
      2: { cellWidth: 40 }, // Width for -α column
      3: { cellWidth: 40 }, // Width for -β column
      4: { cellWidth: 40 }, // Width for -αβ column
      5: { cellWidth: 40 }, // Width for -D column
      6: { cellWidth: 40 }, // Width for Reverse A column
      7: { cellWidth: 40 }, // Width for Reverse B column
      8: { cellWidth: 40 }, // Width for Reverse O column
      9: { cellWidth: 50 }, // Width for Kell column
      10: { cellWidth: 50 }, // Width for AS column
      11: { cellWidth: 50 }, // Width for C column
      12: { cellWidth: 100 }, // Width for First Entry column
      13: { cellWidth: 100 }, // Width for Second Entry column
    },
  });

  // Footer details
  doc.setFontSize(8);
  doc.text("CNTS Kigali", marginLeft, doc.internal.pageSize.height - 30);
  doc.text(
    `Generated on: ${new Date().toLocaleDateString()}`,
    doc.internal.pageSize.width - 150,
    doc.internal.pageSize.height - 30
  );
  doc.text(
    `Page ${doc.internal.getNumberOfPages()}`,
    doc.internal.pageSize.width - 40,
    doc.internal.pageSize.height - 30
  );

  doc.save(fileName);
}
