import toastMessage from "../toastMessage";
import formatSelectData from "../formatSelectData";
import { getStorage } from "../storage";
import axios from "axios";
import language from "../../language";
import { defineRole, defineRoleCenterSite } from "../handleAccessRoles";
import { ROLE_REQUEST } from "../../constants/ROLES";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

const fetchData = async (
  lng = "english",
  { page, limit, type, category, user, id, status, requestNo }
) => {
  try {
    const { token, accessRole } = await getStorage();
    const { centerRole, hospitalRole } = await defineRoleCenterSite();

    const canAccess = defineRole({
      roles: accessRole,
      menu: ROLE_REQUEST,
      operation: "read",
    });

    if (!canAccess) return;

    const options = {
      method: "GET",
      url: `${API_URL}/bloodrequest`,
      params: {
        page: page,
        limit: limit,
        category,
        user,
        id,
        status,
        center: centerRole,
        hospital: hospitalRole,
        requestNo,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const queryRes = await axios(options);

    let { data, count } = queryRes.data;

    if (!data) {
      data = queryRes.data;
    }

    if (type === "dropdown") {
      return formatSelectData(queryRes.data, "description", "_id");
    }

    return { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};

export default fetchData;

export const deleteRequest = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const canAccess = defineRole({
      roles: user.accessRole,
      menu: ROLE_REQUEST,
      operation: "read",
    });

    if (!canAccess) return;

    const options = {
      method: "DELETE",
      url: `${API_URL}/bloodrequest`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await axios(options);

    toastMessage("success", language[lng].success_delete_request);
  } catch (error) {
    toastMessage("error", error);
  }
};
