// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-counter {
  top: 3px;
  right: 5px;
  background-color: var(--error-color);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--light-color);
}

.notification-counter span {
  color: var(--light-color);
  font-size: 9px;
  font-weight: var(--font-weight-md);
}

.notification-counter:hover span {
  color: var(--light-color) !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Notifications/styles.css"],"names":[],"mappings":"AAAA;EACE,QAAQ;EACR,UAAU;EACV,oCAAoC;EACpC,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,oCAAoC;AACtC;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,kCAAkC;AACpC;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":[".notification-counter {\n  top: 3px;\n  right: 5px;\n  background-color: var(--error-color);\n  width: 20px;\n  height: 20px;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border: 2px solid var(--light-color);\n}\n\n.notification-counter span {\n  color: var(--light-color);\n  font-size: 9px;\n  font-weight: var(--font-weight-md);\n}\n\n.notification-counter:hover span {\n  color: var(--light-color) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
