import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import { CSVLink } from "react-csv";
import handleSearch from "../../utils/handleSearch";
import { fetchCenterDonorBloodGroupData } from "../../utils/queries/report/donorBloodGroup";

let copyData = [];

class CenterDonorsBloodGroup extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selected_data: {},
    totalPageCount: 10,
    delete_password: "",
    error: {},
    csvData: [],
  };

  componentDidMount = async () => {
    await this.getData(true);
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({ error, [field]: e.target.value });
  }

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await fetchCenterDonorBloodGroupData(
        defaultLanguage,
        {
          page,
          limit,
          //center
          //centerSite
        }
      );
      console.log(data);

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].center,
        key: "center.name",
      },
      {
        title: "O+",
        key: "O+",
      },
      {
        title: "O-",
        key: "O-",
      },
      {
        title: "A+",
        key: "A+",
      },
      {
        title: "A-",
        key: "A-",
      },
      {
        title: "AB+",
        key: "AB+",
      },
      {
        title: "AB-",
        key: "AB-",
      },
      {
        title: "Total",
        key: "total",
      },
    ];

    return headers;
  }

  downloadExcel = async () => {};

  downloadPDF = async () => {};

  render() {
    const { export_ } = language[this.props.defaultLanguage];
    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={(e) =>
            handleSearch(this, {
              copyData,
              value: e.target.value,
            })
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          // filters={[
          //   {
          //     type: "export",
          //     title: export_,
          //     button_type: "dropdown",
          //     icon: "bx bxs-download",
          //     options: [
          //       {
          //         name: "PDF",
          //         onPress: this.downloadPDF.bind(this),
          //       },
          //       {
          //         name: "CSV",
          //         onPress: this.downloadExcel.bind(this),
          //       },
          //     ],
          //   },
          // ]}
        />

        <CSVLink
          ref="csvDownload"
          filename={"Campaigns" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(CenterDonorsBloodGroup);
