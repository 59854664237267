import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import { getStorage } from "../../../utils/storage";
import availableOptions from "../../../constants/availableOptions";
import requestQuery from "../../../utils/queries/requestQuery.js";
import icons from "../../../constants/icons";
import { fetchInventories } from "../../../utils/queries/inventories/center";
import Loading from "../../Loader/Loading.js";
import Table from "../../Table/Table.js";
import { Modal } from "../../Modal/index.js";
import { Empty } from "../../Empty";
import ListDistributor from "./Distributor/ListDistributor.js";
import Preparation from "../requestInfo/Preparation.js";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

const statusOptions = [
  {
    label: "Pending",
    value: "pending",
  },
];

class Dispatch extends React.Component {
  state = {
    error: {},
    isSubmitting: false,
    distributors: [],
    numberOfPackages: "",
    isActive: availableOptions[0],
    packages: [],
    status: statusOptions[0],
    isLoading: false,
    requestInfo: {},
    productInfo: {},
    packageInfo: {},
    givenVolume: "",
    givenNumberOfPackages: "",
    refresh: false,
  };

  componentDidMount = async () => {
    this.getRequestInfo(true);

    await this.setState({ ...this.props });
  };

  getRequestInfo = async (isLoading) => {
    try {
      this.setState({
        isLoading,
      });

      const { requestId } = this.props;

      const data = await requestQuery(this.props.defaultLanguage, {
        id: requestId,
      });

      let requestInfo = {};

      if (data?.data?.length > 0) {
        requestInfo = data?.data[0];
      } else {
        toastMessage("error", "Couldn't find request information");
      }

      this.setState({
        requestInfo,
        packages: requestInfo?.packages,
        isLoading: false,
      });
    } catch (error) {
      this.setState({ isLoading: false });

      toastMessage("error", error);
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e.target ? e.target.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });

    if (field === "hospital") {
      this.getBloodBanks(true);
    }
  };

  onSavePackages(packages) {
    this.setState({
      packages,
    });
  }

  validateSearchForm() {
    let { error, code } = this.state;

    if (code === "") {
      error.code = language[this.props.defaultLanguage].code_required;
    }

    this.setState({ error });
  }

  validateForm() {
    let { error, givenNumberOfPackages, givenVolume } = this.state;

    if (givenNumberOfPackages === "") {
      error.givenNumberOfPackages =
        language[this.props.defaultLanguage].number_of_packages_required;
    }

    if (givenVolume === "") {
      error.givenVolume = language[this.props.defaultLanguage].volume_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let {
          givenVolume,
          _id,
          givenNumberOfPackages,
          productInfo,
          status,
          requestInfo,
        } = this.state,
        url = `${API_URL}/bloodRequest/respondRequest`,
        method = "POST",
        user = await getStorage();

      let requestedBody = {
        requestId: requestInfo?._id,
        code: productInfo?.component?.code,
        package: {
          product: productInfo?.component?._id,
          volume: parseInt(givenVolume),
          numberOfPackages: parseInt(givenNumberOfPackages),
          status: status?.value,
          processedBy: user.id,
        },
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestedBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
            refresh: true,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT" ? "update_data_success" : "add_data_success"
            ]
          );

          this.handleSearch();
          this.getRequestInfo();
        })
        .catch((error) => {
          toastMessage("error", error);
          this.setState({
            isSubmitting: false,
            refresh: false,
          });
        });
    }
  };

  handleSearch = async () => {
    await this.validateSearchForm();

    try {
      if (Object.keys(this.state.error).length > 0) return;

      const { code, requestInfo, packages } = this.state;

      this.setState({
        isSearching: true,
      });

      const data = await fetchInventories(this.props.defaultLanguage, {
        code: code?.replace(/\s+/g, ""),
        center: requestInfo?.bloodBank?.center?._id,
      });

      let productInfo = {};
      let packageInfo = {};

      if (data.length === 1) {
        productInfo = data[0];
        this.handleShowModal(
          "showModal",
          language[this.props.defaultLanguage].product_info
        );

        packages?.forEach((el) => {
          if (el.productCode === productInfo.code) {
            packageInfo = {
              ...el,
            };
          }
        });
      } else {
        toastMessage("error", "Product is not available in the stock");
      }
      this.setState({
        productInfo,
        isSearching: false,
        packageInfo,
        // givenVolume: packageInfo?.volume || "",
        // givenNumberOfPackages: packageInfo?.numberOfPackages || "",
      });
    } catch (error) {
      this.setState({ isSearching: false });
    }
  };

  handleShowModal(modal) {
    this.setState({
      [modal]: true,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  render() {
    if (this.state.isLoading) {
      return (
        <center>
          <Loading />
        </center>
      );
    }

    if (!this.state.requestInfo?._id) {
      return <Empty title={language[this.props.defaultLanguage].no_data} />;
    }

    return (
      <>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-6" style={{ marginBottom: "1rem" }}>
                <Input
                  placeholder={
                    language[this.props.defaultLanguage]
                      .request_number_placeholder
                  }
                  label={language[this.props.defaultLanguage].request_number}
                  required
                  value={this.state?.requestInfo?.requestNo}
                  onChange={(e) => this.onChangeText("requestNo", e)}
                  error={this.state.error.requestNo}
                  disabled
                />
                <Input
                  placeholder={
                    language[this.props.defaultLanguage].search_product + "..."
                  }
                  label={language[this.props.defaultLanguage].code}
                  value={this.state.code}
                  onChange={(e) => this.onChangeText("code", e)}
                  error={this.state.error.product}
                  leftIcon={icons.package}
                />
                <Button
                  text={language[this.props.defaultLanguage].search}
                  isSubmitting={this.state.isSearching}
                  onPress={this.handleSearch.bind(this)}
                />
              </div>
              <hr />
              <Table
                data={this.state.requestInfo.packages}
                headers={[
                  {
                    title: language[this.props.defaultLanguage].code,
                    key: "productCode",
                  },
                  {
                    title: language[this.props.defaultLanguage].product_name,
                    key: "productName",
                    styles: {
                      textTransform: "uppercase",
                    },
                  },
                  {
                    title: language[this.props.defaultLanguage].blood_group,
                    key: "bloodGroup",
                  },
                  {
                    title:
                      language[this.props.defaultLanguage].number_of_packages,
                    key: "numberOfPackages",
                  },
                  {
                    title:
                      language[this.props.defaultLanguage].requested_volume +
                      "(ML)",
                    key: "volume",
                  },
                  {
                    title: language[this.props.defaultLanguage].note,
                    key: "note",
                  },
                ]}
              />
            </div>
          </div>
        </div>

        <div className="card" style={{ marginTop: "1rem" }}>
          <div className="card-header">
            <span className="text-bold">
              {language[this.props.defaultLanguage].product_request_history}
            </span>
          </div>
          <div className="card-body">
            <Preparation
              requestId={this.props.requestId}
              refresh={this.state.refresh}
            />
          </div>
        </div>
        {this.state.requestInfo?._id && (
          <div className="card" style={{ marginTop: "1rem" }}>
            <div className="card-header">
              <span className="text-bold">
                {language[this.props.defaultLanguage].distributor}
              </span>
            </div>
            <div className="card-body">
              <ListDistributor
                requestId={this.state.requestInfo._id}
                bloodBank={this.state?.requestInfo?.bloodBank}
                requestNo={this.state?.requestInfo?.requestNo}
              />
            </div>
          </div>
        )}
        <Modal
          show={this.state.showModal}
          handleClose={() => this.handleCloseModal("showModal")}
        >
          <div className="card-body">
            <Input
              placeholder={language[this.props.defaultLanguage].code}
              label={language[this.props.defaultLanguage].code}
              value={this.state.productInfo.code}
              disabled
            />
            <Input
              placeholder={language[this.props.defaultLanguage].name}
              label={language[this.props.defaultLanguage].name}
              value={this.state.productInfo.name}
              disabled
            />
            <Input
              placeholder={
                language[this.props.defaultLanguage].available_volume
              }
              label={language[this.props.defaultLanguage].available_volume}
              value={this.state.productInfo.volume}
              disabled
            />
            <hr />
            <Input
              placeholder={
                language[this.props.defaultLanguage].number_of_packages
              }
              label={language[this.props.defaultLanguage].number_of_packages}
              value={this.state.givenNumberOfPackages}
              onChange={(e) => this.onChangeText("givenNumberOfPackages", e)}
              error={this.state.error.givenNumberOfPackages}
              type="number"
            />
            <Input
              placeholder={language[this.props.defaultLanguage].given_volume}
              label={language[this.props.defaultLanguage].given_volume}
              value={this.state.givenVolume}
              onChange={(e) => this.onChangeText("givenVolume", e)}
              error={this.state.error.givenVolume}
            />
            <Select
              options={statusOptions}
              placeholder={language[this.props.defaultLanguage].select}
              label={language[this.props.defaultLanguage].status}
              value={this.state.status}
              onChange={(e) => this.onChangeText("status", e)}
              error={this.state.error.status}
            />
          </div>
          <div className="modal-footer">
            <Button
              text={language[this.props.defaultLanguage].close}
              onPress={this.handleCloseModal.bind(this, "showModal")}
              className="btn-default"
            />
            <Button
              text={language[this.props.defaultLanguage].submit}
              onPress={this.onSubmit.bind(this)}
              isSubmitting={this.state.isSubmitting}
            />
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Dispatch);
