import React from "react";
import { connect } from "react-redux";
import "./styles.css";
import { Button } from "../../Button";
import language from "../../../language";

class CenterInfo extends React.Component {
  render() {
    return (
      <>
        <div className="card-body">
          <div className="center-info-item">
            <span className="center-info-item-name">Code</span>
            <span className="text-bold">{this.props?.center?.code}</span>
          </div>
          <div className="center-info-item">
            <span className="center-info-item-name">Province</span>
            <span className="text-bold">
              {this.props?.center?.address?.province}
            </span>
          </div>
          <div className="center-info-item">
            <span className="center-info-item-name">District</span>
            <span className="text-bold">
              {this.props?.center?.address?.district}
            </span>
          </div>
          <div className="center-info-item">
            <span className="center-info-item-name">Sector</span>
            <span className="text-bold">
              {this.props?.center?.address?.sector}
            </span>
          </div>
          <div className="center-info-item">
            <span className="center-info-item-name">Cell</span>
            <span className="text-bold">
              {this.props?.center?.address?.cell}
            </span>
          </div>
          <div className="center-info-item">
            <span className="center-info-item-name">Village</span>
            <span className="text-bold">
              {this.props?.center?.address?.village}
            </span>
          </div>
          <div className="center-info-item">
            <span className="center-info-item-name">Contact</span>
            <span className="text-bold">
              {this.props?.center?.contactNumber}
            </span>
          </div>
          <div className="center-info-item">
            <span className="center-info-item-name">Email Address</span>
            <span className="text-bold">
              <a href={`mailto:${this.props?.center?.email}`} target="_blank">
                {this.props?.center?.email}
              </a>
            </span>
          </div>
        </div>
        <div className="card-footer">
          <Button
            text={language[this.props.defaultLanguage].view_address_map}
            onPress={() =>
              window.open(
                `https://maps.google.com?q=${this.props.center?.address?.latitude},${this.props.center?.address?.longitude} `
              )
            }
          />
        </div>
      </>
    );
  }
}

const mapStateProps = (state) => {
  const { defaultLanguage } = state.Language;
  return { defaultLanguage };
};

export default connect(mapStateProps)(CenterInfo);
