import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { withRouter } from "../../utils/withRouter";
import { DashboardHeader } from "../../components/Header";
import BloodBanks from "../../components/BloodBank/BloodBanks";

class BloodBankScreen extends React.Component {
  render() {
    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <DashboardHeader
              title={language[this.props.defaultLanguage].blood_banks}
            />
            <BloodBanks />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(BloodBankScreen));
