import React from "react";
import { connect } from "react-redux";
import language from "../../language";

class VolumeColleted extends React.Component {
  render() {
    return (
      <div className="card">
        <div className="card-body">
          <h3>{language[this.props.defaultLanguage].volume}</h3>
          <div>{this.props.volume}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;

  return { defaultLanguage };
};

export default connect(mapStateToProps)(VolumeColleted);
