import React from "react";

import { connect } from "react-redux";
import AuthFormLayout from "../../Layout/AuthFormLayout";
import { Input } from "../Input";
import { Button } from "../Button";
import { Link } from "react-router-dom";
import { Slides } from "../Slides";
import screened from "../../assets/screened-colored.png";
import giveBlood from "../../assets/giveBlood.png";
import register from "../../assets/register.png";
import language from "../../language";
import validateEmail from "../../utils/validateEmail";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: props.email || "",
      isSubmitting: false,
      error: {},
      otpSent: false,
    };
  }

  componentDidMount() {
    if (this.props.email !== "") {
      this.onSubmit();
    }
  }

  onChangeText = (field, e) => {
    let { error } = this.state;

    delete error[field];

    this.setState({ [field]: e.target.value });
  };

  validateForm = () => {
    let { error, email } = this.state;

    if (email === "") {
      error.email = language[this.props.defaultLanguage].email_required;
    } else if (!validateEmail(email)) {
      error.email = language[this.props.defaultLanguage].invalid_email_required;
    }

    this.setState({ error });
  };

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({ isSubmitting: true });

      const { email } = this.state;

      const options = {
        method: "POST",
        url: `${API_URL}/account/requestpasswordreset`,
        data: {
          email,
        },
      };

      axios(options)
        .then((data) => {
          console.log(data.data);
          this.setState({
            isSubmitting: false,
            successMessage: data.data.message,
          });

          // toastMessage("success", data.data.message);
          // window.location.href = `/reset-password/${this.props.type}/${data.data.token}/`;
        })
        .catch((error) => {
          this.setState({ isSubmitting: false, successMessage: undefined });

          toastMessage("error", error);
        });
    }
  };

  render() {
    return (
      <AuthFormLayout>
        <div className="row">
          <div className="col-md-7 hidden-sm hidden-xs">
            <div className="auth-form-desc">
              <Slides
                data={[
                  {
                    title: "Blood Donation Tracking",
                    description:
                      "Simplifying the donation process, providing a clear and concise overview for effective monitoring.",
                    image: giveBlood,
                  },
                  {
                    title: "Donation Analytics",
                    description:
                      "Comprehensive analytics on donation contribution and usage trends per blood centers and respective sites.",
                    image: register,
                  },
                  {
                    title: "Blood Testing & Eligibility",
                    description:
                      "Personalized feedback on blood testing results and eligibility. Streamline donation process with quality monitoring.",
                    image: screened,
                  },
                ]}
                viewSize={1}
              />
            </div>
          </div>
          <div className="col-md-5">
            <div className="auth-form">
              <div className="title">
                <h3 className="text-primary">
                  {this.state.otpSent
                    ? language[this.props.defaultLanguage].verify_account
                    : language[this.props.defaultLanguage].change_password}
                </h3>
                {this.state.otpSent && (
                  <p>
                    {language[this.props.defaultLanguage].enter_otp_received_on}
                  </p>
                )}
              </div>
              {this.state.successMessage && (
                <div className="alert alert-success">
                  <span>{this.state.successMessage}</span>
                </div>
              )}
              <form>
                <Input
                  label={language[this.props.defaultLanguage].email_address}
                  placeholder={
                    language[this.props.defaultLanguage].email_placeholder
                  }
                  required
                  leftIcon="mail"
                  value={this.state.email}
                  error={this.state.error.email}
                  onChange={(e) => this.onChangeText("email", e)}
                  helperText={
                    language[this.props.defaultLanguage].receive_otp_description
                  }
                />
                {this.state.otpSent && (
                  <Input
                    label={
                      language[this.props.defaultLanguage].otp_code_six_digits
                    }
                    placeholder={
                      language[this.props.defaultLanguage].enter_otp_code
                    }
                    required
                    leftIcon="lock"
                    value={this.state.otpCode}
                    error={this.state.error.otpCode}
                    onChange={(e) => this.onChangeText("otpCode", e)}
                    helperText={
                      language[this.props.defaultLanguage]
                        .receive_otp_description
                    }
                  />
                )}
                <Button
                  text={language[this.props.defaultLanguage].submit}
                  className="w-100"
                  isSubmitting={this.state.isSubmitting}
                  onPress={this.onSubmit.bind(this)}
                />
                <br />
                <div className="footer">
                  <span>
                    <Link to="/login">
                      {language[this.props.defaultLanguage].login_text}
                    </Link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </AuthFormLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ChangePassword);
