import {
  generateRandomNumber,
  generateRandomString,
} from "../../../../utils/random";
import { getStorage } from "../../../../utils/storage";
import { fetchBloodTestHistory } from "../../../../utils/queries/bloodTestHistory";
import bloodTestQuery from "../../../../utils/queries/bloodTest/bloodTestQuery";
import toastMessage from "../../../../utils/toastMessage";
import axios from "axios";

const getBloodTesting = async (that, donation) => {
  try {
    that.setState({
      isLoading: true,
      loadingMessage: "Loading blood testing...",
    });
    const bloodTest = await bloodTestQuery(that.props.defaultLanguage, {
      donation,
    });

    console.log("bloodTest", bloodTest);

    let bloodGrouping = {},
      serology = {};

    if (bloodTest.length > 0) {
      const data = await fetchBloodTestHistory(that.props.defaultLanguage, {
        bloodTest: bloodTest[0]._id,
      });

      data?.forEach((el) => {
        if (el.questionnaireType === "bloodGrouping") {
          bloodGrouping = el;
        }

        if (el.questionnaireType === "serology") {
          serology = el;
        }
      });

      that.setState({
        isLoading: false,
      });

      console.log({ bloodGrouping, serology });

      return { bloodGrouping, serology };
    }
  } catch (error) {
    that.setState({
      isLoading: false,
    });
    return toastMessage("error", error);
  }
};

const handleTestResult = (res = [], type) => {
  let textRes = "";
  res.forEach((el) => {
    if (el.printingEnabled && el.answer) {
      if (el.answer.value && el.settings && el.settings[el.answer.value]) {
        textRes +=
          (el.settings[el.answer.value]?.abbreviation || el.questionText) +
          ":" +
          el.answer.value +
          " ";
      } else if (el.answer.value) {
        textRes += el.questionText + ":" + el.answer.value + " ";
      } else if (typeof el.answer === "string") {
        textRes += el.questionText + ":" + el.answer + " ";
      }
    }
  });

  return textRes.trim() || `Missing ${type} test result`;
};

const validateForm = (that) => {
  let { error, donation } = that.state;

  console.log("I am here donation", donation);

  if (!donation?._id) {
    error.donation = "Donation is required";
  }

  that.setState({ error });
};

export const onGenerateLabel = async (that, item, type) => {
  await validateForm(that);
  console.log(that.state.donation);
  console.log({ state: that.state, props: that.props });
  // if (Object.keys(that.state.error).length === 0) {
  if (that.state.donation?._id) {
    that.setState({
      ["isSubmitting_" + item.code]: true,
    });

    let { donation } = that.state;

    const user = await getStorage();

    let { bloodGrouping = {}, serology = {} } =
      (await getBloodTesting(that, donation?._id || donation)) || {};

    let requestedBody = {
      donation: donation._id,
      addedBy: user.id,
      component: item.component._id,
      componentProfile: item.componentProfile._id,
      componentProfileCode: item.componentProfile.code,
      name: item.name,
      code: item.code,
      volume: item.volume,
      bloodGroup: bloodGrouping.bloodGroup || "unspecified",
      bloodGroupingResult: handleTestResult(
        bloodGrouping.questionnaire,
        "bloodGrouping"
      ),
      serologyResult: handleTestResult(serology.questionnaire, "serology"),
      center: donation?.center?._id,
      centerSite: donation?.centerSite?._id,
      status: "pending",
      type,
      productId: item._id,
    };

    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BBMS_BASE_API}/inventory`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
      data: requestedBody,
    };
    return axios(options)
      .then((data) => {
        that.setState({
          ["isSubmitting_" + item.code]: false,
          ["isGenerated_" + item.code]: true,
          selectedItem: data.data,
        });

        toastMessage(
          "success",
          `Label for ${item.code} ${item.name} was generated successfully`
        );

        //   that.getComponentSeparations();
        //   that.getComponentTransformation();

        return data.data;
      })
      .catch((error) => {
        console.log(error);
        that.setState({
          ["isSubmitting_" + item.code]: false,
          ["isGenerated_" + item.code]: false,
        });
        toastMessage("error", error);

        return;
      });
  }
};
