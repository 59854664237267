export default [
  {
    label: "Unspecified",
    value: "unspecified",
  },
  {
    label: "A+",
    value: "A+",
    color: "yellow",
  },
  {
    label: "A-",
    value: "A-",
    color: "yellow",
  },
  {
    label: "B+",
    value: "B+",
    color: "white",
  },
  {
    label: "B-",
    value: "B-",
    color: "white",
  },
  {
    label: "AB+",
    value: "AB+",
    color: "pink",
  },
  {
    label: "AB-",
    value: "AB-",
    color: "pink",
  },
  {
    label: "O+",
    value: "O+",
    color: "green",
  },
  {
    label: "O-",
    value: "O-",
    color: "green",
  },
];

export const aboGroups = [
  {
    label: "Unspecified",
    value: "unspecified",
  },
  {
    label: "A",
    value: "A",
  },
  {
    label: "B",
    value: "B",
  },

  {
    label: "AB",
    value: "AB",
  },
  {
    label: "O",
    value: "O",
  },
];

export const statuses = [
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Tested",
    value: "tested",
  },

  {
    label: "Block Donation",
    value: "blockDonation",
  },
];
