// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-header {
  border-bottom: 1px solid var(--text-color-10);
}

.modal-footer {
  padding-right: 1rem !important;
}

.modal_title {
  font-weight: var(--font-weight-md);
  font-size: var(--font-size-md);
}

.confirmation-modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

/* .modal-content {
  max-height: 100vh;
  overflow-y: auto;
} */

.confirmation-modal-header span {
  color: var(--text-color-10);
  font-size: 40px;
}

.confirmation-modal-header.failed span {
  color: var(--error-color);
}

.confirmation-modal-header.success span {
  color: var(--primary-color);
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Modal/styles.css"],"names":[],"mappings":"AAAA;EACE,6CAA6C;AAC/C;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,kCAAkC;EAClC,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;;;GAGG;;AAEH;EACE,2BAA2B;EAC3B,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".modal-header {\r\n  border-bottom: 1px solid var(--text-color-10);\r\n}\r\n\r\n.modal-footer {\r\n  padding-right: 1rem !important;\r\n}\r\n\r\n.modal_title {\r\n  font-weight: var(--font-weight-md);\r\n  font-size: var(--font-size-md);\r\n}\r\n\r\n.confirmation-modal-header {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  margin-bottom: 1rem;\r\n}\r\n\r\n/* .modal-content {\r\n  max-height: 100vh;\r\n  overflow-y: auto;\r\n} */\r\n\r\n.confirmation-modal-header span {\r\n  color: var(--text-color-10);\r\n  font-size: 40px;\r\n}\r\n\r\n.confirmation-modal-header.failed span {\r\n  color: var(--error-color);\r\n}\r\n\r\n.confirmation-modal-header.success span {\r\n  color: var(--primary-color);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
