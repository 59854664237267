import language from "../language";

export const hospitals = (lng = "english") => {
  return [
    {
      label: language[lng].public,
      value: "Public",
    },
    {
      label: language[lng].private,
      value: "Private",
    },
  ];
};
