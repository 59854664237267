// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Track */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: var(--text-color-10);
  border-radius: var(--border-radius);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary-color-10);
}
`, "",{"version":3,"sources":["webpack://./src/app/common/styles/scrollbar.styles.css"],"names":[],"mappings":"AAAA,UAAU;AACV;EACE,UAAU;EACV,WAAW;AACb;;AAEA,WAAW;AACX;EACE,sCAAsC;EACtC,mCAAmC;AACrC;;AAEA,oBAAoB;AACpB;EACE,yCAAyC;AAC3C","sourcesContent":["/* Track */\r\n::-webkit-scrollbar {\r\n  width: 8px;\r\n  height: 8px;\r\n}\r\n\r\n/* Handle */\r\n::-webkit-scrollbar-thumb {\r\n  background-color: var(--text-color-10);\r\n  border-radius: var(--border-radius);\r\n}\r\n\r\n/* Handle on hover */\r\n::-webkit-scrollbar-thumb:hover {\r\n  background-color: var(--primary-color-10);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
