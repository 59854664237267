import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { Table } from "../../Table";
import { Modal, DeleteModal } from "../../Modal";
import { defineRole } from "../../../utils/handleAccessRoles";
import { getStorage } from "../../../utils/storage";
import { ROLE_APPLICATION_CONFIGURATION } from "../../../constants/ROLES";
import icons from "../../../constants/icons";
import {
  deleteConfigurations,
  fetchConfigurationsData,
} from "../../../utils/queries/configurations/configurationsQuery";
import NewConfiguration from "./NewConfiguration";

class ListConfigurations extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    totalPageCount: 10,
    delete_password: "",
    error: {},
    user: {},
    data: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    await this.getData(true);
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({ error, [field]: e.target.value });
  }

  getData = async (isLoading) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const data = await fetchConfigurationsData(defaultLanguage);

      this.setState({
        data: Object.keys(data).length > 0 ? data : {},
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].donation_interval,
        key: "donationInterval",
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];

    return headers;
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      let { data } = this.state;

      data.configuration.donationInterval = "";

      await deleteConfigurations(this.props.defaultLanguage, {
        id: this.state.data._id,
        configuration: data?.configuration,
      });

      this.setState({ isDeleting: false });

      this.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      this.setState({
        isDeleting: false,
      });
    }
  };

  render() {
    const { add, account, edit, delete_, view, configuration } =
      language[this.props.defaultLanguage];

    return (
      <>
        <Table
          data={
            this.state.data?.configuration
              ? [this.state.data?.configuration]
              : []
          }
          isLoading={this.state.isLoading}
          headers={this.returnTableHeaders()}
          showAdd
          addButtonText={add}
          handleAddPressed={this.handleShowModal.bind(
            this,
            "showModal",
            configuration
          )}
          rowPress={(item) =>
            this.handleShowModal("showModal", item.name, item)
          }
          actions={[
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_APPLICATION_CONFIGURATION,
                operation: "delete",
              }),
              icon: icons.delete,
              name: delete_,
              onPress: this.handleShowModal.bind(
                this,
                "showDeleteModal",
                delete_
              ),
            },
          ]}
        />

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
        >
          <NewConfiguration
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            getData={this.getData.bind(this)}
            {...this.state.selectedData}
            config={this.state.data}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={() => this.onDelete(this.state.selectedData)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selectedData.name}
            getData={this.getData.bind(this)}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ListConfigurations);
