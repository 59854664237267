import language from "../language";
import { clearStorage, getStorage } from "../utils/storage";

const onLogout = async () => {
  const user = await getStorage();
  await clearStorage();

  window.location.href = user.role === "donor" ? "/login" : "/admin-login";
};

const nonAuthMenus = (lng = "english") => [
  {
    name: language[lng].home,
    route: "/landing",
    access: ["public"],
  },
  {
    name: language[lng].who_can_give_blood,
    route: "/landing#giveBlood",
    access: ["public"],
  },
  {
    name: language[lng].how_works,
    route: "/landing#howWorks",
    access: ["public"],
  },
  {
    name: language[lng].hospitals_or_sites,
    route: "/landing#findSites",
    access: ["public"],
  },
  {
    name: language[lng].partners,
    route: "/landing#partners",
    access: ["public"],
  },
  {
    name: language[lng].contact_us,
    route: "/landing#contactUs",
    access: ["public"],
  },
];

const authMenus = (lng = "english") => {
  return [
    // {
    //   name: language[lng].dashboard,
    //   icon: "grid_view",
    //   onPress: () => (window.location.href = "/dashboard/home"),
    // },
    {
      name: language[lng].settings,
      icon: "settings",
      onPress: () => (window.location.href = "/dashboard/settings"),
    },
    {
      name: language[lng].logout,
      onPress: () => onLogout(),
      icon: "logout",
    },
  ];
};

export { nonAuthMenus, authMenus };
