import React from "react";
import { connect } from "react-redux";
import { Input } from "../Input";
import language from "../../language";
import { ListItem } from "../List";
import requestQuery from "../../utils/queries/requestQuery.js";
import icons from "../../constants/icons";
import { Button } from "../Button";
import toastMessage from "../../utils/toastMessage";
import ModalContainer from "../Modal/Modal";
import RequestInfo from "./requestInfo/index.js";
import { requestActionMenus } from "./actionsMenus/index.js";
class SearchRequest extends React.Component {
  state = {
    error: {},
    data: [],
    isDoneSearching: false,
    requestNo: "",
  };

  validateForm() {
    let { error, requestNo } = this.state;

    if (requestNo === "") {
      error.requestNo =
        language[this.props.defaultLanguage].request_number_required;
    }

    this.setState({ error });
  }

  handleSearch = async () => {
    await this.validateForm();

    try {
      if (Object.keys(this.state.error).length > 0) return;

      const { requestNo } = this.state;

      this.setState({
        isSubmitting: true,
      });

      const { data } = await requestQuery(this.props.defaultLanguage, {
        requestNo: requestNo?.replace(/\s+/g, ""),
      });

      let requestInfo = {};

      if (data.length === 1) {
        requestInfo = data[0];
        this.handleShowModal(
          "showModal",
          language[this.props.defaultLanguage].actions
        );
      } else {
        toastMessage("error", "Request info couldn't find");
      }
      this.setState({
        requestInfo,
        isSubmitting: false,
      });
    } catch (error) {
      this.setState({ isSubmitting: false });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e.target ? e.target.value : e;

    delete error[field];

    this.setState({
      [field]: inputValue?.toUpperCase(),
      isDoneSearching: false,
    });
  };

  handleShowModal(modal, title, item = {}) {
    this.setState({
      [modal]: true,
      modalTitle: title,
      ...item,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  render() {
    return (
      <>
        <div className="card-body">
          {this.state.error.errorMessage && (
            <div className="alert alert-danger">
              <span>{this.state.error.errorMessage}</span>
            </div>
          )}
          <Input
            placeholder={
              language[this.props.defaultLanguage].request_number_placeholder
            }
            label={language[this.props.defaultLanguage].request_number}
            value={this.state.requestNo}
            onChange={(e) => this.onChangeText("requestNo", e)}
            error={this.state.error.requestNo}
            leftIcon={icons.request}
          />

          {this.props.handleCloseModal && (
            <Button
              className="btn-default"
              text={language[this.props.defaultLanguage].cancel}
              onPress={this.props.handleCloseModal}
            />
          )}
          <Button
            text={language[this.props.defaultLanguage].search}
            isSubmitting={this.state.isSubmitting}
            onPress={this.handleSearch.bind(this)}
          />
        </div>

        <ModalContainer
          title={language[this.props.defaultLanguage].actions}
          show={this.state.showModal}
          handleClose={() => this.handleCloseModal("showModal")}
        >
          <div className="card-body">
            <div className="row">
              {requestActionMenus({ that: this, props: this.props }).map(
                (el, i) => {
                  return (
                    <div
                      key={i}
                      style={{ marginBottom: "1rem" }}
                      className={"col-md-12"}
                    >
                      <ListItem
                        icon={el.icon}
                        name={el.name}
                        rightIcon={icons.chevronRight}
                        className="list-item-card"
                        onPress={() => el.onPress(this.state.requestInfo)}
                      />
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </ModalContainer>

        <ModalContainer
          title={this.state.modalTitle}
          show={this.state.showRequestInfoModal}
          handleClose={() => this.handleCloseModal("showRequestInfoModal")}
          size="fullscreen"
        >
          <div className="container" style={{ marginTop: "1rem" }}>
            <RequestInfo requestId={this.state?.requestInfo?._id} />
          </div>
        </ModalContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(SearchRequest);
