import React from "react";
import "./styles.css";

const Checkbox = (props) => {
  return (
    <div className="checkbox-container">
      <label style={{ cursor: "pointer" }}>
        <input
          type="checkbox"
          onChange={props.onCheck}
          checked={props.checked}
        />
        <div>
          {props.title && <h3 className="mb-3">{props.title}</h3>}
          <span style={{ textTransform: "capitalize" }}>{props.label}</span>
          <div>{<p className="text-muted">{props.description}</p>}</div>
        </div>
      </label>
    </div>
  );
};
export default Checkbox;
