import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../../../utils/withRouter";
import { DashboardHeader } from "../../../../components/Header";
import language from "../../../../language";
import ComponentLabeling from "../../../../components/Production/ComponentLabeling/Labeling";

class ComponentLabelingScreen extends React.Component {
  render() {
    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <DashboardHeader
              title={language[this.props.defaultLanguage].component_labeling}
            />
            <ComponentLabeling {...this.props.routeParams} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(ComponentLabelingScreen));
