import { Route, Routes, Outlet } from "react-router-dom";
import SplashScreen from "../screens/common/SplashScreen";
import LandingScreen from "../screens/common/LandingScreen";
import SitesLandingScreen from "../screens/common/SitesLandingScreen";
import CentersLandingScreen from "../screens/common/CentersLandingScreen";
import Common from "../screens/common";
// import Donor from "../screens/common/Donor";
const routes = [
  {
    path: "/",
    element: <SplashScreen />,
  },
  {
    path: "/landing",
    element: <LandingScreen />,
  },
  {
    path: "/centers",
    element: <CentersLandingScreen />,
  },
  {
    path: "/sites/:centerId",
    element: <SitesLandingScreen />,
  },
  {
    path: "/common/*",
    element: <Common />,
  },
];

export default () => {
  return (
    <>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
      <Outlet />
    </>
  );
};
