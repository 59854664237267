import { ContactUs } from "../../components/Landing/ContactUs";
import { Footer } from "../../components/Landing/Footer";
import { Partners } from "../../components/Landing/Partners";
import Navbar from "../../components/Navbar/Navbar";
import LandingPageLayout from "./LandingPageLayout";

const PageWrapper = (props) => {
  return (
    <>
      <Navbar {...props} isFixed isShadowActive />
      <div className="page-landing-wrapper" id="landingContainer">
        <LandingPageLayout size={props.size} id={props.id}>
          {props.children}
        </LandingPageLayout>
        <Footer />
      </div>
    </>
  );
};

export default PageWrapper;
