import React from "react";
import { Switch } from "../Input";
import TestedBy from "./TestedBy";
import BloodGroup from "./BloodGroup";
import VolumeColleted from "./VolumeColleted";
import BarCode from "./BarCode";

class TestMoreInfo extends React.Component {
  render() {
    return (
      <div className="col-md-4">
        <div className="card mb-3">
          <Switch
            label="Activate Donation"
            onCheck={this.props.handleActivateDonation}
            checked={this.props.activateDonation}
          />
        </div>
        <div className="card mb-3">
          <TestedBy
            testedBy={this.props.testedBy}
            currentDate={this.props.currentTime}
          />
        </div>
        <div className="card mb-3">
          <BloodGroup
            bloodGroup={this.props?.bloodGroup?.value}
            testId={this.props._id}
            edit={this.props._id}
          />
        </div>
        <div className="card mb-3">
          <VolumeColleted volume={this.props?.donation?.bloodInfo?.volume} />
        </div>
        <div className="card mb-3">
          {this.props.donation._id && (
            <BarCode donationId={this.props.donation._id} />
          )}
        </div>
      </div>
    );
  }
}

export default TestMoreInfo;
